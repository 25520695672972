import { useEffect, useState } from 'react';
import {
  showErrorModal,
  showWarningModal
} from '@/shared/design-system/thermal-ceramics/components/modal/promise/confirmation';
import { Modal } from '@/shared/design-system/thermal-ceramics/components/modal';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';

export function confirmMeasurementChange() {
  return showWarningModal({
    body: () => <>
      <Modal.Title>Measurement system change</Modal.Title>
      <Modal.Description>Temperature and measurement fields will be cleared so you can enter new values. Continue?</Modal.Description>
    </>,
    footer: (resolve, reject) => <>
      <Button modifier={ButtonModifier.SECONDARY} onClick={reject}>No</Button>
      <Button onClick={resolve}>Yes</Button>
    </>
  });
}