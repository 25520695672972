import { MaterialInformation } from '@/app/api/morgan-thermal/material';
import { Options as PopperOptions } from '@popperjs/core/lib/types';
import React, { Fragment, useState } from 'react';
import { usePopper } from 'react-popper';
import { Menu, Popover, Transition } from '@headlessui/react';
import classNames from './styles.module.css';
import { createPortal } from 'react-dom';
import enterAnimationClasses from '@/shared/design-system/thermal-ceramics/animations/enter/styles.module.css';
import { clsx } from 'clsx';
import leaveAnimationClasses from '@/shared/design-system/thermal-ceramics/animations/leave/styles.module.css';
import { MaterialDetails } from '@/app/details/material';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { app } from '@/app';
import { getMaterial } from '@/app/api/morgan-thermal/material/get';
import { Spinner } from '@/shared/design-system/thermal-ceramics/components/spinner';
import { observer } from 'mobx-react-lite';
import { ScrollArea } from '@/shared/design-system/thermal-ceramics/components/scroll-area';
import { VisuallyHidden } from '@/shared/design-system/thermal-ceramics/components/visually-hidden';

type MaterialDetailsPopupProps = {
  id: MaterialInformation['id'],
};

const popperOptions: PopperOptions = {
  placement: 'right-start',
  strategy: 'fixed',
  modifiers: [
    {
      name: 'flip',
      options: {
        boundary: 'document',
        fallbackPlacements: ['right-start', 'left-start'],
      },
    },
    {
      name: 'offset',
      options: {
        offset: [0, 4],
      },
    },
  ],
};

export const MaterialDetailsPopup = observer((props: MaterialDetailsPopupProps) => {
  const { id } = props;
  const [material] = useState<SynchronizableValue<MaterialInformation | null>>(() => new SynchronizableValue<MaterialInformation | null>(null, () => {
    return app.morganThermalAPI.fetch(getMaterial(id));
  }));
  const { value } = material;
  const [popoverReference, setPopoverReference] = useState<HTMLDivElement | null>(null);
  const [popoverElement, setPopoverElement] = useState<HTMLDivElement | null>(null);
  const {
    styles: { popper: popoverStyle },
    attributes: { popper: popoverAttributes}
  } = usePopper(popoverReference, popoverElement, popperOptions);

  return (
    <Popover className={classNames.materialDetailsPopup} as="div" >
      {({ open }) => (
        <>
          <div className={classNames.buttonContainer} ref={setPopoverReference}>
            <Popover.Button
              className={classNames.button}
              data-materials-popup-button
              onClick={() => {
                if (!material.value) material.synchronize();
              }}
            >
              <span className={classNames.icon} aria-hidden="true">?</span>
              <VisuallyHidden>Toggle</VisuallyHidden>
            </Popover.Button>
          </div>
          {createPortal((
            <div className={classNames.popover} ref={setPopoverElement} style={popoverStyle} {...popoverAttributes}>
              <Transition
                enter={enterAnimationClasses.enter}
                enterFrom={clsx(
                  enterAnimationClasses.enterFromOpacity,
                  enterAnimationClasses.enterFromTransform,
                )}
                enterTo={clsx(
                  enterAnimationClasses.enterToOpacity,
                  enterAnimationClasses.enterToTransform,
                )}

                leave={leaveAnimationClasses.leave}
                leaveFrom={clsx(
                  leaveAnimationClasses.leaveFromOpacity,
                  leaveAnimationClasses.leaveFromTransform,
                )}
                leaveTo={clsx(
                  leaveAnimationClasses.leaveToOpacity,
                  leaveAnimationClasses.leaveToTransform,
                )}
              >
                <ScrollArea className={classNames.scrollArea} style={{ maxHeight: 320 }}>
                  {material.isSynchronizationInProgress && <Spinner className={classNames.spinner}/>}
                  {value && <MaterialDetails material={value}/>}
                </ScrollArea>
              </Transition>
            </div>
          ), document.body)}
        </>
      )}
    </Popover>
  );
});