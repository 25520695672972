import { createEndpoint } from '@/shared/models/api';
import { Informative } from '@/app/api/morgan-thermal';
import { CompanyInformation } from '@/app/api/morgan-thermal/company';

export type CompanyUpdate = {
  id: number,
  name: string;
  contactPhone: string;
  secondaryPhone?: string;
  address: string;
  additionalAddress?: string;
  city: string;
  zip: string;
  industryId: number | null;
  countryId: number;
  stateProvinceId: number | null;
  domains: string[];
};

export const updateCompany = createEndpoint<Informative & { id: CompanyInformation['id']}>(({ fetch }) => (data: CompanyUpdate) => {
  return fetch(`/Company/Update/${data.id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
});