import { PropsWithChildren } from 'react';

import classNames from './styles.module.css';

export const Actions = ({ children }: PropsWithChildren) => {
  return (
    <div className={classNames.actions}>
      {children}
    </div>
  );
};