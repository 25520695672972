import { HookFormField, FieldInputProps } from '@/shared/libs/hook-form';
import { ValueMeasurement } from '@/app/form/fields/material/helpers/value-measurement';
import { numberOrEmpty } from '@/shared/libs/yup/validations';

export const specificHeatTemperatureSchema = numberOrEmpty().label('Density');

export const SpecificHeatTemperatureHookFormField = (props: FieldInputProps) => (
  <HookFormField.Input
    label="Specific Heat Temperature"
    extendProps={(props) => ({
      ...props,
      endSlot: (
        <ValueMeasurement
          imperial={<>&deg;F</>}
          metric={<>&deg;C</>}
        />
      )
    })}
    {...props}
  />
);