import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

export const useOnFieldChange = (form: UseFormReturn<any>, fieldName: string, callback: (changedValue: any) => void, dependencies: any[] = []) => {
  const { watch, getValues } = form;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === fieldName && type === 'change') callback(getValues(name));
    });

    return () => subscription.unsubscribe();
  }, [watch, fieldName, ...dependencies]);
};