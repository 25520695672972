import { HTMLAttributes } from 'react';

import { ClickableArea } from '@/shared/design-system/thermal-ceramics/components/clickable-area';
import { LogoutIcon } from '@/shared/design-system/thermal-ceramics/icons';

import classNames from './styles.module.css';

export type LogoutProps = HTMLAttributes<HTMLButtonElement>;

export function Logout(props: LogoutProps) {
  return (
    <ClickableArea as='button' className={classNames.logout} {...props}>
      <LogoutIcon className={classNames.icon}/>
    </ClickableArea>
  );
}