import { createColumnHelper } from '@tanstack/react-table';
import { FillLink } from '@/shared/design-system/thermal-ceramics/components/fill-link';
import { MaterialListingItem } from '@/app/api/morgan-thermal/material';
import { MeasurementSystem } from '@/app/measurement-systems/model';
import { createMeasurementSystemBasedColumn } from '@/app/listing/helpers/measurement-system-based-column';
import { MaterialOrderByOptions } from '@/app/api/morgan-thermal/material/search';
import { roundDecimals } from '@/shared/helpers/number';

const columnHelper = createColumnHelper<MaterialListingItem>();

export const createColumns = (measurementSystem: MeasurementSystem) => [
  columnHelper.accessor('name', {
    header: 'Material',
    meta: {
      orderBy: MaterialOrderByOptions.Name,
    },
    cell: info => <FillLink to={`/materials/${info.row.original.id}`}>{info.renderValue()}</FillLink>,
  }),
  columnHelper.accessor('region', {
    header: 'Region',
    cell: info => info.renderValue(),
    meta: {
      orderBy: MaterialOrderByOptions.Region,
    },
  }),
  createMeasurementSystemBasedColumn<MaterialListingItem>(measurementSystem, 'density', {
    header: 'Density',
    modifier: (value) => roundDecimals(value, measurementSystem === MeasurementSystem.METRIC ? 0 : 1),
    meta: {
      orderBy: MaterialOrderByOptions.Density,
    },
  }),
  createMeasurementSystemBasedColumn<MaterialListingItem>(measurementSystem, 'classificationTemperature', {
    header: 'Classification Temperature',
    modifier: roundDecimals,
    meta: {
      orderBy: MaterialOrderByOptions.ClassificationTemperature,
    },
  }),
  createMeasurementSystemBasedColumn<MaterialListingItem>(measurementSystem, 'useLimit', {
    header: 'Use Limit',
    modifier: roundDecimals,
    meta: {
      orderBy: MaterialOrderByOptions.UseLimit,
    },
  }),
  createMeasurementSystemBasedColumn<MaterialListingItem>(measurementSystem, 'specificHeat', {
    header: 'Specific Heat',
    modifier: (value) => roundDecimals(value, 2),
    meta: {
      orderBy: MaterialOrderByOptions.SpecificHeat,
    },
  }),
];