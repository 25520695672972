import { CompanyInformation } from '@/app/api/morgan-thermal/company';

export const createInitialValues = (company?: CompanyInformation) => ({
  name: company?.name ?? '',
  industryId: company?.industryId ?? null,
  city: company?.city ?? '',
  zip: company?.zip ?? '',
  address: company?.address ?? '',
  additionalAddress: company?.additionalAddress ?? '',
  contactPhone: company?.contactPhone ?? '',
  secondaryPhone: company?.secondaryPhone ?? '',
  countryId: company?.countryId ?? null,
  stateProvinceId: company?.stateProvinceId ?? null,
  domains: company?.domains.length ? company.domains.map((value) => ({ value })) : [{ value: '' }],
});