export enum UserRole {
  User = 1,
  GlobalAdministrator = 2,
  CompanyAdmin = 3,
  GlobalCompanyManagerAdmin = 4,
}

export type UserInformation = {
  id: number,
  roleId: UserRole,
  role: string,
  companyId: number,
  name: string,
  email: string,
  job?: string,
  address: string,
  additionalAddress?: string,
  city: string,
  contactPhone: string,
  secondaryPhone?: string,
  countryId: number,
  country: string,
  stateProvinceId?: number,
  stateProvince: string,
  preferMetric: boolean,
  zip: string,
  createdDate: string,
  lastLoginDate?: string,
}

export type UserListingItem = {
  id: number,
  roleId: number,
  role: string,
  name: string,
  email: string,
  isActive: boolean,
  createdDate: string,
  lastLoginDate: string,
};

export type UserListing = Array<UserListingItem>;
