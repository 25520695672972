import { PropsWithChildren } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import { clsx } from 'clsx';
import styles from '@/app/routing/breadcrumbs/crumb/styles.module.css';

export type CrumbProps = PropsWithChildren & {
  isStatic?: boolean,
  to?: string,
  pathname: string,
};

export function Crumb({ pathname, to, isStatic = false, children }: CrumbProps ) {
  const isCurrentPage = useMatch(pathname);

  if (isCurrentPage || isStatic) return <span className={clsx(styles.crumb, { [styles.current]: isCurrentPage })} aria-current="page">{children}</span>;

  return <NavLink className={clsx(styles.crumb, styles.link)} to={to ?? pathname}>{children}</NavLink>
}