import React, {
  AriaAttributes, ChangeEvent,
  ComponentType, FocusEvent, ForwardedRef, forwardRef,
  Fragment, ReactElement,
  ReactNode, Ref,
  useRef,
  useState
} from 'react';
import { usePopper } from 'react-popper';
import { clsx } from 'clsx';
import { Combobox, Transition } from '@headlessui/react';
import { ScrollArea } from '@/shared/design-system/thermal-ceramics/components/scroll-area';
import { Input, InputProps } from '@/shared/design-system/thermal-ceramics/components/form/input';
import { VisuallyHidden } from '@/shared/design-system/thermal-ceramics/components/visually-hidden';
import { useSyncRefs } from '@/shared/hooks/use-sync-refs';
import { Spinner } from '@/shared/design-system/thermal-ceramics/components/spinner';
import { CheckIcon, ChevronDownIcon } from '@/shared/design-system/thermal-ceramics/icons';
import classNames from '@/shared/design-system/thermal-ceramics/components/form/select/styles.module.css';

type RenderInputParameters = {
  Element: ComponentType<InputProps>,
  props: InputProps,
};

export type SelectProps<TType> = {
  value?: TType,
  options: TType[],
  disabled?: boolean,
  hasAllOption?: boolean,
  inProgress?: boolean,
  placeholder?: string,
  emptyLabel?: string,
  renderInput?: (parameters: RenderInputParameters) => ReactNode,
  createKey: (option: TType) => string | number,
  createOptionLabel: (option: TType) => string,
  onBlur?: (event: FocusEvent) => void,
  onChange?: (option: TType) => void,
  'aria-invalid'?: AriaAttributes['aria-invalid'],
  'aria-describedby'?: AriaAttributes['aria-describedby'],
};

const popperOptions = {
  modifiers: [
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['top', 'bottom'],
      },
    },
    {
      name: 'offset',
      options: {
        offset: [0, 4],
      },
    },
  ],
};

export const Select = forwardRef(function <TType>({
  options = [],
  value,
  disabled,
  placeholder,
  emptyLabel,
  hasAllOption,
  inProgress = false,
  renderInput,
  createKey,
  createOptionLabel,
  onBlur,
  onChange,
  ...selectProps
}: SelectProps<TType>, ref: ForwardedRef<HTMLDivElement>) {
  const comboboxElement = useRef<HTMLDivElement>(null);
  const toggleButton = useRef<HTMLButtonElement>(null);
  const input = useRef<HTMLInputElement>(null);
  const allComboboxRefs = useSyncRefs(comboboxElement, ref);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [popoverReference, setPopoverReference] = useState<HTMLDivElement | null>(null);
  const [popoverElement, setPopoverElement] = useState<HTMLDivElement | null>(null);

  const { styles: { popper: popoverStyle }, attributes: { popper: popoverAttributes} } = usePopper(popoverReference, popoverElement, popperOptions);

  const filteredOptions = (searchQuery.length > 0) ? options.filter((option) => {
    return createOptionLabel(option).toLowerCase().includes(searchQuery.toLowerCase());
  }) : options;

  const handleBlur = (event: FocusEvent) => {
    const isFocusGoOutside = !comboboxElement.current?.contains(event.relatedTarget);
    if (onBlur && isFocusGoOutside) onBlur(event);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleInputClick = () => {
    if (input.current?.dataset.headlessuiState !== 'open') toggleButton.current?.click();
  };

  const handleInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    const isFocusOutsideCombobox = !event.relatedTarget?.closest('[data-combobox]');

    if (isFocusOutsideCombobox) toggleButton.current?.click();

    setSearchQuery('');
  };

  const displayValue = (option: TType) => {
    if (hasAllOption && option === null) return 'All';
    return option ? createOptionLabel(option) : (emptyLabel ?? '')
  };

  const createInputEndSlot = (inProgress: boolean) => {
    if (inProgress) return <Spinner className={classNames.spinner}/>;

    return (
      <Combobox.Button ref={toggleButton} className={classNames.toggle}>
        {({ open }) => (
          <>
            <ChevronDownIcon className={classNames.chevron}/>
            <VisuallyHidden>{ open ? 'Close' : 'Open' }</VisuallyHidden>
          </>
        )}
      </Combobox.Button>
    );
  }

  const renderSelectInput = () => {
    const props = {
      placeholder,
      ref: input,
      autoComplete: 'off',
      endSlot: createInputEndSlot(inProgress),
      'aria-invalid': selectProps['aria-invalid'],
      'aria-describedby': selectProps['aria-describedby'],
      onClick: handleInputClick,
      onFocus: handleInputFocus,
    };

    if (renderInput) {
      return renderInput({ Element: Input, props: props });
    }

    return <Input {...props}/>;
  }

  const renderOptionItem = (active: boolean, selected: boolean, label: string) => {
    return (
      <li className={clsx(classNames.item, {
        [classNames.active]: active,
        [classNames.selected]: selected,
      })}>
        {label}
        {selected && (<CheckIcon className={classNames.check} aria-hidden="true" />)}
      </li>
    );
  };

  const renderOption = (option: TType) => {
    return (
      <Combobox.Option key={createKey(option)} as={Fragment} value={option}>
        {({ active, selected }) => (
          renderOptionItem(active, selected, createOptionLabel(option))
        )}
      </Combobox.Option>
    );
  }

  const renderOptions = () => {
    if (filteredOptions.length <= 0) {
      return (
        <Combobox.Options as={Fragment}>
          <span className={classNames.nothingFound}>Nothing found</span>
        </Combobox.Options>
      );
    }

    return (
      <Combobox.Options as={Fragment}>
        <ul className={classNames.list}>
          {hasAllOption && (
            <Combobox.Option key="all" as={Fragment} value={null}>
              {({ active, selected }) => (
                renderOptionItem(active, selected, 'All')
              )}
            </Combobox.Option>
          )}
          {filteredOptions.map((option) => renderOption(option))}
        </ul>
      </Combobox.Options>
    );
  }

  return (
    <Combobox<TType, 'div'>
      as={'div'}
      ref={allComboboxRefs}
      multiple={false}
      nullable={true}
      value={value ?? null}
      className={classNames.select}
      disabled={disabled}
      onChange={onChange}
      onBlur={handleBlur}
      data-combobox
    >
      <div className={classNames.popoverReference} ref={setPopoverReference}>
        <Combobox.Input
          as={Fragment}
          displayValue={displayValue}
          onChange={handleInputChange}
        >
          {renderSelectInput()}
        </Combobox.Input>
      </div>
      <div className={classNames.popover} ref={setPopoverElement} style={popoverStyle} {...popoverAttributes}>
        <Transition
          enter={classNames.enter}
          enterFrom={classNames.enterFrom}
          enterTo={classNames.enterTo}
          leave={classNames.leave}
          leaveFrom={classNames.leaveFrom}
          leaveTo={classNames.leaveTo}
        >
          <ScrollArea style={{ maxHeight: 320 }}>
            {renderOptions()}
          </ScrollArea>
        </Transition>
      </div>
    </Combobox>
  );
}) as <TType,>(props: SelectProps<TType> & { ref?: Ref<HTMLDivElement> }) => ReactElement;
