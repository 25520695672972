import { mergeHeaders } from '@/shared/helpers/merge-headers';

export type FetchAPI<T> = (request: URL | RequestInfo, options?: RequestInit) => Promise<T>;

export type Endpoint<T> = {
  fetch(fetchAPI: FetchAPI<T|null>): Promise<T>,
};

export type API = {
  fetch<T>(endpoint: Endpoint<T>): Promise<T>,
};

type CallWithParameters<T> = {
  fetch: FetchAPI<T>,
}

type CallWith<T, P> = (parameters: CallWithParameters<T>) => (...args: P[]) => Promise<T>;

export function createEndpoint<T, P = any>(callWith: CallWith<T, P>) {
  return (...args: P[]) => ({
    fetch(fetchAPI: FetchAPI<T>) {
      return new Promise<T>((resolve, reject) => {
        callWith({ fetch: fetchAPI })(...args)
          .then(resolve)
          .catch(reject);
      });
    }
  });
}

export function createAPI(url: URL | string, createGlobalOptions: () => Promise<RequestInit>) {
  async function fetchAPI<T>(request: URL | RequestInfo, options: RequestInit = {}): Promise<T|null> {
    const requestUrl = request instanceof Request ? request.url : request;
    const globalOptions = typeof createGlobalOptions === 'function' ? await createGlobalOptions() : {};

    return new Promise((resolve, reject) => {
      const rejectAsInformative = (data: any) => {
        if (typeof data === 'string') return reject({ errorMessage: data });
        reject(data);
      };

      fetch(new URL(requestUrl, url), {
        ...globalOptions,
        ...options,
        headers: mergeHeaders(globalOptions?.headers ?? [], options?.headers ?? []),
      })
        .then((response) => {
          const contentType = response.headers.get('content-type');
          const responseParseMethod = (contentType && contentType.includes('application/json')) ? 'json' : 'text';

          response[responseParseMethod]()
            .then((data: T) => {
              if (response.ok) return resolve(data);
              rejectAsInformative(data);
            })
            .catch((error) => {
              rejectAsInformative(error);
            });
        })
        .catch((error) => {
          rejectAsInformative(error);
        });
    })
  }

  const api: API = {
    fetch<T>(endpoint: Endpoint<T>) {
      return endpoint.fetch(fetchAPI);
    },
  };

  return api;
}