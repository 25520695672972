import { Configuration } from '@azure/msal-browser';

export const authorities = {
  login: 'https://morganheatflowdev.b2clogin.com/morganheatflowdev.onmicrosoft.com/B2C_1_SignIn',
  resetPassword: 'https://morganheatflowdev.b2clogin.com/morganheatflowdev.onmicrosoft.com/B2C_1_password_reset',
};

export const configuration: Configuration = {
  auth: {
    clientId: 'cb502ace-3f90-4af7-908b-f66e141dbeba',
    authority: authorities.login,
    knownAuthorities: ['morganheatflowdev.b2clogin.com'],
    redirectUri: `${window.location.origin}/auth-popup.html`,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const scopes = {
  login: ['openid', 'https://morganheatflowdev.onmicrosoft.com/tasks-api/tasks.read'],
  token: ['https://morganheatflowdev.onmicrosoft.com/tasks-api/tasks.read'],
}