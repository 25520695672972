import { app } from '@/app';
import { enableMaterial as enable } from '@/app/api/morgan-thermal/material/enable';
import { ActionType, runServerSideAction } from '@/app/actions';
import { MaterialInformation } from '@/app/api/morgan-thermal/material';

export const enableMaterial = (id: MaterialInformation['id']) => runServerSideAction({
  type: ActionType.POSITIVE,

  title: 'Enable',
  confirmationMessage: 'Enable this material?',
  approveLabel: 'Enable',

  runAction: () => app.morganThermalAPI.fetch(enable(id)),
});