import EventEmitter from 'eventemitter3';

export enum AuthorizationProviderStatus {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  LOGGING_IN = 'LOGGING_IN',
  LOGGING_OUT = 'LOGGING_OUT',
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
}

export interface AuthorizationProvider extends EventEmitter {
  isLoggedIn: boolean,
  status: AuthorizationProviderStatus,
  login(): Promise<unknown>,
  logout(): Promise<unknown>,
  resetPassword(): Promise<unknown>,
  getToken(): Promise<string>,
  getAccount(): AuthorizationProviderAccount | null,
}

export type AuthorizationProviderAccount = {
  name: string,
  email: string,
}