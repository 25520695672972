import { app } from '@/app';
import { MeasurementSystem } from '@/app/measurement-systems/model';
import { printCalculationComparison as print } from '@/app/api/morgan-thermal/calculation/compare/print';
import { createAndDownloadBlobFile } from '@/shared/helpers/blob';
import { File } from '@/app/api/morgan-thermal';

export const printCalculationComparison = (calculationIds: number[]): Promise<File> => app.morganThermalAPI.fetch(print({
  isMetric: app.measurementSystems.currentSystem === MeasurementSystem.METRIC,
  calculationIds,
}))
  .then(({ pdf: file }) => {
    createAndDownloadBlobFile(file.bytes, file.contentType, file.name);
    return file;
  });