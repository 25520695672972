import React, { Fragment, HTMLAttributes, PropsWithChildren, ReactElement, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { Menu, Transition } from '@headlessui/react';
import { Options as PopperOptions } from '@popperjs/core';
import { ScrollArea } from '@/shared/design-system/thermal-ceramics/components/scroll-area';
import enterAnimationClasses from '@/shared/design-system/thermal-ceramics/animations/enter/styles.module.css';
import leaveAnimationClasses from '@/shared/design-system/thermal-ceramics/animations/leave/styles.module.css';
import classNames from './styles.module.css';
import { clsx } from 'clsx';

export type DropdownProps = PropsWithChildren<{
  button: ReactElement,
}>;

export type DropdownItemProps = PropsWithChildren<HTMLAttributes<HTMLLIElement>>;

const popperOptions: PopperOptions = {
  placement: 'right-start',
  strategy: 'fixed',
  modifiers: [
    {
      name: 'flip',
      options: {
        boundary: 'document',
        fallbackPlacements: ['right-start', 'left-start'],
      },
    },
    {
      name: 'offset',
      options: {
        offset: [0, 4],
      },
    },
  ],
};

const Root = (props: DropdownProps) => {
  const { children, button } = props;
  const [popoverReference, setPopoverReference] = useState<HTMLDivElement | null>(null);
  const [popoverElement, setPopoverElement] = useState<HTMLDivElement | null>(null);
  const {
    styles: { popper: popoverStyle },
    attributes: { popper: popoverAttributes}
  } = usePopper(popoverReference, popoverElement, popperOptions);

  return (
    <Menu className={classNames.dropdown} as="div">
      {({ open }) => (
        <>
          <div className={classNames.buttonContainer} ref={setPopoverReference}>
            <Menu.Button as={Fragment}>{button}</Menu.Button>
          </div>
          {createPortal((
            <div className={classNames.popover} ref={setPopoverElement} style={popoverStyle} {...popoverAttributes}>
              <Transition
                enter={enterAnimationClasses.enter}
                enterFrom={clsx(
                  enterAnimationClasses.enterFromOpacity,
                  enterAnimationClasses.enterFromTransform,
                )}
                enterTo={clsx(
                  enterAnimationClasses.enterToOpacity,
                  enterAnimationClasses.enterToTransform,
                )}

                leave={leaveAnimationClasses.leave}
                leaveFrom={clsx(
                  leaveAnimationClasses.leaveFromOpacity,
                  leaveAnimationClasses.leaveFromTransform,
                )}
                leaveTo={clsx(
                  leaveAnimationClasses.leaveToOpacity,
                  leaveAnimationClasses.leaveToTransform,
                )}
              >
                <ScrollArea className={classNames.scrollArea} style={{ maxHeight: 320 }}>
                  <Menu.Items as={Fragment}>
                    <ul className={classNames.list}>{children}</ul>
                  </Menu.Items>
                </ScrollArea>
              </Transition>
            </div>
          ), document.body)}
        </>
      )}
    </Menu>
  );
};

const Item = ({ children, className, ...attributes }: DropdownItemProps) => (
  <Menu.Item as={Fragment}>
    <li className={clsx(classNames.item, className)} {...attributes}>
      {children}
    </li>
  </Menu.Item>
);

export const Dropdown = Object.assign(Root, {
  Item,
});