import { MaterialInformation, MaterialProperties } from '@/app/api/morgan-thermal/material';
import { app } from '@/app';
import { roundDecimals } from '@/shared/helpers/number';
import { MeasurementSystem } from '@/app/measurement-systems/model';

export const createInitialValues = (material?: MaterialInformation) => {
  const materialProperties: MaterialProperties | undefined = (material ?? {})[app.measurementSystems.currentSystem];
  let kValues = material?.thermalConductivityValues ? material?.thermalConductivityValues.map((thermalConductivityValue) => {
    const properties = thermalConductivityValue[app.measurementSystems.currentSystem];
    return {
      id: thermalConductivityValue.id,
      temperature: roundDecimals(properties.temperature),
      kValue: roundDecimals(properties.kValue, 3),
    };
  }) : Array(3).fill({
    temperature: '',
    kValue: '',
  });

  if (kValues.length < 3) kValues = [...kValues, ...Array.from({ length: 3 - kValues.length }).fill({
    temperature: '',
    kValue: '',
  })];

  return {
    name: material?.name ?? '',
    manufacturer: material?.manufacturer ?? '',
    materialCategoryId: material?.materialCategoryId ?? null,
    originOfManufacturerId: material?.originOfManufacturerId ?? null,
    density: roundDecimals(materialProperties?.density, app.measurementSystems.currentSystem === MeasurementSystem.METRIC ? 0 : 1) ?? 0,
    useLimit: roundDecimals(materialProperties?.useLimit) ?? 0,
    specificHeat: roundDecimals(materialProperties?.specificHeat, 2) ?? 0,
    specificHeatTemperature: roundDecimals(materialProperties?.specificHeatTemperature) ?? 0,
    classificationTemperature: roundDecimals(materialProperties?.classificationTemperature) ?? 0,
    kValues,
  };
};