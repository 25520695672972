import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/normalize.css';
import '@/index.module.css';
import { AppRouterProvider } from '@/app/routing/router';

ReactDOM
  .createRoot(document.getElementById('root') as HTMLElement)
  .render(
    <React.StrictMode>
      <AppRouterProvider />
    </React.StrictMode>
  );
