import { object, number } from '@/shared/libs/yup';
import { nameSchema } from '@/app/form/fields/common/name';
import { hotFaceTemperatureSchema } from '@/app/form/fields/calculation/hot-face-temperature';
import { ambientTemperatureSchema } from '@/app/form/fields/calculation/ambient-temperature';
import { emissivitySchema } from '@/app/form/fields/calculation/emissivity';
import { outsideWindSpeedSchema } from '@/app/form/fields/calculation/ambient-velocity';
import { array, boolean } from 'yup';
import { casingSchema } from '@/app/form/fields/calculation/casings';
import { numberOrEmpty } from '@/shared/libs/yup/validations';
import { MaterialInformation } from '@/app/api/morgan-thermal/material';
import { MeasurementSystem } from '@/app/measurement-systems/model';

const casingIdsWithDiameter = [4, 5, 6];

export const createValidationSchema = (measurementSystem: MeasurementSystem) => object({
  name: nameSchema.required().label('Calculation name'),
  insideTemperature: hotFaceTemperatureSchema(measurementSystem).required(),
  outsideTemperature: ambientTemperatureSchema(measurementSystem).required(),
  outsideEmissivity: emissivitySchema.required(),
  outsideWindSpeed: outsideWindSpeedSchema.required(),

  casingId: casingSchema.required(),
  diameter: numberOrEmpty().label('Cylinder Diameter').when("casingId", {
    is: (value: number) => casingIdsWithDiameter.includes(value),
    then: (schema) => schema.required(),
  }),

  calculationMaterials:
    array()
      .label('Materials')
      .required()
      .min(1, 'Materials must have at least 1 item.')
      .test('onlyActive', 'Disabled materials are not allowed.', function () {
        if (this.parent.calculationMaterials.some((material: MaterialInformation) => !material.isActive)) return false;

        return true;
      })
      .of(object({
        thickness: number().moreThan(0).required().label('Thickness'),
      })),
});