import { app } from '@/app';
import { MeasurementSystem } from '@/app/measurement-systems/model';
import { printMaterialComparison as print } from '@/app/api/morgan-thermal/material/compare/print';
import { createAndDownloadBlobFile } from '@/shared/helpers/blob';
import { File } from '@/app/api/morgan-thermal';

export const printMaterialComparison = (materialIds: number[]): Promise<File> => app.morganThermalAPI.fetch(print({
  isMetric: app.measurementSystems.currentSystem === MeasurementSystem.METRIC,
  materialIds,
}))
  .then(({ pdf: file }) => {
    createAndDownloadBlobFile(file.bytes, file.contentType, file.name);
    return file;
  });