import { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { app } from '@/app';
import { number } from '@/shared/libs/yup';
import { HookFormField, FieldSelectProps } from '@/shared/libs/hook-form';
import { Country } from '@/app/api/morgan-thermal/configuration/get-countries';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { createSelectOptions } from '@/shared/libs/hook-form/select';
import { MaterialCategory } from '@/app/api/morgan-thermal/configuration/get-material-categories';
import { FormField, FormFieldProps } from '@/shared/design-system/thermal-ceramics/components/form/field';
import { Select } from '@/shared/design-system/thermal-ceramics/components/form/select';
import { Region } from '@/app/api/morgan-thermal/configuration/get-regions';

export const originOfManufacturerSchema = number().typeError('${path} is a required field').nullable().label('Region');

export const useOriginOfManufacturerSelector = () => {
  const [regions] = useState(() => {
    const synchronizableValue = new SynchronizableValue<Region[]>([], app.configuration.getRegions);
    synchronizableValue.synchronize().catch(console.error);
    return synchronizableValue;
  });

  const options = useMemo(() => createSelectOptions(regions.value), [createSelectOptions(regions.value)])

  const hasOptions = useMemo(() => !!regions.value.length, [regions.value.length]);

  return {
    regions,
    options,
    hasOptions,
  };
};

export type OriginOfManufacturerHookFormFieldProps = Omit<FieldSelectProps, 'options'> & {
  regions: SynchronizableValue<Region[]>;
};

export const OriginOfManufacturerHookFormField = observer((props: OriginOfManufacturerHookFormFieldProps) => {
  const { regions } = props;

  return (
    <HookFormField.Select
      label="Region"
      options={createSelectOptions(regions.value)}
      inProgress={regions.isSynchronizationInProgress}
      {...props}
    />
  );
});

export type OriginOfManufacturerFormFieldProps = Omit<FormFieldProps, 'renderControl'> & {
  hasAllOption?: boolean,
  onChange?: (id: Region['id']) => void,
};

export const OriginOfManufacturerFormField = observer((props: OriginOfManufacturerFormFieldProps) => {
  const { hasAllOption, onChange } = props;
  const [value, setValue] = useState<MaterialCategory>();
  const materialCategoriesSelector = useOriginOfManufacturerSelector();

  return (
    <FormField
      label="Region"
      renderControl={(props) => (
        <Select<MaterialCategory>
          {...props}
          hasAllOption={hasAllOption}
          value={value}
          options={materialCategoriesSelector.regions.value}
          createKey={(option) => option.id}
          createOptionLabel={(option) => option.name}
          inProgress={materialCategoriesSelector.regions.isSynchronizationInProgress}
          onChange={(value) => {
            if (onChange) onChange(value?.id ?? null);
            setValue(value);
          }}
        />
      )}
      {...props}
    />
  );
});