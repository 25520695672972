import { createEndpoint } from '@/shared/models/api';
import { File } from '@/app/api/morgan-thermal';

export type PrintCalculationsRequest = {
  isMetric: boolean;
  calculationIds: number[];
};

export const printCalculations = createEndpoint<File>(({fetch}) => (request: PrintCalculationsRequest) => fetch(`/Calculation/Print`, {
  method: 'POST',
  body: JSON.stringify(request),
  headers: {
    'Content-Type': 'application/json',
  },
}));