import { createColumnHelper } from '@tanstack/react-table';
import { MaterialListingItem } from '@/app/api/morgan-thermal/material';
import { MeasurementSystem } from '@/app/measurement-systems/model';
import { createMeasurementSystemBasedColumn } from '@/app/listing/helpers/measurement-system-based-column';
import React from 'react';
import classNames from './styles.module.css';
import { MaterialDetailsPopup } from '@/app/popup/material-details';
import { MaterialOrderByOptions } from '@/app/api/morgan-thermal/material/search';
import { roundDecimals } from '@/shared/helpers/number';
import { app } from '@/app';

const columnHelper = createColumnHelper<MaterialListingItem>();

export const createColumns = (measurementSystem: MeasurementSystem) => [
  columnHelper.display({
    header: 'Info',
    size: 50,
    meta: {
      headCellClassName: classNames.infoCellHead,
      cellClassName: classNames.infoCell,
    },
    cell: info => <MaterialDetailsPopup id={info.row.original.id}/>
  }),
  columnHelper.accessor('name', {
    header: 'Material',
    meta: {
      orderBy: MaterialOrderByOptions.Name,
      canBeChecked: true,
    },
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('region', {
    header: 'Region',
    meta: {
      orderBy: MaterialOrderByOptions.Region,
    },
    cell: info => info.renderValue(),
  }),
  createMeasurementSystemBasedColumn<MaterialListingItem>(measurementSystem, 'density', {
    header: 'Density',
    modifier: (value) => roundDecimals(value, measurementSystem === MeasurementSystem.METRIC ? 0 : 1),
    meta: {
      orderBy: MaterialOrderByOptions.Density,
    },
  }),
  createMeasurementSystemBasedColumn<MaterialListingItem>(measurementSystem, 'classificationTemperature', {
    header: 'Classification Temperature',
    modifier: roundDecimals,
    meta: {
      orderBy: MaterialOrderByOptions.ClassificationTemperature,
    },
  }),
  createMeasurementSystemBasedColumn<MaterialListingItem>(measurementSystem, 'useLimit', {
    header: 'Use Limit',
    modifier: roundDecimals,
    meta: {
      orderBy: MaterialOrderByOptions.UseLimit,
    },
  }),
  createMeasurementSystemBasedColumn<MaterialListingItem>(measurementSystem, 'specificHeat', {
    header: 'Specific Heat',
    modifier: (value) => roundDecimals(value, 2),
    meta: {
      orderBy: MaterialOrderByOptions.SpecificHeat,
    },
  }),
];