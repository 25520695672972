import { useCallback } from 'react';
import { NavLink, useNavigate, useRouteLoaderData } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { app } from '@/app';
import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { Breadcrumbs } from '@/app/routing/breadcrumbs';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';
import { showInformativeModal } from '@/app/components/informative-modal';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { CompanyInformation } from '@/app/api/morgan-thermal/company';
import { SaveUserForm } from '@/app/form/scenarios/save-user';
import { updateUser, SaveUser } from '@/app/api/morgan-thermal/user/update';

export const UserCreateRouteView = observer(() => {
  const company = useRouteLoaderData('company') as SynchronizableValue<CompanyInformation | null>;
  const navigate = useNavigate();

  const handleSubmit = useCallback((data: SaveUser) => {
    return app.morganThermalAPI.fetch(updateUser(data))
      .then((response) => {
        navigate(`/companies/${company.value?.id}/users/${response?.id}`);
        showInformativeModal(response).catch();
      })
      .catch((response) => {
        showInformativeModal(response).catch();
      });
  }, [navigate, company.value?.id]);

  return (
    <Page hasOperationsInProgress={company.isSynchronizationInProgress} loaded={!!company.value}>
      <Breadcrumbs/>

      <Heading as="h1">Create user</Heading>

      {company.value && (
        <SaveUserForm
          submit={handleSubmit}
          companyId={company.value.id}
          footer={<>
            <Button modifier={ButtonModifier.TERTIARY} as={NavLink} to={`/companies/${company.value.id}/users`}>Cancel</Button>
            <Button type="submit">Create</Button>
          </>}
        />
      )}
    </Page>
  );
});