import React, { PropsWithChildren, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { app } from '@/app';
import { Header } from '@/shared/design-system/morgan/components/header';
import { Footer } from '@/shared/design-system/morgan/components/footer';
import { Subheader } from '@/app/layout/subheader';
import { Spinner } from '@/shared/design-system/thermal-ceramics/components/spinner';

import logo from './assets/images/logo.svg';
import { footerData } from './footer-data';
import classNames from './styles.module.css';
import { SiteNavigation } from '@/app/layout/site-navigation';
import { useLocation } from 'react-router-dom';

type LayoutProps = PropsWithChildren;

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const Layout = observer((props: LayoutProps) => {
  const { children } = props;

  const isSubheaderVisible = app.session.isLoggedIn;
  const isPlaceholderVisible = app.session.isLoggingOut || app.session.isLoggedOut || app.session.isLoggingIn;

  return (
    <div className={classNames.layout}>
      <ScrollToTop/>
      <Header
        logo={logo}
        name="Thermal Ceramics"
      />
      <main className={clsx(classNames.main, isSubheaderVisible ? classNames.hasSubheader : null)}>
        <Subheader/>
        <SiteNavigation/>
        {isPlaceholderVisible ? (
          <div className={classNames.placeholder}>
            <Spinner className={classNames.spinner}/>
          </div>
        ) : children}
      </main>
      <Footer
        topLinks={footerData.topLinks}
        accreditations={footerData.accreditations}
        externalLinks={footerData.externalLinks}
        socialLinks={footerData.socialLinks}
        additionalLinks={footerData.additionalLinks}
      />
    </div>
  );
});