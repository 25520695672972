import { app } from '@/app';
import { withCrumb } from '@/app/routing/breadcrumbs';
import { indexRoute } from './index/route';
import { materialRoute } from './id/route';
import { createRoute } from './create/route';

export const materialsRoute = {
  path: 'materials',
  children: [
    indexRoute,
    materialRoute,
    createRoute,
  ],
  handle: () => {
    if (!app.session.isGlobalAdmin) return null;

    return withCrumb({
      element: () => <span>Materials</span>,
    });
  }
}