import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';

import { Layout } from '@/app/layout';

import { withCrumb } from '@/app/routing/breadcrumbs';
import { ErrorRouteView } from '@/app/routing/error';

import { indexRoute } from '@/app/routing/root/index/route';
import { authRoute } from '@/app/routing/root/auth/route';
import { profileRoute } from '@/app/routing/root/profile/route';
import { companiesRoute } from '@/app/routing/root/companies/route';
import { materialsRoute } from '@/app/routing/root/materials/route';
import { projectsRoute } from '@/app/routing/root/projects/route';
import { myMaterialsRoute } from '@/app/routing/root/my-materials/route';

import { AuthGuard } from '@/app/routing/guards/auth';

import { HomeIcon } from '@/shared/design-system/thermal-ceramics/icons';

export const router = createBrowserRouter([{
  element: (<Layout><AuthGuard><Outlet/></AuthGuard></Layout>),
  errorElement: (<Layout><ErrorRouteView/></Layout>),
  children: [
    indexRoute,
    authRoute,
    profileRoute,
    companiesRoute,
    materialsRoute,
    projectsRoute,
    myMaterialsRoute,
  ],
  handle: () => withCrumb({
    element: () => <HomeIcon/>,
  }),
}]);

export function AppRouterProvider() {
  return (<RouterProvider router={router} />);
}
