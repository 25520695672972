import { RouteObject } from 'react-router-dom';

import { app } from '@/app';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { UserInformation } from '@/app/api/morgan-thermal/user';
import { getUser } from '@/app/api/morgan-thermal/user/get';
import { withCrumb } from '@/app/routing/breadcrumbs';

import { indexRoute } from './index/route';
import { editRoute } from './edit/route';

export const userRoute: RouteObject = {
  id: 'user',
  path: ':userId',
  children: [
    indexRoute,
    editRoute,
  ],
  async loader({ params: { userId } }) {
    const user = new SynchronizableValue<UserInformation | null>(null, () => {
      return app.morganThermalAPI.fetch(getUser(userId));
    });

    await user.synchronize();

    return user;
  },
  handle: (user: SynchronizableValue<UserInformation | null>) => withCrumb({
    element: () => <span>{user?.value?.name}</span>,
  }),
}