import { app } from '@/app';
import { disableUser as disable } from '@/app/api/morgan-thermal/user/disable';
import { ActionType, runServerSideAction } from '@/app/actions';
import { UserInformation } from '@/app/api/morgan-thermal/user';

export const disableUser = (userId: UserInformation['id']) => runServerSideAction({
  type: ActionType.NEGATIVE,

  title: 'Disable',
  confirmationMessage: 'Disable this user?',
  approveLabel: 'Yes, user',

  runAction: () => app.morganThermalAPI.fetch(disable(userId)),
});