import { HTMLAttributes } from 'react';
import { clsx } from 'clsx';

import classNames from './styles.module.css';

export type SpinnerProps = Omit<HTMLAttributes<HTMLSpanElement>, 'aria-hidden'>;

export const Spinner = ({ className, ...attributes }: SpinnerProps) => (
  <div
    className={clsx(classNames.spinner, className)}
    aria-hidden="true"
    {...attributes}
  />
);