import { ReactNode } from 'react';
import { useMatches } from 'react-router-dom';

import { Crumb } from '@/app/routing/breadcrumbs/crumb';
import { Match, MatchWithCrumbs } from '@/app/routing/types';
import { ChevronRightIcon } from '@/shared/design-system/thermal-ceramics/icons';

import classNames from './styles.module.css';

export type WithCrumbOptions = {
  isStatic?: boolean,
  to?: string,
  element: () => ReactNode,
};

export const withCrumb = ({ isStatic = false, to, element }: WithCrumbOptions, handle?: object) => ({
  ...(handle ?? {}),
  crumb: {
    isStatic,
    to,
    element,
  },
});

export const Breadcrumbs = () => {
  const matches = useMatches() as Match[];
  const matchesWithCrumb = matches.filter((match) => {
    if (typeof match.handle !== 'function') return false;
    const handle = match.handle(match.data);
    return Boolean(handle?.crumb);
  }) as MatchWithCrumbs[];

  return matchesWithCrumb.length > 1 ? (
    <nav className={classNames.breadcrumbs} aria-label="Breadcrumb">
      <ol className={classNames.list}>
        {matchesWithCrumb.map((match, index) => (
          <li className={classNames.item} key={match.id}>
            <Crumb pathname={match.pathname} to={match.handle(match.data).crumb?.to} isStatic={match.handle(match.data).crumb?.isStatic}>{match.handle(match.data).crumb?.element()}</Crumb>
            {(index < (matchesWithCrumb.length - 1)) && (
              <div className={classNames.iconContainer}>
                <ChevronRightIcon className={classNames.icon}/>
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  ) : null;
};