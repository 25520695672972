import { RouteObject } from 'react-router-dom';

import { app } from '@/app';
import { withCrumb } from '@/app/routing/breadcrumbs';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { getMaterial } from '@/app/api/morgan-thermal/material/get';
import { MaterialInformation } from '@/app/api/morgan-thermal/material';

import { indexRoute } from './index/route';
import { editRoute } from './edit/route';

export const myMaterialRoute: RouteObject = {
  id: 'my-material',
  path: ':materialId',
  children: [
    indexRoute,
    editRoute,
  ],
  async loader({ params: { materialId } }) {
    const material = new SynchronizableValue<MaterialInformation | null>(null, () => {
      return app.morganThermalAPI.fetch(getMaterial(materialId));
    });

    await material.synchronize();

    return material;
  },
  handle: (material: SynchronizableValue<MaterialInformation | null>) => withCrumb({
    element: () => <span>{material?.value?.name}</span>,
  }),
}