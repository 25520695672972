import { MeasurementSystem } from '@/app/measurement-systems/model';
import { CellContext, ColumnDef, createColumnHelper, DeepKeys } from '@tanstack/react-table';
import { AccessorFn, DisplayColumnDef, IdentifiedColumnDef } from '@tanstack/table-core/build/lib/types';
import { DeepValue } from '@tanstack/table-core/build/lib/utils';

type Accessor<TType> = AccessorFn<TType> | DeepKeys<TType>;
type Value<TType> = Accessor<TType> extends AccessorFn<TType, infer TReturn> ? TReturn : Accessor<TType> extends DeepKeys<TType> ? DeepValue<TType, Accessor<TType>> : never;
type Column<TType> = Accessor<TType> extends AccessorFn<TType> ? DisplayColumnDef<TType, Value<TType>> : IdentifiedColumnDef<TType, Value<TType>>;
type Options<TType> = Column<TType> & { modifier?: (value: any) => any };

export const createMeasurementSystemBasedColumn = <TType,>(measurementSystem: MeasurementSystem, name: string, options: Options<TType>, extendCell?: (info: CellContext<TType, Value<TType>>, Element: JSX.Element) => JSX.Element) => {
  const accessor = (measurementSystem === MeasurementSystem.IMPERIAL ? `imperial.${name}` : `metric.${name}`) as Accessor<TType>;
  const labelKey = `${name}Label`;

  const columnHelper = createColumnHelper<TType>();

  return columnHelper.accessor(accessor, {
    ...options,
    cell: info => {
      const Element = (
        <span
          dangerouslySetInnerHTML={{
            // @ts-ignore
            __html: `${options.modifier ? options.modifier(info.row.original[measurementSystem][name]) : info.row.original[measurementSystem][name]} ${info.row.original[measurementSystem][labelKey]}`
          }}
        />
      );

      if (extendCell) return extendCell(info, Element);

      return Element;
    },
  }) as ColumnDef<TType>;
};