import { Fieldset } from '@/shared/design-system/thermal-ceramics/components/form/fieldset';
import { DiameterHookFormField } from '@/app/form/fields/calculation/diameter';

import { observer } from 'mobx-react-lite';
import { CasingsHookFormField } from '@/app/form/fields/calculation/casings';

import classNames from './styles.module.css';
import { useWatch } from 'react-hook-form';
import { app } from '@/app';
import { MeasurementSystem } from '@/app/measurement-systems/model';

const casingIdsWithDiameter = [4, 5, 6];

export type CasingConditionsFieldsetProps = {};

export const CasingConditionsFieldset = observer((props: CasingConditionsFieldsetProps) => {
  const casingId = useWatch({ name: 'casingId' });
  const isInsideDiameterVisible = casingIdsWithDiameter.includes(Number(casingId));

  return (
    <Fieldset className={classNames.casingConditions}>
      <Fieldset.Legend>Casing Conditions</Fieldset.Legend>
      <Fieldset.Grid>
        <CasingsHookFormField name="casingId" required/>
        {isInsideDiameterVisible && <DiameterHookFormField name="diameter" type="number" decimalPlaces={(app.measurementSystems.currentSystem === MeasurementSystem.METRIC) ? 0 : 2} label="Inside Diameter" hint="Inside diameter of tubes or spheres"/>}
      </Fieldset.Grid>
    </Fieldset>
  );
});