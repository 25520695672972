import { observer } from 'mobx-react-lite';
import { useRouteLoaderData } from 'react-router-dom';

import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { Breadcrumbs } from '@/app/routing/breadcrumbs';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';
import { CalculationInformation } from '@/app/api/morgan-thermal/calculation';
import { ProjectInformation } from '@/app/api/morgan-thermal/project';
import { CalculationDetails } from '@/app/details/calculation';

export const CalculationIndexRouteView = observer(() => {
  const project = useRouteLoaderData('project') as SynchronizableValue<ProjectInformation | null>;
  const calculation = useRouteLoaderData('calculation') as SynchronizableValue<CalculationInformation | null>;

  return (
    <Page
      hasOperationsInProgress={calculation.isSynchronizationInProgress}
      loaded={!!calculation.value}
    >
      <Breadcrumbs/>
      {(calculation.value && project.value) && (
        <>
          <Heading as="h1">{calculation.value?.name}</Heading>
          <CalculationDetails
            editUrl={`/projects/${project.value.id}/calculations/${calculation.value.id}/edit`}
            calculation={calculation.value}
          />
        </>
      )}
    </Page>
  );
});