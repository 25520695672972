import { clsx } from 'clsx';
import { HTMLAttributes, PropsWithChildren } from 'react';
import { MobileMenuState, useMobileMenu } from '../context';
import classNames from './styles.module.css';

export function ToggleMobileMenu({ className }: PropsWithChildren<HTMLAttributes<HTMLElement>>) {
  const { toggle, state } = useMobileMenu();
  const burgerClassName = clsx(classNames.burger, {
    [classNames.cross]: state === MobileMenuState.OPENED || state === MobileMenuState.OPENING,
  });

  function renderText() {
    switch (state) {
      case MobileMenuState.OPENING:
      case MobileMenuState.OPENED: return 'Close';
      default: return 'Menu'
    }
  }

  return (
    <button className={clsx(classNames.toggleMobileMenu, className)} onClick={toggle}>
      <span className={classNames.text}>{renderText()}</span>
      <span className={burgerClassName} aria-hidden="true"/>
    </button>
  );
}