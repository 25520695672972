import { array, lazy, number, object } from '@/shared/libs/yup';
import { originOfManufacturerSchema } from '@/app/form/fields/material/origin-of-manufacturer';
import { nameSchema } from '@/app/form/fields/common/name';
import { materialCategorySchema } from '@/app/form/fields/material/category';
import { materialUseLimitSchema } from '@/app/form/fields/material/use-limit';
import { densitySchema } from '@/app/form/fields/material/density';
import { specificHeatSchema } from '@/app/form/fields/material/specific-heat';
import { specificHeatTemperatureSchema } from '@/app/form/fields/material/specific-heat-temperature';
import { classificationTemperatureSchema } from '@/app/form/fields/material/clasification-temperature';
import { numberOrEmpty } from '@/shared/libs/yup/validations';
import { MeasurementSystem } from '@/app/measurement-systems/model';

export type EditMaterialFormValidationOptions = { isGlobalAdmin: boolean, measurementSystem: MeasurementSystem };

export const createValidationSchema = ({ isGlobalAdmin, measurementSystem }: EditMaterialFormValidationOptions) => object({
  name: nameSchema.required().label('Name'),
  originOfManufacturerId: (
    isGlobalAdmin ?
      originOfManufacturerSchema.required().label('Origin of Manufacturer') :
      originOfManufacturerSchema.label('Origin of Manufacturer')
  ),
  manufacturer: nameSchema.required().label('Manufacturer'),
  materialCategoryId: materialCategorySchema.required().label('Material Category'),
  useLimit:
    materialUseLimitSchema(measurementSystem)
      .required()
      .label('Use Limit')
      .test('cannotExceedClassificationTemperature', 'Use Limit cannot exceed Classification Temperature.', function () {
        if (this.parent.useLimit > this.parent.classificationTemperature) 
          return false;
        return true;
      }),
  density:
    densitySchema
      .required()
      .label('Density'),
  specificHeat:
    specificHeatSchema
      .label('Specific Heat'),
  specificHeatTemperature: specificHeatTemperatureSchema.label('Specific Heat Temperature'),
  classificationTemperature:
    classificationTemperatureSchema(measurementSystem)
      .required()
      .label('Classification Temperature'),
  kValues: lazy((values) => {
    return array(lazy((item) => {
      const index = values.findIndex((value: any) => value === item);
      const isLastItem = index === (values.length - 1);

      if (isLastItem && index >= 3) {
        return object().shape({
          temperature: numberOrEmpty().label('Temperature').when('kValue', (values: any[], schema) => {
            if (typeof values[0] === 'number' && values[0] === 0) return schema.notOneOf([0], ' 0, 0 pair is not allowed');
            if (typeof values[0] === 'number') return schema.required();
            return schema;
          }),
          kValue: numberOrEmpty().label('K Value').when('temperature', (values: any[], schema) => {
            if (typeof values[0] === 'number' && values[0] === 0) return schema.notOneOf([0], ' 0, 0 pair is not allowed');
            if (typeof values[0] === 'number') return schema.required();
            return schema;
          }),
        }, [['temperature', 'kValue']]);
      }

      return object({
        temperature: number().required().label('Temperature'),
        kValue: number().required().label('K Value'),
      });
    })).label('Thermal Conductivity Values');
  }),
});