import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useState } from 'react';

import { number } from '@/shared/libs/yup';
import { HookFormField, FieldSelectProps } from '@/shared/libs/hook-form';
import { app } from '@/app';
import { Country } from '@/app/api/morgan-thermal/configuration/get-countries';
import { createSelectOptions } from '@/shared/libs/hook-form/select';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { StateProvince } from '@/app/api/morgan-thermal/configuration/get-state-provinces';

export type StateProvinceFieldProps = Omit<FieldSelectProps, 'options'> & {
  stateProvinces: SynchronizableValue<StateProvince[]>,
};

// eslint-disable-next-line
export const stateProvinceSchema = number().typeError('${path} is a required field').nullable().label('State/Province');

export const useStateProvincesSelector = () => {
  const [countryId, setCountryId] = useState<Country['id'] | null>(null);
  const [stateProvinces] = useState(() => new SynchronizableValue<StateProvince[]>([], app.configuration.getStateProvinces));

  const changeCountryId = useCallback((newCountryId: Country['id'] | null) => {
    if (countryId === newCountryId) return;

    setCountryId(newCountryId);

    if (!newCountryId) return stateProvinces.reset([]);

    stateProvinces.synchronize(newCountryId).catch(console.error);
  }, [countryId, setCountryId, stateProvinces]);

  const options = useMemo(() => createSelectOptions(stateProvinces.value), [stateProvinces.value])

  const hasOptions = useMemo(() => !!stateProvinces.value.length, [stateProvinces.value.length]);

  return {
    stateProvinces,
    options,
    hasOptions,
    changeCountryId,
  };
};

export const StateProvinceField = observer((props: StateProvinceFieldProps) => {
  const { stateProvinces } = props;

  return (
    <HookFormField.Select
      {...props}
      label="State/Province"
      options={createSelectOptions(stateProvinces.value)}
      inProgress={stateProvinces.isSynchronizationInProgress}
    />
  );
});