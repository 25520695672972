import { HTMLProps } from 'react';
import { clsx } from 'clsx';
import classNames from './styles.module.css';

export enum HintType {
  DEFAULT = 'default',
  ERROR = 'error',
}

export type HintProps = HTMLProps<HTMLSpanElement> & {
  type?: HintType,
};

export const Hint = ({ type = HintType.DEFAULT, children, className, ...attributes }: HintProps) => {
  return <span className={clsx(classNames.hint, classNames[type], className)} {...attributes}>{children}</span>;
}