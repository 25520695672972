import { HTMLAttributes, PropsWithChildren } from 'react';
import { clsx } from 'clsx';
import { AttentionIcon, ErrorIcon, SuccessIcon, WarningIcon } from '@/shared/design-system/thermal-ceramics/icons';
import classNames from './styles.module.css';

export enum NotificationType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  ATTENTION = 'attention',
}

export type NotificationProps = PropsWithChildren<HTMLAttributes<HTMLDivElement>> & {
  type?: NotificationType,
};

export type NotificationTitleProps = PropsWithChildren<HTMLAttributes<HTMLElement>>;

export type NotificationBodyProps = PropsWithChildren<HTMLAttributes<HTMLSpanElement>>;

const renderIcon = (type: NotificationType) => {
  switch(type) {
    case NotificationType.WARNING: return <WarningIcon className={clsx(classNames.icon, classNames.warningIcon)}/>;
    case NotificationType.ERROR: return <ErrorIcon className={clsx(classNames.icon, classNames.errorIcon)}/>;
    case NotificationType.ATTENTION: return <AttentionIcon className={clsx(classNames.icon, classNames.attentionIcon)}/>;
    default: return <SuccessIcon className={clsx(classNames.icon, classNames.successIcon)}/>;
  }
}

const getRole = (type: NotificationType) => {
  switch(type) {
    case NotificationType.ERROR: return 'alert';
    default: return 'status';
  }
}

const Root = (props: NotificationProps) => {
  const { type = NotificationType.SUCCESS, className, children } = props;

  return (
    <p className={clsx(classNames.notification, classNames[type], className)} aria-live="polite" role={getRole(type)}>
      <span className={classNames.iconContainer}>{renderIcon(type)}</span>
      <span className={classNames.contentContainer}>{children}</span>
    </p>
  );
}

const Title = ({ children, ...attributes }: NotificationTitleProps) => (
  <strong className={classNames.title} {...attributes}>{children}</strong>
);

const Body = ({ children, ...attributes }: NotificationBodyProps) => (
  <span className={classNames.body} {...attributes}>{children}</span>
);

export const Notification = Object.assign(Root, {
  Title,
  Body,
});