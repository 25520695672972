import { ReactElement, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Fieldset } from '@/shared/design-system/thermal-ceramics/components/form/fieldset';
import { NameHookFormField } from '@/app/form/fields/common/name';
import { ProjectInformation } from '@/app/api/morgan-thermal/project';
import { ProjectSave } from '@/app/api/morgan-thermal/project/save';
import { NumberHookFormField } from '@/app/form/fields/common/number';
import { DateHookFormField } from '@/app/form/fields/common/date';

import { createValidationSchema } from './validation';
import { createInitialValues } from './initial-values';
import classNames from './styles.module.css';
import { RequiredHint } from '@/app/form/required-hint';

export type SaveProjectFormProps = {
  project?: ProjectInformation;
  submit: (data: ProjectSave) => void;
  footer: ReactElement;
};

export const SaveProjectForm = observer((props: SaveProjectFormProps) => {
  const { submit, project, footer } = props;

  const form = useForm({
    mode: 'onTouched',
    defaultValues: createInitialValues(project),
    resolver: yupResolver(createValidationSchema()),
  });

  const handleValidSubmit = useCallback((values: FieldValues) => {
    submit({
      ...values,
      id: project?.id ?? 0,
    } as ProjectSave);
  }, [project?.id, submit]);

  return (
    <FormProvider {...form}>
      <RequiredHint/>
      <form onSubmit={form.handleSubmit(handleValidSubmit)} noValidate>
        <Fieldset className={classNames.fieldset}>
          <Fieldset.Grid>
            <NameHookFormField name="project" label="Project name" required/>
            <NumberHookFormField name="revisionNumber" label="Revision Number" required/>
            <NameHookFormField name="customer" label="Customer" required/>
            <DateHookFormField name="revisionDate" label="Revision Date" required/>
          </Fieldset.Grid>

          {footer && (<Fieldset.Footer>{footer}</Fieldset.Footer>)}
        </Fieldset>
      </form>
    </FormProvider>
  );
});