import { app } from '@/app';
import { disableCalculation as disable } from '@/app/api/morgan-thermal/calculation/disable';
import { ActionType, runServerSideAction } from '@/app/actions';
import { CalculationInformation } from '@/app/api/morgan-thermal/calculation';

export const disableCalculation = (id: CalculationInformation['id']) => runServerSideAction({
  type: ActionType.NEGATIVE,

  title: 'Disable',
  confirmationMessage: 'Disable this calculation?',
  approveLabel: 'Disable',

  runAction: () => app.morganThermalAPI.fetch(disable(id)),
});