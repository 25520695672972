import { Textarea, TextareaProps } from '@/shared/design-system/thermal-ceramics/components/form/textarea';
import { Field, FieldProps } from '@/shared/libs/hook-form/field';
import { FocusEventHandler } from 'react';

export type FieldTextareaProps = Omit<FieldProps, 'renderControl'> & {
  type?: string,
  inProgress?: boolean,
  onBlur?: FocusEventHandler,
  extendProps?: (props: TextareaProps) => TextareaProps,
};

export const FieldTextarea = (props: FieldTextareaProps) => {
  const { extendProps, onBlur, inProgress = false, type, ...fieldProps } = props;

  const renderControl = (inputProps: TextareaProps) => {
    const extendedProps: TextareaProps = extendProps ? extendProps(inputProps) : inputProps;

    if (extendedProps.onBlur && onBlur) {
      const originalOnBlur = extendedProps.onBlur;
      extendedProps.onBlur = (event) => {
        originalOnBlur(event);
        onBlur(event);
      };
    }

    extendedProps.inProgress = typeof inProgress === 'boolean' ? inProgress : extendedProps.inProgress;

    return <Textarea {...extendedProps}/>;
  };

  return (
    <Field
      {...fieldProps}
      renderControl={renderControl}
    />
  )
}