import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

export type UseFillWithProps = {
  isEnabledInitially?: boolean,
  form: UseFormReturn<any>,
  mapping: [string, string][],
};

export const useFillWith = (props: UseFillWithProps) => {
  const { isEnabledInitially = false, form, mapping } = props;
  const [isFillEnabled, setIsFillEnabled] = useState(isEnabledInitially);
  const { watch, trigger, setValue, getValues } = form;
  const fillToFields = mapping.map(([, to]) => to);

  useEffect(() => {
    if (!isFillEnabled) return;

    const subscription = watch((value, { name, type }) => {
      if (!name && type !== 'change') return;

      mapping.forEach(([from, to]) => {
        if (from !== name) return;

        // @ts-ignore
        setValue(to, getValues(from), {
          shouldValidate: false,
          shouldTouch: true,
        });
      });
    });

    return () => subscription.unsubscribe();
  }, [isFillEnabled, watch]);

  return {
    toggle(isFillEnabled: boolean) {
      setIsFillEnabled(isFillEnabled);
      // @ts-ignore
      trigger(fillToFields);

      if (!isFillEnabled) return;

      mapping.forEach(([from, to]) => {
        // @ts-ignore
        setValue(to, getValues(from), {
          shouldValidate: true,
          shouldTouch: true,
        });
      });
    },
  };
};