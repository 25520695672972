import { ReactElement, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { SendHelpEmailRequest } from '@/app/api/morgan-thermal/user/send-help-email';
import { HelpMessageHookFormField } from '@/app/form/fields/user/help-message';

import { createValidationSchema } from './validation';
import { createInitialValues } from './initial-values';
import classNames from './styles.module.css';

export type SendHelpEmailFormProps = {
  submit: (data: SendHelpEmailRequest) => void;
  footer?: ReactElement;
};

export const SendHelpEmailForm = observer((props: SendHelpEmailFormProps) => {
  const { submit, footer } = props;

  const form = useForm({
    mode: 'onTouched',
    defaultValues: createInitialValues(),
    resolver: yupResolver(createValidationSchema()),
  });

  const handleValidSubmit = useCallback((values: FieldValues) => submit(values as SendHelpEmailRequest), [submit]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleValidSubmit)} noValidate>
        <HelpMessageHookFormField name="input"/>
        {footer}
      </form>
    </FormProvider>
  );
});