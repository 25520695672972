import { createEndpoint } from '@/shared/models/api';
import { SearchFilters, SearchResponse } from '@/app/api/morgan-thermal';
import { MaterialListing } from '@/app/api/morgan-thermal/material';

export type MaterialSearchFilters = SearchFilters<{
  materialName?: string,
  materialCategoryId: number | null,
  regionId: number | null,
  showDisabled?: boolean,
}>;

export type MaterialSearchResponse = SearchResponse<MaterialListing>;

export enum MaterialOrderByOptions {
  Name = 'Name',
  Region = 'Region',
  Density = 'Density',
  ClassificationTemperature = 'ClassificationTemperature',
  UseLimit = 'UseLimit',
  SpecificHeat = 'SpecificHeat',
}

export const searchMaterials = createEndpoint<MaterialSearchResponse>(({fetch}) => (filters: MaterialSearchFilters) => fetch(`/Material/Search`, {
  method: 'POST',
  body: JSON.stringify(filters),
  headers: {
    'Content-Type': 'application/json',
  },
}));