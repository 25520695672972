import { HTMLAttributes, PropsWithChildren } from 'react';
import classNames from './styles.module.css';

export type VisuallyHiddenProps = PropsWithChildren<HTMLAttributes<HTMLSpanElement>>;

export const VisuallyHidden = (props: VisuallyHiddenProps) => {
  const { children, ...attributes } = props;

  return (
    <span
      {...attributes}
      className={classNames.visuallyHidden}
    >
      {children}
    </span>
  );
}