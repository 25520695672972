import { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { app } from '@/app';
import { number } from '@/shared/libs/yup';
import { HookFormField, FieldSelectProps } from '@/shared/libs/hook-form';
import { Country } from '@/app/api/morgan-thermal/configuration/get-countries';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { createSelectOptions } from '@/shared/libs/hook-form/select';

export type CountryFieldProps = Omit<FieldSelectProps, 'options'> & {
  countries: SynchronizableValue<Country[]>;
};

export const countrySchema = number().typeError('${path} is a required field').nullable().label('Country');

export const useCountriesSelector = () => {
  const [countries] = useState(() => {
    const synchronizableValue = new SynchronizableValue<Country[]>([], app.configuration.getCountries);
    synchronizableValue.synchronize().catch(console.error);
    return synchronizableValue;
  });

  const options = useMemo(() => createSelectOptions(countries.value), [createSelectOptions(countries.value)])

  const hasOptions = useMemo(() => !!countries.value.length, [countries.value.length]);

  return {
    countries,
    options,
    hasOptions,
  };
};

export const CountryField = observer((props: CountryFieldProps) => {
  const { countries } = props;

  return (
    <HookFormField.Select
      label="Country"
      options={createSelectOptions(countries.value)}
      inProgress={countries.isSynchronizationInProgress}
      {...props}
    />
  );
});