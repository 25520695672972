import { app } from '@/app';
import { disableProject as disable } from '@/app/api/morgan-thermal/project/disable';
import { ActionType, runServerSideAction } from '@/app/actions';
import { ProjectInformation } from '@/app/api/morgan-thermal/project';

export const disableProject = (id: ProjectInformation['id']) => runServerSideAction({
  type: ActionType.NEGATIVE,

  title: 'Disable',
  confirmationMessage: 'Disable this project?',
  approveLabel: 'Yes, disable',

  runAction: () => app.morganThermalAPI.fetch(disable(id)),
});