import { action, makeObservable, observable } from 'mobx';

const LOCAL_STORAGE_KEY = 'MEASUREMENT_SYSTEM';

export enum MeasurementSystem {
  IMPERIAL = 'imperial',
  METRIC = 'metric',
}

export class MeasurementSystems {
  currentSystem: MeasurementSystem;

  constructor() {
    makeObservable(this, {
      currentSystem: observable,
      change: action.bound,
    });

    this.currentSystem = (localStorage.getItem(LOCAL_STORAGE_KEY) as MeasurementSystem) ?? MeasurementSystem.METRIC;
  }

  change(system: MeasurementSystem) {
    localStorage.setItem(LOCAL_STORAGE_KEY, system);
    this.currentSystem = system;
  }
}