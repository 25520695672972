import { HTMLAttributes, PropsWithChildren } from 'react';
import { clsx } from 'clsx';
import { ButtonModifier, ButtonWithIcon } from '@/shared/design-system/morgan/components/button';
import { GlobeIcon, RightArrowIcon } from '@/shared/design-system/thermal-ceramics/icons';
import classNames from './styles.module.css';

export function Actions({ className, ...props }: PropsWithChildren<HTMLAttributes<HTMLElement>>) {
  return (
    <div {...props} className={clsx(classNames.actions, className)}>
      <ButtonWithIcon
        tag='a'
        modifier={ButtonModifier.SECONDARY}
        href="https://www.morganadvancedmaterials.com/en-gb/our-company/our-locations/"
        icon={<GlobeIcon width="1.125rem" height="1.125rem"/>}
      >
        Our Locations
      </ButtonWithIcon>

      <ButtonWithIcon
        tag='a'
        href="mailto:marketing.tc@morganplc.com?subject=Morgan%20Thermal%20Ceramics%20Enquiry"
        icon={<RightArrowIcon width="0.875rem" height="0.875rem"/>}
      >
        Contact Us
      </ButtonWithIcon>
    </div>
  );
}