import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';

import { app } from '@/app';

import { Button } from '@/shared/design-system/thermal-ceramics/components/button';
import { Container } from '@/shared/design-system/thermal-ceramics/components/container';

import classNames from './styles.module.css';

export const AuthIndexRouteView = observer(() => {
  return (
    <div className={classNames.page}>
      <Container>
        <div className={classNames.content}>
          <div className={classNames.inner}>
            <div className={classNames.unauthorized}>
              <Button onClick={() => app.session.login()}>Login</Button>
              <Button as={NavLink} to={'/auth/sign-up'}>Sign Up</Button>
            </div>
          </div>
        </div>
        <p className={classNames.feedback}>For any questions or feedback please contact: <a href="mailto:MorganHeatflow.Support@morganplc.com">MorganHeatflow.Support@morganplc.com</a></p>
      </Container>
  </div>
  );
});