import { createEndpoint } from '@/shared/models/api';
import { File } from '@/app/api/morgan-thermal';
import { MaterialInformation } from '@/app/api/morgan-thermal/material';

export type PrintMaterialComparisonRequest = {
  isMetric: boolean;
  materialIds: number[];
};

export type PrintMaterialComparisonResponse = {
  materials: MaterialInformation[];
  pdf: File;
};


export const printMaterialComparison = createEndpoint<PrintMaterialComparisonResponse>(({fetch}) => (request: PrintMaterialComparisonRequest) => fetch(`/Material/Compare`, {
  method: 'POST',
  body: JSON.stringify(request),
  headers: {
    'Content-Type': 'application/json',
  },
}));