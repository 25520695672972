import { createEndpoint } from '@/shared/models/api';
import { Informative } from '@/app/api/morgan-thermal';
import { CompanyInformation } from '@/app/api/morgan-thermal/company';

export type ProjectSave = {
  id: number,
  customer: string,
  project: string,
  revisionNumber: string,
  revisionDate: string,
};

export type ProjectSaveResponse = Informative & { id: CompanyInformation['id']};

export const saveProject = createEndpoint<ProjectSaveResponse>(({ fetch }) => (data: ProjectSave) => {
  return fetch(`/Project`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
});