import { HTMLAttributes, PropsWithChildren } from 'react';
import AnimateHeight from 'react-animate-height';
import { clsx } from 'clsx';
import { MobileMenuState, useMobileMenu } from './context';
import classNames from './styles.module.css';

export function MobileMenu({ children, className, ...props }: PropsWithChildren<HTMLAttributes<HTMLElement>>) {
  const { state, currentHeight, onHeightAnimationEnd } = useMobileMenu();
  const isVisible = state !== MobileMenuState.CLOSED;

  return isVisible ? (
    <AnimateHeight
      {...props}
      className={clsx(classNames.mobileMenu, className)}
      duration={500}
      height={currentHeight}
      onHeightAnimationEnd={onHeightAnimationEnd}
    >
      {children}
    </AnimateHeight>
  ) : null;
}
