import { observer } from 'mobx-react-lite';

import { app } from '@/app';
import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { Breadcrumbs } from '@/app/routing/breadcrumbs';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';

import { UserDetails } from '@/app/details/user';

export const ProfileIndexRouteView = observer(() => {
  return (
    <Page hasOperationsInProgress={app.session.user.isSynchronizationInProgress} loaded={!!app.session.user.value}>
      <Breadcrumbs/>

      <Heading as="h1">My profile</Heading>

      {(app.session.user.value && !app.session.user.isSynchronizationInProgress) && <UserDetails editUrl="/profile/edit" user={app.session.user.value}/>}
    </Page>
  );
});