import { createEndpoint } from '@/shared/models/api';
import { File } from '@/app/api/morgan-thermal';
import { MaterialInformation } from '@/app/api/morgan-thermal/material';

export type PrintCalculationComparisonRequest = {
  isMetric: boolean;
  calculationIds: number[];
};

export type PrintCalculationComparisonResponse = {
  materials: MaterialInformation[];
  pdf: File;
};

export const printCalculationComparison = createEndpoint<PrintCalculationComparisonResponse>(({fetch}) => (request: PrintCalculationComparisonRequest) => fetch(`/Calculation/Compare`, {
  method: 'POST',
  body: JSON.stringify(request),
  headers: {
    'Content-Type': 'application/json',
  },
}));