import { createEndpoint } from '@/shared/models/api';
import { CalculationInformation } from '@/app/api/morgan-thermal/calculation';
import { CalculationSave } from '@/app/api/morgan-thermal/calculation/save';
import { Informative } from '@/app/api/morgan-thermal';

export const calculate = createEndpoint<CalculationInformation & Informative>(({ fetch }) => (data: CalculationSave) => {
  return fetch(`/Calculation/Calculate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
});