import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';

import { Listing } from '@/app/listing';
import { Button } from '@/shared/design-system/thermal-ceramics/components/button';
import { Search } from '@/shared/design-system/thermal-ceramics/components/form/search';
import { UserListingItem } from '@/app/api/morgan-thermal/user';
import { CompanyInformation, CompanyStateId } from '@/app/api/morgan-thermal/company';
import { ActionsDropdown, ActionsDropdownItemModifier } from '@/app/components/actions-dropdown';

import { createColumns } from './columns';
import { UserListingModel } from './model';
import { ToggleButton } from '@/shared/design-system/thermal-ceramics/components/toggle-button/button';
import { ToggleButtonGroup } from '@/shared/design-system/thermal-ceramics/components/toggle-button/group';
import { UserOrderByOptions } from '@/app/api/morgan-thermal/user/search';
import { app } from '@/app';

export type UsersListingProps = {
  company: CompanyInformation,
};

export const UsersListing = observer((props: UsersListingProps) => {
  const { company } = props;
  const [{ listing, nameOrEmailFilter, showDisabledFilter, enable, disable, sendPasswordReset }] = useState(() => new UserListingModel({ companyId: company.id }));
  const { data, results, totalRecords, numberOfRecords, pageFilter, orderByFilter, orderByAscendingFilter } = listing;
  const canAddNewUser = (company.companyStateId === CompanyStateId.ACTIVE || company.companyStateId === CompanyStateId.PENDING) && !app.session.isGlobalCompanyManagerAdmin;

  return (
    <Listing<UserListingItem, UserOrderByOptions>
      header={
        <>
          <ToggleButtonGroup
            value={showDisabledFilter.value}
            onChange={showDisabledFilter.setValue}
          >
            <ToggleButton value={null} nullable>All</ToggleButton>
            <ToggleButton value={true}>Active</ToggleButton>
            <ToggleButton value={false}>Disabled</ToggleButton>
          </ToggleButtonGroup>
          <Search value={nameOrEmailFilter.value} onSearch={nameOrEmailFilter.setValue}/>
          {canAddNewUser && <Button as={NavLink} to={`/companies/${company.id}/users/create`}>Add user</Button>}
        </>
      }
      renderActions={(item: UserListingItem) => {
        const { id: userId, isActive } = item;
        const { id: companyId, companyStateId } = company;
        const isPendingCompany = companyStateId === CompanyStateId.PENDING;

        return (
          <ActionsDropdown>
            <ActionsDropdown.Item key="details" as={NavLink} to={`/companies/${companyId}/users/${userId}`}>Details</ActionsDropdown.Item>
            {!app.session.isGlobalCompanyManagerAdmin && !isPendingCompany && isActive && <ActionsDropdown.Item key="sendPasswordReset" onClick={() => sendPasswordReset(userId)}>Send Password Reset</ActionsDropdown.Item>}
            {!isPendingCompany && !isActive && <ActionsDropdown.Item key="enable" modifier={ActionsDropdownItemModifier.POSITIVE} onClick={() => enable(userId)}>Enable</ActionsDropdown.Item>}
            {isActive && <ActionsDropdown.Item key="disable" modifier={ActionsDropdownItemModifier.DANGER} onClick={() => disable(userId)}>Disable</ActionsDropdown.Item>}
          </ActionsDropdown>
        );
      }}
      columns={createColumns(company)}
      data={results}
      isInitialized={!!data}
      hasOperationsInProgress={data.isSynchronizationInProgress}
      totalRecords={totalRecords}
      numberOfRecords={numberOfRecords}
      page={pageFilter.value}
      onPageChange={pageFilter.setValue}

      orderBy={orderByFilter.value as UserOrderByOptions}
      orderByAscending={orderByAscendingFilter.value}
      onOrderByChange={orderByFilter.setValue}
      onOrderByAscendingChange={orderByAscendingFilter.setValue}
    />
  );
});