import { Input, InputProps } from '@/shared/design-system/thermal-ceramics/components/form/input';
import { Field, FieldProps } from '@/shared/libs/hook-form/field';
import { FocusEventHandler } from 'react';

export type FieldInputProps = Omit<FieldProps, 'renderControl'> & {
  type?: string,
  inProgress?: boolean,
  decimalPlaces?: number,
  onBlur?: FocusEventHandler,
  extendProps?: (props: InputProps) => InputProps,
};

export const FieldInput = (props: FieldInputProps) => {
  const { extendProps, onBlur, inProgress = false, decimalPlaces, type, ...fieldProps } = props;

  const renderControl = (inputProps: InputProps) => {
    const extendedProps: InputProps = extendProps ? extendProps({ ...inputProps, decimalPlaces }) : { ...inputProps, decimalPlaces };

    extendedProps.type = type ?? 'text';

    if (extendedProps.onBlur && onBlur) {
      const originalOnBlur = extendedProps.onBlur;
      extendedProps.onBlur = (event) => {
        originalOnBlur(event);
        onBlur(event);
      };
    }

    extendedProps.inProgress = typeof inProgress === 'boolean' ? inProgress : extendedProps.inProgress;

    return <Input {...extendedProps}/>;
  };

  return (
    <Field
      {...fieldProps}
      renderControl={renderControl}
    />
  )
}