import { PropsWithChildren, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { clsx } from 'clsx';

import { VisuallyHidden } from '@/shared/design-system/thermal-ceramics/components/visually-hidden';
import { KeyValueSection, KeyValueSectionModifier } from '@/shared/design-system/thermal-ceramics/components/key-value-section';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';
import { PenIcon } from '@/shared/design-system/thermal-ceramics/icons';

import classNames from './styles.module.css';

type Information = Array<{
  key: string,
  value?: any | (() => ReactNode),
} | null>;

export type EntityDetailsProps = PropsWithChildren<{
  heading?: string,
  editUrl?: string,
  mainInformation?: Information,
  additionalInformation?: Information,
  mainHeader?: ReactNode,
  mainFooter?: ReactNode,
  additionalFooter?: ReactNode,
}>;

function renderInfoValue(value?: string | number) {
  if ((typeof value === 'string' && value?.length > 0) || (typeof value === 'number')) {
    if (typeof value === 'string') {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: value,
          }}
        />
      );
    }

    return value;
  }
  return 'Empty';
}

export const EntityDetails = (props: EntityDetailsProps) => {
  const { heading, editUrl, mainInformation, additionalInformation, mainHeader, mainFooter, additionalFooter } = props;

  return (
    <section className={clsx(classNames.section, (mainInformation && additionalInformation) ? classNames.hasFullInformation : '')}>
      {heading && <VisuallyHidden><h1>{heading}</h1></VisuallyHidden>}
      <div className={classNames.information}>
        {mainInformation && <div className={classNames.informationColumn}>
          <KeyValueSection>
            {editUrl && (
              <Button as={NavLink} className={classNames.editButton} to={editUrl} modifier={[ButtonModifier.CIRCLE, ButtonModifier.SECONDARY]}>
                <VisuallyHidden>Edit</VisuallyHidden>
                <PenIcon aria-hidden="true"/>
              </Button>
            )}

            {mainHeader && <div className={classNames.mainHeader}>{mainHeader}</div>}

            <KeyValueSection.List>
              {mainInformation.map((item) => {
                if (!item) return null;

                const { key, value } = item;

                return (
                  <KeyValueSection.Item key={key}>
                    <KeyValueSection.Key>{key}</KeyValueSection.Key>
                    <KeyValueSection.Value>{typeof value === 'function' ? value() : renderInfoValue(value)}</KeyValueSection.Value>
                  </KeyValueSection.Item>
                );
              })}
            </KeyValueSection.List>

            {mainFooter && <div className={classNames.mainFooter}>{mainFooter}</div>}

            {additionalFooter && <div className={classNames.additionalFooter}>{additionalFooter}</div>}
          </KeyValueSection>
        </div>}

        {additionalInformation && <div className={classNames.informationColumn}>
          <KeyValueSection modifier={KeyValueSectionModifier.LIST}>
            {additionalInformation.map((item) => {
              if (!item) return null;

              const { key, value } = item;

              return (
                <KeyValueSection.Item key={key}>
                  <KeyValueSection.Key>{key}</KeyValueSection.Key>
                  <KeyValueSection.Value>{typeof value === 'function' ? value() : renderInfoValue(value)}</KeyValueSection.Value>
                </KeyValueSection.Item>
              );
            })}
          </KeyValueSection>
        </div>}
      </div>
    </section>
  );
};