import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Container } from '@/shared/design-system/thermal-ceramics/components/container';
import { Logout } from '@/shared/design-system/thermal-ceramics/components/logout';
import { UserCaption } from '@/shared/design-system/thermal-ceramics/components/user-caption';
import { app } from '@/app';
import userPlaceholder from './assets/images/user-placeholder.svg';
import { SendHelpEmailButton } from '@/app/actions/user/send-help-email';

import classNames from './styles.module.css';

export const Subheader = observer(function Subheader() {
  if (!app.session.user.value) return null;

  const fullName = (app.session.user.value.name ?? '');
  const firstName = fullName.split(' ')[0] ?? '';

  return (
    <section className={classNames.subheader}>
      <section className={classNames.user}>
        <Container>
          <SendHelpEmailButton/>
          <Link to="/profile" className={classNames.userLink}>
            <UserCaption src={userPlaceholder} name={firstName} aria-hidden="true"/>
          </Link>
          <Logout onClick={() => app.authorizationProvider.logout()} />
        </Container>
      </section>
    </section>
  );
});