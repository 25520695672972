import { useCallback } from 'react';
import { NavLink, useNavigate, useRouteLoaderData } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { app } from '@/app';
import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { Breadcrumbs } from '@/app/routing/breadcrumbs';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';
import { showInformativeModal } from '@/app/components/informative-modal';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { UserInformation } from '@/app/api/morgan-thermal/user';
import { SaveUserForm } from '@/app/form/scenarios/save-user';
import { updateUser, SaveUser } from '@/app/api/morgan-thermal/user/update';

export const UserEditRouteView = observer(() => {
  const user = useRouteLoaderData('user') as SynchronizableValue<UserInformation | null>;
  const navigate = useNavigate();

  const handleSubmit = useCallback((data: SaveUser) => {
    return app.morganThermalAPI.fetch(updateUser(data))
      .then((response) => {
        user.synchronize({ noCache: true }).catch(console.error);
        navigate(`/companies/${user.value?.companyId}/users/${user.value?.id}`);
        showInformativeModal(response).catch();
        return response;
      })
      .catch((response) => {
        showInformativeModal(response).catch();
        return response.data;
      });
  }, [user, navigate]);

  return (
    <Page hasOperationsInProgress={user.isSynchronizationInProgress} loaded={!!user.value}>
      <Breadcrumbs/>

      <Heading as="h1">Edit user</Heading>

      {user.value && (
        <SaveUserForm
          user={user.value}
          companyId={user.value?.companyId}
          submit={handleSubmit}
          footer={<>
            <Button modifier={ButtonModifier.TERTIARY} as={NavLink} to={`/companies/${user.value?.companyId}/users/${user.value?.id}`}>Cancel</Button>
            <Button type="submit">Save</Button>
          </>}
        />
      )}
    </Page>
  );
});