import { createContext, HTMLAttributes, PropsWithChildren } from 'react';
import { ToggleButtonValue } from '@/shared/design-system/thermal-ceramics/components/toggle-button/button';
import classNames from './styles.module.css';

export type ToggleButtonGroupProps =
  PropsWithChildren &
  Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & {
    value?: ToggleButtonValue,
    onChange?: (value: ToggleButtonValue) => void,
  };

export type ToggleButtonGroupContextValue = {
  value?: ToggleButtonValue,
  handleChange: (value: ToggleButtonValue) => void,
};

export const ToggleButtonGroupContext = createContext<ToggleButtonGroupContextValue>({
  handleChange: () => {},
});

export const ToggleButtonGroup = (props: ToggleButtonGroupProps) => {
  const { children, value, onChange, ...groupProps } = props;

  function handleChange(newValue: ToggleButtonValue) {
    if (onChange && value !== newValue) onChange(newValue);
  }

  return (
    <div
      {...groupProps}
      className={classNames.group}
    >
      <ToggleButtonGroupContext.Provider value={{ value, handleChange }}>
        {children}
      </ToggleButtonGroupContext.Provider>
    </div>
  );
};