import { app } from '@/app';
import { observer } from 'mobx-react-lite';
import { ToggleButton } from '@/shared/design-system/thermal-ceramics/components/toggle-button/button';
import { ToggleButtonGroup } from '@/shared/design-system/thermal-ceramics/components/toggle-button/group';
import { MeasurementSystem } from '@/app/measurement-systems/model';

export type MeasurementSystemsToggleProps = {
  beforeChange?: (measurementSystem: MeasurementSystem) => Promise<boolean>,
};

export const MeasurementSystemsToggle = observer(( { beforeChange }: MeasurementSystemsToggleProps) => (
  <ToggleButtonGroup
    value={app.measurementSystems.currentSystem}
    onChange={(value) => {
      const change = () => app.measurementSystems.change(value as MeasurementSystem);

      if (!beforeChange) return change();

      beforeChange(value as MeasurementSystem)
        .then((isChangeConfirmed) => {
          if (isChangeConfirmed) change();
        });
    }}
  >
    <ToggleButton value={MeasurementSystem.IMPERIAL}>F</ToggleButton>
    <ToggleButton value={MeasurementSystem.METRIC}>C</ToggleButton>
  </ToggleButtonGroup>
));