import { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { app } from '@/app';
import { number } from '@/shared/libs/yup';
import { HookFormField, FieldSelectProps } from '@/shared/libs/hook-form';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { createSelectOptions } from '@/shared/libs/hook-form/select';
import { UserRole } from '@/app/api/morgan-thermal/configuration/get-available-roles';
import { CompanyInformation } from '@/app/api/morgan-thermal/company';

export type UserRoleFieldProps = Omit<FieldSelectProps, 'options'> & {
  roles: SynchronizableValue<UserRole[]>;
};

// eslint-disable-next-line
export const userRoleSchema = number().typeError('${path} is a required field').nullable().label('User Role');

export const useUserRoleSelector = (companyId: CompanyInformation['id']) => {
  const [roles] = useState(() => {
    const synchronizableValue = new SynchronizableValue<UserRole[]>([], app.configuration.getAvailableRoles);
    synchronizableValue.synchronize(companyId).catch(console.error);
    return synchronizableValue;
  });
  const rolesList = roles.value;
  const rolesCount = roles.value.length;

  const options = useMemo(() => createSelectOptions(rolesList), [rolesList])

  const hasOptions = useMemo(() => !!rolesCount, [rolesCount]);

  return {
    roles,
    options,
    hasOptions,
  };
};

export const UserRoleField = observer((props: UserRoleFieldProps) => {
  const { roles } = props;

  return (
    <HookFormField.Select
      label="User Role"
      options={createSelectOptions(roles.value)}
      inProgress={roles.isSynchronizationInProgress}
      {...props}
    />
  );
});