import React, { PropsWithChildren } from 'react';
import { clsx } from 'clsx';
import { UnorderedList } from '@/shared/design-system/thermal-ceramics/components/list';
import classNames from './styles.module.css';

export type AdditionalLink = PropsWithChildren<JSX.IntrinsicElements['a']> & {
  id: string,
};

export type AdditionalLinksProps = {
  links: AdditionalLink[],
}

export function AdditionalLinks({ links }: AdditionalLinksProps) {
  return (
    <UnorderedList<AdditionalLink>
      className={classNames.additionalLinks}
      itemClassName={classNames.item}
      items={links}
      createKey={(link) => link.id}
      render={({ children, ...props }) => (
        <a {...props} className={clsx(classNames.link, props.className)}>{children}</a>
      )}
    />
  );
}