import { createEndpoint } from '@/shared/models/api';
import { UserListing } from '@/app/api/morgan-thermal/user';
import { CompanyInformation } from '@/app/api/morgan-thermal/company';
import { SearchFilters, SearchResponse } from '@/app/api/morgan-thermal';

export type UserSearchFilters = SearchFilters<{
  isActive?: boolean,
  nameOrEmail?: string,
}>;

export type UserSearchResponse = SearchResponse<UserListing>;

export enum UserOrderByOptions {
  Name = 'Name',
  Email = 'Email',
  Role = 'Role',
  CreatedDate = 'CreatedDate',
  LastLoginDate = 'LastLoginDate',
}

export const searchUsers = createEndpoint<UserSearchResponse>(({fetch}) => (companyId: CompanyInformation['id'], filters: UserSearchFilters) => fetch(`/User/Search/${companyId}`, {
  method: 'POST',
  body: JSON.stringify(filters),
  headers: {
    'Content-Type': 'application/json',
  },
}));