import { RouteObject } from 'react-router-dom';

import { app } from '@/app';
import { withCrumb } from '@/app/routing/breadcrumbs';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { getProject } from '@/app/api/morgan-thermal/project/get';
import { ProjectInformation } from '@/app/api/morgan-thermal/project';

import { indexRoute } from './index/route';
import { editRoute } from './edit/route';
import { calculationsRoute } from './calculations/route';

export const projectRoute: RouteObject = {
  id: 'project',
  path: ':projectId',
  children: [
    indexRoute,
    editRoute,
    calculationsRoute,
  ],
  async loader({ params: { projectId } }) {
    const project = new SynchronizableValue<ProjectInformation | null>(null, () => {
      return app.morganThermalAPI.fetch(getProject(projectId));
    });

    await project.synchronize();

    return project;
  },
  handle: (project: SynchronizableValue<ProjectInformation | null>) => withCrumb({
    element: () => <span>{project?.value?.project}</span>,
  }),
}