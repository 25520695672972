import { ReactElement, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { CountryField } from '@/app/form/fields/common/country';
import { StateProvinceField, } from '@/app/form/fields/common/state-province';
import { Fieldset } from '@/shared/design-system/thermal-ceramics/components/form/fieldset';
import { NameHookFormField } from '@/app/form/fields/common/name';
import { AddressField } from '@/app/form/fields/common/address';
import { CityField } from '@/app/form/fields/common/city';
import { PostalCodeField } from '@/app/form/fields/common/postal-code';
import { PhoneField } from '@/app/form/fields/common/phone';
import { CompanyInformation } from '@/app/api/morgan-thermal/company';
import { IndustryField } from '@/app/form/fields/company/industry';
import { DomainsField } from '@/app/form/fields/company/domains';
import { CompanyUpdate } from '@/app/api/morgan-thermal/company/update';
import { GlobalLocationContextProvider, useGlobalLocationSelector } from '@/app/form/fields/common/global-location';

import { createValidationSchema } from './validation';
import { createInitialValues } from './initial-values';
import classNames from './styles.module.css';
import { confirmDomainRemove } from '@/app/actions/company/confirm-domain-remove';
import { RequiredHint } from '@/app/form/required-hint';

export type SaveCompanyFormProps = {
  company?: CompanyInformation;
  submit: (data: CompanyUpdate) => void;
  footer: ReactElement;
};

export const SaveCompanyForm = observer((props: SaveCompanyFormProps) => {
  const { submit, company, footer } = props;

  const globalLocationSelector = useGlobalLocationSelector({
    countryIdName: 'countryId',
    stateProvinceIdName: 'stateProvinceId',
  });

  const form = useForm({
    mode: 'onTouched',
    defaultValues: createInitialValues(company),
    resolver: yupResolver(createValidationSchema({
      hasStateProvinces: globalLocationSelector.stateProvincesSelector.hasOptions,
    })),
  });

  const handleValidSubmit = useCallback((values: FieldValues) => {
    submit({ ...values, domains: form.getValues('domains').map(({ value }) => value), id: company?.id ?? 0 } as CompanyUpdate);
  }, [company, form, submit]);

  return (
    <FormProvider {...form}>
      <RequiredHint/>
      <form onSubmit={form.handleSubmit(handleValidSubmit)} noValidate>
        <Fieldset className={classNames.fieldset}>
          <Fieldset.Grid>
            <GlobalLocationContextProvider globalLocationSelector={globalLocationSelector}>
              <NameHookFormField name="name" label="Company Name" error={''} required/>
              <CityField name="city" label="City" required/>
              <IndustryField name="industryId" label="Industry"/>
              <CountryField name="countryId" countries={globalLocationSelector.countriesSelector.countries} required/>
              {globalLocationSelector.stateProvincesSelector.hasOptions ? (
                <>
                  <PhoneField name="contactPhone" label="Contact Phone" required/>
                  <StateProvinceField name="stateProvinceId" stateProvinces={globalLocationSelector.stateProvincesSelector.stateProvinces} required/>
                  <PhoneField name="secondaryPhone" label="Secondary Phone"/>
                  <PostalCodeField name="zip" label="Postal Code" required/>
                  <div className={classNames.innerGrid}>
                    <AddressField name="address" label="Company Address" required/>
                    <AddressField name="additionalAddress" label="Additional Address"/>
                  </div>
                  <DomainsField name="domains" label="Company Domain" beforeRemove={({ value }) => confirmDomainRemove(value)} required/>
                </>
              ) : (
                <>
                  <PhoneField name="contactPhone" label="Contact Phone" required/>
                  <PostalCodeField name="zip" label="Postal Code" required/>
                  <div className={classNames.innerGrid}>
                    <PhoneField name="secondaryPhone" label="Secondary Phone"/>
                    <AddressField name="address" label="Company Address" required/>
                    <AddressField name="additionalAddress" label="Additional Address"/>
                  </div>
                  <DomainsField name="domains" label="Company Domain" beforeRemove={({ value }) => confirmDomainRemove(value)} required/>
                </>
              )}
            </GlobalLocationContextProvider>
          </Fieldset.Grid>

          {footer && (<Fieldset.Footer>{footer}</Fieldset.Footer>)}
        </Fieldset>
      </form>
    </FormProvider>
  );
});