import { app } from '@/app';
import { approveCompany as approve } from '@/app/api/morgan-thermal/company/approve';
import { ActionType, runServerSideAction } from '@/app/actions';
import { CompanyInformation } from '@/app/api/morgan-thermal/company';

export const approveCompany = (companyId: CompanyInformation['id']) => runServerSideAction({
  type: ActionType.POSITIVE,

  title: 'Approve',
  confirmationMessage: 'Approve this company?',
  approveLabel: 'Approve',

  runAction: () => app.morganThermalAPI.fetch(approve(companyId)),
});