import { PropsWithChildren } from 'react';
import { UnorderedList } from '@/shared/design-system/thermal-ceramics/components/list';
import { ButtonWithIcon } from '@/shared/design-system/morgan/components/button';
import { clsx } from 'clsx';
import classNames from './styles.module.css';
import { RightArrowIcon } from '@/shared/design-system/thermal-ceramics/icons';

export type TopLink = PropsWithChildren<JSX.IntrinsicElements['a']> & {
  id: string,
  isButton?: boolean,
};

export type TopLinksProps = JSX.IntrinsicElements['ul'] & {
  links: Array<TopLink & {
    isButton?: boolean,
  }>
}

export function TopLinks({ links, ...props }: TopLinksProps) {
  return (
    <UnorderedList<TopLink>
      {...props}
      className={clsx(classNames.topLinks, props.className)}
      items={links}
      createKey={(link) => link.id}
      render={({ isButton, children, ...props }) => {
        if (isButton) return (
          <ButtonWithIcon
            {...props}
            tag="a"
            icon={<RightArrowIcon/>}
            className={clsx(classNames.button, props.className)}
          >
            {children}
          </ButtonWithIcon>
        );

        return (
          <a {...props} className={clsx(classNames.link, props.className)}>{children}</a>
        );
      }}
    />
  );
}