import { createLocalStorageCache, LocalStorageCache } from '@/shared/models/local-storage-cache';
import { addDaysFromToday } from '@/shared/helpers/date';
import { Endpoint } from '@/shared/models/api';
import { Country, getCountries } from '@/app/api/morgan-thermal/configuration/get-countries';
import { Industry, getIndustries } from '@/app/api/morgan-thermal/configuration/get-industries';
import { MaterialCategory, getMaterialCategories } from '@/app/api/morgan-thermal/configuration/get-material-categories';
import { Region, getRegions } from '@/app/api/morgan-thermal/configuration/get-regions';
import { StateProvince, getStateProvinces } from '@/app/api/morgan-thermal/configuration/get-state-provinces';
import { MorganThermalAPI } from '@/app/api/morgan-thermal';
import { getCasings } from '@/app/api/morgan-thermal/configuration/get-casings';
import { getAvailableRoles } from '@/app/api/morgan-thermal/configuration/get-available-roles';
import { CompanyInformation } from '@/app/api/morgan-thermal/company';

export type ConfigurationOptions = {
  morganThermalAPI: MorganThermalAPI,
};

export class Configuration {
  morganThermalAPI: MorganThermalAPI;
  localStorageCache: LocalStorageCache;

  constructor({ morganThermalAPI }: ConfigurationOptions) {
    this.morganThermalAPI = morganThermalAPI;

    this.localStorageCache = createLocalStorageCache();

    this.fetchWithCache = this.fetchWithCache.bind(this);

    this.getCasings = this.getCasings.bind(this);
    this.getCountries = this.getCountries.bind(this);
    this.getRegions = this.getRegions.bind(this);
    this.getIndustries = this.getIndustries.bind(this);
    this.getStateProvinces = this.getStateProvinces.bind(this);
    this.getMaterialCategories = this.getMaterialCategories.bind(this);
    this.getAvailableRoles = this.getAvailableRoles.bind(this);
  }

  fetchWithCache<TType>(key: string, endpoint: Endpoint<TType>, expiresAt: Date) {
    const cachedData = this.localStorageCache.get<TType>(key);

    if (cachedData) return Promise.resolve(cachedData);

    return this.morganThermalAPI.fetch<TType>(endpoint)
      .then((data) => {
        this.localStorageCache.set(key, data, expiresAt);
        return data;
      })
      .catch((error) => error);
  }

  getCasings() {
    return this.fetchWithCache<Country[]>('CASINGS', getCasings(), addDaysFromToday(30));
  }

  getCountries() {
    return this.fetchWithCache<Country[]>('COUNTRIES', getCountries(), addDaysFromToday(30));
  }

  getRegions() {
    return this.fetchWithCache<Region[]>('REGIONS', getRegions(), addDaysFromToday(30));
  }

  getIndustries() {
    return this.fetchWithCache<Industry[]>('INDUSTRIES', getIndustries(), addDaysFromToday(30));
  }

  getStateProvinces(countryId: Country['id']) {
    return this.fetchWithCache<StateProvince[]>(`STATE_PROVINCE[${countryId}]`, getStateProvinces(countryId), addDaysFromToday(30));
  }

  getMaterialCategories() {
    return this.fetchWithCache<MaterialCategory[]>('MATERIALS_CATEGORIES', getMaterialCategories(), addDaysFromToday(30));
  }

  getAvailableRoles(companyId: CompanyInformation['id']) {
    return this.morganThermalAPI.fetch(getAvailableRoles(companyId));
  }
}