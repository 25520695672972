import { createEndpoint } from '@/shared/models/api';
import { Informative } from '@/app/api/morgan-thermal';
import { MaterialInformation, ThermalConductivityDataPointSave } from '@/app/api/morgan-thermal/material';

export type MaterialUpdate = {
  id: number;
  name: string;
  manufacturer: string;
  materialCategoryId: number;
  originOfManufacturerId?: number;
  isMetric?: boolean;
  isGlobal?: boolean;
  density: number,
  anchorPoint: number,
  useLimit: number,
  specificHeat: number,
  specificHeatTemperature: number,
  classificationTemperature: number,
  kValues: ThermalConductivityDataPointSave[],
};

export const updateMaterial = createEndpoint<Informative & { id: MaterialInformation['id'] }>(({fetch}) => (update: MaterialUpdate) => {
  return fetch(`/Material`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(update),
  });
});

export const updateMaterialAsGlobalAdmin = createEndpoint<Informative & { id: MaterialInformation['id'] }>(({fetch}) => (update: MaterialUpdate) => {
  return fetch(`/Material/GlobalAdminSave`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(update),
  });
});