import { app } from '@/app';
import { enableCompany as enable } from '@/app/api/morgan-thermal/company/enable';
import { ActionType, runServerSideAction } from '@/app/actions';
import { CompanyInformation } from '@/app/api/morgan-thermal/company';

export const enableCompany = (companyId: CompanyInformation['id']) => runServerSideAction({
  type: ActionType.POSITIVE,

  title: 'Enable',
  confirmationMessage: 'This will enable the company and all users who were disabled the same day. Users who were disabled prior to the company being disabled will remain inactive.',
  approveLabel: 'Enable',

  runAction: () => app.morganThermalAPI.fetch(enable(companyId)),
});