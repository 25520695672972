import { observer } from 'mobx-react-lite';
import { useRouteLoaderData } from 'react-router-dom';

import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { Breadcrumbs } from '@/app/routing/breadcrumbs';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { MaterialDetails } from '@/app/details/material';
import { MaterialInformation } from '@/app/api/morgan-thermal/material';

export const MaterialIndexRouteView = observer(() => {
  const material = useRouteLoaderData('my-material') as SynchronizableValue<MaterialInformation | null>;

  return (
    <Page hasOperationsInProgress={material.isSynchronizationInProgress} loaded={!!material.value}>
      <Breadcrumbs/>
      {material.value && <MaterialDetails editUrl={`/my-materials/${material.value.id}/edit`} material={material.value}/>}
    </Page>
  );
});