import { CalculationInformation, CalculationProperties } from '@/app/api/morgan-thermal/calculation';
import { app } from '@/app';
import { MeasurementSystem } from '@/app/measurement-systems/model';
import { roundDecimals } from '@/shared/helpers/number';

export const createInitialValues = (calculation?: CalculationInformation) => {
  const calculationProperties: CalculationProperties | undefined = (calculation ?? {})[app.measurementSystems.currentSystem];
  const calculationMaterials = calculation?.calculationMaterials ?? [];

  return {
    name: calculation?.name ?? '',
    casingId: calculation?.casingId ?? null,
    insideTemperature: roundDecimals(calculationProperties?.hotFaceTemperature) ?? '',
    outsideTemperature: roundDecimals(calculationProperties?.ambientTemperature, (app.measurementSystems.currentSystem === MeasurementSystem.METRIC) ? 1 : 0) ?? '',
    outsideEmissivity: roundDecimals(calculationProperties?.emissivity, 2) ?? '',
    outsideWindSpeed: roundDecimals(calculationProperties?.outsideWindSpeed, 1) ?? 0,
    diameter: roundDecimals(calculationProperties?.diameter, (app.measurementSystems.currentSystem === MeasurementSystem.METRIC) ? 0 : 2) ?? '',
    calculationMaterials: calculationMaterials.slice().sort((a, b) => a.order - b.order).map((material) => ({
      id: material.id,
      isActive: material.isActive,
      materialId: material.materialId,
      name: material.materialName,
      metric: {
        ...material.metric,
        density: roundDecimals(material.metric.density) ?? undefined,
        temperature: roundDecimals(material.metric.temperature),
      },
      imperial: {
        ...material.imperial,
        density: roundDecimals(material.imperial.density) ?? undefined,
        temperature: roundDecimals(material.imperial.temperature),
      },
      thickness: roundDecimals(material[app.measurementSystems.currentSystem].thickness, (app.measurementSystems.currentSystem === MeasurementSystem.METRIC) ? 0 : 2) ?? '',
    })),
  };
};