import { ReactElement, useEffect, useState } from 'react';

import { createPromiseComponent } from '@/shared/design-system/thermal-ceramics/components/promise';
import { Modal } from '@/shared/design-system/thermal-ceramics/components/modal';

export const showPromiseModal = (executor: (resolve: () => void, reject: () => void) => ReactElement) => {
  const { promise, resolve, reject, render, dispose } = createPromiseComponent({ manualDispose: true });

  function PromiseModalComponent() {
    const [isOpened, setIsOpened] = useState(false);

    useEffect(() => setIsOpened(true), []);

    function handleClose() {
      reject(false);
      setIsOpened(false);
    }

    function handleApprove() {
      resolve(true);
      setIsOpened(false);
    }

    return (
      <Modal onClose={handleClose} onAfterLeave={dispose} opened={isOpened}>
        {executor(handleApprove, handleClose)}
      </Modal>
    );
  }

  render(<PromiseModalComponent/>);

  return promise.then(() => true).catch(() => false);
};