import { number } from '@/shared/libs/yup';
import { HookFormField, FieldInputProps } from '@/shared/libs/hook-form';
import { ValueMeasurement } from '@/app/form/fields/material/helpers/value-measurement';

export const diameterSchema = number().label('Diameter');

export const DiameterHookFormField = (props: FieldInputProps) => (
  <HookFormField.Input
    label="Diameter"
    extendProps={(props) => ({
      ...props,
      endSlot: <ValueMeasurement imperial={<>In</>} metric={<>mm</>}/>
    })}
    {...props}
  />
);