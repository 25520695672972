import { ReactElement, useCallback, useEffect, useState } from 'react';
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FieldValues, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { app } from '@/app';
import { Fieldset } from '@/shared/design-system/thermal-ceramics/components/form/fieldset';
import { MaterialInformation, MaterialListingItem } from '@/app/api/morgan-thermal/material';
import { MeasurementSystem } from '@/app/measurement-systems/model';
import { CalculationInformation } from '@/app/api/morgan-thermal/calculation';
import { CalculationSave } from '@/app/api/morgan-thermal/calculation/save';

import { createValidationSchema } from './validation';
import { createInitialValues } from './initial-values';
import { OperatingParametersFieldset } from '@/app/form/scenarios/save-calculation/operating-parameters';
import { CasingConditionsFieldset } from '@/app/form/scenarios/save-calculation/casing-conditions';
import { PersonalizedMaterialListing } from '@/app/listing/personalized-material';
import { LiningDesignFieldset } from '@/app/form/scenarios/save-calculation/lining-design';
import { ProjectInformation } from '@/app/api/morgan-thermal/project';
import { CalculationResult } from '@/app/details/calculation/result';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';

import classNames from './styles.module.css';
import { convertTemperature } from '@/app/api/morgan-thermal/converter/temperature';
import { convertEmissivity } from '@/app/api/morgan-thermal/converter/emissivity';
import { convertSpeed } from '@/app/api/morgan-thermal/converter/speed';
import { convertDistance } from '@/app/api/morgan-thermal/converter/distance';
import { FetchAPI } from '@/shared/models/api';
import { roundDecimals } from '@/shared/helpers/number';
import { Notification, NotificationType } from '@/shared/design-system/thermal-ceramics/components/notification';

export type SaveCalculationFormProps = {
  projectId: ProjectInformation['id'],
  calculation?: CalculationInformation;
  submit: (data: CalculationSave) => void;
  footer: ReactElement;
};

export type SaveCalculationFormValues = {
  name: string,
  casingId: number | null,
  insideTemperature: number | string
  outsideTemperature: number | string
  outsideEmissivity: number | string
  outsideWindSpeed: number | string
  diameter: number | string
  calculationMaterials: {
    id: number,
    isActive: boolean,
    materialId: MaterialInformation['id'],
    name: MaterialInformation['name'],
    metric: MaterialInformation['metric'] & { temperature?: number | null },
    imperial: MaterialInformation['imperial'] & { temperature?: number | null },
    thickness: number | string,
  }[],
};

export const SaveCalculationForm = observer((props: SaveCalculationFormProps) => {
  const { submit, projectId, calculation, footer } = props;

  const [selectedMaterialListingItems, setSelectedMaterialListingItems] = useState<MaterialListingItem[]>([]);
  const initiallySelectedIds = calculation ? calculation.calculationMaterials.map(({ materialId }) => materialId) : [];

  const form = useForm<SaveCalculationFormValues>({
    mode: 'onTouched',
    defaultValues: createInitialValues(calculation),
    resolver: yupResolver(createValidationSchema(app.measurementSystems.currentSystem)),
  });

  const calculationMaterials = useFieldArray({
    control: form.control,
    name: 'calculationMaterials',
  });

  const handleValidSubmit = useCallback((values: FieldValues) => {
    submit({
      ...values,
      id: calculation?.id ?? 0,
      projectId,
      isMetric: app.measurementSystems.currentSystem === MeasurementSystem.METRIC,
      calculationMaterials: values.calculationMaterials.map((material: SaveCalculationFormValues['calculationMaterials'][0], index: number) => ({
        id: material.id,
        materialId: material.materialId,
        thickness: material.thickness,
        order: index,
      })),
    } as CalculationSave);
  }, [calculation, projectId, submit]);

  useEffect(() => {
    return reaction(() => app.measurementSystems.currentSystem, () => {
      const { setValue } = form;
      const isMetric = app.measurementSystems.currentSystem === MeasurementSystem.METRIC;
      const convertions = [
        { name: 'insideTemperature', converter: convertTemperature, modifier: roundDecimals },
        { name: 'outsideTemperature', converter: convertTemperature, modifier: (number: any) => roundDecimals(number, isMetric ? 1 : 0) },
        { name: 'outsideEmissivity', converter: convertEmissivity, modifier: (number: any) => roundDecimals(number, 2) },
        { name: 'outsideWindSpeed', converter: convertSpeed, modifier: (number: any) => roundDecimals(number, 1) },
        { name: 'diameter', converter: convertDistance, modifier: (number: any) => roundDecimals(number, isMetric ? 0 : 2) },
      ];

      const convert = ({ name, converter, modifier }: { name: string, converter: (...args: any[]) => {fetch(fetchAPI: FetchAPI<number>): Promise<number>}, modifier?: (number: number | undefined | null) => number | null }) => {
        // @ts-ignore
        const value = form.getValues(name);

        if (value === '') return;

        const parsedValue = Number(value);

        if (Number.isNaN(parsedValue)) return;

        app.morganThermalAPI.fetch(converter({ isMetric, value })) // @ts-ignore
          .then((value) => { setValue(name, modifier ? modifier(value) : value) })
          .catch(console.error);
      };

      convertions.forEach(convert);

      calculationMaterials.fields.forEach((_, index) => {
        convert({ name: `calculationMaterials.${index}.thickness`, converter: convertDistance, modifier: (number: any) => roundDecimals(number, isMetric ? 0 : 2) });
      });
    });
  }, [form, calculationMaterials]);

  useEffect(() => {
    const subscription = form.watch((value, { name, type }) => {
      if (name !== 'casingId' || type !== 'change') return;
      form.resetField('diameter', { defaultValue: form.getValues('diameter') });
    });

    return () => subscription.unsubscribe();
  }, [form]);

  const handleSelectMaterial = (material: MaterialListingItem) => {
    if (calculationMaterials.fields.find((field) => field.materialId === material.id)) return;
  
    calculationMaterials.append({
      id: 0,
      isActive: material.isActive,
      materialId: material.id,
      name: material.name,
      metric: material.metric,
      imperial: material.imperial,
      thickness: '',
    });
  
    form.trigger('calculationMaterials', {
      shouldFocus: false,
    });
  };

  const handleUnselectMaterial = (id: MaterialListingItem['id'], removeAll: boolean = false) => {
    if (removeAll) {
      const indexesForRemoval: number[] = [];

      calculationMaterials.fields.forEach(({ materialId }, index) => {
        if (materialId === id) indexesForRemoval.push(index);
      });

      calculationMaterials.remove(indexesForRemoval);
      setSelectedMaterialListingItems(selectedMaterialListingItems.filter((material) => material.id !== id));

      return;
    }

    const calculationMaterialsIndex = calculationMaterials.fields.findIndex(({ materialId }) => materialId === id);
    if (calculationMaterialsIndex != -1) calculationMaterials.remove(calculationMaterialsIndex);
    const hasDuplicateMaterials = (calculationMaterials.fields.filter(({ materialId }) => materialId === id).length - 1) > 0;
    if (hasDuplicateMaterials) return;
    setSelectedMaterialListingItems(selectedMaterialListingItems.filter((material) => material.id !== id));
  };

  const handleSwapMaterials = (fromIndex: number, toIndex: number) => {
    const swappedMaterials = [...selectedMaterialListingItems];
    const temp = swappedMaterials[fromIndex];

    swappedMaterials[fromIndex] = swappedMaterials[toIndex];
    swappedMaterials[toIndex] = temp;

    setSelectedMaterialListingItems(swappedMaterials);
  };

  return (
    <FormProvider {...form}>
      <Notification className={classNames.info} type={NotificationType.ATTENTION}>
        <Notification.Title>Information</Notification.Title>
        <Notification.Body>
          <ul>
            <li>* indicates a required field</li>
            <li>Calculation data is not automatically saved. To save, please use the "Calculate" button.</li>
          </ul>
        </Notification.Body>
      </Notification>
      <form className={classNames.saveCalculationForm} onSubmit={form.handleSubmit(handleValidSubmit)} noValidate>
        <OperatingParametersFieldset/>
        <CasingConditionsFieldset/>
        <PersonalizedMaterialListing
          isDisabled={calculationMaterials.fields.length >= 15}
          initiallySelectedIds={initiallySelectedIds}
          selectedRows={selectedMaterialListingItems}
          onSelect={handleSelectMaterial}
          onUnselect={({ id }) => handleUnselectMaterial(id, true)}
          onSelectionChange={setSelectedMaterialListingItems}
        />
        <LiningDesignFieldset
          calculationMaterials={calculationMaterials}
          onSwap={handleSwapMaterials}
          onUnselect={handleUnselectMaterial}
        />
        {calculation && (
          <div className={classNames.result}>
            <Heading as="h2" className={classNames.resultHeading}>Calculation Preview</Heading>
            <CalculationResult calculation={calculation}/>
          </div>
        )}
        {footer && (
          <Fieldset.Footer>{footer}</Fieldset.Footer>
        )}
      </form>
    </FormProvider>
  );
});