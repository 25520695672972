import { HTMLAttributes, MouseEvent, PropsWithChildren, useContext } from 'react';
import { clsx } from 'clsx';
import { ToggleButtonGroupContext } from '@/shared/design-system/thermal-ceramics/components/toggle-button/group';
import classNames from './styles.module.css';

export type ToggleButtonValue = any;

export type ToggleButtonProps =
  PropsWithChildren &
  Omit<HTMLAttributes<HTMLButtonElement>, 'aria-pressed' | 'type' | 'className' | 'value'> & {
    value?: ToggleButtonValue,
    pressed?: boolean,
    nullable?: boolean,
  };

export const ToggleButton = (props: ToggleButtonProps) => {
  const { children, pressed, value, nullable, onClick, ...attributes } = props;
  const groupContext = useContext(ToggleButtonGroupContext);
  const isPressed = pressed ?? groupContext.value === value;
  const className = clsx(classNames.button, {
    [classNames.pressed]: isPressed,
  });

  function handleOnClick(event: MouseEvent<HTMLButtonElement>) {
    if (onClick) onClick(event);
    if (nullable) return groupContext.handleChange(value);
    if (value !== undefined && value !== null) groupContext.handleChange(value);
  }

  return (
    <button
      {...attributes}
      className={className}
      type="button"
      aria-pressed={isPressed}
      value={value}
      onClick={handleOnClick}
    >
      {children}
    </button>
  );
};