import {
  Context,
  ContextType, createContext,
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  ReactNode, useContext,
  useEffect,
  useState
} from 'react';

import { Modal } from '@/shared/design-system/thermal-ceramics/components/modal';
import { createPromiseComponent } from '@/shared/design-system/thermal-ceramics/components/promise';

export enum ActionModalState {
  CONFIRMATION = 'confirmation',
  IN_PROGRESS = 'in_progress',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

export enum ActionModalResultStatus {
  DECLINED = 'declined',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

export type ActionModalResult<TType> = {
  status: ActionModalResultStatus,
  result: TType | null,
};

export type ShowActionModalProps<TType extends unknown> = {
  runAction: (data?: any) => Promise<TType>,

  renderConfirmation: (approve: (data?: any) => void, decline: () => void) => ReactNode,
  renderInProgress: () => ReactNode,
  renderResult: (result: TType, isFulfilled: boolean, close: () => void) => ReactNode,
};

export const showActionModal = <TType extends unknown,>(props: ShowActionModalProps<TType>) => {
  const { runAction, renderConfirmation, renderInProgress, renderResult } = props;
  const { promise, resolve, reject, render, dispose } = createPromiseComponent<ActionModalResult<TType>>({ manualDispose: true });

  function ActionModalComponent() {
    const [isOpened, setIsOpened] = useState(false);
    const [state, setState] = useState(ActionModalState.CONFIRMATION);
    const [result, setResult] = useState<TType | null>(null);

    useEffect(() => setIsOpened(true), []);

    const decline = () => {
      reject({
        status: ActionModalResultStatus.DECLINED,
        result,
      });
      setIsOpened(false);
    };

    const approve = (data?: unknown) => {
      setState(ActionModalState.IN_PROGRESS);

      runAction(data)
        .then((result) => {
          setState(ActionModalState.FULFILLED);
          setResult(result);
        })
        .catch((result) => {
          setState(ActionModalState.REJECTED);
          setResult(result);
        });
    };

    const close = () => {
      if (state !== ActionModalState.IN_PROGRESS) setIsOpened(false);

      switch (state) {
        case ActionModalState.CONFIRMATION: return decline();
        case ActionModalState.IN_PROGRESS: return {};
        case ActionModalState.FULFILLED: return resolve({
          status: ActionModalResultStatus.FULFILLED,
          result,
        });
        case ActionModalState.REJECTED: return reject({
          status: ActionModalResultStatus.REJECTED,
          result,
        });
        default: return {};
      }
    };

    const renderState = () => {
      switch (state) {
        case ActionModalState.CONFIRMATION: return renderConfirmation(approve, decline);
        case ActionModalState.IN_PROGRESS: return renderInProgress();
        case ActionModalState.FULFILLED: return renderResult(result as TType, true, close);
        case ActionModalState.REJECTED: return renderResult(result as TType, false, close);
        default: return null;
      }
    };

    return (
      <Modal onClose={close} onAfterLeave={dispose} opened={isOpened}>{renderState()}</Modal>
    );
  }

  render(<ActionModalComponent/>);

  return promise;
};