import { createColumnHelper } from '@tanstack/react-table';

import { CompanyListingItem } from '@/app/api/morgan-thermal/company';
import { isoToReadableString } from '@/shared/helpers/date';
import { FillLink } from '@/shared/design-system/thermal-ceramics/components/fill-link';
import { CompanyOrderByOptions } from '@/app/api/morgan-thermal/company/search';

const columnHelper = createColumnHelper<CompanyListingItem>();

export const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
    size: 310,
    meta: {
      orderBy: CompanyOrderByOptions.Name,
    },
    cell: info => <FillLink to={`/companies/${info.row.original.id}`}>{info.renderValue()}</FillLink>,
  }),
  columnHelper.accessor('address', {
    header: 'Address',
    size: 285,
    meta: {
      orderBy: CompanyOrderByOptions.Address,
    },
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('country', {
    header: 'Country',
    size: 248,
    meta: {
      orderBy: CompanyOrderByOptions.Country,
    },
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('domains', {
    header: 'Domains',
    size: 140,
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('createdDate', {
    header: 'Created on',
    size: 168,
    meta: {
      orderBy: CompanyOrderByOptions.CreatedDate,
    },
    cell: info => isoToReadableString(info.getValue()),
  }),
];