import { HTMLProps, ReactNode, useId } from 'react';
import { Label } from '@/shared/design-system/thermal-ceramics/components/form/label';
import { Hint, HintType } from '@/shared/design-system/thermal-ceramics/components/form/hint';
import { observer } from 'mobx-react-lite';
import classNames from './styles.module.css';

export type FormFieldControlProps = {
  id: string,
  required: boolean,
  disabled: boolean,
  'aria-invalid': boolean,
  'aria-describedby': string | undefined,
};

export type FormFieldProps = {
  label?: string,
  hint?: string,
  error?: string,
  required?: boolean,
  disabled?: boolean,
  renderControl: (props: FormFieldControlProps) => ReactNode,
};

export const FormField = observer((props: FormFieldProps) => {
  const { label, hint, error, required = false, disabled = false, renderControl } = props;
  const id = useId();
  const hintId = useId();
  const errorId = useId();

  function renderFieldControl() {
    const props: FormFieldControlProps = {
      id,
      required,
      disabled,
      'aria-invalid': Boolean(error),
      'aria-describedby': Boolean(error) ? errorId : (Boolean(hint) ? hintId : undefined),
    };

    return renderControl(props);
  }

  return (
    <div className={classNames.field}>
      {label && <Label htmlFor={id}>{label} {required && (<span aria-hidden="true">*</span>)}</Label>}
      {renderFieldControl()}
      {hint && !error && <Hint id={hintId} type={HintType.DEFAULT}>{hint}</Hint>}
      {error && <Hint id={errorId} type={HintType.ERROR}>{error}</Hint>}
    </div>
  );
});