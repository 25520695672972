import { Container } from '@/shared/design-system/thermal-ceramics/components/container';
import { Navigation } from '@/shared/design-system/thermal-ceramics/components/navigation';
import { useMatch, useRouteError } from 'react-router-dom';
import { app } from '@/app';

import classNames from './styles.module.css';

export const SiteNavigation = () => {
  const indexRoute = useMatch('/');
  const routerError = useRouteError();
  const companiesRoute = useMatch({ path: '/companies', end: false });
  const materialsRoute = useMatch({ path: '/materials', end: false });
  const myMaterialsRoute = useMatch({ path: '/my-materials', end: false });

  if (!app.session.user.value) return null;

  const isMaterialsVisible = app.session.isGlobalAdmin;
  const isCompaniesVisible = (app.session.isGlobalAdmin || app.session.isGlobalCompanyManagerAdmin || app.session.isCompanyAdmin);
  const isNavigationVisible = ((indexRoute || companiesRoute || materialsRoute || myMaterialsRoute) && !routerError);

  if (!isNavigationVisible) return null;

  const companyLink = app.session.isCompanyAdmin ? `/companies/${app.session.user.value?.companyId}` : '/companies';
  const companyLabel = app.session.isCompanyAdmin ? 'My Company' : 'Companies';

  return (
    <section className={classNames.siteNavigation}>
      <Container>
        <Navigation>
          <Navigation.Item to="/" active={myMaterialsRoute ? true : undefined}>Dashboard</Navigation.Item>
          {isCompaniesVisible && (
            <Navigation.Item to={companyLink}>{companyLabel}</Navigation.Item>
          )}
          {isMaterialsVisible && <Navigation.Item to="/materials">Materials</Navigation.Item>}
        </Navigation>
      </Container>
    </section>
  );
};