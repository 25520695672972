import { useEffect, useState } from 'react';
import { MaterialInformation } from '@/app/api/morgan-thermal/material';
import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';

import classNames from './styles.module.css';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';
import { NavLink, useSearchParams } from 'react-router-dom';
import { Breadcrumbs } from '@/app/routing/breadcrumbs';
import { MeasurementSystemsToggle } from '@/app/measurement-systems/view';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { app } from '@/app';
import { getMaterial } from '@/app/api/morgan-thermal/material/get';
import { observer } from 'mobx-react-lite';
import { Table } from '@/shared/design-system/thermal-ceramics/components/table';
import { ValueMeasurement, ValueMeasurementString } from '@/app/form/fields/material/helpers/value-measurement';
import { clsx } from 'clsx';
import { printMaterialComparison } from '@/app/actions/material/compare/print';

export const CompareMaterialsRouteView = observer(() => {
  const [searchParams] = useSearchParams();
  const materialIds = (searchParams.get('ids') ?? '').split(',').filter((id) => id).map((id) => Number.parseInt(id));
  const hasMaterials = materialIds.length > 0;
  const [materials] = useState(() => new SynchronizableValue<MaterialInformation[]>([], () => {
    return Promise.all(materialIds.map((id) => app.morganThermalAPI.fetch(getMaterial(id))));
  }));
  const [isPrintingInProgress, setIsPrintingInProgress] = useState(false);

  let kValues: { [key: number]: { [index: number]: number } } = {};

  materials.value.map((material, index) => {
    material.thermalConductivityValues.map((thermalConductivityValue) => {
      const temperature = thermalConductivityValue[app.measurementSystems.currentSystem].temperature;
      kValues[temperature] = kValues[temperature] ?? {};
      kValues[temperature][index] = thermalConductivityValue[app.measurementSystems.currentSystem].kValue;
    });
  });

  const kValuesColumns = Object.keys(kValues)
    .map((temperature) => Number(temperature))
    .sort((a, b) => a - b)
    .map((temperature) => {
      const columns: number[] = [temperature];

      for (let index = 0; index < materials.value.length; index++) {
        columns.push(kValues[temperature][index] ?? NaN);
      }

      return columns;
    });


  const handlePrintClick = () => {
    setIsPrintingInProgress(true);
    printMaterialComparison(materialIds).finally(() => setIsPrintingInProgress(false));
  };


  useEffect(() => {
    materials.synchronize().catch(console.error);
  }, []);

  const columnSizer = (
    <colgroup>
      <col width={240}/>
      {materials.value.map((material) => (
        <col key={material.id} width={(1240 - 240) / materials.value.length}/>
      ))}
    </colgroup>
  );

  return (
    <Page className={classNames.compare} hasOperationsInProgress={hasMaterials ? materials.isSynchronizationInProgress : false} loaded={hasMaterials ? !!materials.value : true}>
      <Breadcrumbs/>
      <div className={classNames.header}>
        <Heading as="h1">Compare Materials</Heading>
        {hasMaterials && (
          <div className={classNames.actions}>
            <Button modifier={ButtonModifier.OUTLINE} as={NavLink} to={`/my-materials?selectedMaterialIds=${(searchParams.get('ids') ?? '')}`}>Revise</Button>
            <Button modifier={ButtonModifier.OUTLINE} onClick={handlePrintClick} inProgress={isPrintingInProgress}>Save as PDF</Button>
            <MeasurementSystemsToggle/>
          </div>
        )}
      </div>
      {hasMaterials ? (
        <>
          <div className={classNames.overviewTableWrap}>
            <Table>
              {columnSizer}
              <Table.Head>
                <Table.Row>
                  <Table.HeadCell>Overview</Table.HeadCell>
                  {materials.value.map((material) => (<Table.HeadCell key={material.id}/>))}
                </Table.Row>
              </Table.Head>
              <Table.Body>
                <Table.Row>
                  <Table.Cell className={classNames.label}>Name</Table.Cell>
                  {materials.value.map((material) => <Table.Cell key={material.id}>{material.name}</Table.Cell>)}
                </Table.Row>
                <Table.Row>
                  <Table.Cell className={classNames.label}>Manufacturer</Table.Cell>
                  {materials.value.map((material) => <Table.Cell key={material.id}>{material.manufacturer}</Table.Cell>)}
                </Table.Row>
                <Table.Row>
                  <Table.Cell className={classNames.label}>Material Category</Table.Cell>
                  {materials.value.map((material) => <Table.Cell key={material.id}>{material.materialCategory}</Table.Cell>)}
                </Table.Row>
                <Table.Row>
                  <Table.Cell className={classNames.label}>Classification Temperature</Table.Cell>
                  {materials.value.map((material) => <Table.Cell key={material.id}>
                    {material[app.measurementSystems.currentSystem].classificationTemperature} <ValueMeasurementString hint={material[app.measurementSystems.currentSystem].classificationTemperatureLabel}/>
                  </Table.Cell>)}
                </Table.Row>
                <Table.Row>
                  <Table.Cell className={classNames.label}>Density</Table.Cell>
                  {materials.value.map((material) => <Table.Cell key={material.id}>
                    {material[app.measurementSystems.currentSystem].density} <ValueMeasurementString hint={material[app.measurementSystems.currentSystem].densityLabel}/>
                  </Table.Cell>)}
                </Table.Row>
              </Table.Body>
            </Table>
          </div>

          <Table>
            {columnSizer}
            <Table.Head>
              <Table.Row>
                <Table.HeadCell>Data points</Table.HeadCell>
                {materials.value.map((material) => (<Table.HeadCell key={material.id}/>))}
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Cell></Table.Cell>
                {materials.value.map((material) => (
                  <Table.Cell key={material.id}>
                    <ValueMeasurement
                      imperial={<>BTU</>}
                      metric={<><sup>W</sup>/<sub>M&#215;K</sub></>}
                    />
                  </Table.Cell>
                ))}
              </Table.Row>
              {kValuesColumns.map(([temperature, ...kValues]) => (
                <Table.Row key={temperature}>
                  <Table.Cell className={classNames.label}>
                    Temperature {temperature} <ValueMeasurement imperial={<>&deg;F</>} metric={<>&deg;C</>}/>
                  </Table.Cell>
                  {kValues.map((kValue, index) => (
                    <Table.Cell key={`${kValue}-${index}`}>
                      {Number.isNaN(kValue) ? '' : kValue}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      ) : (
        <p>No items selected for comparison</p>
      )}
    </Page>
  );
});