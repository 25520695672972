import { action, makeObservable, observable } from 'mobx';
import { Filter } from '@/shared/models/filters';
import { CompanyInformation } from '@/app/api/morgan-thermal/company';
import { app } from '@/app';
import { Listing } from '@/app/listing/model';
import {
  searchUsers,
  UserOrderByOptions,
  UserSearchFilters,
  UserSearchResponse
} from '@/app/api/morgan-thermal/user/search';
import { UserInformation } from '@/app/api/morgan-thermal/user';
import { enableUser } from '@/app/actions/user/enable';
import { disableUser } from '@/app/actions/user/disable';
import { sendPasswordReset } from '@/app/actions/user/send-password-reset';

export type UserListingModelOptions = {
  companyId: CompanyInformation['id'];
};

export class UserListingModel {
  companyId: CompanyInformation['id'];

  showDisabledFilter: Filter<boolean | null>;
  nameOrEmailFilter: Filter<string>;

  listing: Listing<UserSearchResponse, UserSearchFilters, UserOrderByOptions>;

  constructor(options: UserListingModelOptions) {
    makeObservable(this, {
      companyId: observable,
      nameOrEmailFilter: observable,
      listing: observable,

      enable: action.bound,
      disable: action.bound,
      sendPasswordReset: action.bound,
    });

    this.companyId = options.companyId;

    this.nameOrEmailFilter = new Filter<string>({
      name: 'nameOrEmail',
      initialValue: '',
      debouncedBy: 300,
    });

    this.showDisabledFilter = new Filter<boolean | null>({
      name: 'isActive',
      initialValue: null,
    });

    this.listing = new Listing<UserSearchResponse, UserSearchFilters, UserOrderByOptions>({
      filters: [this.showDisabledFilter, this.nameOrEmailFilter],
      synchronizer: (filters) => app.morganThermalAPI.fetch(searchUsers(this.companyId, filters)),
    });
    this.listing.search();
  }

  enable(id: UserInformation['id']) {
    enableUser(id).then(this.listing.search).catch(console.error);
  }

  disable(id: UserInformation['id']) {
    disableUser(id).then(this.listing.search).catch(console.error);
  }

  sendPasswordReset(id: UserInformation['id']) {
    sendPasswordReset(id).then(this.listing.search).catch(console.error);
  }
}