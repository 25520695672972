import { app } from '@/app';
import { disableMaterial as disable } from '@/app/api/morgan-thermal/material/disable';
import { ActionType, runServerSideAction } from '@/app/actions';
import { MaterialInformation } from '@/app/api/morgan-thermal/material';

export const disableMaterial = (id: MaterialInformation['id']) => runServerSideAction({
  type: ActionType.NEGATIVE,

  title: 'Disable',
  confirmationMessage: 'Disable this material?',
  approveLabel: 'Disable',

  runAction: () => app.morganThermalAPI.fetch(disable(id)),
});