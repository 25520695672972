import { PropsWithChildren, ReactElement } from 'react';

import { showPromiseModal } from '@/shared/design-system/thermal-ceramics/components/modal/promise';
import { Modal } from '@/shared/design-system/thermal-ceramics/components/modal';
import { Button } from '@/shared/design-system/thermal-ceramics/components/button';
import { AcceptIcon, AlertIcon, CriticalIcon } from '@/shared/design-system/thermal-ceramics/icons';

export enum ShowConfirmationModalType {
  DEFAULT = 'default',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

export type ConfirmationModalContentProps = {
  type?: ShowConfirmationModalType,
};

export type ShowConfirmationModalProps = ConfirmationModalContentProps & {
  body?: (resolve: () => void, reject: () => void) => ReactElement,
  footer?: (resolve: () => void, reject: () => void) => ReactElement,
};

export type ShowTypedModalProps = Omit<ShowConfirmationModalProps, 'type'>;

const renderIcon = (type: ShowConfirmationModalType) => {
  switch (type) {
    case ShowConfirmationModalType.SUCCESS: return <AcceptIcon aria-hidden="true"/>;
    case ShowConfirmationModalType.ERROR: return <CriticalIcon aria-hidden="true"/>;
    case ShowConfirmationModalType.WARNING: return <AlertIcon aria-hidden="true"/>;
    default: return null;
  }
};

export const ConfirmationModalContent = ({ type = ShowConfirmationModalType.DEFAULT, children }: PropsWithChildren<ConfirmationModalContentProps>) => {
  const icon = renderIcon(type);

  return (
    <>
      {icon && <Modal.Header>{icon}</Modal.Header>}
      {children}
    </>
  );
};

export const showConfirmationModal = ({ type = ShowConfirmationModalType.DEFAULT, body, footer }: ShowConfirmationModalProps) => {
  return showPromiseModal((resolve, reject) => (
    <ConfirmationModalContent type={type}>
      {body && <Modal.Body>{body(resolve, reject)}</Modal.Body>}
      <Modal.Footer>
        {footer ? footer(resolve, reject) : <Button onClick={resolve}>OK</Button>}
      </Modal.Footer>
    </ConfirmationModalContent>
  ));
};

export const showSuccessModal = (props: ShowTypedModalProps) => showConfirmationModal({ type: ShowConfirmationModalType.SUCCESS, ...props });

export const showErrorModal = (props: ShowTypedModalProps) => showConfirmationModal({ type: ShowConfirmationModalType.ERROR, ...props });

export const showWarningModal = (props: ShowTypedModalProps) => showConfirmationModal({ type: ShowConfirmationModalType.WARNING, ...props });