import { EntityDetails } from '@/app/details';
import { ProjectInformation } from '@/app/api/morgan-thermal/project';
import { isoToReadableString } from '@/shared/helpers/date';

export type ProjectDetailsProps = {
  editUrl?: string,
  project: ProjectInformation
};

export const ProjectDetails = (props: ProjectDetailsProps) => {
  const { project, editUrl } = props;

  return (
    <EntityDetails
      editUrl={editUrl}
      mainInformation={[
        { key: 'Project name', value: project.project },
        { key: 'Revision number', value: `#${project.revisionNumber}` },
        { key: 'Customer', value: project.customer },
        { key: 'Revision Date', value: isoToReadableString(project.revisionDate) },
      ]}
    />
  );
};