import { ReactElement, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';


import { app } from '@/app';
import { validateEmailChange } from '@/app/api/morgan-thermal/user/validate-email-change';
import { CountryField } from '@/app/form/fields/common/country';
import { StateProvinceField, } from '@/app/form/fields/common/state-province';
import { Fieldset } from '@/shared/design-system/thermal-ceramics/components/form/fieldset';
import { NameHookFormField } from '@/app/form/fields/common/name';
import { AddressField } from '@/app/form/fields/common/address';
import { EmailField } from '@/app/form/fields/common/email';
import { JobField } from '@/app/form/fields/common/job';
import { CityField } from '@/app/form/fields/common/city';
import { PostalCodeField } from '@/app/form/fields/common/postal-code';
import { PhoneField } from '@/app/form/fields/common/phone';
import { useEmailVerification } from '@/app/form/scenarios/email-verification';
import { Informative } from '@/app/api/morgan-thermal';
import { UserInformation, UserRole } from '@/app/api/morgan-thermal/user';
import { SaveUser } from '@/app/api/morgan-thermal/user/update';
import { CompanyInformation } from '@/app/api/morgan-thermal/company';
import { UserRoleField, useUserRoleSelector } from '@/app/form/fields/user/role';
import { GlobalLocationContextProvider, useGlobalLocationSelector } from '@/app/form/fields/common/global-location';

import { createValidationSchema } from './validation';
import { createInitialValues } from './initial-values';
import classNames from './styles.module.css';
import { RequiredHint } from '@/app/form/required-hint';

export type SaveUserFormProps = {
  user?: UserInformation;
  companyId: CompanyInformation['id'];
  submit: (data: SaveUser) => void;
  footer: ReactElement;
};

export const SaveUserForm = observer((props: SaveUserFormProps) => {
  const { submit, user, companyId, footer } = props;
  const canEditRole = (app.session.isAdmin && !(user?.roleId === UserRole.GlobalAdministrator && app.session.isCompanyAdmin));

  const globalLocationSelector = useGlobalLocationSelector({
    countryIdName: 'countryId',
    stateProvinceIdName: 'stateProvinceId',
  });

  const rolesSelector = useUserRoleSelector(companyId);

  const form = useForm({
    mode: 'onTouched',
    defaultValues: createInitialValues(user),
    resolver: yupResolver(createValidationSchema({
      canEditRole,
      hasStateProvinces: globalLocationSelector.stateProvincesSelector.hasOptions,
    })),
  });

  const { setError, handleSubmit } = form;

  const emailVerification = useEmailVerification<Informative>({
    form,
    name: 'email',
    verify: (email: string) => app.morganThermalAPI.fetch(validateEmailChange(user?.id ?? 0, email)),
    onError: (result) => {
      setError('email', { type: 'custom', message: result.errorMessage });
    },
  });

  const handleValidSubmit = useCallback((values: FieldValues) => {
    submit({
      ...values,
      id: user?.id ?? 0,
      roleId: values.roleId ?? user?.roleId ?? UserRole.User,
      companyId: companyId,
    } as SaveUser);
  }, [companyId, user, submit]);

  return (
    <FormProvider {...form}>
      <RequiredHint/>
      <form onSubmit={handleSubmit(handleValidSubmit)} noValidate>
        <Fieldset className={classNames.fieldset}>
          <Fieldset.Grid>
            <GlobalLocationContextProvider globalLocationSelector={globalLocationSelector}>
              <NameHookFormField name="name" required/>
              <AddressField name="address" label="Company Address" required/>
              <EmailField name="email" label="Email" inProgress={emailVerification.inProgress} hint={emailVerification.inProgress ? 'Verifying email...' : ''} onBlur={emailVerification.onBlur} required/>
              <AddressField name="additionalAddress" label="Additional Address"/>
              <JobField name="job" label="Job Title"/>
              <CityField name="city" label="City" required/>
              <PhoneField name="contactPhone" label="Contact Phone" required/>
              <CountryField name="countryId" countries={globalLocationSelector.countriesSelector.countries} required/>
              <PhoneField name="secondaryPhone" label="Secondary Phone"/>
              {globalLocationSelector.stateProvincesSelector.hasOptions && (
                <StateProvinceField name="stateProvinceId" stateProvinces={globalLocationSelector.stateProvincesSelector.stateProvinces} required/>
              )}
              <PostalCodeField name="zip" label="Postal Code" required/>
              {canEditRole && (
                <UserRoleField name="roleId" roles={rolesSelector.roles} required={app.session.isAdmin}/>
              )}
            </GlobalLocationContextProvider>
          </Fieldset.Grid>

          {footer && (<Fieldset.Footer>{footer}</Fieldset.Footer>)}
        </Fieldset>
      </form>
    </FormProvider>
  );
});