import { createAPI, API } from '@/shared/models/api';

export type Informative = {
  errorMessage?: string,
  informationMessage?: string,
  informationMessageHeader?: string,
};

export type IdValue = {
  id: number,
  name: string,
}

export type Pagination = {
  page: number,
  numberOfRecords: number,
  totalRecords: number,
}

export type SearchResponse<TType> = Pagination & { results: TType };

export type SearchFilters<TType> = TType & { page: Pagination['page'], numberOfRecords: Pagination['numberOfRecords'] }

export type MorganThermalAPI = API;

export function createMorganThermalAPI(baseUrl: URL | string, getAccessToken: () => Promise<string>): API {
  return createAPI(baseUrl, async () => {
    const accessToken = await getAccessToken().catch(() => null);
    const headers = accessToken ? {'Authorization': `Bearer ${accessToken}`} : [];

    return { headers };
  });
}


export type File = {
  name: string;
  bytes: string;
  contentType: string;
};