import { HTMLAttributes, PropsWithChildren } from 'react';
import { clsx } from 'clsx';
import { VisuallyHidden } from '@/shared/design-system/thermal-ceramics/components/visually-hidden';
import { NavLink } from 'react-router-dom';
import classNames from './styles.module.css';

export type LogoProps = PropsWithChildren<HTMLAttributes<HTMLElement>> & {
  imageClassName?: string,
  href?: string,
  src: string,
};

export function Logo({ src, href = '/', children, className, imageClassName, ...props }: LogoProps) {
  return (
    <NavLink {...props} className={clsx(classNames.logo, className)} to={href}>
      <img className={clsx(classNames.image, imageClassName)} src={src} role="presentation" alt="" />
      <VisuallyHidden>{children}</VisuallyHidden>
    </NavLink>
  );
}