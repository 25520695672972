import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Container } from '@/shared/design-system/thermal-ceramics/components/container';
import { SignUpForm } from '@/app/form/scenarios/sign-up';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';
import { app } from '@/app';
import { register, RegistrationData } from '@/app/api/morgan-thermal/registration/register';
import { validateSignUp } from '@/app/api/morgan-thermal/registration/validate-sign-up';
import { showInformativeModal } from '@/app/components/informative-modal';

import classNames from './styles.module.css';

export const SignUpRouteView = observer(() => {
  const navigate = useNavigate();

  const handleVerifyEmail = useCallback((email: string) => app.morganThermalAPI.fetch(validateSignUp(email)), []);

  const handleSubmit = useCallback((data: RegistrationData) => {
    return app.morganThermalAPI.fetch(register(data))
      .then((response) => {
        navigate('/');

        showInformativeModal(response).catch();

        return response;
      })
      .catch((response) => {
        showInformativeModal(response.data).catch();

        return response.data;
      });
  }, []);

  return (
    <Container className={classNames.container}>
      <Heading as="h1" className={classNames.heading}>Sign up</Heading>
      <SignUpForm
        verifyEmail={handleVerifyEmail}
        submit={handleSubmit}
      />
    </Container>
  );
});