export type LocalStorageCache = {
  get<T>(key: string): T | null,
  set(key: string, value: unknown, expiresAt: Date): void,
  remove(key: string): void,
};

export function createLocalStorageCache(): LocalStorageCache {
  return {
    get<T>(key: string) {
      const item = localStorage.getItem(key);

      if (!item) return null;

      const parsedItem = JSON.parse(item);
      const expiresAt = new Date(parsedItem.expiresAt);
      const currentDate = new Date();

      if (expiresAt.getTime() < currentDate.getTime()) {
        this.remove(key);
        return null;
      }

      return parsedItem.value as T;
    },
    set(key: string, value: unknown, expiresAt: Date) {
      localStorage.setItem(key, JSON.stringify({
        value,
        expiresAt: expiresAt.toISOString(),
      }));
    },
    remove(key: string) {
      localStorage.removeItem(key);
    },
  };
}