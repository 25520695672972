import { ElementType, ReactNode } from 'react';
import classNames from './styles.module.css';
import { clsx } from 'clsx';

type HeadingTag = keyof JSX.IntrinsicElements;

type HeadingProps = {
  [Tag in HeadingTag]: {
    as?: Tag,
    children?: ReactNode | Element[],
  } & JSX.IntrinsicElements[Tag];
}[HeadingTag];

export const Heading = (props: HeadingProps) => {
  const { as, children, className, ...attributes } = props;
  const Tag = (as ?? 'p') as ElementType;

  return (
    <Tag {...attributes} className={clsx(classNames.heading, className)}>{children}</Tag>
  );
}