import { ReactElement } from 'react';

import { app } from '@/app';
import { MeasurementSystem } from '@/app/measurement-systems/model';

import classNames from './styles.module.css';
import { observer } from 'mobx-react-lite';

export type ValueMeasurementProps = { imperial: ReactElement, metric: ReactElement };

export type ValueMeasurementStringProps = { hint: string };

export const ValueMeasurementString = ({ hint }: ValueMeasurementStringProps) => {
  return <span className={classNames.valueMeasurement} dangerouslySetInnerHTML={{ __html: hint }} />
};

export const ValueMeasurement = observer(({ imperial, metric }: ValueMeasurementProps) => {
  return <span className={classNames.valueMeasurement}>{app.measurementSystems.currentSystem === MeasurementSystem.IMPERIAL ? imperial : metric}</span>
});