import { useCallback, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { app } from '@/app';
import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { Breadcrumbs } from '@/app/routing/breadcrumbs';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';
import { showInformativeModal } from '@/app/components/informative-modal';
import { MaterialUpdate, updateMaterial, updateMaterialAsGlobalAdmin } from '@/app/api/morgan-thermal/material/update';
import { SaveMaterialForm } from '@/app/form/scenarios/save-material';
import { MeasurementSystemsToggle } from '@/app/measurement-systems/view';
import { confirmMeasurementChange } from '@/app/measurement-systems/confirm-measurement-change';

import classNames from './styles.module.css';

export const MaterialCreateRouteView = observer(() => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = useCallback((data: MaterialUpdate) => {
    setIsSubmitting(true);
    return app.morganThermalAPI.fetch(updateMaterialAsGlobalAdmin(data))
      .then((response) => {
        navigate(`/materials/${response?.id}`);
        showInformativeModal(response).catch();
      })
      .catch((response) => {
        showInformativeModal(response).catch();
      })
      .finally(() => setIsSubmitting(false));
  }, [navigate]);

  return (
    <Page hasOperationsInProgress={false} loaded={true}>
      <Breadcrumbs/>

      <div className={classNames.header}>
        <Heading as="h1">Create Material</Heading>
        <MeasurementSystemsToggle beforeChange={confirmMeasurementChange}/>
      </div>

      <SaveMaterialForm
        submit={handleSubmit}
        footer={<>
          <Button modifier={ButtonModifier.TERTIARY} as={NavLink} to={`/materials`}>Cancel</Button>
          <Button type="submit" disabled={isSubmitting}>Save</Button>
        </>}
      />
    </Page>
  );
});