import { ComponentPropsWithoutRef, ElementType } from 'react';
import { clsx } from 'clsx';

import { Spinner } from '@/shared/design-system/thermal-ceramics/components/spinner';

import classNames from './styles.module.css';

export enum ButtonModifier {
  SECONDARY = 'secondary',
  CIRCLE = 'circle',
  TERTIARY = 'tertiary',
  OUTLINE = 'outline',
  INLINE = 'inline',
}

export type ButtonProps<TType extends ElementType = 'button'> = {
  as?: TType,
  modifier?: ButtonModifier | ButtonModifier[] | '',
  inProgress?: boolean,
} & ComponentPropsWithoutRef<TType>;

export const Button = <TType extends ElementType>(props: ButtonProps<TType>) => {
  const { as, modifier = '', inProgress = false, children, disabled = false, className, ...elementProps } = props;
  const Tag = (as ?? 'button') as ElementType;
  const modifierClassNames = Array.isArray(modifier) ? modifier.map((name) => classNames[name]) : classNames[modifier];
  const calculatedDisabled = inProgress ? true : disabled;

  return (
    <Tag className={clsx(classNames.button, className, modifierClassNames)} disabled={calculatedDisabled} {...elementProps}>
      {children}
      {inProgress && <Spinner className={classNames.spinner}/>}
    </Tag>
  );
};