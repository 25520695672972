import { Context, FunctionComponent, ReactElement, ReactNode } from 'react';
import {
  showActionModal,
  ShowActionModalProps
} from '@/shared/design-system/thermal-ceramics/components/modal/promise/action';
import { Informative } from '@/app/api/morgan-thermal';
import { Modal, ModalIcon } from '@/shared/design-system/thermal-ceramics/components/modal';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';
import { Spinner } from '@/shared/design-system/thermal-ceramics/components/spinner';

import classNames from './styles.module.css';

export enum ActionType {
  DEFAULT = 'default',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

export type RunServerSideActionProps<TType extends Informative> = {
  title?: string;

  type?: ActionType;

  renderConfirmation?: (approve: (data?: any) => void, decline: () => void) => ReactNode,
  confirmationMessage?: string | ReactNode;
  confirmationBody?: string | ReactNode;

  declineLabel?: string;
  approveLabel?: string;

  successMessage?: (result: TType) => ReactElement;

  runAction: ShowActionModalProps<TType>['runAction'];
};

const renderConfirmationIcon = (type: ActionType) => {
  switch (type) {
    case ActionType.POSITIVE: return Modal.renderIcon(ModalIcon.SUCCESS);
    case ActionType.NEGATIVE: return Modal.renderIcon(ModalIcon.WARNING);
    default: return null;
  }
}

export const runServerSideAction = <TType extends Informative,>({ type = ActionType.DEFAULT, renderConfirmation, successMessage, title, confirmationMessage, confirmationBody, declineLabel, approveLabel, runAction }: RunServerSideActionProps<TType>) => showActionModal<TType>({
  runAction,

  renderConfirmation: renderConfirmation ?? ((approve, decline) => {
    const icon = renderConfirmationIcon(type);

    return (
      <>
        {icon && <Modal.Header>{icon}</Modal.Header>}

        <Modal.Body>
          {title && <Modal.Title>{title}</Modal.Title>}
          {confirmationMessage && <Modal.Description>{confirmationMessage}</Modal.Description>}
          {confirmationBody}
        </Modal.Body>

        <Modal.Footer>
          <Button modifier={ButtonModifier.SECONDARY} onClick={decline}>{declineLabel ?? 'Close'}</Button>
          <Button onClick={approve}>{approveLabel ?? 'OK'}</Button>
        </Modal.Footer>
      </>
    );
  }),
  renderInProgress: () => (
    <Modal.Body>
      {title && <Modal.Title>{title}</Modal.Title>}
      <Spinner className={classNames.spinner}/>
    </Modal.Body>
  ),
  renderResult: (result, isFulfilled, close) => {
    const icon = Modal.renderIcon(isFulfilled ? ModalIcon.SUCCESS : ModalIcon.ERROR);

    const renderCloseLabel = (isFulfilled: boolean) => isFulfilled ? 'OK' : 'Close';

    const renderTitle = (isFulfilled: boolean, result: TType) => {
      if (result.informationMessageHeader) return result.informationMessageHeader;
      if (isFulfilled) return 'Success';
      return 'Error';
    }

    const renderDescription = (result: TType) => {
      if (successMessage) return <>{successMessage(result)}</>;
      if (result.informationMessage) return <Modal.Description>{result.informationMessage}</Modal.Description>;
      if (result.errorMessage) return <Modal.Description>{result.errorMessage}</Modal.Description>;
      return null;
    }

    return (
      <>
        {icon && <Modal.Header>{icon}</Modal.Header>}

        <Modal.Body>
          <Modal.Title>{renderTitle(isFulfilled, result)}</Modal.Title>
          {renderDescription(result)}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={close}>{renderCloseLabel(isFulfilled)}</Button>
        </Modal.Footer>
      </>
    );
  }
});