import { ReactElement, useCallback, useEffect } from 'react';
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { app } from '@/app';
import { Fieldset } from '@/shared/design-system/thermal-ceramics/components/form/fieldset';
import { NameHookFormField } from '@/app/form/fields/common/name';
import { MaterialInformation } from '@/app/api/morgan-thermal/material';
import {
  OriginOfManufacturerHookFormField,
  useOriginOfManufacturerSelector
} from '@/app/form/fields/material/origin-of-manufacturer';
import { MaterialCategoryHookFormField, useMaterialCategoriesSelector } from '@/app/form/fields/material/category';
import { MaterialUpdate } from '@/app/api/morgan-thermal/material/update';
import { DensityHookFormField } from '@/app/form/fields/material/density';
import { SpecificHeatTemperatureHookFormField } from '@/app/form/fields/material/specific-heat-temperature';
import { SpecificHeatHookFormField } from '@/app/form/fields/material/specific-heat';
import { UseLimitHookFormField } from '@/app/form/fields/material/use-limit';
import { ClassificationTemperatureHookFormField } from '@/app/form/fields/material/clasification-temperature';
import { ThermalConductivityFieldset } from '@/app/form/scenarios/save-material/thermal-conductivity-fieldset';
import { MeasurementSystem } from '@/app/measurement-systems/model';

import { createValidationSchema } from './validation';
import { createInitialValues } from './initial-values';
import classNames from './styles.module.css';
import { Notification, NotificationType } from '@/shared/design-system/thermal-ceramics/components/notification';
import { RequiredHint } from '@/app/form/required-hint';

export type SaveMaterialFormProps = {
  material?: MaterialInformation;
  submit: (data: MaterialUpdate) => void;
  footer: ReactElement;
};

export const SaveMaterialForm = observer((props: SaveMaterialFormProps) => {
  const { submit, material, footer } = props;

  const materialCategoriesSelector = useMaterialCategoriesSelector();
  const originOfManufacturerSelector = useOriginOfManufacturerSelector();

  const form = useForm({
    mode: 'onTouched',
    defaultValues: createInitialValues(material),
    resolver: yupResolver(createValidationSchema({ isGlobalAdmin: app.session.isGlobalAdmin, measurementSystem: app.measurementSystems.currentSystem })),
  });

  const handleValidSubmit = useCallback((values: FieldValues) => {
    submit({
      ...values,
      id: material?.id ?? 0,
      isMetric: app.measurementSystems.currentSystem === MeasurementSystem.METRIC,
      kValues: values.kValues.slice(0).filter((value: any) => typeof value.temperature === 'number' && typeof value.kValue === 'number').map((value: any) => ({
        id: value.id ?? 0,
        materialId: material?.id ?? 0,
        temperature: value.temperature,
        kValue: value.kValue
      })),
    } as MaterialUpdate);
  }, [material, submit]);

  useEffect(() => {
    return reaction(() => app.measurementSystems.currentSystem, () => {
      const { setValue, resetField } = form;

      setValue('useLimit', 0);
      setValue('density', 0)
      setValue('specificHeat', 0);
      setValue('specificHeatTemperature', 0);
      setValue('classificationTemperature', 0);
      resetField('kValues', {
        defaultValue: (() => {
          let kValues = material?.thermalConductivityValues ? material?.thermalConductivityValues.map((thermalConductivityValue) => ({
            id: thermalConductivityValue.id,
            temperature: 0,
            kValue: 0,
          })) : Array(2).fill({
            temperature: 0,
            kValue: 0,
          });

          if (kValues.length < 3) kValues = [...kValues, ...Array.from({ length: 3 - kValues.length }).fill({
            temperature: '',
            kValue: '',
          })];

          return kValues;
        })(),
      });
    });
  }, [form]);

  return (
    <FormProvider {...form}>
      <RequiredHint/>
      <form onSubmit={form.handleSubmit(handleValidSubmit)} noValidate>
        <Fieldset className={classNames.fieldset}>
          {(app.session.isCompanyAdmin || app.session.isGlobalAdmin || app.session.isRegularUser) && (
            <Notification type={NotificationType.WARNING} className={classNames.warning}>
              <Notification.Body>As a representative for your company, any materials added will be available to your entire organization.</Notification.Body>
            </Notification>
          )}
          <Fieldset.Grid className={classNames.grid}>
            <NameHookFormField name="name" hint="Enter material name" required/>
            <OriginOfManufacturerHookFormField label="Origin of Manufacturer" name="originOfManufacturerId" regions={originOfManufacturerSelector.regions} required={app.session.isGlobalAdmin}/>
            <NameHookFormField name="manufacturer" label="Manufacturer" hint="Enter manufacturer name" required/>
            <MaterialCategoryHookFormField name="materialCategoryId" materialCategories={materialCategoriesSelector.materialCategories} required/>
            <UseLimitHookFormField name="useLimit" type="number" decimalPlaces={0} required/>
            <DensityHookFormField name="density" type="number" decimalPlaces={app.measurementSystems.currentSystem === MeasurementSystem.METRIC ? 0 : 1} required/>
            <SpecificHeatHookFormField name="specificHeat" type="number" decimalPlaces={2}/>
            <SpecificHeatTemperatureHookFormField name="specificHeatTemperature" type="number" decimalPlaces={0}/>
            <ClassificationTemperatureHookFormField name="classificationTemperature" type="number" decimalPlaces={0} required/>
          </Fieldset.Grid>

          <ThermalConductivityFieldset name="kValues"/>

          {footer && (<Fieldset.Footer>{footer}</Fieldset.Footer>)}
        </Fieldset>
      </form>
    </FormProvider>
  );
});