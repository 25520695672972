import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';

import { app } from '@/app';
import { Listing } from '@/app/listing';
import { ToggleButton } from '@/shared/design-system/thermal-ceramics/components/toggle-button/button';
import { ToggleButtonGroup } from '@/shared/design-system/thermal-ceramics/components/toggle-button/group';
import { ActionsDropdown, ActionsDropdownItemModifier } from '@/app/components/actions-dropdown';
import { CalculationListingItem } from '@/app/api/morgan-thermal/calculation';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';
import { PlusIcon } from '@/shared/design-system/thermal-ceramics/icons';
import { ProjectInformation } from '@/app/api/morgan-thermal/project';
import { printCalculations } from '@/app/actions/calculation/print';

import classNames from './styles.module.css';
import { createColumns } from './columns';
import { CalculationListingModel } from './model';

export type CalculationListingProps = {
  projectId: ProjectInformation['id'],
  initiallySelectedIds?: CalculationListingItem['id'][],
  selectedRows?: CalculationListingItem[],
  onSelect?: (calculationListingItem: CalculationListingItem) => void,
  onUnselect?: (calculationListingItem: CalculationListingItem) => void,
  onSelectionChange?: (calculationListingItems: CalculationListingItem[]) => void,
};

export const CalculationListing = observer((props: CalculationListingProps) => {
  const { projectId, initiallySelectedIds, selectedRows = [], onSelect, onUnselect, onSelectionChange } = props;
  const [{ listing, showDisabledFilter, enable, disable }] = useState(() => new CalculationListingModel({ projectId }));
  const { data, results, totalRecords, numberOfRecords, pageFilter } = listing;
  const [isPrintingInProgress, setIsPrintingInProgress] = useState(false);

  const handlePrintClick = () => {
    setIsPrintingInProgress(true);
    printCalculations(selectedRows.map(({ id }) => id)).finally(() => setIsPrintingInProgress(false));
  };

  return (
    <div className={classNames.calculationListing}>
      <Listing<CalculationListingItem>
        header={
          <>
            <Heading as="h2">Calculations</Heading>
            <ToggleButtonGroup
              value={showDisabledFilter.value}
              onChange={showDisabledFilter.setValue}
            >
              <ToggleButton value={false}>Active</ToggleButton>
              <ToggleButton value={true}>Disabled</ToggleButton>
            </ToggleButtonGroup>
          </>
        }
        renderActions={(item: CalculationListingItem) => (
          <ActionsDropdown>
            <ActionsDropdown.Item key="details" as={NavLink} to={`/projects/${projectId}/calculations/${item.id}`}>Details</ActionsDropdown.Item>
            <ActionsDropdown.Item key="edit" as={NavLink} to={`/projects/${projectId}/calculations/${item.id}/edit`}>Edit</ActionsDropdown.Item>
            {showDisabledFilter.value && <ActionsDropdown.Item key="enable" modifier={ActionsDropdownItemModifier.POSITIVE} onClick={() => enable(item.id)}>Enable</ActionsDropdown.Item>}
            {!showDisabledFilter.value && <ActionsDropdown.Item key="disable" modifier={ActionsDropdownItemModifier.DANGER} onClick={() => disable(item.id)}>Disable</ActionsDropdown.Item>}
          </ActionsDropdown>
        )}
        initiallySelectedIds={initiallySelectedIds}
        selectedRows={selectedRows}
        columns={createColumns(projectId, app.measurementSystems.currentSystem)}
        data={results}
        isInitialized={!!data}
        hasOperationsInProgress={data.isSynchronizationInProgress}
        totalRecords={totalRecords}
        numberOfRecords={numberOfRecords}
        page={pageFilter.value}
        selectionLimit={4}
        onPageChange={pageFilter.setValue}
        onSelect={onSelect}
        onUnselect={onUnselect}
        onSelectionChange={onSelectionChange}
      />
      <div className={classNames.footer}>
        <Button modifier={ButtonModifier.INLINE} as={NavLink} to={`/projects/${projectId}/calculations/create`}><PlusIcon aria-hidden="true"/> Add new calculation</Button>
        {!!results.length && <Button modifier={ButtonModifier.OUTLINE} onClick={handlePrintClick} disabled={!selectedRows.length} inProgress={isPrintingInProgress}>Print</Button>}
      </div>
    </div>
  );
});