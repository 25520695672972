import { observer } from 'mobx-react-lite';

import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { Breadcrumbs } from '@/app/routing/breadcrumbs';
import { SaveCalculationForm } from '@/app/form/scenarios/save-calculation';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { app } from '@/app';
import { showInformativeModal } from '@/app/components/informative-modal';
import { CalculationSave } from '@/app/api/morgan-thermal/calculation/save';
import { calculate } from '@/app/api/morgan-thermal/calculation/calculate';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';

export const CalculationCreateRouteView = observer(() => {
  const { projectId } = useParams();
  const parsedProjectId = (typeof projectId === 'string') ? Number.parseInt(projectId) : 0;
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback((data: CalculationSave) => {
    setIsSubmitting(true);

    return app.morganThermalAPI.fetch(calculate(data))
      .then((response) => {
        navigate(`/projects/${projectId}/calculations/${response?.id}`);
        showInformativeModal(response, 'Calculation successfully completed.').catch();
      })
      .catch((response) => {
        if (response.id && response.id !== 0) navigate(`/projects/${projectId}/calculations/${response?.id}/edit`);
        showInformativeModal(response).catch();
      })
      .finally(() => setIsSubmitting(false));
  }, [navigate]);

  return (
    <Page hasOperationsInProgress={isSubmitting} loaded={true}>
      <Breadcrumbs/>
      <Heading as="h1">Create calculation</Heading>
      <SaveCalculationForm
        submit={handleSubmit}
        projectId={parsedProjectId}
        footer={<>
          <Button modifier={ButtonModifier.TERTIARY} as={NavLink} to={`/projects/${projectId}`}>Cancel</Button>
          <Button inProgress={isSubmitting} type="submit" disabled={isSubmitting}>Calculate</Button>
        </>}
      />
    </Page>
  );
});