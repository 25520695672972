import { ChangeEvent, useCallback } from 'react';

import { Input, InputProps } from '@/shared/design-system/thermal-ceramics/components/form/input';
import { SearchIcon } from '@/shared/design-system/thermal-ceramics/icons';

import classNames from './styles.module.css';

export type SearchProps = Omit<InputProps, 'startSlot'> & {
  onSearch?: (value: string) => void,
};

export const Search = (props: SearchProps) => {
  const { onChange, onSearch, ...inputProps } = props;

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(event);
    if (onSearch) onSearch(event.target.value);
  }, [onChange, onSearch]);

  return (
    <Input
      className={classNames.inputContainer}
      startSlot={<SearchIcon className={classNames.icon} />}
      placeholder="Search"
      onChange={handleChange}
      {...inputProps}
    />
  );
};