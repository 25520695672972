import { createColumnHelper, Row } from '@tanstack/react-table';
import { MeasurementSystem } from '@/app/measurement-systems/model';
import { CalculationListingItem } from '@/app/api/morgan-thermal/calculation';
import { isoToReadableString } from '@/shared/helpers/date';
import { createMeasurementSystemBasedColumn } from '@/app/listing/helpers/measurement-system-based-column';
import { RecalculationPopover } from '@/app/listing/calculation/recalculation-popover';
import React from 'react';

import classNames from './styles.module.css';
import { roundDecimals } from '@/shared/helpers/number';

const columnHelper = createColumnHelper<CalculationListingItem>();

export const createColumns = (projectId: number, measurementSystem: MeasurementSystem) => [
  columnHelper.accessor('name', {
    header: 'Calculation name',
    size: 200,
    meta: {
      canBeChecked: true,
      detailsLink: (row: Row<CalculationListingItem>) => `/projects/${projectId}/calculations/${row.original.id}`,
    },
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('calculationDate', {
    header: 'Date',
    cell: info => isoToReadableString(info.getValue())
  }),
  createMeasurementSystemBasedColumn<CalculationListingItem>(measurementSystem, 'hotFaceTemperature', {
    header: 'Hot Face Temp',
    modifier: roundDecimals,
  }, (info, Element) => {
    if (info.row.original.needsToBeRecalculated) {
      if (info.row.original.errorMessage) return <RecalculationPopover>{info.row.original.errorMessage}</RecalculationPopover>;
      return <span className={classNames.needsRecalculation}>Needs Recalculation</span>;
    }
    return <span className={classNames.value}>{Element}</span>;
  }),
  createMeasurementSystemBasedColumn<CalculationListingItem>(measurementSystem, 'coldFaceTemperature', {
    header: 'Cold Face Temp',
    modifier: roundDecimals,
  }, (info, Element) => {
    if (info.row.original.needsToBeRecalculated) return <span className={classNames.needsRecalculation}>—</span>;
    return <span className={classNames.value}>{Element}</span>;
  }),
];
