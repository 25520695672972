import { Select, SelectProps } from '@/shared/design-system/thermal-ceramics/components/form/select';
import { Field, FieldProps } from '@/shared/libs/hook-form/field';
import { IdValue } from '@/app/api/morgan-thermal';

export type FieldSelectOption = {
  id: any,
  label: string,
  value: any,
};

export type FieldSelectProps = Omit<FieldProps, 'renderControl'> & {
  inProgress?: boolean,
  options: FieldSelectOption[],
  extendProps?: (props: SelectProps<FieldSelectOption>) => SelectProps<FieldSelectOption>,
};

export const FieldSelect = (props: FieldSelectProps) => {
  const { inProgress, options, extendProps, ...fieldProps } = props;

  return (
    <Field
      {...fieldProps}
      renderControl={({ value, onChange, onBlur, ...controlProps }) => {
        const props: SelectProps<FieldSelectOption> = {
          ...controlProps,
          inProgress,
          options,
          value: options.find((option) => option.value === value),
          createKey(option) { return option.id; },
          createOptionLabel(option) { return option.label },
          onBlur() {
            if (onBlur) onBlur();
          },
          onChange(option) {
            if (!option) return onChange('');
            if (option && option.value !== value) return onChange(option.value);
          },
        };
        const extendedProps: SelectProps<FieldSelectOption> = extendProps ? extendProps(props) : props;

        return <Select<FieldSelectOption> {...extendedProps}/>;
      }
      }/>
  );
}

export const createSelectOptions = (items: IdValue[]) => items.map((item) => ({
  id: item.id,
  label: item.name,
  value: item.id,
}));