import { HookFormField, FieldInputProps } from '@/shared/libs/hook-form';
import { ValueMeasurement } from '@/app/form/fields/material/helpers/value-measurement';
import { numberOrEmpty } from '@/shared/libs/yup/validations';

export const specificHeatSchema = numberOrEmpty().label('Density');

export const SpecificHeatHookFormField = (props: FieldInputProps) => (
  <HookFormField.Input
    label="Specific Heat"
    extendProps={(props) => ({
      ...props,
      endSlot: (
        <ValueMeasurement
          imperial={<><sup>Btu</sup>/<sub>Lbs&#215;F</sub></>}
          metric={<><sup>kJ</sup>/<sub>kg&#215;K</sub></>}
        />
      )
    })}
    {...props}
  />
);