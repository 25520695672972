import { Fragment, HTMLAttributes, PropsWithChildren } from 'react'
import { clsx } from 'clsx';
import { Dialog, DialogProps, Transition } from '@headlessui/react';

import enterAnimationClassNames from '@/shared/design-system/thermal-ceramics/animations/enter/styles.module.css';
import leaveAnimationClassNames from '@/shared/design-system/thermal-ceramics/animations/leave/styles.module.css';

import classNames from './styles.module.css';
import { AcceptIcon, AlertIcon, CriticalIcon } from '@/shared/design-system/thermal-ceramics/icons';

export enum ModalIcon {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

export type ModalProps = PropsWithChildren & {
  opened?: boolean,
  onAfterLeave?: () => void,
  onClose: DialogProps<'div'>['onClose'],
};

export type ModalTitleProps = PropsWithChildren;
export type ModalDescriptionProps = PropsWithChildren & HTMLAttributes<HTMLParagraphElement>;
export type ModalHeaderProps = PropsWithChildren;
export type ModalBodyProps = PropsWithChildren;
export type ModalFooterProps = PropsWithChildren & HTMLAttributes<HTMLParagraphElement>;

const Root = (props: ModalProps) => {
  const { opened = false, children, onClose, onAfterLeave } = props;

  return (
    <Transition
      as={Fragment}
      show={opened}
      afterLeave={onAfterLeave}
    >
      <Dialog className={classNames.modal} onClose={onClose}>
        <div className={classNames.backdrop} aria-hidden="true">
          <Transition.Child
            as={Fragment}

            enter={enterAnimationClassNames.enter}
            enterFrom={enterAnimationClassNames.enterFromOpacity}
            enterTo={enterAnimationClassNames.enterToOpacity}

            leave={leaveAnimationClassNames.leave}
            leaveFrom={leaveAnimationClassNames.leaveFromOpacity}
            leaveTo={leaveAnimationClassNames.leaveToOpacity}
          >
            <div className={classNames.backdropInner}/>
          </Transition.Child>
        </div>
        <div className={classNames.container}>
          <div className={classNames.wrap}>
            <Transition.Child
              as={Fragment}

              enter={enterAnimationClassNames.enter}
              enterFrom={clsx(
                enterAnimationClassNames.enterFromOpacity,
                enterAnimationClassNames.enterFromTransform,
              )}
              enterTo={clsx(
                enterAnimationClassNames.enterToOpacity,
                enterAnimationClassNames.enterToTransform,
              )}

              leave={leaveAnimationClassNames.leave}
              leaveFrom={clsx(
                leaveAnimationClassNames.leaveFromOpacity,
                leaveAnimationClassNames.leaveFromTransform,
              )}
              leaveTo={clsx(
                leaveAnimationClassNames.leaveToOpacity,
                leaveAnimationClassNames.leaveToTransform,
              )}
            >
              <Dialog.Panel className={classNames.panel}>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
};

const Title = ({ children }: ModalTitleProps) => (
  <Dialog.Title className={classNames.title}>{children}</Dialog.Title>
);

const Description = ({ children, className }: ModalDescriptionProps) => (
  <Dialog.Description className={clsx(classNames.description, className)}>{children}</Dialog.Description>
);

const Header = ({ children }: ModalHeaderProps) => (
  <div className={classNames.header}>{children}</div>
);

const Body = ({ children }: ModalBodyProps) => (
  <div className={classNames.body}>{children}</div>
);

const Footer = ({ children, className }: ModalFooterProps) => (
  <div className={clsx(classNames.footer, className)}>{children}</div>
);

const renderIcon = (type: ModalIcon) => {
  switch (type) {
    case ModalIcon.SUCCESS: return <AcceptIcon aria-hidden="true"/>;
    case ModalIcon.ERROR: return <CriticalIcon aria-hidden="true"/>;
    case ModalIcon.WARNING: return <AlertIcon aria-hidden="true"/>;
    default: return null;
  }
};

export const Modal = Object.assign(Root, {
  Title,
  Description,
  Header,
  Body,
  Footer,
  renderIcon,
});
