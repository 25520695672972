import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';
import { Input } from '@/shared/design-system/thermal-ceramics/components/form/input';
import { app } from '@/app';
import { observer } from 'mobx-react-lite';
import { ValueMeasurementString } from '@/app/form/fields/material/helpers/value-measurement';
import { Checkbox } from '@/shared/design-system/thermal-ceramics/components/form/checkbox';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';
import { Label } from '@/shared/design-system/thermal-ceramics/components/form/label';

import classNames from './styles.module.css';
import { clsx } from 'clsx';
import { ScrollArea } from '@/shared/design-system/thermal-ceramics/components/scroll-area';
import { NavLink } from 'react-router-dom';
import { CalculationInformation } from '@/app/api/morgan-thermal/calculation';
import { ProjectInformation } from '@/app/api/morgan-thermal/project';
import { roundDecimals } from '@/shared/helpers/number';

export type CompareCalculationsProps = {
  projectId: ProjectInformation['id'],
  calculations?: {
    id: CalculationInformation['id'],
    metric: CalculationInformation['metric'],
    imperial: CalculationInformation['imperial'],
    name: CalculationInformation['name'],
    needsToBeRecalculated: CalculationInformation['needsToBeRecalculated'],
  }[],
  onUnselect?: (id: CalculationInformation['id']) => void,
};

export const CompareCalculations = observer((props: CompareCalculationsProps) => {
  const { projectId, calculations, onUnselect } = props;

  return (
    <section className={classNames.section}>
      <Heading className={classNames.heading} as="h2">Compare calculations</Heading>
      <ScrollArea className={classNames.scrollArea}>
        <div className={classNames.body}>
          <div className={classNames.header} aria-hidden="true">
            <div className={classNames.column}/>
            <div className={classNames.column}><Label>Name</Label></div>
            <div className={classNames.column}><Label>Hot Face Temp</Label></div>
            <div className={classNames.column}><Label>Cold Face Temp</Label></div>
          </div>
          <ul className={classNames.list}>
            {calculations?.map((calculation) => (
              <li className={classNames.item} key={calculation.id}>
                <div className={clsx(classNames.column, classNames.checkColumn)}><Checkbox checked onChange={() => onUnselect && onUnselect(calculation.id)}/></div>
                <div className={classNames.column}><Input value={calculation.name} disabled readOnly/></div>
                <div className={classNames.column}>
                  <Input
                    value={calculation.needsToBeRecalculated ? 'Needs Recalculation' : roundDecimals(calculation[app.measurementSystems.currentSystem].hotFaceTemperature) ?? ''}
                    endSlot={
                      calculation.needsToBeRecalculated ? undefined : <ValueMeasurementString
                        hint={calculation[app.measurementSystems.currentSystem].hotFaceTemperatureLabel}
                      />
                    }
                    disabled
                    readOnly
                  />
                </div>
                <div className={classNames.column}>
                  <Input
                    value={calculation.needsToBeRecalculated ? '—' : roundDecimals(calculation[app.measurementSystems.currentSystem].coldFaceTemperature) ?? ''}
                    endSlot={
                      calculation.needsToBeRecalculated ? undefined : <ValueMeasurementString
                        hint={calculation[app.measurementSystems.currentSystem].coldFaceTemperatureLabel}
                      />
                    }
                    disabled
                    readOnly
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </ScrollArea>
      <div className={classNames.footer}>
        <Button type="button" modifier={ButtonModifier.OUTLINE} as={NavLink} to={`/projects/${projectId}/calculations/compare?ids=${calculations?.map(({ id }) => id).join(',')}`}>Compare</Button>
      </div>
    </section>
  );
});