import { observer } from 'mobx-react-lite';
import { useRouteLoaderData } from 'react-router-dom';

import { app } from '@/app';
import { approveCompany } from '@/app/actions/company/approve';
import { denyCompany } from '@/app/actions/company/deny';
import { disableCompany } from '@/app/actions/company/disable';
import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { Breadcrumbs } from '@/app/routing/breadcrumbs';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { CompanyInformation, CompanyStateId } from '@/app/api/morgan-thermal/company';
import { CompanyDetails } from '@/app/details/company';
import { Actions } from '@/shared/design-system/thermal-ceramics/components/actions';
import { Navigation } from '@/shared/design-system/thermal-ceramics/components/navigation';
import { NavigationModifier } from '@/shared/design-system/thermal-ceramics/components/navigation';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';
import { enableCompany } from '@/app/actions/company/enable';

export const CompanyIndexRouteView = observer(() => {
  const company = useRouteLoaderData('company') as SynchronizableValue<CompanyInformation | null>;

  const renderGlobalAdminActions = () => {
    if (!app.session.isGlobalAdmin) return null;

    const approveButton = <Button onClick={() => company.value && approveCompany(company.value.id).then(company.synchronize)}>Approve</Button>;
    const denyButton = <Button modifier={ButtonModifier.TERTIARY} onClick={() => company.value && denyCompany(company.value.id).then(company.synchronize)}>Deny</Button>;
    const enableButton = <Button onClick={() => company.value && enableCompany(company.value.id).then(company.synchronize)}>Enable</Button>;
    const disableButton = <Button modifier={ButtonModifier.TERTIARY} onClick={() => company.value && disableCompany(company.value.id).then(company.synchronize)}>Disable</Button>;

    return (
      <Actions>
        {(() => {
          switch (company.value?.companyStateId) {
            case CompanyStateId.ACTIVE: return <>{disableButton}</>;
            case CompanyStateId.PENDING: return <>{approveButton}{denyButton}</>;
            case CompanyStateId.DENIED: return <>{approveButton}</>;
            case CompanyStateId.DISABLED: return <>{enableButton}</>;
            default: return null;
          }
        })()}
      </Actions>
    );
  }

  return (
    <Page hasOperationsInProgress={company.isSynchronizationInProgress} loaded={!!company.value}>
      <Breadcrumbs/>

      <Navigation modifier={NavigationModifier.SECONDARY}>
        <Navigation.Item to={`/companies/${company.value?.id}`}>Company Info</Navigation.Item>
        <Navigation.Item to={`/companies/${company.value?.id}/users`}>Users</Navigation.Item>
      </Navigation>

      {company.value && (
        <>
          <CompanyDetails company={company.value}/>
          {renderGlobalAdminActions()}
        </>
      )}
    </Page>
  );
});