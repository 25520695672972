import { indexRoute } from '@/app/routing/root/companies/index/route';
import { companyRoute } from '@/app/routing/root/companies/id/route';
import { createRoute } from '@/app/routing/root/companies/create/route';
import { withCrumb } from '@/app/routing/breadcrumbs';
import { app } from '@/app';

export const companiesRoute = {
  path: 'companies',
  children: [
    indexRoute,
    companyRoute,
    createRoute,
  ],
  handle: () => {
    if (app.session.isCompanyAdmin) return null;

    return withCrumb({
      element: () => <span>Companies</span>,
    });
  }
}