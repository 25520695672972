import { observer } from 'mobx-react-lite';

import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { MaterialListing } from '@/app/listing/material';

export const MaterialsIndexRouteView = observer(() => {
  return (
    <Page hasOperationsInProgress={false} loaded={true}>
      <MaterialListing/>
    </Page>
  );
});