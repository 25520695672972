import {
  HTMLProps,
  PropsWithChildren,
  Ref,
} from 'react';
import clsx from 'clsx';
import { ScrollArea } from '@/shared/design-system/thermal-ceramics/components/scroll-area';

import classNames from './styles.module.css';

type TableProps = PropsWithChildren<HTMLProps<HTMLTableElement>> & {
  scrollAreaRef?: Ref<HTMLDivElement>;
  tableRef?: Ref<HTMLTableElement>;
};
type TableHeadProps = PropsWithChildren<HTMLProps<HTMLTableSectionElement>>;
type TableBodyProps = PropsWithChildren<HTMLProps<HTMLTableSectionElement>>;
type TableRowProps = PropsWithChildren<HTMLProps<HTMLTableRowElement>>;
type TableHeadCellProps = PropsWithChildren<HTMLProps<HTMLTableCellElement>>;
type TableCellProps = PropsWithChildren<HTMLProps<HTMLTableCellElement>>;

const Root = (props: TableProps) => {
  const { scrollAreaRef, tableRef, children, className, ...attributes } = props;

  return (
    <ScrollArea className={classNames.scrollArea} ref={scrollAreaRef} autoHide={false}>
      <table ref={tableRef} className={clsx(classNames?.table, className)} {...attributes}>
        {children}
      </table>
    </ScrollArea>
  );
};

const Head = ({ children, className, ...attributes }: TableHeadProps) => (
  <thead className={clsx(classNames.head, className)} {...attributes}>{children}</thead>
);

const Body = ({ children, className, ...attributes }: TableBodyProps) => (
  <tbody className={clsx(classNames.body, className)} {...attributes}>{children}</tbody>
);

const Row = ({ children, className, ...attributes }: TableRowProps) => (
  <tr className={clsx(classNames.row, className)} {...attributes}>{children}</tr>
);

const HeadCell = ({ children, className, ...attributes }: TableHeadCellProps) => (
  <th className={clsx(classNames.headCell, className)} {...attributes}>{children}</th>
);

const Cell = ({ children, className, ...attributes }: TableCellProps) => (
  <td className={clsx(classNames.cell, className)} {...attributes}>{children}</td>
);

export const Table = Object.assign(Root, {
  Head,
  Body,
  Row,
  HeadCell,
  Cell,
});