import { Pill, PillModifier } from '@/shared/design-system/thermal-ceramics/components/pill';
import { UserListingItem } from '@/app/api/morgan-thermal/user';

export type StatusPillProps = {
  user: UserListingItem,
  isPendingCompany: boolean,
};

export const StatusPill = (props: StatusPillProps) => {
  const { user: { isActive }, isPendingCompany } = props;

  const renderLabel = (isActive: boolean) => {
    if (isPendingCompany) return 'Pending';
    if (isActive) return 'Active';
    return 'Disabled';
  }

  const createModifier = ({ isPendingCompany, isActive }: { isPendingCompany: boolean, isActive: boolean }) => {
    if (isPendingCompany) return PillModifier.WARNING;
    if (!isActive) return PillModifier.DISABLED;
  }

  return <Pill modifier={createModifier({ isPendingCompany, isActive })}>{renderLabel(isActive)}</Pill>;
}