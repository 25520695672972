import { createEndpoint } from '@/shared/models/api';
import { CompanyListing, CompanyStateId } from '@/app/api/morgan-thermal/company';
import { SearchFilters, SearchResponse } from '@/app/api/morgan-thermal';

export type CompanySearchFilters = SearchFilters<{
  companyName: string,
  state: CompanyStateId,
}>;

export type CompanySearchResponse = SearchResponse<CompanyListing>;

export enum CompanyOrderByOptions {
  CompanyStateId = 'CompanyStateId',
  Name = 'Name',
  Address = 'Address',
  Country = 'Country',
  CreatedDate = 'CreatedDate'
}

export const searchCompanies = createEndpoint<CompanySearchResponse>(({ fetch }) => (filters: CompanySearchFilters) => {
  return fetch('/Company/Search', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(filters),
  });
});