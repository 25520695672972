import { CalculationInformation } from '@/app/api/morgan-thermal/calculation';
import { CalculationResult } from '@/app/details/calculation/result';

export type CalculationDetailsProps = {
  calculation: CalculationInformation,
  editUrl?: string,
};

export const CalculationDetails = (props: CalculationDetailsProps) => {
  const { calculation, editUrl } = props;

  return (
    <CalculationResult
      editUrl={editUrl}
      calculation={calculation}
      showFullDetails
    />
  );
};