import { createContext, PropsWithChildren, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useStateProvincesSelector } from '@/app/form/fields/common/state-province';
import { useCountriesSelector } from '@/app/form/fields/common/country';

export type UseGlobalLocationProps = {
  countryIdName: string,
  stateProvinceIdName: string,
};

export type GlobalLocationContextProviderProps = PropsWithChildren<{
  globalLocationSelector: ReturnType<typeof useGlobalLocationSelector>;
}>;

export const GlobalLocationContext = createContext(null);

export const GlobalLocationContextProvider = (props: GlobalLocationContextProviderProps) => {
  const { globalLocationSelector, children } = props;
  const { countryIdName, stateProvinceIdName } = globalLocationSelector;

  const form = useFormContext();

  useEffect(() => {
    const countryId = form.getValues(countryIdName);
    globalLocationSelector.stateProvincesSelector.changeCountryId(countryId);
  }, []);

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name === countryIdName) {
        const countryId = form.getValues(countryIdName);
        form.setValue(stateProvinceIdName, null);
        globalLocationSelector.stateProvincesSelector.changeCountryId(countryId);
      }
    });

    return () => subscription.unsubscribe();
  }, [form]);

  return (
    <GlobalLocationContext.Provider value={null}>
      {children}
    </GlobalLocationContext.Provider>
  );
};

export const useGlobalLocationSelector = (props: UseGlobalLocationProps) => {
  const { countryIdName, stateProvinceIdName } = props;
  const countriesSelector = useCountriesSelector();
  const stateProvincesSelector = useStateProvincesSelector();

  return {
    countryIdName,
    stateProvinceIdName,
    countriesSelector,
    stateProvincesSelector,
  };
};