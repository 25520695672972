import { action, makeObservable, observable } from 'mobx';
import { Filter } from '@/shared/models/filters';
import { app } from '@/app';
import { Listing } from '@/app/listing/model';
import { MaterialInformation } from '@/app/api/morgan-thermal/material';
import {
  CalculationSearchFilters,
  CalculationSearchResponse,
  searchCalculations
} from '@/app/api/morgan-thermal/calculation/search';
import { enableCalculation } from '@/app/actions/calculation/enable';
import { disableCalculation } from '@/app/actions/calculation/disable';
import { ProjectInformation } from '@/app/api/morgan-thermal/project';

export type CalculationListingModelOptions = {
  projectId: ProjectInformation['id'];
};

export class CalculationListingModel {
  projectIdFilter: Filter<ProjectInformation['id']>;
  showDisabledFilter: Filter<boolean>;

  listing: Listing<CalculationSearchResponse, CalculationSearchFilters>;

  constructor(options: CalculationListingModelOptions) {
    makeObservable(this, {
      projectIdFilter: observable,
      showDisabledFilter: observable,

      listing: observable,

      enable: action.bound,
      disable: action.bound,
    });

    this.projectIdFilter = new Filter<ProjectInformation['id']>({
      name: 'projectId',
      initialValue: options.projectId,
    });

    this.showDisabledFilter = new Filter<boolean>({
      name: 'showDisabled',
      initialValue: false,
    });

    this.listing = new Listing<CalculationSearchResponse, CalculationSearchFilters>({
      filters: [this.showDisabledFilter, this.projectIdFilter],
      synchronizer: (filters) => app.morganThermalAPI.fetch(searchCalculations(filters)),
    });
    this.listing.search();

    this.showDisabledFilter.on('change', () => { this.listing.pageFilter.setValue(1) });
  }

  enable(id: MaterialInformation['id']) {
    enableCalculation(id).then(this.listing.search).catch(console.error);
  }

  disable(id: MaterialInformation['id']) {
    disableCalculation(id).then(this.listing.search).catch(console.error);
  }
}