import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { ProjectsListing } from '@/app/listing/projects';
import { NavigationModifier } from '@/shared/design-system/thermal-ceramics/components/navigation';
import { Navigation } from '@/shared/design-system/thermal-ceramics/components/navigation';

export const IndexRouteView = () => (
  <Page hasOperationsInProgress={false} loaded={true}>
    <Navigation modifier={NavigationModifier.SECONDARY}>
      <Navigation.Item to={`/`}>Projects</Navigation.Item>
      <Navigation.Item to={`/my-materials`}>Materials</Navigation.Item>
    </Navigation>
    <ProjectsListing/>
  </Page>
);