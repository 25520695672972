import { observer } from 'mobx-react-lite';
import { clsx } from 'clsx';

import { Fieldset } from '@/shared/design-system/thermal-ceramics/components/form/fieldset';
import { MaterialListingItem } from '@/app/api/morgan-thermal/material';
import { Input } from '@/shared/design-system/thermal-ceramics/components/form/input';
import { app } from '@/app';
import { ValueMeasurement, ValueMeasurementString } from '@/app/form/fields/material/helpers/value-measurement';
import { LeftArrowIcon, PlusIcon, RightArrowIcon } from '@/shared/design-system/thermal-ceramics/icons';
import { Checkbox } from '@/shared/design-system/thermal-ceramics/components/form/checkbox';
import { Label } from '@/shared/design-system/thermal-ceramics/components/form/label';
import { HookFormField } from '@/shared/libs/hook-form';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';
import { UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import { SaveCalculationFormValues } from '@/app/form/scenarios/save-calculation';
import { Hint, HintType } from '@/shared/design-system/thermal-ceramics/components/form/hint';
import { ScrollArea } from '@/shared/design-system/thermal-ceramics/components/scroll-area';

import classNames from './styles.module.css';
import { MeasurementSystem } from '@/app/measurement-systems/model';
import { roundDecimals } from '@/shared/helpers/number';

export type LiningDesignFieldsetProps = {
  calculationMaterials: UseFieldArrayReturn<SaveCalculationFormValues>,
  onSwap?: (fromIndex: number, toIndex: number) => void,
  onUnselect?: (id: MaterialListingItem['id']) => void,
};

export const LiningDesignFieldset = observer((props: LiningDesignFieldsetProps) => {
  const { calculationMaterials, onUnselect } = props;
  const form = useFormContext();
  const calculationMaterialsFieldState = form.getFieldState('calculationMaterials', form.formState);
  const hasTemperature = !!calculationMaterials.fields.find((material) => typeof material[app.measurementSystems.currentSystem].temperature === 'number');

  return (
    <div className={clsx(classNames.liningDesign, {
      [classNames.hasTemperature]: hasTemperature,
    })}>
      <Fieldset.Legend>Lining Design</Fieldset.Legend>
      <p className={classNames.description}>To add materials, select up to 15 materials from the list above.</p>

      {calculationMaterials.fields.length >=15 && (
        <Hint className={classNames.hint} type={HintType.ERROR}>The maximum of 15 layers have been added.</Hint>
      )}

      {calculationMaterialsFieldState.error && (
        <Hint className={classNames.hint} type={HintType.ERROR}>{calculationMaterialsFieldState.error.message}</Hint>
      )}

      <div className={classNames.materials}>
        <ScrollArea>
          <div className={classNames.materialsInner}>
            <div className={classNames.labelsRow}>
              <div className={clsx(classNames.checkColumn, classNames.column)}/>
              <div className={clsx(classNames.swapColumn, classNames.column)}/>
              <div className={clsx(classNames.nameColumn, classNames.column)}><Label>Name</Label></div>
              <div className={clsx(classNames.densityColumn, classNames.column)}><Label>Density Level</Label></div>
              <div className={clsx(classNames.thicknessColumn, classNames.column)}><Label>Thickness <span aria-hidden="true">*</span></Label></div>
              {hasTemperature && <div className={clsx(classNames.temperatureColumn, classNames.column)}><Label>Interface Temperature</Label></div>}
            </div>
            {calculationMaterials.fields.length ? (
              <div className={classNames.grid}>
                {calculationMaterials.fields.map((field, index) => {
                  const temperature = field[app.measurementSystems.currentSystem].temperature;

                  return (
                    <div className={classNames.row} key={`${field.materialId}_${index}`}>
                      <div className={clsx(classNames.checkColumn, classNames.column)}><Checkbox onChange={() => onUnselect && onUnselect(field.materialId)} defaultChecked/></div>

                      <div className={clsx(classNames.swapColumn, classNames.column)}>
                        {field.isActive ? (
                          <>
                            <Button className={classNames.swapButton} disabled={index === 0} type="button" modifier={ButtonModifier.SECONDARY} onClick={() => calculationMaterials.swap(index, index - 1)}><LeftArrowIcon/></Button>
                            <Button className={classNames.swapButton} disabled={index === (calculationMaterials.fields.length - 1)} type="button" modifier={ButtonModifier.SECONDARY} onClick={() => calculationMaterials.swap(index, index + 1)}><RightArrowIcon/></Button>
                            <Button className={classNames.plusButton} disabled={calculationMaterials.fields.length >= 15} type="button" modifier={ButtonModifier.SECONDARY} onClick={() => {
                              calculationMaterials.append({
                                ...calculationMaterials.fields[index],
                                id: 0,
                              });
                            }}><PlusIcon/></Button>
                          </>
                        ) : (
                          <p className={classNames.disabledMaterialHint}>Disabled</p>
                        )}
                      </div>

                      <div className={clsx(classNames.nameColumn, classNames.column)}><Input value={field.name} disabled/></div>
                      <div className={clsx(classNames.densityColumn, classNames.column)}>
                        <Input
                          value={roundDecimals(field[app.measurementSystems.currentSystem].density, app.measurementSystems.currentSystem === MeasurementSystem.METRIC ? 0 : 1) ?? ''}
                          endSlot={<ValueMeasurementString hint={field[app.measurementSystems.currentSystem].densityLabel}/>}
                          disabled
                        />
                      </div>
                      <div className={clsx(classNames.thicknessColumn, classNames.column)}>
                        <HookFormField.Input
                          name={`calculationMaterials.${index}.thickness`}
                          disabled={!field.isActive}
                          decimalPlaces={(app.measurementSystems.currentSystem === MeasurementSystem.METRIC) ? 0 : 2}
                          extendProps={(props) => ({
                            ...props,
                            endSlot: <ValueMeasurement imperial={<>In</>} metric={<>mm</>}/>
                          })}
                          required
                        />
                      </div>
                      {typeof temperature === 'number' && (
                        <div className={clsx(classNames.temperatureColumn, classNames.column)}>
                          <Input
                            value={temperature}
                            endSlot={<ValueMeasurement
                              imperial={<>&deg;F</>}
                              metric={<>&deg;C</>}
                            />}
                            disabled
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className={classNames.noMaterials}>No materials added.</p>
            )}
          </div>
        </ScrollArea>
      </div>
    </div>
  );
});