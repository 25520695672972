import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import EventEmitter from 'eventemitter3';

export enum SynchronizableValueStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

export type Synchronizer<T> = (...args: any[]) => Promise<T>;

interface ISynchronizableValue<T> {
  value: T,
  status: SynchronizableValueStatus,
  synchronize: Synchronizer<T>,
  reset: (value?: T) => void,
  set: (value: T) => void,
}

export default class SynchronizableValue<T> extends EventEmitter implements ISynchronizableValue<T> {
  private synchronizer: Synchronizer<T>

  public initialValue: T
  public value: T
  public status = SynchronizableValueStatus.PENDING
  public synchronizationInProgress: (Promise<T> | null) = null

  public constructor(initialValue: T, synchronizer: Synchronizer<T>) {
    super();

    makeObservable(this, {
      value: observable,
      status: observable,
      synchronizationInProgress: observable,
      synchronize: action.bound,
      reset: action.bound,
      set: action.bound,
      isSynchronizationPending: computed,
      isSynchronizationInProgress: computed,
      isSynchronizationFulfilled: computed,
      isSynchronizationRejected: computed,
    });

    this.initialValue = initialValue;
    this.value = initialValue;
    this.synchronizer = synchronizer;
    this.synchronizationInProgress = null;
  }

  public synchronize(...args: any[]): Promise<T> {
    this.status = SynchronizableValueStatus.IN_PROGRESS;
    this.synchronizationInProgress = new Promise((resolve, reject) => {
      this.synchronizer(...args)
        .then((value) => runInAction(() => {
          this.value = value;
          this.status = SynchronizableValueStatus.FULFILLED;
          resolve(value);
          this.emit('synchronizationFulfilled', value);
        }))
        .catch((error) => runInAction(() => {
          this.status = SynchronizableValueStatus.REJECTED;
          reject(error);
          this.emit('synchronizationRejected', error);
        }));
    });

    return this.synchronizationInProgress;
  }

  public reset(value?: T) {
    this.value = value ?? this.initialValue;
    this.status = SynchronizableValueStatus.PENDING;
    this.emit('reset', this.value);
  }

  public set(value: T) {
    this.value = value;
    this.status = SynchronizableValueStatus.FULFILLED;
    this.emit('synchronizationFulfilled', value);
  }

  get isSynchronizationPending() {
    return this.status === SynchronizableValueStatus.PENDING;
  }

  get isSynchronizationInProgress() {
    return this.status === SynchronizableValueStatus.IN_PROGRESS;
  }

  get isSynchronizationFulfilled() {
    return this.status === SynchronizableValueStatus.FULFILLED;
  }

  get isSynchronizationRejected() {
    return this.status === SynchronizableValueStatus.REJECTED;
  }
}
