import { RouteObject } from 'react-router-dom';

import { app } from '@/app';
import { withCrumb } from '@/app/routing/breadcrumbs';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { getProject } from '@/app/api/morgan-thermal/project/get';
import { ProjectInformation } from '@/app/api/morgan-thermal/project';

import { editRoute } from './edit/route';
import { indexRoute } from './index/route';
import { CalculationInformation } from '@/app/api/morgan-thermal/calculation';
import { getCalculation } from '@/app/api/morgan-thermal/calculation/get';

export const calculationRoute: RouteObject = {
  id: 'calculation',
  path: ':calculationId',
  children: [
    indexRoute,
    editRoute,
  ],
  async loader({ params: { calculationId } }) {
    const calculation = new SynchronizableValue<CalculationInformation | null>(null, () => {
      return app.morganThermalAPI.fetch(getCalculation(calculationId));
    });

    await calculation.synchronize();

    return calculation;
  },
  handle: (calculation: SynchronizableValue<CalculationInformation | null>) => withCrumb({
    element: () => <span>{calculation?.value?.name}</span>,
  }),
}