import { boolean, object, lazy } from '@/shared/libs/yup';
import { nameSchema } from '@/app/form/fields/common/name';
import { emailSchema } from '@/app/form/fields/common/email';
import { jobSchema } from '@/app/form/fields/common/job';
import { citySchema } from '@/app/form/fields/common/city';
import { postalCodeSchema } from '@/app/form/fields/common/postal-code';
import { addressSchema } from '@/app/form/fields/common/address';
import { phoneSchema } from '@/app/form/fields/common/phone';
import { countrySchema } from '@/app/form/fields/common/country';
import { stateProvinceSchema } from '@/app/form/fields/common/state-province';
import { industrySchema } from '@/app/form/fields/company/industry';
import { domainsSchema } from '@/app/form/fields/company/domains';

export type SignUpFormValidationOptions = { hasCompanyFieldset: boolean, hasUserStateProvinces: boolean, hasCompanyStateProvinces: boolean };

export const createValidationSchema = (options: SignUpFormValidationOptions) => object({
  user: object({
    name: nameSchema.required(),
    email: emailSchema.required(),
    job: jobSchema.label('Job Title'),
    city: citySchema.required().label('City'),
    zip: postalCodeSchema.required().label('Postal Code'),
    address: addressSchema.required().label('Company Address'),
    additionalAddress: addressSchema.label('Additional Address'),
    contactPhone: phoneSchema.required().label('Contact Phone'),
    secondaryPhone: phoneSchema.label('Secondary Phone'),
    countryId: countrySchema.required().label('Country'),
    stateProvinceId: lazy(() => {
      const schema = stateProvinceSchema.label('State/Province');
      return options.hasUserStateProvinces ? schema.required() : schema;
    }),
  }),
  company: options.hasCompanyFieldset ? object({
    fillWithUser: boolean(),
    name: nameSchema.required().label('Company Name'),
    industryId: industrySchema.label('Industry'),
    city: citySchema.when("fillWithUser", {
      is: true,
      otherwise: (schema) => schema.required(),
    }),
    zip: postalCodeSchema.label('Postal Code').when("fillWithUser", {
      is: true,
      otherwise: (schema) => schema.required(),
    }),
    address: addressSchema.label('Company Address').when("fillWithUser", {
      is: true,
      otherwise: (schema) => schema.required(),
    }),
    additionalAddress: addressSchema.label('Additional Address'),
    contactPhone: phoneSchema.label('Contact Phone').when("fillWithUser", {
      is: true,
      otherwise: (schema) => schema.required(),
    }),
    secondaryPhone: phoneSchema.label('Secondary Phone'),
    countryId: countrySchema.when("fillWithUser", {
      is: true,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.required(),
    }),
    stateProvinceId: stateProvinceSchema.when("fillWithUser", {
      is: true,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => options.hasCompanyStateProvinces ? schema.required() : schema,
    }),
    domains: domainsSchema.required().label('Company Domain'),
  }) : object().notRequired(),
});