import { lazy, object } from '@/shared/libs/yup';
import { nameSchema } from '@/app/form/fields/common/name';
import { emailSchema } from '@/app/form/fields/common/email';
import { jobSchema } from '@/app/form/fields/common/job';
import { citySchema } from '@/app/form/fields/common/city';
import { postalCodeSchema } from '@/app/form/fields/common/postal-code';
import { addressSchema } from '@/app/form/fields/common/address';
import { phoneSchema } from '@/app/form/fields/common/phone';
import { countrySchema } from '@/app/form/fields/common/country';
import { stateProvinceSchema } from '@/app/form/fields/common/state-province';
import { userRoleSchema } from '@/app/form/fields/user/role';

export type EditProfileFormValidationOptions = {
  canEditRole: boolean,
  hasStateProvinces: boolean,
};

export const createValidationSchema = (options: EditProfileFormValidationOptions) => object({
  name: nameSchema.required(),
  email: emailSchema.required(),
  job: jobSchema.label('Job Title'),
  city: citySchema.required().label('City'),
  zip: postalCodeSchema.required().label('Postal Code'),
  address: addressSchema.required().label('Company Address'),
  additionalAddress: addressSchema.label('Additional Address'),
  contactPhone: phoneSchema.required().label('Contact Phone'),
  secondaryPhone: phoneSchema.label('Secondary Phone'),
  countryId: countrySchema.required().label('Country'),
  stateProvinceId: lazy(() => {
    const schema = stateProvinceSchema.label('State/Province');
    return options.hasStateProvinces ? schema.required() : schema;
  }),
  roleId: lazy(() => {
    const schema = userRoleSchema;
    return options.canEditRole ? schema.required() : schema;
  }),
});