import { observer } from 'mobx-react-lite';
import { useRouteLoaderData, useSearchParams } from 'react-router-dom';

import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { Breadcrumbs } from '@/app/routing/breadcrumbs';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { ProjectInformation } from '@/app/api/morgan-thermal/project';
import { ProjectDetails } from '@/app/details/project';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';
import { CalculationListing } from '@/app/listing/calculation';

import classNames from './styles.module.css';
import { useEffect, useState } from 'react';
import { CalculationListingItem } from '@/app/api/morgan-thermal/calculation';
import { app } from '@/app';
import { getCalculation } from '@/app/api/morgan-thermal/calculation/get';
import { CompareMaterials } from '@/app/actions/material/compare';
import { CompareCalculations } from '@/app/actions/calculation/compare';

export const ProjectIndexRouteView = observer(() => {
  const [selectedRows, setSelectedRows] = useState<CalculationListingItem[]>([]);
  const [searchParams] = useSearchParams();
  const calculationIds = (searchParams.get('selectedCalculationIds') ?? '').split(',').filter((id) => id).map((id) => Number.parseInt(id));
  const project = useRouteLoaderData('project') as SynchronizableValue<ProjectInformation | null>;

  useEffect(() => {
    Promise.all(calculationIds.map((id) => app.morganThermalAPI.fetch(getCalculation(id))))
      // @ts-ignore
      .then((calculation) => setSelectedRows(calculation));
  }, []);

  return (
    <Page className={classNames.projectPage} hasOperationsInProgress={project.isSynchronizationInProgress} loaded={!!project.value}>
      <Breadcrumbs/>
      <Heading as="h1">Project Details</Heading>
      {project.value && (
        <>
          <ProjectDetails editUrl={project.value?.isActive ? `/projects/${project.value.id}/edit` : undefined} project={project.value}/>
          <div className={classNames.listing}>
            <CalculationListing
              projectId={project.value.id}
              initiallySelectedIds={calculationIds}
              selectedRows={selectedRows}
              onSelectionChange={(selectedRows) => {
                setSelectedRows(selectedRows);
              }}
            />
          </div>
          {!!selectedRows.length && (
            <CompareCalculations
              projectId={project.value.id}
              calculations={selectedRows}
              onUnselect={(id) => {
                setSelectedRows(selectedRows.filter((calculation) => calculation.id !== id));
              }}
            />
          )}
        </>
      )}
    </Page>
  );
});