import { createEndpoint } from '@/shared/models/api';
import { Informative } from '@/app/api/morgan-thermal';

export type SendHelpEmailRequest = {
  input: string;
};

export const sendHelpEmail = createEndpoint<Informative>(({fetch}) => (request: SendHelpEmailRequest) => fetch(`/User/SendHelpEmail`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(request),
}));