import {
  AuthenticationResult,
  EndSessionRequest,
  EventMessage,
  EventType,
  PublicClientApplication
} from '@azure/msal-browser';
import EventEmitter from 'eventemitter3';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { authorities, configuration, scopes } from '@/app/auth/providers/msal/configuration';
import { AuthorizationProviderAccount, AuthorizationProvider, AuthorizationProviderStatus } from '@/app/auth/providers/types';


export class MSALAuthorizationProvider extends EventEmitter implements AuthorizationProvider {
  public status: AuthorizationProvider['status'];

  private msal: PublicClientApplication;

  constructor() {
    super();

    makeObservable(this, {
      isLoggedIn: computed,
      status: observable,
      login: action.bound,
      logout: action.bound,
      resetPassword: action.bound,
      getToken: action.bound,
      getAccount: action.bound,
    });

    this.msal = new PublicClientApplication(configuration);
    this.handleRedirectPromise();
    this.handleMSALEvent = this.handleMSALEvent.bind(this);
    this.msal.addEventCallback(this.handleMSALEvent);
    this.status = (this.msal.getAllAccounts().length > 0) ? AuthorizationProviderStatus.LOGGED_IN : AuthorizationProviderStatus.UNAUTHENTICATED;

    this.fallbackActiveAccount();
  }

  public get isLoggedIn() {
    return this.status === AuthorizationProviderStatus.LOGGED_IN;
  }

  private handleMSALEvent({ eventType, payload }: EventMessage) {
    runInAction(() => {
      switch (eventType) {
        case EventType.LOGIN_START: {
          this.status = AuthorizationProviderStatus.LOGGING_IN;
          this.emit('statusChange', this.status);
          break;
        }
        case EventType.LOGIN_FAILURE: {
          this.status = AuthorizationProviderStatus.UNAUTHENTICATED;
          this.emit('statusChange', this.status);
          break;
        }
        case EventType.LOGIN_SUCCESS: {
          this.status = AuthorizationProviderStatus.LOGGED_IN;
          this.msal.setActiveAccount((payload as AuthenticationResult).account);
          this.emit('statusChange', this.status);
          break;
        }
        case EventType.LOGOUT_START: {
          this.status = AuthorizationProviderStatus.LOGGING_OUT;
          this.emit('statusChange', this.status);
          break;
        }
        case EventType.LOGOUT_FAILURE: {
          this.status = AuthorizationProviderStatus.LOGGED_IN;
          this.emit('statusChange', this.status);
          break;
        }
        case EventType.LOGOUT_SUCCESS: {
          this.status = AuthorizationProviderStatus.LOGGED_OUT;
          this.emit('statusChange', this.status);
          break;
        }
      }
    });
  }

  private fallbackActiveAccount() {
    if (!this.msal.getActiveAccount() && this.msal.getAllAccounts().length > 0) {
      this.msal.setActiveAccount(this.msal.getAllAccounts()[0]);
    }
  }

  private handleRedirectPromise() {
    this.msal.handleRedirectPromise()
      .then((authenticationResult: AuthenticationResult | null) => {
        if (!authenticationResult) return;

        this.msal.setActiveAccount(authenticationResult.account);
      }).catch(console.error);
  }

  login() {
    return this.msal.loginPopup({
      scopes: scopes.login,
    }).catch(console.error);
  }

  resetPassword() {
    return this.msal.loginPopup({
      scopes: scopes.login,
      authority: authorities.resetPassword,
    }).then(() => this.logout(window.location.origin + '/profile'));
  }

  logout(postLogoutRedirectUri?: string) {
    const endSessionRequest: EndSessionRequest  = {
      account: this.msal.getActiveAccount(),
    };

    if (postLogoutRedirectUri) endSessionRequest.postLogoutRedirectUri = postLogoutRedirectUri;

    return this.msal.logoutRedirect(endSessionRequest);
  }

  getToken() {
    return this.msal.acquireTokenSilent({
      scopes: scopes.token,
    })
      .then(({ accessToken }) => {
        return Promise.resolve<string>(accessToken);
      })
      .catch(() => {
        if (this.isLoggedIn) this.logout();
        return Promise.reject(null);
      });
  }

  getAccount(): AuthorizationProviderAccount | null {
    const account = this.msal.getActiveAccount();

    if (!account) return null;

    return {
      name: account.name ?? '',
      email: account.username ?? '',
    };
  }
}