import React, { PropsWithChildren } from 'react';
import { clsx } from 'clsx';
import { UnorderedList } from '@/shared/design-system/thermal-ceramics/components/list';
import { ExternalIcon } from '@/shared/design-system/thermal-ceramics/icons';
import classNames from './styles.module.css';

export type ExternalLink = PropsWithChildren<JSX.IntrinsicElements['a']> & {
  id: string,
};

export type ExternalLinksProps = JSX.IntrinsicElements['ul'] & {
  links: ExternalLink[],
}

export function ExternalLinks({ links, ...props }: ExternalLinksProps) {
  return (
    <UnorderedList<ExternalLink>
      {...props}
      className={clsx(classNames.externalLinks, props.className)}
      itemClassName={classNames.item}
      items={links}
      createKey={(link) => link.id}
      render={({ children, ...props }) => (
        <a {...props} className={clsx(classNames.link, props.className)}>
          {props.target === '_blank' && <ExternalIcon className={classNames.icon}/>}
          <span className={classNames.text}>{children}</span>
        </a>
      )}
    />
  );
}