import { indexRoute } from './index/route';
import { createRoute } from './create/route';
import { myMaterialRoute } from './id/route';
import { compareRoute } from './compare/route';
import { withCrumb } from '@/app/routing/breadcrumbs';

export const myMaterialsRoute = {
  path: 'my-materials',
  children: [
    myMaterialRoute,
    indexRoute,
    createRoute,
    compareRoute,
  ],
  handle: () => withCrumb({
    element: () => <span>Materials</span>,
  })
};
