import { Options as PopperOptions } from '@popperjs/core/lib/types';
import React, { PropsWithChildren, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Popover, Transition } from '@headlessui/react';
import { createPortal } from 'react-dom';
import enterAnimationClasses from '@/shared/design-system/thermal-ceramics/animations/enter/styles.module.css';
import { clsx } from 'clsx';
import leaveAnimationClasses from '@/shared/design-system/thermal-ceramics/animations/leave/styles.module.css';

import classNames from './styles.module.css';

const popperOptions: PopperOptions = {
  placement: 'bottom-start',
  strategy: 'fixed',
  modifiers: [
    {
      name: 'flip',
      options: {
        rootBoundary: 'document',
        allowedAutoPlacements: ['top-start', 'bottom-start'],
      },
    },
    {
      name: 'offset',
      options: {
        offset: [0, 4],
      },
    },
    {
      name: 'preventOverflow',
      options: {
        padding: 8,
      },
    },
  ],
};

export const RecalculationPopover = ({ children }: PropsWithChildren) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [popoverReference, setPopoverReference] = useState<HTMLDivElement | null>(null);
  const [popoverElement, setPopoverElement] = useState<HTMLDivElement | null>(null);
  const popper = usePopper(popoverReference, popoverElement, popperOptions);
  const {
    styles: { popper: popoverStyle },
    attributes: { popper: popoverAttributes}
  } = popper;

  const handleMouseEnter = (open: boolean) => {
    if (open) return;

    buttonRef?.current?.click();
    setTimeout(() => {
      if (popper?.update) popper.update();
    }, 0);
  };

  return (
    <Popover className={classNames.popover} as="div" >
      {({ open, close }) => (
        <div
          onMouseEnter={() => handleMouseEnter(open)}
          onMouseLeave={close}
        >
          <div className={classNames.buttonContainer} ref={setPopoverReference}>
            <Popover.Button ref={buttonRef} className={classNames.button}>Needs Recalculation</Popover.Button>
          </div>
          {createPortal((
            <div className={classNames.popoverElement} ref={setPopoverElement} style={popoverStyle} {...popoverAttributes}>
              <Transition
                show={open}
                enter={enterAnimationClasses.enter}
                enterFrom={clsx(
                  enterAnimationClasses.enterFromOpacity,
                  enterAnimationClasses.enterFromTransform,
                )}
                enterTo={clsx(
                  enterAnimationClasses.enterToOpacity,
                  enterAnimationClasses.enterToTransform,
                )}
                leave={leaveAnimationClasses.leave}
                leaveFrom={clsx(
                  leaveAnimationClasses.leaveFromOpacity,
                  leaveAnimationClasses.leaveFromTransform,
                )}
                leaveTo={clsx(
                  leaveAnimationClasses.leaveToOpacity,
                  leaveAnimationClasses.leaveToTransform,
                )}
              >
                <div className={classNames.content}>{children}</div>
              </Transition>
            </div>
          ), document.body)}
        </div>
      )}
    </Popover>
  );
};