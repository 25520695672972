import { app } from '@/app';
import { enableCalculation as enable } from '@/app/api/morgan-thermal/calculation/enable';
import { ActionType, runServerSideAction } from '@/app/actions';
import { CalculationInformation } from '@/app/api/morgan-thermal/calculation';

export const enableCalculation = (id: CalculationInformation['id']) => runServerSideAction({
  type: ActionType.POSITIVE,

  title: 'Enable',
  confirmationMessage: 'Enable this calculation?',
  approveLabel: 'Enable',

  runAction: () => app.morganThermalAPI.fetch(enable(id)),
});