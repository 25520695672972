import { EntityDetails } from '@/app/details';
import { MaterialInformation } from '@/app/api/morgan-thermal/material';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';
import { app } from '@/app';

import classNames from './styles.module.css';
import { observer } from 'mobx-react-lite';
import { roundDecimals } from '@/shared/helpers/number';
import { MeasurementSystem } from '@/app/measurement-systems/model';

export type MaterialDetailsProps = {
  editUrl?: string,
  material: MaterialInformation
};

export const MaterialDetails = observer((props: MaterialDetailsProps) => {
  const { material, editUrl } = props;
  const materialProperties = material[app.measurementSystems.currentSystem];

  return (
    <div className={classNames.materialDetails}>
      <EntityDetails
        editUrl={(app.session.isGlobalAdmin || material.createdById === app.session.user.value?.id) ? editUrl : undefined}
        mainInformation={[
          { key: 'Name', value: material.name },
          { key: 'Origin of Manufacturer', value: material.originOfManufacturer },
          { key: 'Manufacturer', value: material.manufacturer },
          { key: 'Material Category', value: material.materialCategory },
          { key: 'Use Limit', value: `${roundDecimals(materialProperties.useLimit)} ${materialProperties.useLimitLabel}` },
          { key: 'Density', value: `${roundDecimals(materialProperties.density, app.measurementSystems.currentSystem === MeasurementSystem.METRIC ? 0 : 1)} ${materialProperties.densityLabel}` },
          { key: 'Specific Heat', value: typeof materialProperties.specificHeat === 'number' && `${roundDecimals(materialProperties.specificHeat, 2)} ${materialProperties.specificHeatLabel}` },
          { key: 'Specific Heat Temperature', value: typeof materialProperties.specificHeatTemperature === 'number' && `${roundDecimals(materialProperties.specificHeatTemperature)} ${materialProperties.specificHeatTemperatureLabel}` },
          { key: 'Classification Temperature', value: `${roundDecimals(materialProperties.classificationTemperature)} ${materialProperties.classificationTemperatureLabel}` },
        ]}
      />

      <Heading as='h2' className={classNames.thermalConductivityValuesHeading}>Thermal Conductivity Values</Heading>
      <div className={classNames.thermalConductivityValues}>
        {material.thermalConductivityValues.map((thermalConductivity) => {
          const { temperature, temperatureLabel, kValue, kValueLabel } = thermalConductivity[app.measurementSystems.currentSystem];

          return (
            <div
              key={thermalConductivity.id}
              className={classNames.thermalConductivity}
            >
              <dt
                className={classNames.temperature}
                dangerouslySetInnerHTML={{
                  __html: `${roundDecimals(temperature)} ${temperatureLabel}`,
                }}
              />
              <dd
                className={classNames.kValue}
                dangerouslySetInnerHTML={{
                  __html: `${roundDecimals(kValue, 3)} ${kValueLabel}`,
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
});