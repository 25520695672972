import { number } from '@/shared/libs/yup';
import { HookFormField, FieldInputProps } from '@/shared/libs/hook-form';
import { ValueMeasurement } from '@/app/form/fields/material/helpers/value-measurement';
import { MeasurementSystem } from '@/app/measurement-systems/model';

export const ambientTemperatureSchema = (measurementSystem: MeasurementSystem) =>
  number()
    .min(-40)
    .max(measurementSystem === MeasurementSystem.METRIC ? 500 : 932)
    .label('Ambient Temperature');

export const AmbientTemperatureHookFormField = (props: FieldInputProps) => (
  <HookFormField.Input
    label="Ambient Temperature"
    extendProps={(props) => ({
      ...props,
      endSlot: (
        <ValueMeasurement
          imperial={<>&deg;F</>}
          metric={<>&deg;C</>}
        />
      )
    })}
    {...props}
  />
);