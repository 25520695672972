import { useCallback } from 'react';
import { NavLink, useNavigate, useRouteLoaderData } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { app } from '@/app';
import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { Breadcrumbs } from '@/app/routing/breadcrumbs';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';
import { showInformativeModal } from '@/app/components/informative-modal';
import { SaveCompanyForm } from '@/app/form/scenarios/save-company';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { CompanyInformation } from '@/app/api/morgan-thermal/company';
import { CompanyUpdate, updateCompany } from '@/app/api/morgan-thermal/company/update';

export const CompanyEditRouteView = observer(() => {
  const company = useRouteLoaderData('company') as SynchronizableValue<CompanyInformation | null>;
  const navigate = useNavigate();

  const handleSubmit = useCallback((data: CompanyUpdate) => {
    return app.morganThermalAPI.fetch(updateCompany(data))
      .then((response) => {
        company.synchronize({ noCache: true }).catch(console.error);
        navigate(`/companies/${company.value?.id}`);
        showInformativeModal(response).catch();
        return response;
      })
      .catch((response) => {
        showInformativeModal(response).catch();
        return response.data;
      });
  }, []);

  return (
    <Page hasOperationsInProgress={company.isSynchronizationInProgress} loaded={!!company.value}>
      <Breadcrumbs/>

      <Heading as="h1">Edit company details</Heading>

      {company.value && (
        <SaveCompanyForm
          company={company.value}
          submit={handleSubmit}
          footer={<>
            <Button modifier={ButtonModifier.TERTIARY} as={NavLink} to={`/companies/${company.value.id}`}>Cancel</Button>
            <Button type="submit">Save</Button>
          </>}
        />
      )}
    </Page>
  );
});