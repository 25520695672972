import { useEffect, useState } from 'react';
import { app } from '@/app';
import { getMaterial } from '@/app/api/morgan-thermal/material/get';
import { MaterialListingItem } from '@/app/api/morgan-thermal/material';
import { CompareMaterials } from '@/app/actions/material/compare';
import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { NavigationModifier } from '@/shared/design-system/thermal-ceramics/components/navigation';
import { Navigation } from '@/shared/design-system/thermal-ceramics/components/navigation';
import { MyMaterialListing } from '@/app/listing/my-materials';
import { NavLink, useSearchParams } from 'react-router-dom';
import { Button } from '@/shared/design-system/thermal-ceramics/components/button';

import classNames from './styles.module.css';

export const MyMaterialsRouteView = () => {
  const [selectedRows, setSelectedRows] = useState<MaterialListingItem[]>([]);
  const [searchParams] = useSearchParams();
  const materialIds = (searchParams.get('selectedMaterialIds') ?? '').split(',').filter((id) => id).map((id) => Number.parseInt(id));

  useEffect(() => {
    Promise.all(materialIds.map((id) => app.morganThermalAPI.fetch(getMaterial(id))))
      // @ts-ignore
      .then((materials) => setSelectedRows(materials));
  }, []);

  return (
    <Page hasOperationsInProgress={false} loaded={true}>
      <div className={classNames.header}>
        <Navigation modifier={NavigationModifier.SECONDARY}>
          <Navigation.Item to={`/`}>Projects</Navigation.Item>
          <Navigation.Item to={`/my-materials`}>Materials</Navigation.Item>
        </Navigation>
        <Button className={classNames.addMaterial} as={NavLink} to={'/my-materials/create'}>Create material</Button>
      </div>
      <div className={classNames.listing}>
        <MyMaterialListing initiallySelectedIds={materialIds} selectedRows={selectedRows} onSelectionChange={(selectedRows) => {
          setSelectedRows(selectedRows);
        }}/>
      </div>
      {!!selectedRows.length && <CompareMaterials materials={selectedRows} onUnselect={(id) => {
        setSelectedRows(selectedRows.filter((material) => material.id !== id));
      }}/>}
    </Page>
  );
}