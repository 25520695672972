import { ValueMeasurementString } from '@/app/form/fields/material/helpers/value-measurement';
import { app } from '@/app';
import { ScrollArea } from '@/shared/design-system/thermal-ceramics/components/scroll-area';
import { CalculationInformation } from '@/app/api/morgan-thermal/calculation';

import classNames from './styles.module.css';
import { Table } from '@/shared/design-system/thermal-ceramics/components/table';
import { roundDecimals } from '@/shared/helpers/number';
import { MeasurementSystem } from '@/app/measurement-systems/model';
import { observer } from 'mobx-react-lite';

export type CalculationTableProps = {
  calculation: CalculationInformation,
};

export const CalculationTable = observer((props: CalculationTableProps) => {
  const { calculation } = props;
  const isMetric = app.measurementSystems.currentSystem === MeasurementSystem.METRIC;

  return (
    <ScrollArea className={classNames.scrollArea}>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.HeadCell>Material Description</Table.HeadCell>
            <Table.HeadCell>Density Level</Table.HeadCell>
            <Table.HeadCell>Thickness</Table.HeadCell>
            <Table.HeadCell>Interface temp.</Table.HeadCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {calculation.calculationMaterials.slice().sort((a, b) => a.order - b.order).map((material) => (
            <Table.Row key={material.id}>
              <Table.Cell>{material.materialName}</Table.Cell>
              <Table.Cell>{roundDecimals(material[app.measurementSystems.currentSystem].density, isMetric ? 0 : 1)} <ValueMeasurementString hint={material[app.measurementSystems.currentSystem].densityLabel}/></Table.Cell>
              <Table.Cell>{roundDecimals(material[app.measurementSystems.currentSystem].thickness, isMetric ? 0 : 2)} <ValueMeasurementString hint={material[app.measurementSystems.currentSystem].thicknessLabel}/></Table.Cell>
              <Table.Cell>{typeof material[app.measurementSystems.currentSystem].temperature === 'number' && <>{roundDecimals(material[app.measurementSystems.currentSystem].temperature, 0)} <ValueMeasurementString hint={material[app.measurementSystems.currentSystem].temperatureLabel}/></>}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </ScrollArea>
  );
});