import classNames from './styles.module.css';

export type AvatarProps = {
  src: string,
};

export const Avatar = (props: AvatarProps) => {
  const { src } = props;

  return (
    <div className={classNames.avatar}>
      <img className={classNames.image} src={src} alt="" role="presentation" />
    </div>
  );
};