import { Modal } from '@/shared/design-system/thermal-ceramics/components/modal';
import { Informative } from '@/app/api/morgan-thermal';
import { showErrorModal, showSuccessModal } from '@/shared/design-system/thermal-ceramics/components/modal/promise/confirmation';

export const showInformativeModal = (informative: Informative, successMessage='Success') => {
  if (informative.errorMessage) {
    return showErrorModal({
      body: () => <>
        <Modal.Title>Error</Modal.Title>
        {informative.errorMessage && <Modal.Description><div dangerouslySetInnerHTML={{ __html: informative.errorMessage }}/></Modal.Description>}
      </>,
    });
  }

  return showSuccessModal({
    body: () => <>
      <Modal.Title>{informative.informationMessageHeader ?? successMessage}</Modal.Title>
      {informative.informationMessage && <Modal.Description><div dangerouslySetInnerHTML={{ __html: informative.informationMessage }}/></Modal.Description>}
    </>,
  }).catch(console.error);
};