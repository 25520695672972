import { observer } from 'mobx-react-lite';
import { useRouteLoaderData } from 'react-router-dom';

import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { Breadcrumbs } from '@/app/routing/breadcrumbs';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { UserInformation } from '@/app/api/morgan-thermal/user';
import { UserDetails } from '@/app/details/user';
import { app } from '@/app';

export const UserIndexRouteView = observer(() => {
  const user = useRouteLoaderData('user') as SynchronizableValue<UserInformation | null>;

  return (
    <Page hasOperationsInProgress={user.isSynchronizationInProgress} loaded={!!user.value}>
      <Breadcrumbs/>

      {user.value && <UserDetails user={user.value} editUrl={app.session.isAdmin ? `/companies/${user.value.companyId}/users/${user.value.id}/edit` : ''}/>}
    </Page>
  );
});