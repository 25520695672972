import { clsx } from 'clsx';

import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';
import { LeftArrowIcon, RightArrowIcon } from '@/shared/design-system/thermal-ceramics/icons';

import classNames from './styles.module.css';

export type PaginationProps = {
    pagesCount: number,
    currentPageIndex: number,
    boundaryCount?: number,
    siblingCount?: number,
    canPreviousPage?: boolean,
    canNextPage?: boolean,
    onChange?: (pageIndex: number) => void,
  };

function createRange(from: number, to: number): number[] {
  return Array.from(
    { length: to - from + 1 },
    (value, index) => from + index,
  );
}

function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

export const Pagination = (props: PaginationProps) => {
  const { pagesCount = 1, currentPageIndex = 0, boundaryCount = 1, siblingCount = 0, canPreviousPage, canNextPage, onChange } = props;

  if (pagesCount <= 1) return null;

  function handleChange(pageIndex: number) {
    if (pageIndex !== currentPageIndex)
    if (onChange) onChange(pageIndex);
  }

  const normalizedSiblingCount = clamp(siblingCount, 0, pagesCount - 1);
  const previousSiblingsStartIndex = Math.max(currentPageIndex - normalizedSiblingCount, 0);
  const nextSiblingsEndIndex = Math.min(currentPageIndex + normalizedSiblingCount, pagesCount - 1);
  const nearPages = createRange(previousSiblingsStartIndex, nextSiblingsEndIndex);
  const nearPagesFirst = nearPages[0];
  const nearPagesLast = nearPages[nearPages.length - 1];

  const maxBoundaryCount = Math.floor(pagesCount / 2);
  const normalizedBoundaryCount = clamp(boundaryCount, 1, maxBoundaryCount);
  const startBoundariesFirst = 0;
  const startBoundariesLast = normalizedBoundaryCount - 1;
  const endBoundariesFirst = pagesCount - normalizedBoundaryCount;
  const endBoundariesLast = pagesCount - 1;
  const startBoundariesIntersectionSize = nearPagesFirst <= startBoundariesLast ? Math.max(startBoundariesLast - nearPagesFirst + 1, 0) : 0;
  const endBoundariesIntersectionSize = nearPagesLast >= endBoundariesFirst ? Math.max(nearPagesLast - endBoundariesFirst + 1, 0) : 0;
  const startBoundaries = createRange(startBoundariesFirst, startBoundariesLast);
  const endBoundaries = createRange(endBoundariesFirst, endBoundariesLast);

  if (startBoundariesIntersectionSize > 0 && startBoundariesIntersectionSize < normalizedBoundaryCount) {
    endBoundaries.reverse().splice(startBoundariesIntersectionSize);
  } else if (endBoundariesIntersectionSize > 0 && endBoundariesIntersectionSize < normalizedBoundaryCount) {
    startBoundaries.splice(endBoundariesIntersectionSize);
  } else if (startBoundariesIntersectionSize === 0 && endBoundariesIntersectionSize === 0) {
    startBoundaries.splice(1);
    endBoundaries.reverse().splice(1);
  }

  const pages = Array.from(new Set([...startBoundaries, ...endBoundaries, ...nearPages, currentPageIndex].sort((a, b) => a - b)));

  function renderButtons() {
    return pages.flatMap((pageIndex, pagesListIndex) => {
      const buttons = [];
      const nextPageIndex = pages[pagesListIndex + 1];

      buttons.push(
        <button
          className={clsx(classNames.button, {
            [classNames.active]: pageIndex === currentPageIndex,
          })}
          type="button"
          key={pageIndex}
          onClick={() => handleChange(pageIndex)}
        >
          {pageIndex + 1}
        </button>
      );

      if (nextPageIndex - pageIndex > 1) {
        buttons.push(<div className={classNames.divider} key={`${pageIndex}_divider`} aria-hidden="true">...</div>);
      }

      return buttons;
    });
  }

  return (
    <div className={classNames.pagination}>
      <div className={classNames.buttons}>{renderButtons()}</div>

      <Button
        type="button"
        className={classNames.goButton}
        modifier={ButtonModifier.SECONDARY}
        disabled={!canPreviousPage}
        onClick={() => handleChange(currentPageIndex - 1)}
      >
        <LeftArrowIcon aria-hidden="true"/>
        <span className={classNames.goButtonText}>Previous</span>
      </Button>

      <div className={classNames.text}>
        {pagesCount > 1 ? (
          <span>Page <span className={classNames.number}>{currentPageIndex + 1}</span> of <span className={classNames.paginationNumber}>{pagesCount}</span></span>
        ) : (
          <span>Page <span className={classNames.number}>{currentPageIndex + 1}</span></span>
        )}
      </div>

      <Button
        type="button"
        className={classNames.goButton}
        modifier={ButtonModifier.SECONDARY}
        disabled={!canNextPage}
        onClick={() => handleChange(currentPageIndex + 1)}
      >
        <span className={classNames.goButtonText}>Next</span>
        <RightArrowIcon aria-hidden="true"/>
      </Button>
    </div>
  );
};