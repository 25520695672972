import { createColumnHelper } from '@tanstack/react-table';

import { isoToReadableString } from '@/shared/helpers/date';
import { FillLink } from '@/shared/design-system/thermal-ceramics/components/fill-link';
import { ProjectListingItem } from '@/app/api/morgan-thermal/project';
import { ProjectOrderByOptions } from '@/app/api/morgan-thermal/project/search';

const columnHelper = createColumnHelper<ProjectListingItem>();

export const columns = [
  columnHelper.accessor('customer', {
    header: 'Customer Name',
    size: 310,
    meta: {
      orderBy: ProjectOrderByOptions.Customer,
    },
    cell: info => <FillLink to={`/projects/${info.row.original.id}`}>{info.renderValue()}</FillLink>,
  }),
  columnHelper.accessor('project', {
    header: 'Project',
    size: 285,
    meta: {
      orderBy: ProjectOrderByOptions.Project,
    },
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('revisionNumber', {
    header: 'Revision Number ',
    size: 248,
    meta: {
      orderBy: ProjectOrderByOptions.RevisionNumber,
    },
    cell: info => info.renderValue(),
  }),
  columnHelper.accessor('revisionDate', {
    header: 'Revision Date',
    size: 168,
    meta: {
      orderBy: ProjectOrderByOptions.RevisionDate,
    },
    cell: info => isoToReadableString(info.getValue()),
  }),
];