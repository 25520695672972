import { ElementType, ReactElement, ReactNode } from 'react';
import { clsx } from 'clsx';
import classNames from './styles.module.css';

export enum ButtonModifier {
  SECONDARY = 'secondary',
}

type ButtonTag = 'a' | 'button';

type ButtonProps = {
  [Tag in ButtonTag]: {
    tag: Tag,
    modifier?: ButtonModifier | '',
    children?: ReactNode | Element[],
  } & JSX.IntrinsicElements[Tag];
}[ButtonTag];

type ButtonWithIconProps = ButtonProps & {
  icon: ReactElement,
};

export function Button({ tag, className, modifier = '', children, ...props }: ButtonProps) {
  const Tag = (tag ?? 'button') as ElementType;

  return (
    <Tag className={clsx(classNames.button, classNames[modifier], className)} {...props}>{children}</Tag>
  );
}

export function ButtonWithIcon({ icon, children, className, ...props }: ButtonWithIconProps) {
  return (
    <Button {...props} className={clsx(className, classNames.withIcon)}>
      <span className={classNames.text}>{children}</span>
      <span className={classNames.iconContainer} aria-hidden="true">{icon}</span>
    </Button>
  );
}