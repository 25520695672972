import { number } from '@/shared/libs/yup';
import { HookFormField, FieldInputProps } from '@/shared/libs/hook-form';
import { ValueMeasurement } from '@/app/form/fields/material/helpers/value-measurement';

export const outsideWindSpeedSchema = number().min(0).label('Outside Wind Speed');

export const OutsideWindSpeedHookFormField = (props: FieldInputProps) => (
  <HookFormField.Input
    label="Outside Wind Speed"
    extendProps={(props) => ({
      ...props,
      endSlot: (
        <ValueMeasurement
          imperial={<><sup>ft</sup>/<sub>s</sub></>}
          metric={<><sup>m</sup>/<sub>s</sub></>}
        />
      )
    })}
    {...props}
  />
);