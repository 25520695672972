import { SignUpRouteView } from './view';
import { withCrumb } from '@/app/routing/breadcrumbs';

export const signUpRoute = {
  path: 'sign-up',
  element: <SignUpRouteView/>,
  handle: () => withCrumb({
    element: () => <span>Sign Up</span>,
  }),
};
