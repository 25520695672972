import { ElementType, forwardRef, PropsWithChildren, ReactElement } from 'react';
import { PolymorphicComponentPropsWithRef, PolymorphicRef } from '@/shared/design-system/thermal-ceramics/components/polymorphic/types';

type ClickableAreaProps<C extends ElementType> = PolymorphicComponentPropsWithRef<C, PropsWithChildren>;

type ClickableAreaComponent = <C extends ElementType = 'button'>(props: ClickableAreaProps<C>) => ReactElement | null;

export const ClickableArea: ClickableAreaComponent = forwardRef(<C extends ElementType = 'button'>(props: ClickableAreaProps<C>, ref?: PolymorphicRef<C>) => {
  const { as, children, ...componentProps } = props;
  const extendedProps = as === 'button' ? { type: 'button', ...componentProps } : componentProps;
  const Tag = as ?? 'button';

  return (
    <Tag {...extendedProps} ref={ref}>{children}</Tag>
  );
});