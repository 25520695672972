export function addDaysToDate(date: Date, count: number) {
  const futureDate = new Date(date);

  futureDate.setDate(futureDate.getDate() + count);

  return futureDate;
}

export function addDaysFromToday(count: number) {
  return addDaysToDate(new Date(), count);
}

export function isoToReadableString(iso?: string | null) {
  if (!iso) return '';

  const date = new Date(iso);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}