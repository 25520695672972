import { action, makeObservable, observable } from 'mobx';
import {
  CompanyOrderByOptions,
  CompanySearchFilters,
  CompanySearchResponse,
  searchCompanies
} from '@/app/api/morgan-thermal/company/search';
import { Filter } from '@/shared/models/filters';
import { CompanyInformation, CompanyStateId } from '@/app/api/morgan-thermal/company';
import { app } from '@/app';
import { approveCompany } from '@/app/actions/company/approve';
import { denyCompany } from '@/app/actions/company/deny';
import { disableCompany } from '@/app/actions/company/disable';
import { Listing } from '@/app/listing/model';
import { enableCompany } from '@/app/actions/company/enable';

export class CompanyListingModel {
  stateFilter: Filter<CompanyStateId>;
  companyNameFilter: Filter<string>;

  listing: Listing<CompanySearchResponse, CompanySearchFilters, CompanyOrderByOptions>;

  constructor() {
    makeObservable(this, {
      listing: observable,

      approve: action.bound,
      deny: action.bound,
      enable: action.bound,
      disable: action.bound,
    });

    this.companyNameFilter = new Filter<string>({
      name: 'companyName',
      initialValue: '',
      debouncedBy: 300,
    });
    this.stateFilter = new Filter<CompanyStateId>({
      name: 'state',
      initialValue: CompanyStateId.PENDING,
    });

    this.listing = new Listing<CompanySearchResponse, CompanySearchFilters, CompanyOrderByOptions>({
      filters: [this.stateFilter, this.companyNameFilter],
      synchronizer: (filters) => app.morganThermalAPI.fetch(searchCompanies(filters)),
    });
    this.listing.search();

    this.stateFilter.on('change', () => { this.listing.pageFilter.setValue(1) });
  }

  approve(id: CompanyInformation['id']) {
    approveCompany(id).then(this.listing.search).catch(console.error);
  }

  deny(id: CompanyInformation['id']) {
    denyCompany(id).then(this.listing.search).catch(console.error);
  }

  enable(id: CompanyInformation['id']) {
    enableCompany(id).then(this.listing.search).catch(console.error);
  }

  disable(id: CompanyInformation['id']) {
    disableCompany(id).then(this.listing.search).catch(console.error);
  }
}