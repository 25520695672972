import React, { HTMLAttributes } from 'react';
import { clsx } from 'clsx';

import { StarIcon } from '@/shared/design-system/thermal-ceramics/icons';
import { VisuallyHidden } from '@/shared/design-system/thermal-ceramics/components/visually-hidden';

import classNames from './style.module.css';
import { Spinner } from '@/shared/design-system/thermal-ceramics/components/spinner';

export type FavoriteButtonProps = HTMLAttributes<HTMLButtonElement> & {
  active?: boolean;
  inProgress?: boolean;
};

export const FavoriteButton = (props: FavoriteButtonProps) => {
  const { className, active = false, inProgress = false, ...attributes } = props;
  const computedClassName = clsx(classNames.button, className, {
    [classNames.active]: active,
  });

  if (inProgress) return <Spinner className={classNames.spinner}/>;

  return (
    <button className={computedClassName} type="button" {...attributes}>
      <StarIcon className={classNames.icon}/>
      <VisuallyHidden>{active ? 'Remove from favorite' : 'Add to favorite'}</VisuallyHidden>
    </button>
  );
};