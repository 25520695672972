import { useCallback, useState } from 'react';
import { Informative } from '@/app/api/morgan-thermal';
import { useOnFieldChange } from '@/shared/libs/hook-form/hooks/on-field-change';
import { UseFormReturn } from 'react-hook-form';

export type UseEmailVerificationProps<TType extends Informative> = {
  form: UseFormReturn<any>,
  name: string,
  verify: (email: string) => Promise<TType>;
  onSuccess?: (result: TType) => void;
  onError?: (result: TType) => void;
};

export const useEmailVerification = <TType extends Informative,>(props: UseEmailVerificationProps<TType>) => {
  const { form, name, verify: consumerVerify, onSuccess, onError } = props;
  const { getFieldState, getValues } = form;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [inProgress, setInProgress] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [lastVerifiedEmail, setLastVerifiedEmail] = useState<string | null>(null);
  const [result, setResult] = useState<TType | null>(null);

  const handleError = useCallback((email: string, response: TType) => {
    setIsValid(false);
    setLastVerifiedEmail(email);
    setResult(response);

    response.errorMessage ? setErrorMessage(response.errorMessage) : setErrorMessage(null);

    if (onError) onError(response);
  }, [onError]);

  const handleSuccess = useCallback((email: string, response: TType) => {
    setResult(response);
    setIsValid(true);
    setLastVerifiedEmail(email);

    if (onSuccess) onSuccess(response);
  }, [onSuccess]);

  const verify = (email: string) => {
    if (email === lastVerifiedEmail) return;

    setInProgress(true);
    setIsValid(false);

    consumerVerify(email)
      .then((response) => {
        if (response.errorMessage) return handleError(email, response);
        handleSuccess(email, response);
      })
      .catch((response) => handleError(email, response))
      .finally(() => setInProgress(false));
  };

  const reset = () => {
    setLastVerifiedEmail(null)
    setIsValid(false);
    setResult(null);
  };

  const onBlur = useCallback(() => setTimeout(() => {
    if (!getFieldState(name).invalid) verify(getValues(name));
  }), [getFieldState, verify]);

  useOnFieldChange(form, name, () => {
    if (getFieldState(name).invalid) reset();
  });

  return {
    errorMessage,
    inProgress,
    isValid,
    result,
    onBlur,
    verify,
    reset,
  }
};