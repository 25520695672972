export enum CompanyStateId {
  ACTIVE = 1,
  PENDING = 2,
  DENIED = 3,
  DISABLED = 4,
}

export type CompanyInformation = {
  id: number,
  name: string,
  industryId?: number,
  industry: string,
  contactPhone: string,
  secondaryPhone: string,
  address: string,
  additionalAddress: string,
  city: string,
  countryId: number,
  country: string,
  stateProvinceId?: number,
  stateProvince: string,
  zip: string,
  domains: string[],
  companyStateId: number,
  amountOfMaterials: string,
  amountOfProjects: string,
  amountOfCalculations: string,
};

export type CompanyListingItem = {
  id: number;
  companyStateId: CompanyStateId;
  name: string;
  address: string;
  country: string;
  domains: number;
  createdDate: string;
};

export type CompanyListing = Array<CompanyListingItem>;