import { PropsWithChildren, HTMLProps } from 'react';
import { clsx } from 'clsx';
import { NavLink, To } from 'react-router-dom';
import classNames from './styles.module.css';

export enum NavigationModifier {
  SECONDARY = 'secondary',
}

export type NavigationItemProps = HTMLProps<HTMLLIElement> & {
  to: To,
  active?: boolean,
};

export type NavigationProps = PropsWithChildren<HTMLProps<HTMLElement>> & {
  modifier?: NavigationModifier,
};

const NavigationItem = (props: NavigationItemProps) => {
  const { to, active, children, ...itemProps } = props;

  return (
    <li {...itemProps} className={classNames.item}>
      <NavLink
        to={to}
        className={
          ({ isActive }) => {
            if (typeof active === 'boolean') return clsx(classNames.link, { [classNames.active]: active });
            return clsx(classNames.link, { [classNames.active]: isActive });
          }
        }
      >
        {children}
      </NavLink>
    </li>
  );
};

const NavigationRoot = (props: NavigationProps) => {
  const { modifier = '', children, ...navigationProps } = props;

  return (
    <nav {...navigationProps} className={clsx(classNames.navigation, classNames[modifier])}>
      <ul className={classNames.list}>{children}</ul>
    </nav>
  );
};

export const Navigation = Object.assign(NavigationRoot, {
  Item: NavigationItem,
});
