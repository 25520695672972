import { ReactNode, useEffect, useState } from 'react';
import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';

import classNames from './styles.module.css';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import { Breadcrumbs } from '@/app/routing/breadcrumbs';
import { MeasurementSystemsToggle } from '@/app/measurement-systems/view';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { app } from '@/app';
import { observer } from 'mobx-react-lite';
import { Table } from '@/shared/design-system/thermal-ceramics/components/table';
import { ValueMeasurement, ValueMeasurementString } from '@/app/form/fields/material/helpers/value-measurement';
import { clsx } from 'clsx';
import { printCalculationComparison } from '@/app/actions/calculation/compare/print';
import {
  CalculationInformation,
  CalculationMaterialProperties,
  CalculationProperties
} from '@/app/api/morgan-thermal/calculation';
import { getCalculation } from '@/app/api/morgan-thermal/calculation/get';
import { isoToReadableString } from '@/shared/helpers/date';
import { ProjectInformation } from '@/app/api/morgan-thermal/project';
import { roundDecimals } from '@/shared/helpers/number';
import { MeasurementSystem } from '@/app/measurement-systems/model';

type renderPropertyRowParameters = {
  calculations: CalculationInformation[],
  name: ReactNode,
  property: keyof CalculationProperties,
  label?: keyof CalculationProperties,
  roundTo: number | undefined,
};

const renderPropertyRow = ({ calculations, name, property, roundTo, label: propertyLabel }: renderPropertyRowParameters) => {
  return (
    <Table.Row>
      <Table.Cell className={classNames.label}>{name}</Table.Cell>
      {calculations.map((calculation) => {
        var value = calculation[app.measurementSystems.currentSystem][property];
        const label = propertyLabel ? calculation[app.measurementSystems.currentSystem][propertyLabel] as string : null;
        const isValidValue = (typeof value === 'number' && !Number.isNaN(value)) || (typeof value === 'string' && value.length > 0);
        if(roundTo != undefined && isValidValue){
          value = roundDecimals(Number(value), roundTo);
        }

        return (
          <Table.Cell key={calculation.id}>
            {(isValidValue) && (
              <>{value} {!!label && <ValueMeasurementString hint={label}/>}</>
            )}
          </Table.Cell>
        );
      })}
    </Table.Row>
  );
};

type renderMaterialRowParameters = {
  index: number;
  calculations: CalculationInformation[],
  name: ReactNode,
  property: keyof CalculationMaterialProperties,
  label?: keyof CalculationMaterialProperties,
  roundTo: number | undefined,
};

const renderMaterialRow = ({ index, calculations, name, property, roundTo, label: propertyLabel }: renderMaterialRowParameters) => {
  return (
    <Table.Row>
      <Table.Cell className={classNames.label}>{name}</Table.Cell>
      {calculations.map((calculation) => {
        const material = calculation.calculationMaterials[index];

        if (!material) return <Table.Cell key={calculation.id}/>

        var value = material[app.measurementSystems.currentSystem][property];
        const label = propertyLabel ? material[app.measurementSystems.currentSystem][propertyLabel] as string : null;
        const isValidValue = (typeof value === 'number' && !Number.isNaN(value)) || (typeof value === 'string' && value.length > 0);
        if(roundTo != undefined && isValidValue){
          value = roundDecimals(Number(value), roundTo);
        }

        return (
          <Table.Cell key={calculation.id}>
            {(isValidValue) && (
              <>{value} {!!label && <ValueMeasurementString hint={label}/>}</>
            )}
          </Table.Cell>
        );
      })}
    </Table.Row>
  );
};

export const CompareCalculationsRouteView = observer(() => {
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const calculationIds = (searchParams.get('ids') ?? '').split(',').filter((id) => id).map((id) => Number.parseInt(id));
  const hasCalculations = calculationIds.length > 0;
  const [calculations] = useState(() => new SynchronizableValue<CalculationInformation[]>([], () => {
    return Promise.all(calculationIds.map((id) => app.morganThermalAPI.fetch(getCalculation(id))));
  }));
  const [isPrintingInProgress, setIsPrintingInProgress] = useState(false);
  const materialsCount = Math.max(...calculations.value.map((calculation) => calculation.calculationMaterials.length));

  const handlePrintClick = () => {
    setIsPrintingInProgress(true);
    printCalculationComparison(calculationIds).finally(() => setIsPrintingInProgress(false));
  };

  useEffect(() => {
    calculations.synchronize().catch(console.error);
  }, []);

  const columnSizer = (
    <colgroup>
      <col width={240}/>
      {calculations.value.map((calculation) => (
        <col key={calculation.id} width={(1240 - 240) / calculations.value.length}/>
      ))}
    </colgroup>
  );

  const renderMaterials = () => {
    const materials: ReactNode[] = [];

    for (let index = 0; index < materialsCount; index++) {
      materials.push(
        <div className={classNames.materialTable}>
          <Table>
            {columnSizer}
            <Table.Head>
              <Table.Row>
                <Table.HeadCell>Overview</Table.HeadCell>
                {calculations.value.map((calculation) => (<Table.HeadCell key={calculation.id}/>))}
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Cell className={classNames.label}>Name</Table.Cell>
                {calculations.value.map((calculation) => {
                  const material = calculation.calculationMaterials[index];

                  if (!material) return <Table.Cell key={calculation.id}/>

                  return (
                    <Table.Cell key={calculation.id}>
                      {calculation.calculationMaterials[index].materialName}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
              {renderMaterialRow({
                index,
                name: 'Density',
                calculations: calculations.value,
                property: 'density',
                roundTo: (app.measurementSystems.currentSystem === MeasurementSystem.METRIC) ? 0 : 1,
                label: 'densityLabel',
              })}
              {renderMaterialRow({
                index,
                name: 'Thickness',
                calculations: calculations.value,
                property: 'thickness',
                roundTo: (app.measurementSystems.currentSystem === MeasurementSystem.METRIC) ? 0 : 2,
                label: 'thicknessLabel',
              })}
              {renderMaterialRow({
                index,
                name: 'Interface Temperature',
                calculations: calculations.value,
                property: 'temperature',
                roundTo: 0,
                label: 'temperatureLabel',
              })}
            </Table.Body>
          </Table>
        </div>
      );
    }

    return materials;
  }

  return (
    <Page className={classNames.compare} hasOperationsInProgress={hasCalculations ? calculations.isSynchronizationInProgress : false} loaded={hasCalculations ? !!calculations.value : true}>
      <Breadcrumbs/>
      <div className={classNames.header}>
        <Heading as="h1">Compare calculations</Heading>
        {hasCalculations && (
          <div className={classNames.actions}>
            <Button modifier={ButtonModifier.OUTLINE} as={NavLink} to={`/projects/${projectId}?selectedCalculationIds=${(searchParams.get('ids') ?? '')}`}>Revise</Button>
            <Button modifier={ButtonModifier.OUTLINE} onClick={handlePrintClick} inProgress={isPrintingInProgress}>Save as PDF</Button>
            <MeasurementSystemsToggle/>
          </div>
        )}
      </div>
      {hasCalculations ? (
        <>
          <div className={classNames.overviewTableWrap}>
            <Table>
              {columnSizer}
              <Table.Head>
                <Table.Row>
                  <Table.HeadCell>Overview</Table.HeadCell>
                  {calculations.value.map((calculation) => (<Table.HeadCell key={calculation.id}/>))}
                </Table.Row>
              </Table.Head>
              <Table.Body>
                <Table.Row>
                  <Table.Cell className={classNames.label}>Name</Table.Cell>
                  {calculations.value.map((calculation) => <Table.Cell key={calculation.id}>{calculation.name}</Table.Cell>)}
                </Table.Row>
                <Table.Row>
                  <Table.Cell className={classNames.label}>Casing Condition</Table.Cell>
                  {calculations.value.map((calculation) => <Table.Cell key={`${calculation.casing}_${calculation.id}`}>{calculation.casing}</Table.Cell>)}
                </Table.Row>
                <Table.Row>
                  <Table.Cell className={classNames.label}>Calculation date</Table.Cell>
                  {calculations.value.map((calculation) => <Table.Cell key={`${calculation.calculationDate}_${calculation.id}`}>{isoToReadableString(calculation.calculationDate)}</Table.Cell>)}
                </Table.Row>
                {renderPropertyRow({
                  calculations: calculations.value,
                  name: 'Diameter',
                  property: 'diameter',
                  roundTo: undefined,
                  label: 'diameterLabel',
                })}
                {renderPropertyRow({
                  calculations: calculations.value,
                  name: 'Ambient Temperature',
                  property: 'ambientTemperature',
                  roundTo: 1,
                  label: 'ambientTemperatureLabel',
                })}
                {renderPropertyRow({
                  calculations: calculations.value,
                  name: 'Heat Loss',
                  property: 'heatLoss',
                  roundTo: 1,
                  label: 'heatLossLabel',
                })}
                {renderPropertyRow({
                  calculations: calculations.value,
                  name: 'Weight',
                  property: 'weight',
                  roundTo: 1,
                  label: 'weightLabel',
                })}
                {renderPropertyRow({
                  calculations: calculations.value,
                  name: 'Ambient Wind',
                  property: 'outsideWindSpeed',
                  roundTo: 1,
                  label: 'outsideWindSpeedLabel',
                })}
                {renderPropertyRow({
                  calculations: calculations.value,
                  name: 'Heat Storage',
                  property: 'heatStorage',
                  roundTo: 1,
                  label: 'heatStorageLabel',
                })}
                {renderPropertyRow({
                  calculations: calculations.value,
                  name: 'Total Thickness',
                  property: 'totalThickness',
                  roundTo: (app.measurementSystems.currentSystem === MeasurementSystem.METRIC) ? 0 : 2,
                  label: 'totalThicknessLabel',
                })}
                {renderPropertyRow({
                  calculations: calculations.value,
                  name: 'Casing Emissivity',
                  property: 'emissivity',
                  roundTo: undefined,
                  label: 'emissivityLabel',
                })}
              </Table.Body>
            </Table>
          </div>

          <Heading as="h2">Materials</Heading>
          {renderMaterials()}
        </>
      ) : (
        <p>No items selected for comparison</p>
      )}
    </Page>
  );
});