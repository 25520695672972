import { FocusEvent, InputHTMLAttributes, ReactElement, useRef, useState, forwardRef } from 'react';

import { clsx } from 'clsx';
import { useSyncRefs } from '@/shared/hooks/use-sync-refs';
import { Spinner } from '@/shared/design-system/thermal-ceramics/components/spinner';

import classNames from './styles.module.css';
import { roundDecimals } from '@/shared/helpers/number';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  inProgress?: boolean,
  startSlot?: ReactElement,
  endSlot?: ReactElement,
  decimalPlaces?: number,
};

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { inProgress, startSlot, endSlot, disabled, decimalPlaces, onFocus, onBlur, className, ...attributes } = props;
  const inputElement = useRef<HTMLInputElement>(null);
  const mergedRefs = useSyncRefs(inputElement, ref);
  const [hasFocus, setHasFocus] = useState(false);
  let value = attributes.value;

  if (attributes.type === 'date' && typeof attributes.value === 'string') {
    const date = new Date(attributes.value);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    value = `${year}-${month}-${day}`;
  }

  if (attributes.type === 'number') {
    attributes.type = 'text';
  }

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    if (typeof onFocus === 'function') onFocus(event);
    setHasFocus(true);
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (typeof onBlur === 'function') onBlur(event);
    setHasFocus(false);

    if (typeof decimalPlaces === 'number') {
      const element = inputElement?.current;

      if (!element) return;

      const value = event.target.value;

      if (value.length === 0) return;

      const valueAsNumber = Number(value);
      const isValidNumber = !Number.isNaN(valueAsNumber);

      if (!isValidNumber) return;

      const roundedValue = roundDecimals(valueAsNumber, decimalPlaces);
      const isValidRoundedValue = !Number.isNaN(roundedValue);

      if (!isValidRoundedValue) return;

      // @ts-ignore
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;

      if (!nativeInputValueSetter) return;

      nativeInputValueSetter.call(element, roundedValue);
      element.dispatchEvent(new Event('input', { bubbles: true }));
    }
  }

  const computedClassName = clsx(classNames.container, className, {
    [classNames.invalid]: attributes['aria-invalid'] === 'true' || attributes['aria-invalid'] === true,
    [classNames.disabled]: inProgress ? true : disabled,
    [classNames.hasFocus]: hasFocus,
  });

  return (
    <div className={computedClassName}>
      {startSlot}
      <input
        {...attributes}
        value={value}
        ref={mergedRefs}
        disabled={inProgress ? true : disabled}
        className={classNames.input}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {inProgress ? <Spinner className={classNames.spinner}/> : endSlot}
    </div>
  );
});