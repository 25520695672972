import { number } from '@/shared/libs/yup';
import { HookFormField, FieldInputProps } from '@/shared/libs/hook-form';
import { ValueMeasurement } from '@/app/form/fields/material/helpers/value-measurement';

export const emissivitySchema =
  number()
    .moreThan(0)
    .lessThan(1)
    .label('Emissivity');

export const EmissivityHookFormField = (props: FieldInputProps) => (
  <HookFormField.Input
    label="Emissivity"
    {...props}
  />
);