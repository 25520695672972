import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import { Listing } from '@/app/listing';
import { Button } from '@/shared/design-system/thermal-ceramics/components/button';
import { Search } from '@/shared/design-system/thermal-ceramics/components/form/search';
import { ToggleButton } from '@/shared/design-system/thermal-ceramics/components/toggle-button/button';
import { ToggleButtonGroup } from '@/shared/design-system/thermal-ceramics/components/toggle-button/group';
import { ActionsDropdown, ActionsDropdownItemModifier } from '@/app/components/actions-dropdown';
import { createColumns } from './columns';
import { PersonalizedMaterialListingModel } from './model';
import { MaterialListingItem } from '@/app/api/morgan-thermal/material';
import { app } from '@/app';
import { MaterialCategoryField } from '@/app/form/fields/material/category';
import { OriginOfManufacturerFormField } from '@/app/form/fields/material/origin-of-manufacturer';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';

import classNames from './styles.module.css';
import { MaterialOrderByOptions } from '@/app/api/morgan-thermal/material/search';


export type PersonalizedMaterialListingProps = {
  isDisabled?: boolean;
  initiallySelectedIds?: MaterialListingItem['id'][],
  selectedRows?: MaterialListingItem[],
  onSelect?: (materialListingItem: MaterialListingItem) => void,
  onUnselect?: (materialListingItem: MaterialListingItem) => void,
  onSelectionChange?: (materialListingItems: MaterialListingItem[]) => void,
};

export const PersonalizedMaterialListing = observer((props: PersonalizedMaterialListingProps) => {
  const {isDisabled} = props;
  const { initiallySelectedIds, selectedRows, onSelect, onUnselect, onSelectionChange } = props;
  const [{ listing, getFavoritesFilter, getMyMaterialsFilter, materialCategoryIdFilter, regionIdFilter, materialNameFilter, enable, disable }] = useState(() => new PersonalizedMaterialListingModel());
  const { data, results, totalRecords, numberOfRecords, pageFilter, orderByFilter, orderByAscendingFilter } = listing;

  return (

    <div className={`classNames.materialListing} ${isDisabled ? classNames.disabled : ''}`}>
      <Heading as="h2" className={classNames.heading}>Select Materials</Heading>
      <Listing<MaterialListingItem, MaterialOrderByOptions>
        header={
          <>
            <ToggleButtonGroup
              value={(() => {
                if (getFavoritesFilter.value) return 'favorites';
                if (getMyMaterialsFilter.value) return 'myMaterials';
                return 'all';
              })()}
              onChange={(value) => {
                getFavoritesFilter.setValue(false);
                getMyMaterialsFilter.setValue(false);

                switch (value) {
                  case 'favorites': return getFavoritesFilter.setValue(true);
                  case 'myMaterials': return getMyMaterialsFilter.setValue(true);
                }
              }}
            >
              <ToggleButton value="all">All</ToggleButton>
              <ToggleButton value="favorites">Favorites</ToggleButton>
              <ToggleButton value="myMaterials">My Materials</ToggleButton>
            </ToggleButtonGroup>
            <MaterialCategoryField
              hasAllOption={true}
              onChange={materialCategoryIdFilter.setValue}
            />
            <OriginOfManufacturerFormField
              hasAllOption={true}
              onChange={regionIdFilter.setValue}
            />
            <Search value={materialNameFilter.value} onSearch={materialNameFilter.setValue}/>
          </>
        }
        columns={createColumns(app.measurementSystems.currentSystem)}
        data={results}
        isInitialized={!!data}
        hasOperationsInProgress={data.isSynchronizationInProgress}
        totalRecords={totalRecords}
        numberOfRecords={numberOfRecords}
        page={pageFilter.value}
        initiallySelectedIds={initiallySelectedIds}
        onRowClick={(row, event) => {
          if (event.target instanceof Element) {
            const rowElement = event.target.closest('tr');
            const materialsPopupButton = rowElement?.querySelector('[data-materials-popup-button]') as HTMLElement;
            materialsPopupButton?.click();
          }
        }}
        selectedRows={selectedRows}
        onPageChange={pageFilter.setValue}
        onSelect={onSelect}
        onUnselect={onUnselect}
        onSelectionChange={onSelectionChange}

        orderBy={orderByFilter.value as MaterialOrderByOptions}
        orderByAscending={orderByAscendingFilter.value}
        onOrderByChange={orderByFilter.setValue}
        onOrderByAscendingChange={orderByAscendingFilter.setValue}
      />
    </div>
  );
});