import { createEndpoint } from '@/shared/models/api';
import { SearchFilters, SearchResponse } from '@/app/api/morgan-thermal';
import { CalculationListing } from '@/app/api/morgan-thermal/calculation';
import { ProjectInformation } from '@/app/api/morgan-thermal/project';

export type CalculationSearchFilters = SearchFilters<{
  projectId: ProjectInformation['id'];
  showDisabled?: boolean;
}>;

export type CalculationSearchResponse = SearchResponse<CalculationListing>;

export const searchCalculations = createEndpoint<CalculationSearchResponse>(({fetch}) => (filters: CalculationSearchFilters) => fetch(`/Calculation/Search`, {
  method: 'POST',
  body: JSON.stringify(filters),
  headers: {
    'Content-Type': 'application/json',
  },
}));