import { observer } from 'mobx-react-lite';

import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { CompanyListing } from '@/app/listing/company';

export const CompaniesIndexRouteView = observer(() => {
  return (
    <Page hasOperationsInProgress={false} loaded={true}>
      <CompanyListing/>
    </Page>
  );
});