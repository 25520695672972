import ReactDOM, { Root } from 'react-dom/client';
import { createPortal } from 'react-dom';

interface PromiseComponent<TType extends unknown> {
  render: Root['render'];
  promise: Promise<TType>,
  resolve: (value: (PromiseLike<TType> | TType)) => void,
  reject: (reason?: any) => void,
  dispose: () => void,
}

type PromiseComponentOptions = {
  manualDispose?: boolean;
} | undefined;

export function createPromiseComponent<TType extends unknown>(options: PromiseComponentOptions = {}) {
  const { manualDispose = false } = options;
  const container = document.createElement('div');
  const root = ReactDOM.createRoot(document.body.appendChild(container));

  function dispose() {
    root.unmount();
    container.remove();
  }

  let resolve = (value: (PromiseLike<TType> | TType)) => {};
  let reject = (reason?: any) => {};

  const promise = new Promise<TType>((promiseResolve, promiseReject) => {
    resolve = promiseResolve;
    reject = promiseReject;
  }).finally(() => {
    if (!manualDispose) dispose();
  });

  const component: PromiseComponent<TType> = {
    render: root.render.bind(root),
    promise,
    resolve,
    reject,
    dispose,
  };

  return component;
}