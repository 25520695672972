import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Label } from '@/shared/design-system/thermal-ceramics/components/form/label';
import { ClickableArea } from '@/shared/design-system/thermal-ceramics/components/clickable-area';
import { VisuallyHidden } from '@/shared/design-system/thermal-ceramics/components/visually-hidden';
import { Button } from '@/shared/design-system/thermal-ceramics/components/button';
import { InputProps } from '@/shared/design-system/thermal-ceramics/components/form/input';
import { FieldInput } from '@/shared/libs/hook-form/input';
import { PlusIcon, RemoveIcon } from '@/shared/design-system/thermal-ceramics/icons';
import classNames from './styles.module.css';
import { Hint } from '@/shared/design-system/thermal-ceramics/components/form/hint';
import { Obj } from '@popperjs/core';

export type FieldArrayProps = {
  label?: string,
  hint?: string,
  addLabel?: string,
  name: string,
  required?: boolean,
  extendProps?: (props: InputProps) => InputProps,
  beforeRemove?: (item: { value: string }) => Promise<boolean>,
};

export const FieldArray = (props : FieldArrayProps) => {
  const { label, hint, addLabel = 'Add', name, required, beforeRemove, extendProps = (props) => props } = props;
  const [isRemoveInProgress, setIsRemoveInProgress] = useState(false);
  const fieldArray = useFieldArray({ name });
  const fields = fieldArray.fields as Array<{ id: string, value: any, readOnly?: boolean }>;
  const { getValues } = useFormContext();

  const handleRemoveClick = (index: number) => {
    if (!beforeRemove) return () => fieldArray.remove(index);

    return () => {
      setIsRemoveInProgress(true);

      return beforeRemove(getValues(name)[index])
        .then((isRemoveApproved) => {
          if (isRemoveApproved) fieldArray.remove(index);
        })
        .catch(console.error)
        .finally(() => {
          setIsRemoveInProgress(false);
        });
    }
  }

  const handleAddClick = () => fieldArray.append({ value: '' });

  return (
    <div className={classNames.field}>
      <Label>{label} {required && (<span aria-hidden="true">*</span>)}</Label>
      {hint && <Hint>{hint}</Hint>}
      <div className={classNames.inputs}>
        {fields.map((field, index) => {
          if (field.readOnly) return <p key={field.id}>{field.value}</p>;

          return (
            <FieldInput
              key={field.id}
              disabled={isRemoveInProgress}
              name={`${name}.${index}.value`}
              extendProps={(props) => {
                if (index === 0) return props;

                return extendProps({
                  ...props,
                  endSlot: (
                    <ClickableArea type="button" className={classNames.remove} onClick={handleRemoveClick(index)}>
                      <RemoveIcon aria-hidden="true"/>
                      <VisuallyHidden>Remove</VisuallyHidden>
                    </ClickableArea>
                  )
                });
              }}
              required
            />
          );
        })}
      </div>

      <Button className={classNames.add} type="button" onClick={handleAddClick}>
        <PlusIcon aria-hidden="true"/>
        {addLabel}
      </Button>
    </div>
  );
};