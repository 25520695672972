import { createEndpoint } from '@/shared/models/api';
import { Informative } from '@/app/api/morgan-thermal';
import { UserInformation, UserRole } from '@/app/api/morgan-thermal/user';

export type SaveUser = {
  id: number,
  roleId: UserRole,
  companyId: number,
  name: string,
  email: string,
  job: string | null,
  address: string,
  additionalAddress: string | null,
  city: string,
  contactPhone: string,
  secondaryPhone: string | null,
  countryId: number,
  country: string,
  stateProvinceId: number | null,
  stateProvince: string,
  preferMetric: boolean,
  zip: string,
}

export const updateUser = createEndpoint<Informative & { id: UserInformation['id'] }>(({fetch}) => (update: SaveUser) => {
  return fetch(`/User`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(update),
  });
});