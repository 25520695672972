import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';

import { Listing } from '@/app/listing';
import { Button } from '@/shared/design-system/thermal-ceramics/components/button';
import { Search } from '@/shared/design-system/thermal-ceramics/components/form/search';
import { ToggleButton } from '@/shared/design-system/thermal-ceramics/components/toggle-button/button';
import { ToggleButtonGroup } from '@/shared/design-system/thermal-ceramics/components/toggle-button/group';
import { CompanyListingItem, CompanyStateId } from '@/app/api/morgan-thermal/company';
import { ActionsDropdown, ActionsDropdownItemModifier } from '@/app/components/actions-dropdown';

import { columns } from './columns';
import { CompanyListingModel } from './model';
import { CompanyOrderByOptions } from '@/app/api/morgan-thermal/company/search';
import { app } from '@/app';

export const CompanyListing = observer(() => {
  const [{ listing, stateFilter, companyNameFilter, approve, deny, enable, disable }] = useState(() => new CompanyListingModel());
  const { data, results, totalRecords, numberOfRecords, pageFilter, orderByFilter, orderByAscendingFilter } = listing;

  return (
    <Listing<CompanyListingItem, CompanyOrderByOptions>
      header={
        <>
          <ToggleButtonGroup
            value={stateFilter.value}
            onChange={stateFilter.setValue}
          >
            <ToggleButton value={CompanyStateId.ACTIVE}>Active</ToggleButton>
            <ToggleButton value={CompanyStateId.PENDING}>Pending</ToggleButton>
            <ToggleButton value={CompanyStateId.DENIED}>Denied</ToggleButton>
            <ToggleButton value={CompanyStateId.DISABLED}>Disabled</ToggleButton>
          </ToggleButtonGroup>
          <Search value={companyNameFilter.value} onSearch={companyNameFilter.setValue}/>
          {!app.session.isGlobalCompanyManagerAdmin && <Button as={NavLink} to={'/companies/create'}>Add company</Button>}
        </>
      }
      renderActions={(item: CompanyListingItem) => {
        const canApprove = item.companyStateId !== CompanyStateId.ACTIVE && item.companyStateId !== CompanyStateId.DISABLED;
        const canDeny = item.companyStateId === CompanyStateId.PENDING;
        const canEnable = item.companyStateId === CompanyStateId.DISABLED;
        const canDisable = item.companyStateId === CompanyStateId.ACTIVE;

        return (
          <ActionsDropdown>
            <ActionsDropdown.Item key="details" as={NavLink} to={`/companies/${item.id}`}>Details</ActionsDropdown.Item>
            {canApprove && <ActionsDropdown.Item key="approve" modifier={ActionsDropdownItemModifier.POSITIVE} onClick={() => approve(item.id)}>Approve</ActionsDropdown.Item>}
            {canDeny && <ActionsDropdown.Item key="deny" modifier={ActionsDropdownItemModifier.DANGER} onClick={() => deny(item.id)}>Deny</ActionsDropdown.Item>}
            {canEnable && <ActionsDropdown.Item key="enable" modifier={ActionsDropdownItemModifier.POSITIVE} onClick={() => enable(item.id)}>Enable</ActionsDropdown.Item>}
            {canDisable && <ActionsDropdown.Item key="disable" modifier={ActionsDropdownItemModifier.DANGER} onClick={() => disable(item.id)}>Disable</ActionsDropdown.Item>}
          </ActionsDropdown>
        );
      }}
      columns={columns}
      data={results}
      isInitialized={!!data}
      hasOperationsInProgress={data.isSynchronizationInProgress}
      totalRecords={totalRecords}
      numberOfRecords={numberOfRecords}
      page={pageFilter.value}
      onPageChange={pageFilter.setValue}

      orderBy={orderByFilter.value as CompanyOrderByOptions}
      orderByAscending={orderByAscendingFilter.value}
      onOrderByChange={orderByFilter.setValue}
      onOrderByAscendingChange={orderByAscendingFilter.setValue}
    />
  );
});