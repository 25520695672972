import { ReactNode } from 'react';
import { clsx } from 'clsx';
import { RocketIcon } from '@/shared/design-system/thermal-ceramics/icons';
import classNames from './styles.module.css';

function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

type BackToTopButtonProps = {
  className?: string,
  children?: ReactNode,
}

export function BackToTopButton({ children, className }: BackToTopButtonProps) {
  return (
    <button className={clsx(classNames.backToTop, className)} onClick={scrollToTop}>
      <span className={classNames.text}>{ children ?? 'Back to top' }</span>
      <RocketIcon className={classNames.icon}/>
    </button>
  );
}