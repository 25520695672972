import { PropsWithChildren } from 'react';
import { clsx } from 'clsx';
import classNames from './styles.module.css';

export enum KeyValueSectionModifier {
  LIST = 'asList',
}

export type KeyValueSectionProps = PropsWithChildren & { modifier?: KeyValueSectionModifier };
export type KeyValueSectionListProps = PropsWithChildren;
export type KeyValueSectionItemProps = PropsWithChildren;
export type KeyValueSectionKeyProps = PropsWithChildren;
export type KeyValueSectionValueProps = PropsWithChildren;

const Root = (props: KeyValueSectionProps) => {
  const { modifier = '', children } = props;

  return (
    <section className={clsx(classNames.section, classNames[modifier])}>
      {children}
    </section>
  );
};

const List = ({ children }: KeyValueSectionListProps) => (
  <dl className={classNames.list}>
    {children}
  </dl>
);

const Item = ({ children }: KeyValueSectionItemProps) => (
  <div className={classNames.item}>
    {children}
  </div>
);

const Key = ({ children }: KeyValueSectionKeyProps) => (
  <dt className={classNames.key}>{children}</dt>
);

const Value = ({ children }: KeyValueSectionValueProps) => (
  <dd className={classNames.value}>{children}</dd>
);

export const KeyValueSection = Object.assign(Root, {
  List,
  Item,
  Key,
  Value,
})