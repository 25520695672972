import { HTMLAttributes, PropsWithChildren } from 'react';
import { clsx } from 'clsx';
import classNames from './styles.module.css';

export enum PillModifier {
  DISABLED = 'disabled',
  WARNING = 'warning',
}

export type PillProps = PropsWithChildren<HTMLAttributes<HTMLSpanElement>> & { modifier?: PillModifier };

export const Pill = (props: PillProps) => {
  const { modifier = '', children, ...attributes } = props;

  return (
    <span
      {...attributes}
      className={clsx(classNames.pill, classNames[modifier])}
    >
      {children}
    </span>
  );
};