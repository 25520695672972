export function mergeHeaders(...headers: HeadersInit[]) {
    const result: Record<string, string> = {};

    for (const header of headers) {
        const currentHeaders: Headers = new Headers(header);

        Array.from(currentHeaders.entries()).forEach(([key, value]) => {
            result[key] = value;
        });
    }

    return new Headers(result)
}