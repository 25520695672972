import { createEndpoint } from '@/shared/models/api';
import { Informative } from '@/app/api/morgan-thermal';

export type ValidateSignUpResult = Informative & {
  domain: string;
  email: string;
  companyId: string | null;
  companyName: string | null;
};

export const validateSignUp = createEndpoint<ValidateSignUpResult>(
  ({ fetch }) =>
    (email: string) => {
      return fetch(`/Registration/ValidateSignUp`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json-patch+json",
        },
        body: `\"${email}\"`, // eslint-disable-line
      });
    }
);