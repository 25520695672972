import { indexRoute } from '@/app/routing/root/companies/id/users/index/route';
import { userRoute } from '@/app/routing/root/companies/id/users/id/route';
import { createRoute } from '@/app/routing/root/companies/id/users/create/route';
import { withCrumb } from '@/app/routing/breadcrumbs';

export const usersRoute = {
  path: 'users',
  children: [
    indexRoute,
    userRoute,
    createRoute,
  ],
  handle: () => {
    return withCrumb({
      element: () => <span>Users</span>,
    });
  }
}