import { createEndpoint } from '@/shared/models/api';
import { SearchFilters, SearchResponse } from '@/app/api/morgan-thermal';
import { MaterialListing } from '@/app/api/morgan-thermal/material';

export type MaterialPersonalizedSearchFilters = SearchFilters<{
  getFavorites?: boolean,
  getMyMaterials?: boolean,
  materialName?: string,
  materialCategoryId: number | null,
  regionId: number | null,
  showDisabled?: boolean,
}>;

export type MaterialPersonalizedSearchResponse = SearchResponse<MaterialListing>;

export const searchPersonalizedMaterials = createEndpoint<MaterialPersonalizedSearchResponse>(({fetch}) => (filters: MaterialPersonalizedSearchFilters) => fetch(`/Material/PersonalizedSearch`, {
  method: 'POST',
  body: JSON.stringify(filters),
  headers: {
    'Content-Type': 'application/json',
  },
}));