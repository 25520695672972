import { ReactNode } from 'react';

export type ListProps<T> = {
  items?: Array<T>,
  itemClassName?: string,
  createKey: (item: T) => string,
  render: (item: T) => ReactNode,
};

export type UnorderedListProps<T> = JSX.IntrinsicElements['ul'] & ListProps<T>;

export type OrderedListProps<T> = JSX.IntrinsicElements['ol'] & ListProps<T>;

export const UnorderedList = <T,>({ items = [], itemClassName, createKey, render, ...props }: UnorderedListProps<T>) => {
  return (
    <ul {...props}>
      {items.map((item) => (
        <li key={createKey(item)} className={itemClassName}>{render(item)}</li>
      ))}
    </ul>
  );
}

export const OrderedList = <T,>({ items = [], itemClassName, createKey, render, ...props }: OrderedListProps<T>) => {
  return (
    <ol {...props}>
      {items.map((item) => (
        <li key={createKey(item)} className={itemClassName}>{render(item)}</li>
      ))}
    </ol>
  );
}