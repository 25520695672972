import { createEndpoint } from '@/shared/models/api';
import { Informative } from '@/app/api/morgan-thermal';

export const validateEmailChange = createEndpoint<Informative>(
  ({fetch}) =>
    (userId: string, email: string) => {
      return fetch(`/User/ValidateEmailChange/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
        body: `\"${email}\"`, // eslint-disable-line
      });
    }
);