import { RouteObject } from 'react-router-dom';

import { app } from '@/app';
import { indexRoute } from '@/app/routing/root/companies/id/index/route';
import { withCrumb } from '@/app/routing/breadcrumbs';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { CompanyInformation } from '@/app/api/morgan-thermal/company';
import { getCompany } from '@/app/api/morgan-thermal/company/get';
import { editRoute } from '@/app/routing/root/companies/id/edit/route';
import { usersRoute } from '@/app/routing/root/companies/id/users/route';

export const companyRoute: RouteObject = {
  id: 'company',
  path: ':companyId',
  children: [
    indexRoute,
    editRoute,
    usersRoute,
  ],
  async loader({ params: { companyId } }) {
    const company = new SynchronizableValue<CompanyInformation | null>(null, () => {
      return app.morganThermalAPI.fetch(getCompany(companyId));
    });

    await company.synchronize();

    return company;
  },
  handle: (company: SynchronizableValue<CompanyInformation | null>) => withCrumb({
    element: () => <span>{company?.value?.name}</span>,
  }),
}