import { observer } from 'mobx-react-lite';

import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { UsersListing } from '@/app/listing/users';
import { useRouteLoaderData } from 'react-router-dom';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { CompanyInformation } from '@/app/api/morgan-thermal/company';
import { Breadcrumbs } from '@/app/routing/breadcrumbs';
import { Navigation } from '@/shared/design-system/thermal-ceramics/components/navigation';
import { NavigationModifier } from '@/shared/design-system/thermal-ceramics/components/navigation';

export const UsersIndexRouteView = observer(() => {
  const company = useRouteLoaderData('company') as SynchronizableValue<CompanyInformation | null>;

  return (
    <Page hasOperationsInProgress={company.isSynchronizationInProgress} loaded={!!company.value}>
      <Breadcrumbs/>

      <Navigation modifier={NavigationModifier.SECONDARY}>
        <Navigation.Item to={`/companies/${company.value?.id}`} active={false}>Company Info</Navigation.Item>
        <Navigation.Item to={`/companies/${company.value?.id}/users`}>Users</Navigation.Item>
      </Navigation>

      {company.value && <UsersListing company={company.value}/>}
    </Page>
  );
});