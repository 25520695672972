import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';

import { app } from '@/app';
import { Listing } from '@/app/listing';
import { MaterialListingItem } from '@/app/api/morgan-thermal/material';
import { MaterialCategoryField } from '@/app/form/fields/material/category';
import { OriginOfManufacturerFormField } from '@/app/form/fields/material/origin-of-manufacturer';
import { MeasurementSystemsToggle } from '@/app/measurement-systems/view';
import { Search } from '@/shared/design-system/thermal-ceramics/components/form/search';
import { ToggleButton } from '@/shared/design-system/thermal-ceramics/components/toggle-button/button';
import { ToggleButtonGroup } from '@/shared/design-system/thermal-ceramics/components/toggle-button/group';
import { ActionsDropdown, ActionsDropdownItemModifier } from '@/app/components/actions-dropdown';
import { Select } from '@/shared/design-system/thermal-ceramics/components/form/select';
import { FormField } from '@/shared/design-system/thermal-ceramics/components/form/field';

import { createColumns } from './columns';
import { MyMaterialListingModel } from './model';
import classNames from './styles.module.css';
import { ProjectOrderByOptions } from '@/app/api/morgan-thermal/project/search';
import { MaterialOrderByOptions } from '@/app/api/morgan-thermal/material/search';

export type MyMaterialListingProps = {
  initiallySelectedIds?: MaterialListingItem['id'][],
  selectedRows?: MaterialListingItem[],
  onSelect?: (materialListingItem: MaterialListingItem) => void,
  onUnselect?: (materialListingItem: MaterialListingItem) => void,
  onSelectionChange?: (materialListingItems: MaterialListingItem[]) => void,
};

export const MyMaterialListing = observer((props: MyMaterialListingProps) => {
  const { initiallySelectedIds, selectedRows, onSelect, onUnselect, onSelectionChange } = props;
  const [myMaterialListingModel] = useState(() => new MyMaterialListingModel());
  const { listing, showDisabledFilter, getFavoritesFilter, getMyMaterialsFilter, materialCategoryIdFilter, regionIdFilter, materialNameFilter, enable, disable } = myMaterialListingModel;
  const { data, results, totalRecords, numberOfRecords, pageFilter, orderByFilter, orderByAscendingFilter } = listing;

  return (
    <div className={classNames.materialListing}>
      <Listing<MaterialListingItem, MaterialOrderByOptions>
        renderActions={(getMyMaterialsFilter.value && !getFavoritesFilter.value) ? (item: MaterialListingItem) => (
          <ActionsDropdown>
            <ActionsDropdown.Item key="details" as={NavLink} to={`/materials/${item.id}`}>Details</ActionsDropdown.Item>
            {!item.isActive && <ActionsDropdown.Item key="enable" modifier={ActionsDropdownItemModifier.POSITIVE} onClick={() => enable(item.id)}>Enable</ActionsDropdown.Item>}
            {item.isActive && <ActionsDropdown.Item key="disable" modifier={ActionsDropdownItemModifier.DANGER} onClick={() => disable(item.id)}>Disable</ActionsDropdown.Item>}
          </ActionsDropdown>
        ) : undefined}
        header={
          <>
            <FormField
              label="Category"
              renderControl={(props) => (
                <Select
                  {...props}
                  options={[
                    'All',
                    'Favorites',
                    'My Materials',
                  ]}
                  createKey={(option) => option}
                  createOptionLabel={(option) => option}
                  value={(() => {
                    if (getFavoritesFilter.value) return 'Favorites';
                    if (getMyMaterialsFilter.value) return 'My Materials';
                    return 'All';
                  })()}
                  onChange={(value) => {
                    showDisabledFilter.setValue(false);
                    getFavoritesFilter.setValue(false);
                    getMyMaterialsFilter.setValue(false);

                    switch (value) {
                      case 'Favorites': return getFavoritesFilter.setValue(true);
                      case 'My Materials': return getMyMaterialsFilter.setValue(true);
                    }
                  }}
                />
              )}
            />
            {(getMyMaterialsFilter.value && !getFavoritesFilter.value) && (
              <ToggleButtonGroup
                value={showDisabledFilter.value}
                onChange={showDisabledFilter.setValue}
              >
                <ToggleButton value={false}>Active</ToggleButton>
                <ToggleButton value={true}>Disabled</ToggleButton>
              </ToggleButtonGroup>
            )}
            <MaterialCategoryField hasAllOption={true} onChange={materialCategoryIdFilter.setValue}/>
            <OriginOfManufacturerFormField hasAllOption={true} onChange={regionIdFilter.setValue}/>
            <Search value={materialNameFilter.value} onSearch={materialNameFilter.setValue}/>
            <MeasurementSystemsToggle/>
          </>
        }
        columns={createColumns(app.measurementSystems.currentSystem, myMaterialListingModel)}
        data={results}
        isInitialized={!!data}
        hasOperationsInProgress={data.isSynchronizationInProgress}
        totalRecords={totalRecords}
        numberOfRecords={numberOfRecords}
        page={pageFilter.value}
        initiallySelectedIds={initiallySelectedIds}
        selectedRows={selectedRows}
        selectionLimit={4}
        onPageChange={pageFilter.setValue}
        onSelect={onSelect}
        onUnselect={onUnselect}
        onSelectionChange={onSelectionChange}

        orderBy={orderByFilter.value as MaterialOrderByOptions}
        orderByAscending={orderByAscendingFilter.value}
        onOrderByChange={orderByFilter.setValue}
        onOrderByAscendingChange={orderByAscendingFilter.setValue}
      />
    </div>
  );
});