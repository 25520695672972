import { observer } from 'mobx-react-lite';

import { app } from '@/app';
import { EntityDetails } from '@/app/details';
import { CalculationTable } from '@/app/details/calculation/table';
import { CalculationInformation } from '@/app/api/morgan-thermal/calculation';
import { Notification, NotificationType } from '@/shared/design-system/thermal-ceramics/components/notification';

import classNames from './styles.module.css';
import { roundDecimals } from '@/shared/helpers/number';
import { MeasurementSystem } from '@/app/measurement-systems/model';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';
import { printCalculations } from '@/app/actions/calculation/print';
import { useState } from 'react';

export type CalculationResultProps = {
  calculation: CalculationInformation,
  editUrl?: string,
  showFullDetails?: boolean,
};

const casingIdsWithDiameter = [4, 5, 6];

export const CalculationResult = observer((props: CalculationResultProps) => {
  const { calculation, editUrl, showFullDetails = false } = props;
  const properties = calculation[app.measurementSystems.currentSystem];
  const [isPrintingInProgress, setIsPrintingInProgress] = useState(false);

  const isValidValue = (value: any) => {
    if (typeof value === 'string' || typeof value === 'number') return true;
    return false
  }

  const handlePrintClick = () => {
    setIsPrintingInProgress(true);
    printCalculations([calculation.id]).finally(() => setIsPrintingInProgress(false));
  };

  return (
    <div className={classNames.calculationResult}>
      <EntityDetails
        editUrl={editUrl}
        mainHeader={
          showFullDetails && (
            <div className={classNames.header}>
              {calculation.errorMessage && (
                <Notification type={NotificationType.ERROR} className={classNames.error}>
                  <Notification.Body>{calculation.errorMessage}</Notification.Body>
                </Notification>
              )}
              <CalculationTable calculation={calculation}/>
            </div>
          )
        }
        mainInformation={[
          { key: 'Casing Conditions', value: calculation.casing },
          { key: 'Cold Face', value: isValidValue(properties.coldFaceTemperature) && `${roundDecimals(properties.coldFaceTemperature)} ${properties.coldFaceTemperatureLabel}` },
          { key: 'Heat Loss', value: isValidValue(properties.heatLoss) && `${roundDecimals(properties.heatLoss, 1)} ${properties.heatLossLabel}` },
          { key: 'Ambient Temp', value: isValidValue(properties.ambientTemperature) && `${roundDecimals(properties.ambientTemperature, (app.measurementSystems.currentSystem === MeasurementSystem.METRIC) ? 1 : 0)} ${properties.ambientTemperatureLabel}` },
          { key: 'Hot Face Temperature', value: isValidValue(properties.hotFaceTemperature) && `${roundDecimals(properties.hotFaceTemperature)} ${properties.hotFaceTemperatureLabel}` },
          { key: 'Heat Storage', value: isValidValue(properties.heatStorage) && `${roundDecimals(properties.heatStorage, 1)} ${properties.heatStorageLabel}` },
          { key: 'Ambient Wind', value: isValidValue(properties.outsideWindSpeed) && `${roundDecimals(properties.outsideWindSpeed, 1)} ${properties.outsideWindSpeedLabel}` },
          { key: 'Casing Emissivity', value: isValidValue(properties.emissivity) && `${roundDecimals(properties.emissivity, 2)} ${properties.emissivityLabel}` },
          { key: 'Weight', value: isValidValue(properties.weight) && `${roundDecimals(properties.weight, 1)} ${properties.weightLabel}` },
          { key: 'Total Thickness', value: isValidValue(properties.totalThickness) && `${roundDecimals(properties.totalThickness, (app.measurementSystems.currentSystem === MeasurementSystem.METRIC) ? 0 : 2)} ${properties.totalThicknessLabel}` },
          casingIdsWithDiameter.includes(calculation.casingId) ? { key: 'Inside Diameter', value: isValidValue(properties.diameter) && `${properties.diameter} ${properties.diameterLabel}` } : null,
        ]}
        mainFooter={(
          <Notification type={NotificationType.WARNING} className={classNames.warning}>
            <Notification.Body>RESULTS SHOULD BE USED FOR COMPARISON PURPOSES ONLY. DO NOT USE FOR DESIGN SPECIFICATION.</Notification.Body>
          </Notification>
        )}
        additionalFooter={(
          <>
            <Notification type={NotificationType.ATTENTION} className={classNames.attention}>
              <Notification.Body>Calculation method: ASTM C-680 Issue 2004.</Notification.Body>
            </Notification>
            <Button modifier={ButtonModifier.OUTLINE} onClick={handlePrintClick} inProgress={isPrintingInProgress}>Print</Button>
          </>
        )}
      />
    </div>
  );
});