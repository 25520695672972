import { app } from '@/app';
import { enableUser as enable } from '@/app/api/morgan-thermal/user/enable';
import { ActionType, runServerSideAction } from '@/app/actions';
import { UserInformation } from '@/app/api/morgan-thermal/user';

export const enableUser = (userId: UserInformation['id']) => runServerSideAction({
  type: ActionType.POSITIVE,

  title: 'Enable',
  confirmationMessage: 'Enable this user?',
  approveLabel: 'Enable',

  runAction: () => app.morganThermalAPI.fetch(enable(userId)),
});