export const createInitialValues = () => ({
  user: {
    name: '',
    email: '',
    job: '',
    city: '',
    zip: '',
    address: '',
    additionalAddress: '',
    contactPhone: '',
    secondaryPhone: '',
    countryId: null,
    stateProvinceId: null,
  },
  company: {
    fillWithUser: false,
    name: '',
    industryId: '',
    city: '',
    zip: '',
    address: '',
    additionalAddress: '',
    contactPhone: '',
    secondaryPhone: '',
    countryId: null,
    stateProvinceId: null,
    domains: [{ value: '', readOnly: false }],
  },
});