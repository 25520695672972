import React, { MouseEvent, ReactElement, ReactNode, useRef, useState } from 'react';
import { clsx } from 'clsx';
import {
  Cell,
  ColumnDef, createColumnHelper,
  flexRender,
  getCoreRowModel, Header,
  PaginationState, Row, RowData, RowSelectionState, TableMeta,
  Updater,
  useReactTable
} from '@tanstack/react-table';

import { Table } from '@/shared/design-system/thermal-ceramics/components/table';
import { Pagination } from '@/shared/design-system/thermal-ceramics/components/pagination';
import { Spinner } from '@/shared/design-system/thermal-ceramics/components/spinner';
import { Checkbox } from '@/shared/design-system/thermal-ceramics/components/form/checkbox';
import { FillLink } from '@/shared/design-system/thermal-ceramics/components/fill-link';
import { VisuallyHidden } from '@/shared/design-system/thermal-ceramics/components/visually-hidden';

import classNames from './styles.module.css';
import { ArrowDownIcon } from '@/shared/design-system/thermal-ceramics/icons';

declare module '@tanstack/table-core' {
  // eslint-disable-next-line
  interface ColumnMeta<TData extends RowData, TValue> {
    orderBy?: string;
    canBeChecked?: boolean;
    cellClassName?: string,
    headCellClassName?: string,
    detailsLink?: (row: Row<TData>) => string;
  }
}

export type ListingProps<TType extends { id: any }, TOrder extends string = string> = {
  columns: (ColumnDef<TType, any> | undefined)[],
  data: TType[],
  meta?: TableMeta<unknown>,
  isInitialized: boolean,
  hasOperationsInProgress: boolean,
  totalRecords: number,
  numberOfRecords: number,
  page: number,
  header?: ReactElement,
  navigation?: ReactElement,
  initiallySelectedIds?: TType['id'][],
  selectedRows?: TType[],
  selectionLimit?: number,
  renderActions?: (row: TType) => ReactNode,
  onRowClick?: (row: TType, event: MouseEvent<HTMLElement>) => void,
  onPageChange: (pageIndex: number) => void,
  onSelect?: (row: TType) => void,
  onUnselect?: (row: TType) => void,
  onSelectionChange?: (rows: TType[]) => void,

  orderBy?: TOrder | null,
  orderByAscending?: boolean | null,
  onOrderByChange?: (orderBy: TOrder) => void,
  onOrderByAscendingChange?: (orderByAscending: boolean) => void,
};

export const Listing = <TType extends { id: any }, TOrder extends string = string>(props: ListingProps<TType, TOrder>) => {
  const {
    header,
    navigation,
    data,
    columns,
    meta,
    selectionLimit = Infinity,
    isInitialized,
    hasOperationsInProgress,
    totalRecords,
    numberOfRecords,
    page,
    renderActions,
    selectedRows = [],
    onPageChange,
    onRowClick,
    onSelect,
    onUnselect,
    onSelectionChange,

    orderBy,
    orderByAscending,
    onOrderByChange,
    onOrderByAscendingChange,
  } = props;
  const currentPageIndex = page - 1;
  const pageCount = Math.ceil(totalRecords / numberOfRecords);
  const hasPagination = !!pageCount;
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const allColumns = [...columns];
  const previousSelectedRows = selectedRows;
  const [initiallySelectedIds, setInitiallySelectedIds] = useState(props.initiallySelectedIds ?? []);

  if (typeof renderActions === 'function') {
    const columnHelper = createColumnHelper<TType>();

    allColumns.unshift(
      columnHelper.display({
        id: 'actions',
        header: 'Actions',
        size: 96,
        cell: (info) => renderActions(info.row.original),
      })
    );
  }

  function handlePaginationChange(updater: Updater<PaginationState>) {
    if (hasOperationsInProgress) return;

    const paginationChanges = (typeof updater === 'function') ? updater({
      pageIndex: currentPageIndex,
      pageSize: numberOfRecords,
    }) : updater;

    if (scrollAreaRef?.current) {
      scrollAreaRef.current.scrollLeft = 0;
    }

    onPageChange(paginationChanges.pageIndex + 1);
  }

  const getSelectedRows = () => {
    if (!selectedRows) return {};

    const rows: RowSelectionState = {};
    const selectedIds = new Set([...selectedRows.map(({ id }) => id), ...(initiallySelectedIds ?? [])]);

    data.forEach(({ id }, index) => {
      if (selectedIds.has(id)) rows[index] = true;
    });

    return rows;
  };

  const handleRowSelectionChange = (updater: Updater<RowSelectionState>) => {
    if (hasOperationsInProgress) return;

    setInitiallySelectedIds([]);

    const selectionChanges = (typeof updater === 'function') ? updater(getSelectedRows()) : updater;

    const allIndexes = Array.from({ length: data.length }, (value, index) => index);

    const selectedRowIndexes = Object.keys(selectionChanges).map((key) => Number.parseInt(key));
    const selectedRows = selectedRowIndexes.map((index) => data[index]);
    const selectedRowIds = selectedRows.map(({ id }) => id);

    const unselectedRowIndexes = allIndexes.filter((index) => !selectedRowIndexes.includes(index));
    const unselectedRows = unselectedRowIndexes.map((index) => data[index]);
    const unselectedRowIds = unselectedRows.map(({ id }) => id);
    const unselectedRowsIdSet = new Set(unselectedRowIds);

    const previousSelectedRowIds = previousSelectedRows.map(({ id }) => id);
    const previousSelectedRowsIdSet = new Set(previousSelectedRowIds);

    selectedRows.forEach((row) => {
      if (!previousSelectedRowsIdSet.has(row.id) && onSelect) onSelect(row);
    });

    unselectedRows.forEach((row) => {
      if (previousSelectedRowsIdSet.has(row.id) && onUnselect) onUnselect(row);
    });

    if (onSelectionChange) {
      const selectionChange: TType[] = [];
      const allRows = new Map([...previousSelectedRows, ...data].map((row) => [row.id, row]));
      const allSelectedRowsIds = Array.from(new Set([...previousSelectedRowIds, ...selectedRowIds])).filter((id) => !unselectedRowsIdSet.has(id));

      allSelectedRowsIds.forEach((id) => {
        const row = allRows.get(id);
        if (row) selectionChange.push(row);
      });

      onSelectionChange(selectionChange.slice(0, selectionLimit));
    }
  };

  const table = useReactTable<TType>({
    columns: allColumns.slice().filter((column) => column !== undefined) as ColumnDef<TType, any>[],
    data,
    meta,
    pageCount: pageCount,
    enableRowSelection: true,
    state: {
      rowSelection: getSelectedRows(),
      pagination: {
        pageIndex: currentPageIndex,
        pageSize: numberOfRecords,
      },
    },
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: handleRowSelectionChange,
    onPaginationChange: handlePaginationChange,
  });
  const headerGroups = table.getHeaderGroups();
  const rows = table.getRowModel().rows;

  const renderHeadCell = (header: Header<TType, any>) => {
    const canBeChecked = header.column.columnDef.meta?.canBeChecked && rows.length > 0;
    const content = header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext());

    const orderIcon = !!orderBy && orderBy === header.column.columnDef.meta?.orderBy ? (
      <ArrowDownIcon className={clsx(classNames.orderIcon, {
        [classNames.ascending]: !!orderByAscending,
      })}/>
    ) : null;

    if (canBeChecked) {
      const isAllRowsSelected = table.getIsAllRowsSelected();

      if (selectedRows.length >= selectionLimit) return <>{content}{orderIcon}</>;

      return (
        <>
          <Checkbox
            checked={isAllRowsSelected}
            onChange={table.getToggleAllRowsSelectedHandler()}
          >
            {content}
          </Checkbox>
          {orderIcon}
        </>
      );
    }

    return <>{content}{orderIcon}</>;
  };

  const renderCell = (cell: Cell<TType, any>) => {
    const canBeChecked = cell.column.columnDef.meta?.canBeChecked && rows.length > 0;
    let content = flexRender(cell.column.columnDef.cell, cell.getContext());
    let detailsLink = null;

    if (canBeChecked) {
      const isChecked = cell.row.getIsSelected();
      if (selectedRows.length >= selectionLimit && !isChecked) return content;

      content = <Checkbox checked={cell.row.getIsSelected()} onChange={cell.row.getToggleSelectedHandler()}>{content}</Checkbox>;
    }

    if (cell.column.columnDef?.meta?.detailsLink) {
      detailsLink = <FillLink to={cell.column.columnDef.meta.detailsLink(cell.row)}><VisuallyHidden>Details</VisuallyHidden></FillLink>
    }

    return <>{content}{detailsLink}</>;
  }

  const handleHeadCellClick = (header: Header<TType, any>) => {
    const newOrderBy = header.column.columnDef?.meta?.orderBy;

    if (!newOrderBy) return;

    if (onOrderByChange) onOrderByChange(newOrderBy as TOrder);

    if (onOrderByAscendingChange) {
      if (newOrderBy === orderBy) return onOrderByAscendingChange(orderByAscending ? !orderByAscending : true);
      onOrderByAscendingChange(true);
    }
  };

  return (
    <div className={classNames.listing}>
      {navigation && <div className={classNames.navigation}>{navigation}</div>}
      {header && <div className={classNames.header}>{header}</div>}
      <Table scrollAreaRef={scrollAreaRef} className={classNames.table}>
        <Table.Head>
          {headerGroups.map(headerGroup => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Table.HeadCell
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{ width: header.getSize() }}
                  className={clsx(classNames?.headCell, header.column.columnDef?.meta?.headCellClassName ?? '', classNames ? classNames[`${header.id}HeadCell`] : '')}
                  onClick={() => handleHeadCellClick(header)}
                >
                  {renderHeadCell(header)}
                </Table.HeadCell>
              ))}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.Body>
          {rows.length > 0 ? (
            rows.map(row => (
              <Table.Row className={classNames.row} key={row.id} onClick={(event) => onRowClick && onRowClick(row.original, event)}>
                {row.getVisibleCells().map(cell => (
                  <Table.Cell
                    className={clsx(classNames?.cell, cell.column.columnDef?.meta?.cellClassName ?? '', classNames ? classNames[`${cell.column.id}Cell`] : '')}
                    key={cell.id}
                    style={{ width: cell.column.getSize() }}
                  >
                    {renderCell(cell)}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))
          ) : (
            <Table.Row className={classNames.row}>
              <Table.Cell colSpan={columns.length + 1}>
                 <span className={classNames.status}>{(!isInitialized && hasOperationsInProgress) ? 'Loading' : 'Empty'}</span>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>

      {hasPagination && (
        <div className={classNames.footer}>
          <Pagination
            pagesCount={pageCount}
            currentPageIndex={currentPageIndex}
            boundaryCount={3}
            siblingCount={1}
            canPreviousPage={table.getCanPreviousPage()}
            canNextPage={table.getCanNextPage()}
            onChange={(pageIndex) => table.setPageIndex(pageIndex)}
          />
        </div>
      )}

      {hasOperationsInProgress && (
        <div className={classNames.placeholder}>
          <Spinner className={classNames.spinner}/>
        </div>
      )}
    </div>
  );
};