import React, { ElementType, PropsWithChildren } from 'react';
import { UnorderedList } from '@/shared/design-system/thermal-ceramics/components/list';
import { clsx } from 'clsx';
import classNames from './styles.module.css';

export type Accreditation = PropsWithChildren<JSX.IntrinsicElements['a']> & {
  id: string,
  image: string,
};

export type AccreditationsProps = {
  items: Array<Accreditation>,
}

export function Accreditations({ items }: AccreditationsProps) {
  return (
    <UnorderedList<Accreditation>
      className={classNames.accreditations}
      itemClassName={classNames.item}
      items={items}
      createKey={(item) => item.id}
      render={({ image, children, ...props }) => {
        const Element = (props.href ? 'a' : 'div') as ElementType;

        return (
          <Element {...props} className={clsx(classNames.accreditation, props.className)}>
            <img className={classNames.image} src={image} alt="" role="presentation"/>
            <span className={classNames.name}>{children}</span>
          </Element>
        );
      }}
    />
  );
}