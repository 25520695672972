import { app } from '@/app';
import { disableCompany as disable } from '@/app/api/morgan-thermal/company/disable';
import { ActionType, runServerSideAction } from '@/app/actions';
import { CompanyInformation } from '@/app/api/morgan-thermal/company';

export const disableCompany = (companyId: CompanyInformation['id']) => runServerSideAction({
  type: ActionType.NEGATIVE,

  title: 'Disable',
  confirmationMessage: 'Disable this company?',
  approveLabel: 'Yes, disable',

  runAction: () => app.morganThermalAPI.fetch(disable(companyId)),
});