import { app } from '@/app';
import { denyCompany as deny } from '@/app/api/morgan-thermal/company/deny';
import { ActionType, runServerSideAction } from '@/app/actions';
import { CompanyInformation } from '@/app/api/morgan-thermal/company';

export const denyCompany = (companyId: CompanyInformation['id']) => runServerSideAction({
  type: ActionType.NEGATIVE,

  title: 'Deny',
  confirmationMessage: 'Deny this company?',
  approveLabel: 'Yes, deny',

  runAction: () => app.morganThermalAPI.fetch(deny(companyId)),
});