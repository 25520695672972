import { Link, useRouteError } from 'react-router-dom';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';
import { Container } from '@/shared/design-system/thermal-ceramics/components/container';
import { Button } from '@/shared/design-system/thermal-ceramics/components/button';

import classNames from './styles.module.css';

function createHeading(status?: number) {
  switch (status) {
    case 400: return 'Bad Request';
    case 401: return 'Unauthorized';
    case 404: return 'Sorry, this page doesn\'t exist.';
    default: return 'Error';
  }
}

function createDescription(status?: number) {
  switch (status) {
    case 400: return 'Server cannot or will not process the request.';
    case 401: return 'You aren\'t authorized to see this.';
    case 404: return 'Please go back using your browser or click below to go back to the homepage.';
    default: return null;
  }
}

export const ErrorRouteView = () => {
    const error = useRouteError() as { data?: string, status?: number };
    const heading = createHeading(error?.status);
    const description = createDescription(error?.status);

    return (
        <Container className={classNames.container}>
            <div className={classNames.content}>
              <Heading className={classNames.heading} as='h1'>{heading}</Heading>
              {description && <p className={classNames.description}>{description}</p>}
              <Button as={Link} className={classNames.link} to="/">Return to Dashboard</Button>
            </div>
        </Container>
    );
}