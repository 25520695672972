import { indexRoute } from '@/app/routing/root/profile/index/route';
import { editRoute } from '@/app/routing/root/profile/edit/route';
import { withCrumb } from '@/app/routing/breadcrumbs';
import { app } from '@/app';

export const profileRoute = {
  path: 'profile',
  children: [
    indexRoute,
    editRoute,
  ],
  loader() {
    app.session.user.synchronize({ noCache: true }).catch(console.error);
    return null;
  },
  handle: () => withCrumb({
    element: () => <span>My Profile</span>,
  }),
}