import { clsx } from 'clsx';
import { Container } from '@/shared/design-system/thermal-ceramics/components/container';
import { useWindowScrollPosition } from '@/shared/hooks/use-window-scroll-position';
import { BackToTopButton } from '@/shared/design-system/morgan/components/back-to-top';
import { MobileMenu } from '@/shared/design-system/morgan/components/header/mobile-menu';
import { ToggleMobileMenu } from '@/shared/design-system/morgan/components/header/mobile-menu/toggle';
import { MobileMenuContextProvider } from '@/shared/design-system/morgan/components/header/mobile-menu/context';
import { Actions } from '@/shared/design-system/morgan/components/header/actions';
import { Logo } from './logo';
import classNames from './styles.module.css';

export type HeaderProps = {
  logo: string,
  name: string,
  stickyOffset?: number,
}

export function Header({ stickyOffset = 10, logo, name }: HeaderProps) {
  const windowScrollPosition = useWindowScrollPosition();
  const isStickyState = windowScrollPosition.y > stickyOffset;

  return (
    <MobileMenuContextProvider height="100%">
      <header className={`${classNames.header} ${isStickyState ? classNames.sticky : ''}`}>
        <div className={classNames.wrap}>
          <div className={classNames.main}>
            <Container>
              <div className={classNames.grid}>
                <Logo className={classNames.logo} imageClassName={classNames.logoImage} src={logo}>{name}</Logo>

                <p className={clsx(classNames.siteName, classNames.desktopSiteName)} aria-hidden="true">{name}</p>

                <Actions className={classNames.desktopActions}/>

                <ToggleMobileMenu className={classNames.toggleMobileMenu}/>

                <BackToTopButton className={classNames.backToTop}/>
              </div>
            </Container>
          </div>

          <div className={classNames.mobileSiteName}>
            <Container>
              <p className={classNames.siteName} aria-hidden="true">{name}</p>
            </Container>
          </div>

          <MobileMenu className={classNames.mobileMenu}>
            <div className={classNames.mobileMenuContent}>
              <Container>
                <Actions className={classNames.mobileActions}/>
              </Container>
            </div>
          </MobileMenu>
        </div>
      </header>
    </MobileMenuContextProvider>
  );
}