import { HTMLAttributes, PropsWithChildren } from 'react';
import { clsx } from 'clsx';

import { Spinner } from '@/shared/design-system/thermal-ceramics/components/spinner';
import { Container } from '@/shared/design-system/thermal-ceramics/components/container';

import classNames from './styles.module.css';

export type PageProps = PropsWithChildren<HTMLAttributes<HTMLDivElement> & {
  hasOperationsInProgress: boolean,
  loaded: boolean,
}>;

export const Page = (props: PageProps) => {
  const { hasOperationsInProgress, loaded, children, className, ...attributes } = props;

  return (
    <Container {...attributes} className={clsx(classNames.container, classNames.page, className, {
      [classNames.inProgress]: hasOperationsInProgress,
    })}>
      {hasOperationsInProgress && (
        <div className={classNames.spinnerContainer}>
          <Spinner className={classNames.spinner}/>
        </div>
      )}
      {loaded && children}
    </Container>
  );
};