import { Checkbox, CheckboxProps } from '@/shared/design-system/thermal-ceramics/components/form/checkbox';
import { Field, FieldProps } from '@/shared/libs/hook-form/field';

export type FieldCheckboxProps = Omit<FieldProps, 'renderControl'> & {
  extendProps?: (props: CheckboxProps) => CheckboxProps,
};

export const FieldCheckbox = (props: FieldCheckboxProps) => {
  const { extendProps, label, ...fieldProps } = props;

  return <Field {...fieldProps} renderControl={(checkboxProps) => {
    const extendedProps: CheckboxProps = extendProps ? extendProps(checkboxProps) : checkboxProps;
    return <Checkbox {...extendedProps}>{label}</Checkbox>;
  }}/>
}