import { SearchFilters, SearchResponse } from '@/app/api/morgan-thermal';
import EventEmitter from 'eventemitter3';
import { Filter, Filters } from '@/shared/models/filters';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { action, computed, makeObservable, observable } from 'mobx';
import debounce from 'lodash.debounce';
import { ProjectOrderByOptions } from '@/app/api/morgan-thermal/project/search';
import { To } from 'react-router-dom';

export type ListingOptions<TResponse extends SearchResponse<unknown>, TFilters extends SearchFilters<unknown>> = {
  filters?: Filter<any>[],
  synchronizer: (filters: TFilters) => Promise<TResponse>;
};

export class Listing<TResponse extends SearchResponse<unknown>, TFilters extends SearchFilters<unknown>, TOrder extends string = string> extends EventEmitter {
  pageFilter: Filter<number>;
  numberOfRecordsFilter: Filter<number>;

  orderByFilter: Filter<TOrder | null>;
  orderByAscendingFilter: Filter<boolean | null>;

  filters: Filters;
  data: SynchronizableValue<TResponse | null>;

  constructor({filters = [], synchronizer}: ListingOptions<TResponse, TFilters>) {
    super();

    makeObservable(this, {
      pageFilter: observable,
      numberOfRecordsFilter: observable,
      orderByFilter: observable,
      orderByAscendingFilter: observable,
      filters: observable,

      data: observable,

      results: computed,
      totalRecords: computed,

      search: action.bound,
    });

    this.pageFilter = new Filter<number>({
      name: 'page',
      initialValue: 1,
    });

    this.numberOfRecordsFilter = new Filter<number>({
      name: 'numberOfRecords',
      initialValue: 10,
    });

    this.orderByFilter = new Filter<TOrder | null>({
      name: 'orderBy',
      initialValue: null,
    });

    this.orderByAscendingFilter = new Filter<boolean | null>({
      name: 'orderByAscending',
      initialValue: null,
    });

    this.filters = new Filters([...filters, this.pageFilter, this.numberOfRecordsFilter, this.orderByFilter, this.orderByAscendingFilter]);

    this.data = new SynchronizableValue<TResponse | null>(null, (filters: TFilters) => {
      return synchronizer({
        ...filters,
        numberOfRecords: 10,
      })
        .then((result) => {
          this.pageFilter.setValueWithoutSideEffects(result.page);
          this.numberOfRecordsFilter.setValueWithoutSideEffects(result.numberOfRecords);

          return result;
        })
        .catch((error) => error);
    });

    this.filters.on('change', debounce(this.search.bind(this), 100));
  }

  get results(): TResponse['results'] {
    return this.data.value?.results ?? [];
  }

  get totalRecords(): TResponse['totalRecords'] {
    return this.data.value?.totalRecords ?? 0;
  }

  get numberOfRecords(): TResponse['numberOfRecords'] {
    return this.numberOfRecordsFilter.value ?? 0;
  }

  search() {
    this.data.synchronize(this.filters.getCurrentState()).catch(() => []);
  }
}