import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';

import { MaterialListingItem } from '@/app/api/morgan-thermal/material';
import { MeasurementSystem } from '@/app/measurement-systems/model';
import { createMeasurementSystemBasedColumn } from '@/app/listing/helpers/measurement-system-based-column';
import { MyMaterialListingModel } from '@/app/listing/my-materials/model';
import { FavoriteButton } from '@/shared/design-system/thermal-ceramics/components/favorite';

import classNames from './styles.module.css';
import { Pill, PillModifier } from '@/shared/design-system/thermal-ceramics/components/pill';
import { ProjectOrderByOptions } from '@/app/api/morgan-thermal/project/search';
import { MaterialOrderByOptions } from '@/app/api/morgan-thermal/material/search';
import { roundDecimals } from '@/shared/helpers/number';

const columnHelper = createColumnHelper<MaterialListingItem>();

export const createColumns = (measurementSystem: MeasurementSystem, model: MyMaterialListingModel) => [
  model.listing.data.value?.results.find((material) => material.isActive) ? columnHelper.display({
    header: 'Favorite',
    size: 50,
    meta: {
      headCellClassName: classNames.favoriteCellHead,
      cellClassName: classNames.favoriteCell,
    },
    cell: info => info.row.original.isActive && (
      <FavoriteButton
        className={classNames.favoriteButton}
        active={info.row.original.isFavorite}
        onClick={() => {
          if (info.row.original.isFavorite) return model.removeFavorite(info.row.original.id);
          return model.addFavorite(info.row.original.id);
        }}
      />
    ),
  }) : undefined,
  columnHelper.accessor('name', {
    header: 'Material',
    meta: {
      orderBy: MaterialOrderByOptions.Name,
      canBeChecked: true,
      detailsLink: (row) => `/my-materials/${row.original.id}`,
    },
    cell: info => info.renderValue(),
  }),
  !model.getFavoritesFilter.value ? columnHelper.accessor('isActive', {
    header: 'Status',
    cell: info => <Pill modifier={info.row.original.isActive ? undefined : PillModifier.DISABLED}>{info.row.original.isActive ? 'Active' : 'Disabled'}</Pill>
  }) : undefined,
  columnHelper.accessor('region', {
    header: 'Region',
    cell: info => info.renderValue(),
    meta: {
      orderBy: MaterialOrderByOptions.Region,
    },
  }),
  createMeasurementSystemBasedColumn<MaterialListingItem>(measurementSystem, 'density', {
    header: 'Density',
    modifier: (value) => roundDecimals(value, measurementSystem === MeasurementSystem.METRIC ? 0 : 1),
    meta: {
      orderBy: MaterialOrderByOptions.Density,
    },
  }),
  createMeasurementSystemBasedColumn<MaterialListingItem>(measurementSystem, 'classificationTemperature', {
    header: 'Classification Temperature',
    modifier: roundDecimals,
    meta: {
      orderBy: MaterialOrderByOptions.ClassificationTemperature,
    },
  }),
  createMeasurementSystemBasedColumn<MaterialListingItem>(measurementSystem, 'useLimit', {
    header: 'Use Limit',
    modifier: roundDecimals,
    meta: {
      orderBy: MaterialOrderByOptions.UseLimit,
    },
  }),
  createMeasurementSystemBasedColumn<MaterialListingItem>(measurementSystem, 'specificHeat', {
    header: 'Specific Heat',
    modifier: (value) => roundDecimals(value, 2),
    meta: {
      orderBy: MaterialOrderByOptions.SpecificHeat,
    },
  }),
];