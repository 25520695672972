import { app } from '@/app';
import { isoToReadableString } from '@/shared/helpers/date';
import { EntityDetails } from '@/app/details';
import { UserInformation } from '@/app/api/morgan-thermal/user';

import classNames from './styles.module.css';

export type UserDetailsProps = {
  editUrl?: string,
  user: UserInformation
};

export const UserDetails = (props: UserDetailsProps) => {
  const { user, editUrl } = props;

  return (
    <EntityDetails
      editUrl={editUrl}
      mainInformation={[
        { key: 'Name', value: user.name },
        { key: 'Company Address', value: user.address },
        { key: 'Email', value: user.email },
        { key: 'Additional Address', value: user.additionalAddress },
        { key: 'Job Title', value: user.job },
        { key: 'City', value: user.city },
        { key: 'Contact Phone', value: user.contactPhone },
        { key: 'Country', value: user.country },
        { key: 'Secondary Phone', value: user.secondaryPhone },
        user.stateProvince ? { key: 'State/Province', value: user.stateProvince } : null,
        { key: 'Postal Code', value: user.zip },
        { key: 'Role', value: user.role },
      ]}
      additionalInformation={[
        { key: 'Join date:', value: isoToReadableString(user.createdDate) },
        { key: 'Last login:', value: isoToReadableString(user.lastLoginDate) },
      ]}
      mainFooter={app.session.user.value?.id === user.id ? <button className={classNames.resetPassword} onClick={app.authorizationProvider.resetPassword} type="button">Reset password</button> : null}
    />
  );
};