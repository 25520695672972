import { UserInformation } from '@/app/api/morgan-thermal/user';

export const createInitialValues = (user?: UserInformation) => ({
  name: user?.name ?? '',
  email: user?.email ?? '',
  job: user?.job ?? '',
  city: user?.city ?? '',
  zip: user?.zip ?? '',
  address: user?.address ?? '',
  additionalAddress: user?.additionalAddress ?? '',
  contactPhone: user?.contactPhone ?? '',
  secondaryPhone: user?.secondaryPhone ?? '',
  countryId: user?.countryId ?? null,
  roleId: user?.roleId ?? null,
  stateProvinceId: user?.stateProvinceId ?? null,
});