import { EntityDetails } from '@/app/details';
import { CompanyInformation } from '@/app/api/morgan-thermal/company';
import listResetHelper from '@/shared/design-system/thermal-ceramics/helpers/list-reset.module.css';
import { app } from '@/app';

export type CompanyDetailsProps = {
  company: CompanyInformation
};

export const CompanyDetails = (props: CompanyDetailsProps) => {
  const { company } = props;

  return (
    <EntityDetails
      editUrl={app.session.isAdmin ? `/companies/${company.id}/edit` : ''}
      mainInformation={[
        { key: 'Company name', value: company.name },
        { key: 'City', value: company.city },
        { key: 'Industry', value: company.industry },
        { key: 'Country', value: company.country },
        { key: 'Contact phone', value: company.contactPhone },
        { key: 'State/Province', value: company.stateProvince },
        { key: 'Secondary phone', value: company.secondaryPhone },
        { key: 'Postal Code', value: company.zip },
        { key: 'Address', value: company.address },
        { key: 'Company domain', value: () => (
          <ul className={listResetHelper.listReset}>
            {company.domains.map((domain) => <li key={domain}>{domain}</li>)}
          </ul>
        )},
        { key: 'Additional Address', value: company.additionalAddress },
      ]}
      additionalInformation={[
        { key: 'Materials:', value: company.amountOfMaterials },
        { key: 'Projects:', value: company.amountOfProjects },
        { key: 'Calculations:', value: company.amountOfCalculations },
      ]}
    />
  );
};