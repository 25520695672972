import 'simplebar-react/dist/simplebar.min.css';
import { forwardRef, PropsWithChildren } from 'react';
import SimpleBar, { Props } from 'simplebar-react';
import { clsx } from 'clsx';

import classNames from './styles.module.css';

export type ScrollAreaProps = PropsWithChildren<Props>;

export const ScrollArea = forwardRef<HTMLDivElement, ScrollAreaProps>(({ className, children, ...attributes }, ref) => {
  return (
    <SimpleBar
      className={clsx(classNames.scrollArea, className)}
      scrollableNodeProps={{ ref }}
      {...attributes}
    >
      {children}
    </SimpleBar>
  );
});