import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { Navigate, useMatch } from 'react-router-dom';
import { app } from '@/app';

export const AuthGuard = observer(function AuthGuard({ children }: PropsWithChildren) {
  const isAuthRoute = useMatch('/auth/*');

  if (app.session.isLoggingOut) return null;

  if (!app.session.isLoggedIn && !isAuthRoute) return <Navigate to="/auth" replace />;

  if (app.session.isLoggedIn && isAuthRoute) return <Navigate to="/" replace />;

  return (<>{children}</>);
});