import { object } from '@/shared/libs/yup';
import { nameSchema } from '@/app/form/fields/common/name';
import { citySchema } from '@/app/form/fields/common/city';
import { postalCodeSchema } from '@/app/form/fields/common/postal-code';
import { addressSchema } from '@/app/form/fields/common/address';
import { phoneSchema } from '@/app/form/fields/common/phone';
import { countrySchema } from '@/app/form/fields/common/country';
import { stateProvinceSchema } from '@/app/form/fields/common/state-province';
import { industrySchema } from '@/app/form/fields/company/industry';
import { domainsSchema } from '@/app/form/fields/company/domains';

export type EditProfileFormValidationOptions = { hasStateProvinces: boolean };

export const createValidationSchema = ({ hasStateProvinces }: EditProfileFormValidationOptions) => object({
  name: nameSchema.required().label('Company Name'),
  industryId: industrySchema.nullable().label('Industry'),
  city: citySchema.required(),
  zip: postalCodeSchema.required().label('Postal Code'),
  address: addressSchema.required().label('Company Address'),
  additionalAddress: addressSchema.label('Additional Address'),
  contactPhone: phoneSchema.required().label('Contact Phone'),
  secondaryPhone: phoneSchema.label('Secondary Phone'),
  countryId: countrySchema.required(),
  stateProvinceId: hasStateProvinces ? stateProvinceSchema.required() : stateProvinceSchema.nullable(),
  domains: domainsSchema.required().label('Company Domain'),
});