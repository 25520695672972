import { makeObservable, observable } from 'mobx';

import { AuthorizationProvider } from '@/app/auth/providers/types';
import { MSALAuthorizationProvider } from '@/app/auth/providers/msal';
import { createMorganThermalAPI, MorganThermalAPI } from '@/app/api/morgan-thermal';
import { Session } from '@/app/auth/session';
import { Configuration } from '@/app/configuration';
import { MeasurementSystems } from '@/app/measurement-systems/model';

class App {
  authorizationProvider: AuthorizationProvider;
  morganThermalAPI: MorganThermalAPI;
  configuration: Configuration;
  session: Session;
  measurementSystems: MeasurementSystems;

  constructor() {
    makeObservable(this, {
      authorizationProvider: observable,
      morganThermalAPI: observable,
      configuration: observable,
      session: observable,
      measurementSystems: observable,
    });

    this.authorizationProvider = new MSALAuthorizationProvider();
    this.morganThermalAPI = createMorganThermalAPI(process.env.REACT_APP_API_URL ?? '/', this.authorizationProvider.getToken);
    this.configuration = new Configuration({
      morganThermalAPI: this.morganThermalAPI,
    });
    this.session = new Session({
      authorizationProvider: this.authorizationProvider,
      morganThermalAPI: this.morganThermalAPI,
    });
    this.measurementSystems = new MeasurementSystems();
  }
}

export const app = new App();