import React from 'react';

import { TopLink } from '@/shared/design-system/morgan/components/footer/top-links';
import { Accreditation } from '@/shared/design-system/morgan/components/footer/accreditations';
import { ExternalLink } from '@/shared/design-system/morgan/components/footer/external-links';
import { SocialLink } from '@/shared/design-system/morgan/components/footer/social-links';
import { AdditionalLink } from '@/shared/design-system/morgan/components/footer/additional-links';

import accreditationEcovadis from '@/app/layout/assets/images/silver-medal-ecovadis.png';
import accreditationMCSI from '@/app/layout/assets/images/msci-esg-aaa.png';

import { FacebookIcon, LinkedInIcon } from '@/shared/design-system/thermal-ceramics/icons';

type FooterData = {
    topLinks: TopLink[],
    accreditations: Accreditation[],
    externalLinks: ExternalLink[],
    socialLinks: SocialLink[],
    additionalLinks: AdditionalLink[],
};

export const footerData: FooterData = {
    topLinks: [
        {
            id: 'top_link_trademark',
            href: 'https://www.morganadvancedmaterials.com/en-gb/trademarks/',
            children: 'Trademarks'
        },
        {
            id: 'top_link_morgan_code',
            href: 'https://www.morganadvancedmaterials.com/en-gb/sustainability-responsibility/the-morgan-code/',
            children: 'The Morgan Code'
        },
        {
            id: 'top_link_contact_us',
            href: 'mailto:marketing.tc@morganplc.com?subject=Morgan Thermal Ceramics Enquiry',
            children: 'Contact Us',
            isButton: true
        }
    ],
    accreditations: [
        {id: 'accreditations_ecovadis', image: accreditationEcovadis, children: '2022 silver EcoVadis medal winner'},
        {
            id: 'accreditations_msci',
            image: accreditationMCSI,
            children: 'As of 2021, Morgan received an MSCI ESG Rating of AAA.'
        },
        {
            id: 'accreditations_ecologi',
            image: 'https://api.ecologi.com/badges/trees/60d078180cb1ce4272188ee7?black=true&treeOnly=true',
            children: 'We\'re committed to offsetting our digital carbon footprint',
            href: 'https://ecologi.com/morganadvancedmaterials'
        },
    ],
    externalLinks: [
        {
            id: 'external_links_morgan_advanced_materials',
            href: 'https://www.morganadvancedmaterials.com/',
            target: '_blank',
            children: 'Morgan Advanced Materials'
        },
        {
            id: 'external_links_molten_metal_systems',
            href: 'https://www.morganmms.com/',
            target: '_blank',
            children: 'Molten Metal Systems'
        },
        {
            id: 'external_links_electrical_carbon',
            href: 'https://www.morganelectricalmaterials.com/',
            target: '_blank',
            children: 'Electrical Carbon'
        },
        {
            id: 'external_links_seals_and_bearings',
            href: 'https://www.morgansealsandbearings.com/',
            target: '_blank',
            children: 'Seals and Bearings'
        },
        {
            id: 'external_links_technical_ceramics',
            href: 'https://www.morgantechnicalceramics.com/',
            target: '_blank',
            children: 'Technical Ceramics'
        },
        {
            id: 'external_links_braze_alloys',
            href: 'https://www.morganbrazealloys.com/',
            target: '_blank',
            children: 'Braze Alloys'
        },
        {
            id: 'external_links_speciality_graphite',
            href: 'https://www.morganspecialtygraphite.com/',
            target: '_blank',
            children: 'Specialty Graphite'
        },
        {
            id: 'external_links_murugappa_morgan',
            href: 'https://www.murugappamorgan.com/',
            target: '_blank',
            children: 'Murugappa Morgan'
        },
    ],
    socialLinks: [
        {
            id: 'social_links_linkedin',
            href: 'https://www.linkedin.com/company/morgan-advanced-materials',
            target: '_blank',
            children: 'LinkedIn',
            icon: <LinkedInIcon width="1.125rem" height="1.5rem"/>
        },
        {
            id: 'social_links_facebook',
            href: 'https://www.facebook.com/morganadvancedmaterials',
            target: '_blank',
            children: 'Facebook',
            icon: <FacebookIcon width="1.125rem" height="1.5rem"/>
        },
    ],
    additionalLinks: [
        {
            id: 'additional_links_website_terms',
            href: 'https://www.morganadvancedmaterials.com/en-gb/website-terms-and-conditions-of-use/',
            children: 'Website Terms and Conditions of Use'
        },
        {
            id: 'additional_links_purchase_terms',
            href: 'https://www.morganadvancedmaterials.com/en-gb/terms-and-conditions-sale-purchase/',
            children: 'Terms and Conditions Sale / Purchase'
        },
        {
            id: 'additional_links_legal',
            href: 'https://www.morganadvancedmaterials.com/en-gb/website-terms-and-conditions-of-use/',
            children: 'Legal'
        },
        {
            id: 'additional_links_privacy',
            href: 'https://www.morganadvancedmaterials.com/en-gb/privacy/',
            children: 'Privacy'
        },
        {
            id: 'additional_links_cookies',
            href: 'https://www.morganadvancedmaterials.com/en-gb/cookies/',
            children: 'Cookies'
        },
        {
            id: 'additional_links_accessibility',
            href: 'https://www.morganadvancedmaterials.com/en-gb/accessibility/',
            children: 'Accessibility'
        },
        {
            id: 'additional_links_sitemap',
            href: 'https://www.morganadvancedmaterials.com/en-gb/sitemap/',
            children: 'Sitemap'
        },
    ]
};