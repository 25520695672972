import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { Height } from 'react-animate-height';
import classNames from './styles.module.css';

export enum MobileMenuState {
  OPENED = 'OPENED',
  CLOSED = 'CLOSED',
  OPENING = 'OPENING',
  CLOSING = 'CLOSING',
}

export const MobileMenuContext = createContext({
  state: MobileMenuState.CLOSED,
  currentHeight: 0 as Height,
  toggle: () => {},
  onHeightAnimationEnd: () => {},
});

export function MobileMenuContextProvider({ children, height = 'auto' }: PropsWithChildren<{ height?: Height }>) {
  const [state, setState] = useState(MobileMenuState.CLOSED);
  const [currentHeight, setCurrentHeight] = useState<Height>(0);

  function toggle() {
    switch (state) {
      case MobileMenuState.OPENED: {
        setState(MobileMenuState.CLOSING);
        setCurrentHeight(0);
        break;
      }
      case MobileMenuState.CLOSED: {
        document.documentElement.classList.add(classNames.overflow);
        setState(MobileMenuState.OPENING);
        setTimeout(() => setCurrentHeight(height));
        break;
      }
    }
  }

  function onHeightAnimationEnd() {
    switch (state) {
      case MobileMenuState.CLOSING: {
        document.documentElement.classList.remove(classNames.overflow);
        setState(MobileMenuState.CLOSED);
        break;
      }
      case MobileMenuState.OPENING: {
        setState(MobileMenuState.OPENED);
        break;
      }
    }
  }

  return (
    <MobileMenuContext.Provider value={{
      state,
      currentHeight,
      toggle,
      onHeightAnimationEnd,
    }}>
      {children}
    </MobileMenuContext.Provider>
  );
}

export function useMobileMenu() {
  return useContext(MobileMenuContext);
}