import { useCallback, useState } from 'react';
import { NavLink, useNavigate, useRouteLoaderData } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { app } from '@/app';
import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { Breadcrumbs } from '@/app/routing/breadcrumbs';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';
import { showInformativeModal } from '@/app/components/informative-modal';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { MaterialInformation } from '@/app/api/morgan-thermal/material';
import { MaterialUpdate, updateMaterial } from '@/app/api/morgan-thermal/material/update';
import { SaveMaterialForm } from '@/app/form/scenarios/save-material';
import { MeasurementSystemsToggle } from '@/app/measurement-systems/view';

import classNames from './styles.module.css';
import { confirmMeasurementChange } from '@/app/measurement-systems/confirm-measurement-change';

export const MaterialEditRouteView = observer(() => {
  const material = useRouteLoaderData('my-material') as SynchronizableValue<MaterialInformation | null>;
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = useCallback((data: MaterialUpdate) => {
    setIsSubmitting(true);
    return app.morganThermalAPI.fetch(updateMaterial(data))
      .then((response) => {
        material.synchronize({ noCache: true }).catch(console.error);
        navigate(`/my-materials/${material.value?.id}`);
        showInformativeModal(response).catch();
        return response;
      })
      .catch((response) => {
        showInformativeModal(response).catch();
        return response.data;
      })
      .finally(() => setIsSubmitting(false));
  }, [navigate, material]);

  return (
    <Page hasOperationsInProgress={material.isSynchronizationInProgress} loaded={!!material.value}>
      <Breadcrumbs/>

      <div className={classNames.header}>
        <Heading as="h1">Edit Material</Heading>
        <MeasurementSystemsToggle beforeChange={confirmMeasurementChange}/>
      </div>

      {material.value && (
        <SaveMaterialForm
          material={material.value}
          submit={handleSubmit}
          footer={<>
            <Button modifier={ButtonModifier.TERTIARY} as={NavLink} to={`/my-materials/${material.value.id}`}>Cancel</Button>
            <Button type="submit" disabled={isSubmitting}>Save</Button>
          </>}
        />
      )}
    </Page>
  );
});