import { number } from '@/shared/libs/yup';
import { FieldInputProps, HookFormField } from '@/shared/libs/hook-form';
import { ValueMeasurement } from '@/app/form/fields/material/helpers/value-measurement';
import { MeasurementSystem } from '@/app/measurement-systems/model';

export const hotFaceTemperatureSchema = (measurementSystem: MeasurementSystem) =>
  number()
    .max(measurementSystem === MeasurementSystem.METRIC ? 2200 : 3992)
    .label('Hot Face Temperature');

export const HotFaceTemperatureHookFormField = (props: FieldInputProps) => (
  <HookFormField.Input
    label="Hot Face Temperature"
    extendProps={(props) => ({
      ...props,
      endSlot: (
        <ValueMeasurement
          imperial={<>&deg;F</>}
          metric={<>&deg;C</>}
        />
      )
    })}
    {...props}
  />
);