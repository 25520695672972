import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, NavLink } from 'react-router-dom';

import { Listing } from '@/app/listing';
import { Button } from '@/shared/design-system/thermal-ceramics/components/button';
import { Search } from '@/shared/design-system/thermal-ceramics/components/form/search';
import { ActionsDropdown, ActionsDropdownItemModifier } from '@/app/components/actions-dropdown';
import { ProjectListingItem } from '@/app/api/morgan-thermal/project';

import { columns } from './columns';
import { ProjectListingModel } from './model';
import { ToggleButton } from '@/shared/design-system/thermal-ceramics/components/toggle-button/button';
import { ToggleButtonGroup } from '@/shared/design-system/thermal-ceramics/components/toggle-button/group';
import { ProjectOrderByOptions } from '@/app/api/morgan-thermal/project/search';

export const ProjectsListing = observer(() => {
  const [model] = useState(() => new ProjectListingModel());
  const { listing, customerOrProjectNameFilter, showDisabledFilter, enable, disable, copy } = model;
  const { data, results, totalRecords, numberOfRecords, pageFilter, orderByFilter, orderByAscendingFilter } = listing;

  return (
    <Listing<ProjectListingItem, ProjectOrderByOptions>
      header={
        <>
          <ToggleButtonGroup
            value={showDisabledFilter.value}
            onChange={showDisabledFilter.setValue}
          >
            <ToggleButton value={false}>Active</ToggleButton>
            <ToggleButton value={true}>Disabled</ToggleButton>
          </ToggleButtonGroup>
          <Search value={customerOrProjectNameFilter.value} onSearch={customerOrProjectNameFilter.setValue}/>
          <Button as={Link} to={`/projects/create`}>Create project</Button>
        </>
      }
      renderActions={({ id, project, isActive }: ProjectListingItem) => {
        return (
          <ActionsDropdown>
            <ActionsDropdown.Item key="details" as={NavLink} to={`/projects/${id}`}>Details</ActionsDropdown.Item>
            {isActive && <ActionsDropdown.Item key="saveAs" onClick={() => copy(id, project)}>Save As</ActionsDropdown.Item>}
            {isActive && <ActionsDropdown.Item key="edit" as={NavLink} to={`/projects/${id}/edit`}>Edit</ActionsDropdown.Item>}
            {isActive && <ActionsDropdown.Item key="disable" modifier={ActionsDropdownItemModifier.DANGER} onClick={() => disable(id)}>Disable</ActionsDropdown.Item>}
            {!isActive && <ActionsDropdown.Item key="enable" modifier={ActionsDropdownItemModifier.POSITIVE} onClick={() => enable(id)}>Enable</ActionsDropdown.Item>}
          </ActionsDropdown>
        );
      }}
      columns={columns}
      data={results}
      isInitialized={!!data}
      hasOperationsInProgress={data.isSynchronizationInProgress}
      totalRecords={totalRecords}
      numberOfRecords={numberOfRecords}
      page={pageFilter.value}
      onPageChange={pageFilter.setValue}

      orderBy={orderByFilter.value as ProjectOrderByOptions}
      orderByAscending={orderByAscendingFilter.value}
      onOrderByChange={orderByFilter.setValue}
      onOrderByAscendingChange={orderByAscendingFilter.setValue}
    />
  );
});