import { useCallback } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { app } from '@/app';
import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { Breadcrumbs } from '@/app/routing/breadcrumbs';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';
import { showInformativeModal } from '@/app/components/informative-modal';
import {
  register,
  registerCompany,
  RegistrationCompanyData,
  RegistrationData
} from '@/app/api/morgan-thermal/registration/register';
import { SaveCompanyForm } from '@/app/form/scenarios/save-company';
import { CreateCompanyForm } from '@/app/form/scenarios/create-company';
import { validateSignUp } from '@/app/api/morgan-thermal/registration/validate-sign-up';

export const CompanyCreateRouteView = observer(() => {
  const navigate = useNavigate();

  const handleVerifyEmail = useCallback((email: string) => app.morganThermalAPI.fetch(validateSignUp(email)), []);

  const handleSubmit = useCallback((data: RegistrationData) => {
    return app.morganThermalAPI.fetch(register(data))
      .then((response) => {
        navigate(`/companies/${response?.id}`);
        showInformativeModal(response).catch();
        return response;
      })
      .catch((response) => {
        showInformativeModal(response).catch();
        return response.data;
      });
  }, [navigate]);

  return (
    <Page hasOperationsInProgress={false} loaded={true}>
      <Breadcrumbs/>

      <Heading as="h1">Create company</Heading>

      <CreateCompanyForm
        submit={handleSubmit}
        verifyEmail={handleVerifyEmail}
        footer={<>
          <Button modifier={ButtonModifier.TERTIARY} as={NavLink} to={`/companies`}>Cancel</Button>
          <Button type="submit">Create</Button>
        </>}
      />
    </Page>
  );
});