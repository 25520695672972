import { MaterialInformation, MaterialListingItem } from '@/app/api/morgan-thermal/material';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';
import { Input } from '@/shared/design-system/thermal-ceramics/components/form/input';
import { app } from '@/app';
import { observer } from 'mobx-react-lite';
import { ValueMeasurementString } from '@/app/form/fields/material/helpers/value-measurement';
import { Checkbox } from '@/shared/design-system/thermal-ceramics/components/form/checkbox';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';
import { Label } from '@/shared/design-system/thermal-ceramics/components/form/label';

import classNames from './styles.module.css';
import { clsx } from 'clsx';
import { ScrollArea } from '@/shared/design-system/thermal-ceramics/components/scroll-area';
import { NavLink } from 'react-router-dom';

export type CompareMaterialsProps = {
  materials?: {
    id: MaterialInformation['id'],
    name: MaterialInformation['name'],
    metric: MaterialInformation['metric'],
    imperial: MaterialInformation['imperial'],
  }[],
  onUnselect?: (id: MaterialListingItem['id']) => void,
};

export const CompareMaterials = observer((props: CompareMaterialsProps) => {
  const { materials, onUnselect } = props;

  return (
    <section className={classNames.section}>
      <Heading className={classNames.heading} as="h2">Compare materials</Heading>
      <ScrollArea className={classNames.scrollArea}>
        <div className={classNames.body}>
          <div className={classNames.header} aria-hidden="true">
            <div className={classNames.column}/>
            <div className={classNames.column}><Label>Name</Label></div>
            <div className={classNames.column}><Label>Classification Temperature</Label></div>
          </div>
          <ul className={classNames.list}>
            {materials?.map((material) => (
              <li className={classNames.item} key={material.id}>
                <div className={clsx(classNames.column, classNames.checkColumn)}><Checkbox checked onChange={() => onUnselect && onUnselect(material.id)}/></div>
                <div className={classNames.column}><Input value={material.name} disabled readOnly/></div>
                <div className={classNames.column}>
                  <Input
                    value={material[app.measurementSystems.currentSystem].classificationTemperature}
                    endSlot={
                      <ValueMeasurementString
                        hint={material[app.measurementSystems.currentSystem].classificationTemperatureLabel}
                      />
                    }
                    disabled
                    readOnly
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </ScrollArea>
      <div className={classNames.footer}>
        <Button type="button" modifier={ButtonModifier.OUTLINE} as={NavLink} to={`/my-materials/compare?ids=${materials?.map(({ id }) => id).join(',')}`}>Compare</Button>
      </div>
    </section>
  );
});