import {  useState } from 'react';
import { string } from '@/shared/libs/yup';
import { observer } from 'mobx-react-lite';

import { HookFormField, FieldSelectProps } from '@/shared/libs/hook-form';
import { Industry } from '@/app/api/morgan-thermal/configuration/get-industries';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { createSelectOptions } from '@/shared/libs/hook-form/select';
import { app } from '@/app';

export const industrySchema = string().label('Industry');

export const IndustryField = observer((props: Omit<FieldSelectProps, 'options'>) => {
  const [industries] = useState(() => {
    const synchronizableValue = new SynchronizableValue<Industry[]>([], app.configuration.getIndustries);
    synchronizableValue.synchronize().catch(console.error);
    return synchronizableValue;
  });

  return (
    <HookFormField.Select
      label="Industry"
      options={createSelectOptions(industries.value)}
      inProgress={industries.isSynchronizationInProgress}
      {...props}
    />
  );
});