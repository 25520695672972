import { useCallback, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { app } from '@/app';
import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { Breadcrumbs } from '@/app/routing/breadcrumbs';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';
import { showInformativeModal } from '@/app/components/informative-modal';
import { ProjectSave, saveProject } from '@/app/api/morgan-thermal/project/save';
import { SaveProjectForm } from '@/app/form/scenarios/save-project';

export const ProjectCreateRouteView = observer(() => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = useCallback((data: ProjectSave) => {
    setIsSubmitting(true);
    return app.morganThermalAPI.fetch(saveProject(data))
      .then((response) => {
        navigate(`/projects/${response?.id}`);
        showInformativeModal(response).catch();
      })
      .catch((response) => {
        showInformativeModal(response).catch();
      })
      .finally(() => setIsSubmitting(false));
  }, [navigate]);

  return (
    <Page hasOperationsInProgress={false} loaded={true}>
      <Breadcrumbs/>

      <Heading as="h1">Create Project</Heading>

      <SaveProjectForm
        submit={handleSubmit}
        footer={<>
          <Button modifier={ButtonModifier.TERTIARY} as={NavLink} to={`/`}>Cancel</Button>
          <Button type="submit" disabled={isSubmitting}>Create</Button>
        </>}
      />
    </Page>
  );
});