import { withCrumb } from '@/app/routing/breadcrumbs';

import { createRoute } from './create/route';
import { projectRoute } from './id/route';

export const projectsRoute = {
  path: 'projects',
  children: [
    createRoute,
    projectRoute
  ],
  handle: () => withCrumb({
    to: '/',
    element: () => <span>Projects</span>,
  }),
}