import { number } from '@/shared/libs/yup';
import { HookFormField, FieldInputProps } from '@/shared/libs/hook-form';
import { ValueMeasurement } from '@/app/form/fields/material/helpers/value-measurement';
import { MeasurementSystem } from '@/app/measurement-systems/model';

export const materialUseLimitSchema = (measurementSystem: MeasurementSystem) =>
  number()
    .moreThan(measurementSystem === MeasurementSystem.METRIC ? 0 : 32)
    .max(measurementSystem === MeasurementSystem.METRIC ? 2200 : 3992)
    .label('Use Limit');

export const UseLimitHookFormField = (props: FieldInputProps) => (
  <HookFormField.Input
    label="Use Limit"
    extendProps={(props) => ({
      ...props,
      endSlot: (
        <ValueMeasurement
          imperial={<>&deg;F</>}
          metric={<>&deg;C</>}
        />
      )
    })}
    {...props}
  />
);