import { InputHTMLAttributes, forwardRef, PropsWithChildren } from 'react';
import { clsx } from 'clsx';
import { CheckIcon } from '@/shared/design-system/thermal-ceramics/icons';

import classNames from './styles.module.css';

export type RadioProps = PropsWithChildren<InputHTMLAttributes<HTMLInputElement>>;

export const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const { children, ...attributes } = props;

  return (
    <label
      className={clsx(classNames.radio, {
        [classNames.invalid]: props['aria-invalid'] === 'true' || props['aria-invalid'] === true,
      })}
    >
      <input
        {...attributes}
        ref={ref}
        className={classNames.input}
        type="radio"
      />
      <span className={classNames.check} aria-hidden="true">
        <CheckIcon className={classNames.icon} />
      </span>
      <span className={classNames.label}>{children}</span>
    </label>
  );
});