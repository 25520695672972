import { Fieldset } from '@/shared/design-system/thermal-ceramics/components/form/fieldset';
import { MeasurementSystemsToggle } from '@/app/measurement-systems/view';
import { OutsideWindSpeedHookFormField } from '@/app/form/fields/calculation/ambient-velocity';
import { NameHookFormField } from '@/app/form/fields/common/name';
import { AmbientTemperatureHookFormField } from '@/app/form/fields/calculation/ambient-temperature';
import { HotFaceTemperatureHookFormField } from '@/app/form/fields/calculation/hot-face-temperature';
import { EmissivityHookFormField } from '@/app/form/fields/calculation/emissivity';

import classNames from './styles.module.css';
import { observer } from 'mobx-react-lite';
import { confirmMeasurementChange } from '@/app/measurement-systems/confirm-measurement-change';
import { app } from '@/app';
import { MeasurementSystem } from '@/app/measurement-systems/model';

export type OperatingParametersFieldsetProps = {};

export const OperatingParametersFieldset = observer((props: OperatingParametersFieldsetProps) => {
  return (
    <Fieldset className={classNames.operatingParameters}>
      <div className={classNames.header}>
        <Fieldset.Legend>Operating Parameters</Fieldset.Legend>
        <MeasurementSystemsToggle/>
      </div>
      <Fieldset.Grid>
        <NameHookFormField name="name" label="Calculation name" required/>
        <div className={classNames.filler}/>
        <OutsideWindSpeedHookFormField name="outsideWindSpeed" type="number" decimalPlaces={1} hint="We recommend a wind speed of 0" required/>
        <AmbientTemperatureHookFormField name="outsideTemperature" type="number" decimalPlaces={(app.measurementSystems.currentSystem === MeasurementSystem.METRIC) ? 1 : 0} required/>
        <HotFaceTemperatureHookFormField name="insideTemperature" type="number" decimalPlaces={0} required/>
        <EmissivityHookFormField name="outsideEmissivity" type="number" decimalPlaces={2} required/>
      </Fieldset.Grid>
    </Fieldset>
  );
});