import { app } from '@/app';
import { sendPasswordReset as send } from '@/app/api/morgan-thermal/user/send-password-reset';
import { ActionType, runServerSideAction } from '@/app/actions';
import { UserInformation } from '@/app/api/morgan-thermal/user';

export const sendPasswordReset = (userId: UserInformation['id']) => runServerSideAction({
  type: ActionType.NEGATIVE,

  title: 'Password reset',
  confirmationMessage: 'Send password reset for this user?',
  approveLabel: 'Send',

  runAction: () => app.morganThermalAPI.fetch(send(userId)),
});