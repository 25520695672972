import { action, makeObservable, observable } from 'mobx';

import { app } from '@/app';
import { Listing } from '@/app/listing/model';
import { ProjectInformation } from '@/app/api/morgan-thermal/project';
import { enableProject } from '@/app/actions/project/enable';
import { disableProject } from '@/app/actions/project/disable';
import { copyProject } from '@/app/actions/project/copy';
import {
  ProjectOrderByOptions,
  ProjectSearchFilters,
  ProjectSearchResponse,
  searchProjects
} from '@/app/api/morgan-thermal/project/search';
import { Filter } from '@/shared/models/filters';

export class ProjectListingModel {
  showDisabledFilter: Filter<boolean>;
  customerOrProjectNameFilter: Filter<string>;

  listing: Listing<ProjectSearchResponse, ProjectSearchFilters>;

  constructor() {
    makeObservable(this, {
      showDisabledFilter: observable,
      customerOrProjectNameFilter: observable,
      listing: observable,

      enable: action.bound,
      disable: action.bound,
      copy: action.bound,
    });

    this.showDisabledFilter = new Filter<boolean>({
      name: 'showDisabled',
      initialValue: false,
    });

    this.customerOrProjectNameFilter = new Filter<string>({
      name: 'customerOrProjectName',
      initialValue: '',
      debouncedBy: 300,
    });

    this.listing = new Listing<ProjectSearchResponse, ProjectSearchFilters, ProjectOrderByOptions>({
      filters: [this.showDisabledFilter, this.customerOrProjectNameFilter],
      synchronizer: (filters) => app.morganThermalAPI.fetch(searchProjects(filters)),
    });
    this.listing.search();
  }

  enable(id: ProjectInformation['id']) {
    enableProject(id).then(this.listing.search).catch(console.error);
  }

  disable(id: ProjectInformation['id']) {
    disableProject(id).then(this.listing.search).catch(console.error);
  }

  copy(id: ProjectInformation['id'], name: ProjectInformation['project']) {
    copyProject(id, name).then(this.listing.search).catch(console.error);
  }
}