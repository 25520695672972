import { action, makeObservable, observable } from 'mobx';
import { Filter } from '@/shared/models/filters';
import { app } from '@/app';
import { Listing } from '@/app/listing/model';
import { MaterialInformation } from '@/app/api/morgan-thermal/material';
import { disableMaterial } from '@/app/actions/material/disable';
import {
  MaterialOrderByOptions,
  MaterialSearchFilters,
  MaterialSearchResponse,
  searchMaterials
} from '@/app/api/morgan-thermal/material/search';
import { enableMaterial } from '@/app/actions/material/enable';

export class MaterialListingModel {
  showDisabledFilter: Filter<boolean>;
  materialCategoryIdFilter: Filter<MaterialInformation['materialCategoryId']>;
  regionIdFilter: Filter<MaterialInformation['originOfManufacturerId']>;
  materialNameFilter: Filter<string>;

  listing: Listing<MaterialSearchResponse, MaterialSearchFilters, MaterialOrderByOptions>;

  constructor() {
    makeObservable(this, {
      showDisabledFilter: observable,
      materialCategoryIdFilter: observable,
      regionIdFilter: observable,
      materialNameFilter: observable,

      listing: observable,

      enable: action.bound,
      disable: action.bound,
    });

    this.showDisabledFilter = new Filter<boolean>({
      name: 'showDisabled',
      initialValue: false,
    });

    this.materialCategoryIdFilter = new Filter<MaterialInformation['materialCategoryId']>({
      name: 'materialCategoryId',
      initialValue: null,
    });

    this.regionIdFilter = new Filter<MaterialInformation['originOfManufacturerId']>({
      name: 'regionId',
      initialValue: null,
    });

    this.materialNameFilter = new Filter<string>({
      name: 'materialName',
      initialValue: '',
      debouncedBy: 300,
    });

    this.listing = new Listing<MaterialSearchResponse, MaterialSearchFilters, MaterialOrderByOptions>({
      filters: [this.showDisabledFilter, this.materialCategoryIdFilter, this.regionIdFilter, this.materialNameFilter],
      synchronizer: (filters) => app.morganThermalAPI.fetch(searchMaterials(filters)),
    });
    this.listing.search();

    this.showDisabledFilter.on('change', () => { this.listing.pageFilter.setValue(1) });
  }

  enable(id: MaterialInformation['id']) {
    enableMaterial(id).then(this.listing.search).catch(console.error);
  }

  disable(id: MaterialInformation['id']) {
    disableMaterial(id).then(this.listing.search).catch(console.error);
  }
}