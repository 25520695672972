import { action, makeObservable, observable } from 'mobx';
import { Filter } from '@/shared/models/filters';
import { app } from '@/app';
import { Listing } from '@/app/listing/model';
import { MaterialInformation } from '@/app/api/morgan-thermal/material';
import { disableMaterial } from '@/app/actions/material/disable';
import {
  MaterialOrderByOptions,
  MaterialSearchFilters,
  MaterialSearchResponse,
} from '@/app/api/morgan-thermal/material/search';
import { enableMaterial } from '@/app/actions/material/enable';
import { searchPersonalizedMaterials } from '@/app/api/morgan-thermal/material/personalized-search';
import { enableCalculation } from '@/app/actions/calculation/enable';
import { disableCalculation } from '@/app/actions/calculation/disable';
import { addFavoriteMaterial } from '@/app/actions/material/add-favorite';
import { removeFavoriteMaterial } from '@/app/actions/material/remove-favorite';

export class MyMaterialListingModel {
  getFavoritesFilter: Filter<boolean>;
  getMyMaterialsFilter: Filter<boolean>;
  showDisabledFilter: Filter<boolean>;
  materialCategoryIdFilter: Filter<MaterialInformation['materialCategoryId']>;
  regionIdFilter: Filter<MaterialInformation['originOfManufacturerId']>;
  materialNameFilter: Filter<string>;

  listing: Listing<MaterialSearchResponse, MaterialSearchFilters, MaterialOrderByOptions>;

  constructor() {
    makeObservable(this, {
      getFavoritesFilter: observable,
      getMyMaterialsFilter: observable,
      showDisabledFilter: observable,
      materialCategoryIdFilter: observable,
      regionIdFilter: observable,
      materialNameFilter: observable,

      listing: observable,

      enable: action.bound,
      disable: action.bound,
    });

    this.getFavoritesFilter = new Filter<boolean>({
      name: 'getFavorites',
      initialValue: true,
    });

    this.getMyMaterialsFilter = new Filter<boolean>({
      name: 'getMyMaterials',
      initialValue: true,
    });

    this.showDisabledFilter = new Filter<boolean>({
      name: 'showDisabled',
      initialValue: false,
    });

    this.materialCategoryIdFilter = new Filter<MaterialInformation['materialCategoryId']>({
      name: 'materialCategoryId',
      initialValue: null,
    });

    this.regionIdFilter = new Filter<MaterialInformation['originOfManufacturerId']>({
      name: 'regionId',
      initialValue: null,
    });

    this.materialNameFilter = new Filter<string>({
      name: 'materialName',
      initialValue: '',
      debouncedBy: 300,
    });

    this.listing = new Listing<MaterialSearchResponse, MaterialSearchFilters, MaterialOrderByOptions>({
      filters: [
        this.getFavoritesFilter,
        this.getMyMaterialsFilter,
        this.showDisabledFilter,
        this.materialCategoryIdFilter,
        this.regionIdFilter,
        this.materialNameFilter
      ],
      synchronizer: (filters) => {
        return app.morganThermalAPI.fetch(searchPersonalizedMaterials(filters))
          .catch(() => ({
            page: 1,
            numberOfRecords: 0,
            totalRecords: 0,
            results: [],
          }));
      },
    });
    this.listing.search();
    this.listing.filters.on('change', ({ name }) => {
      if (name !== 'page') this.listing.pageFilter.setValue(1);
    });
  }

  addFavorite(id: MaterialInformation['id']) {
    addFavoriteMaterial(id).then(this.listing.search).catch(console.error);
  }

  removeFavorite(id: MaterialInformation['id']) {
    removeFavoriteMaterial(id).then(this.listing.search).catch(console.error);
  }

  enable(id: MaterialInformation['id']) {
    enableMaterial(id).then(this.listing.search).catch(console.error);
  }

  disable(id: MaterialInformation['id']) {
    disableMaterial(id).then(this.listing.search).catch(console.error);
  }
}