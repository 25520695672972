import { ComponentProps, PropsWithChildren } from 'react';
import { clsx } from 'clsx';

import { Dropdown } from '@/shared/design-system/thermal-ceramics/components/dropdown';
import { ClickableArea } from '@/shared/design-system/thermal-ceramics/components/clickable-area';
import { VisuallyHidden } from '@/shared/design-system/thermal-ceramics/components/visually-hidden';
import { VerticalDotsIcon } from '@/shared/design-system/thermal-ceramics/icons';

import classNames from './styles.module.css';

export enum ActionsDropdownItemModifier {
  POSITIVE = 'positive',
  DANGER = 'danger',
}

export type ActionsDropdownItemProps = PropsWithChildren<ComponentProps<typeof ClickableArea> & {
  modifier?: ActionsDropdownItemModifier,
}>;
export type ActionsDropdownProps = PropsWithChildren;

const Root = ({ children }: ActionsDropdownProps) => (
  <Dropdown
    button={
      <ClickableArea className={classNames.toggle}>
        <VerticalDotsIcon aria-hidden="true"/>
        <VisuallyHidden>Actions</VisuallyHidden>
      </ClickableArea>
    }
  >
    {children}
  </Dropdown>
);

const Item = ({ children, modifier, ...clickableAreaProps }: ActionsDropdownItemProps) => (
  <Dropdown.Item className={clsx(classNames.item, classNames[modifier ?? ''])}>
    <ClickableArea className={classNames.button} {...clickableAreaProps}>
      {children}
    </ClickableArea>
  </Dropdown.Item>
);

export const ActionsDropdown = Object.assign(Root, {
  Item,
});