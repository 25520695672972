import { createRoute } from './create/route';
import { calculationRoute } from './id/route';
import { compareRoute } from './compare/route';

export const calculationsRoute = {
  path: 'calculations',
  children: [
    createRoute,
    calculationRoute,
    compareRoute,
  ],
}