import { useCallback, useState } from 'react';
import { FieldValues, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { CountryField } from '@/app/form/fields/common/country';
import { StateProvinceField, } from '@/app/form/fields/common/state-province';
import { Fieldset } from '@/shared/design-system/thermal-ceramics/components/form/fieldset';
import { NameHookFormField } from '@/app/form/fields/common/name';
import { AddressField } from '@/app/form/fields/common/address';
import { EmailField } from '@/app/form/fields/common/email';
import { JobField } from '@/app/form/fields/common/job';
import { CityField } from '@/app/form/fields/common/city';
import { PostalCodeField } from '@/app/form/fields/common/postal-code';
import { PhoneField } from '@/app/form/fields/common/phone';
import { HookFormField } from '@/shared/libs/hook-form';
import { DomainsField } from '@/app/form/fields/company/domains';
import { IndustryField } from '@/app/form/fields/company/industry';
import { useOnFieldChange } from '@/shared/libs/hook-form/hooks/on-field-change';
import { Button } from '@/shared/design-system/thermal-ceramics/components/button';
import { RegistrationData } from '@/app/api/morgan-thermal/registration/register';
import { ValidateSignUpResult } from '@/app/api/morgan-thermal/registration/validate-sign-up';
import { useEmailVerification } from '@/app/form/scenarios/email-verification';
import { Notification, NotificationType } from '@/shared/design-system/thermal-ceramics/components/notification';
import { Informative } from '@/app/api/morgan-thermal';
import { useFillWith } from '@/app/form/scenarios/fill-with';
import { createValidationSchema } from '@/app/form/scenarios/sign-up/validation';
import { createInitialValues } from '@/app/form/scenarios/sign-up/initial-values';

import classNames from './styles.module.css';
import { observer } from 'mobx-react-lite';
import { GlobalLocationContextProvider, useGlobalLocationSelector } from '@/app/form/fields/common/global-location';
import { RequiredHint } from '@/app/form/required-hint';

export type SignUpFormProps = {
  verifyEmail: (email: string) => Promise<ValidateSignUpResult>;
  submit: (data: RegistrationData) => Promise<Informative>;
};

const fillWithUserFieldsMapping: [string, string][] = [
  ['user.city', 'company.city'],
  ['user.zip', 'company.zip'],
  ['user.countryId', 'company.countryId'],
  ['user.stateProvinceId', 'company.stateProvinceId'],
  ['user.contactPhone', 'company.contactPhone'],
  ['user.secondaryPhone', 'company.secondaryPhone'],
  ['user.address', 'company.address'],
  ['user.additionalAddress', 'company.additionalAddress'],
];

export const SignUpForm = observer((props: SignUpFormProps) => {
  const { verifyEmail, submit } = props;

  const [hasCompanyFieldset, setHasCompanyFieldset] = useState(false);

  const userGlobalLocationSelector = useGlobalLocationSelector({
    countryIdName: 'user.countryId',
    stateProvinceIdName: 'user.stateProvinceId',
  });

  const companyGlobalLocationSelector = useGlobalLocationSelector({
    countryIdName: 'company.countryId',
    stateProvinceIdName: 'company.stateProvinceId',
  });

  const form = useForm({
    mode: 'onTouched',
    defaultValues: createInitialValues(),
    resolver: yupResolver(createValidationSchema({
      hasCompanyFieldset,
      hasUserStateProvinces: userGlobalLocationSelector.stateProvincesSelector.hasOptions,
      hasCompanyStateProvinces: companyGlobalLocationSelector.stateProvincesSelector.hasOptions,
    })),
  });

  const { watch, setError, control, getValues, handleSubmit } = form;
  const fillWithUser = watch('company.fillWithUser');

  const companyDomains = useFieldArray({ name: 'company.domains', control });

  const fillWith = useFillWith({ form, mapping: fillWithUserFieldsMapping });

  const emailVerification = useEmailVerification<ValidateSignUpResult>({
    form,
    name: 'user.email',
    verify: verifyEmail,
    onSuccess: (result) => {
      result.companyId === '0' ? setHasCompanyFieldset(result.companyId === '0') : setHasCompanyFieldset(false);
      companyDomains.replace([{value: result.domain, readOnly: true}]);
    },
    onError: (result) => {
      setHasCompanyFieldset(false);
      setError('user.email', { type: 'custom', message: result.errorMessage });
    },
  });

  useOnFieldChange(form, 'company.fillWithUser', fillWith.toggle);

  const handleValidSubmit = useCallback((values: FieldValues) => {
    const data: RegistrationData = {
      user: {
        ...values.user,
        preferMetric: true,
      },
    };

    if (emailVerification.result?.companyId === '0') {
      data.company = {
        ...values.company,
        domains: getValues('company.domains').map((domain) => domain.value),
      };
    }

    submit(data).catch(console.error);
  }, [companyDomains]);

  return (
    <FormProvider {...form}>
      <RequiredHint/>
      <form onSubmit={handleSubmit(handleValidSubmit)} noValidate>
        <Fieldset className={classNames.fieldset}>
          <Fieldset.Legend>User information</Fieldset.Legend>

          {emailVerification.result?.companyName && (
            <Notification className={classNames.notification} type={NotificationType.WARNING}>
              {emailVerification.result?.informationMessageHeader && <Notification.Title>{emailVerification.result?.informationMessageHeader}</Notification.Title>}
              {emailVerification.result?.informationMessage && <Notification.Body>{emailVerification.result?.informationMessage}</Notification.Body>}
            </Notification>
          )}

          <Fieldset.Grid>
            <GlobalLocationContextProvider globalLocationSelector={userGlobalLocationSelector}>
              <NameHookFormField name="user.name" required/>
              <AddressField name="user.additionalAddress" label="Additional Address"/>
              <EmailField name="user.email" label="Email" inProgress={emailVerification.inProgress} hint={emailVerification.inProgress ? 'Verifying email...' : ''} onBlur={emailVerification.onBlur} required/>
              <JobField name="user.job" label="Job Title"/>
              <PhoneField name="user.contactPhone" label="Contact Phone" required/>
              <PostalCodeField name="user.zip" label="Postal Code" required/>
              <PhoneField name="user.secondaryPhone" label="Secondary Phone"/>
              <CityField name="user.city" label="City" required/>
              <AddressField name="user.address" label="Company Address" required/>
              <CountryField name="user.countryId" countries={userGlobalLocationSelector.countriesSelector.countries} required/>
              {userGlobalLocationSelector.stateProvincesSelector.hasOptions && (
                <>
                  <div></div>
                  <StateProvinceField name="user.stateProvinceId" stateProvinces={userGlobalLocationSelector.stateProvincesSelector.stateProvinces} required/>
                </>
              )}
            </GlobalLocationContextProvider>
          </Fieldset.Grid>
        </Fieldset>

        {hasCompanyFieldset && (
          <Fieldset className={classNames.fieldset}>
            <Fieldset.Legend>Company information</Fieldset.Legend>

            <Notification className={classNames.notification} type={NotificationType.WARNING}>
              <Notification.Title>{emailVerification.result?.informationMessageHeader ?? 'Information'}</Notification.Title>
              {emailVerification.result?.informationMessage && <Notification.Body>{emailVerification.result?.informationMessage}</Notification.Body>}
            </Notification>

            <div className={classNames.fillWithUser}>
              <HookFormField.Checkbox name="company.fillWithUser" label="Fill with user details"/>
            </div>

            <Fieldset.Grid>
              <GlobalLocationContextProvider globalLocationSelector={companyGlobalLocationSelector}>
                <NameHookFormField name="company.name" label="Company Name" error={''} required/>
                <CityField name="company.city" disabled={fillWithUser} label="City" required/>
                <IndustryField name="company.industryId" label="Industry"/>
                <CountryField name="company.countryId" countries={companyGlobalLocationSelector.countriesSelector.countries} disabled={fillWithUser} required/>
                {companyGlobalLocationSelector.stateProvincesSelector.hasOptions ? (
                  <>
                    <PhoneField name="company.contactPhone" disabled={fillWithUser} label="Contact Phone" required/>
                    <StateProvinceField name="company.stateProvinceId" stateProvinces={companyGlobalLocationSelector.stateProvincesSelector.stateProvinces} disabled={fillWithUser} required/>
                    <PhoneField name="company.secondaryPhone" disabled={fillWithUser} label="Secondary Phone"/>
                    <PostalCodeField name="company.zip" disabled={fillWithUser} label="Postal Code" required/>
                    <div className={classNames.innerGrid}>
                      <AddressField name="company.address" disabled={fillWithUser} label="Company Address" required/>
                      <AddressField name="company.additionalAddress" disabled={fillWithUser} label="Additional Address"/>
                    </div>
                    <DomainsField name="company.domains" label="Company Email Domain" hint="Example entry: morganheatflow.com" required/>
                  </>
                ) : (
                  <>
                    <PhoneField name="company.contactPhone" disabled={fillWithUser} label="Contact Phone" required/>
                    <PostalCodeField name="company.zip" disabled={fillWithUser} label="Postal Code" required/>
                    <div className={classNames.innerGrid}>
                      <PhoneField name="company.secondaryPhone" disabled={fillWithUser} label="Secondary Phone"/>
                      <AddressField name="company.address" disabled={fillWithUser} label="Company Address" required/>
                      <AddressField name="company.additionalAddress" disabled={fillWithUser} label="Additional Address"/>
                    </div>
                    <DomainsField name="company.domains" label="Company Email Domain" hint="Example entry: morganheatflow.com" required/>
                  </>
                )}
              </GlobalLocationContextProvider>
            </Fieldset.Grid>
          </Fieldset>
        )}

        <Button type="submit">Submit</Button>
      </form>
    </FormProvider>
  );
});