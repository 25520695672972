import { addMethod, string, setLocale, AnyObject, Maybe, Flags, Schema, ValidationError } from 'yup';
import isEmail from 'validator/lib/isEmail';

/* eslint-disable-next-line no-useless-escape */
const URL_REGEXP = /^((https?):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

addMethod(string, 'numeric', function () {
  return this.matches(/^(\s*|\d+)$/, ({ path }) => `${path} should have digits only`);
});

addMethod(string, 'simpleEmail', function () {
  return this.test(
    'simpleEmail',
    (message) => `${message.path} must be a valid email`,
    (value) => value ? isEmail(value) : new ValidationError("Invalid value"),
  );
});

addMethod(string, 'uniqueArrayItem', function () {
  return this.test({
    name: 'uniqueArrayItem',
    message: (message) => `${message.path} must be unique`,
    test: (value, context) => {
      const namespace = context.path.split('.').reverse()[1].split('[')[0];
      const items = (context?.from ? context?.from[1]?.value[namespace] : []) as { value: string }[];
      return items ? items.length === new Set(items.map((item) => item.value))?.size : true;
    },
  })
});

addMethod(string, 'domain', function (customErrorMessage?: string) {
  return this.test({
    name: 'domain',
    message: (message) => customErrorMessage ?? `${message.path} must be a domain`,
    test: value => {
      if (typeof value !== 'string' || !value.length) return new ValidationError("Invalid value");

      return URL_REGEXP.test(value);
    }
  })
});

setLocale({
  mixed: {
    notType: function notType(_ref) {
      switch (_ref.type) {
        case 'number':
          if (!_ref.spec.optional && typeof _ref.originalValue === 'string' && !_ref.originalValue.length) return `${_ref.path} is a required field`;
          return `${_ref.path} must be a number - please also check decimal separator`;
        case 'date':
          if (!_ref.spec.optional && typeof _ref.originalValue === 'string' && !_ref.originalValue.length) return `${_ref.path} is a required field`;
          return `${_ref.path} must be a date`;
        default:
          return 'Wrong type';
      }
    }
  }
});

declare module 'yup' {
  interface StringSchema<TType extends Maybe<string> = string | undefined, TContext = AnyObject, TDefault = undefined, TFlags extends Flags = ''> extends Schema<TType, TContext, TDefault, TFlags> {
    numeric(): StringSchema<TType, TContext, TDefault, TFlags>;
    simpleEmail(): StringSchema<TType, TContext, TDefault, TFlags>;
    uniqueArrayItem(): StringSchema<TType, TContext, TDefault, TFlags>;
    domain(customErrorMessage?: string): StringSchema<TType, TContext, TDefault, TFlags>;
  }
}

export * from 'yup';
