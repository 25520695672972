import { HTMLAttributes, PropsWithChildren } from 'react';
import { clsx } from 'clsx';

import classNames from './styles.module.css';

const Root = ({ className, children, ...attributes }: PropsWithChildren<HTMLAttributes<HTMLFieldSetElement>>) => <fieldset className={clsx(classNames.fieldset, className)} {...attributes}>{children}</fieldset>;

const Legend = ({ className, children, ...attributes }: PropsWithChildren<HTMLAttributes<HTMLLegendElement>>) => <legend className={clsx(classNames.legend, className)} {...attributes}>{children}</legend>;

const Grid = ({ className, children, ...attributes }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => <div className={clsx(classNames.grid, className)} {...attributes}>{children}</div>;

  const Footer = ({ className, children, ...attributes }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => <div className={clsx(classNames.footer, className)} {...attributes}>{children}</div>;

export const Fieldset = Object.assign(Root, {
  Legend,
  Grid,
  Footer,
});