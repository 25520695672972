import { createColumnHelper } from '@tanstack/react-table';

import { isoToReadableString } from '@/shared/helpers/date';
import { UserListingItem, UserRole } from '@/app/api/morgan-thermal/user';
import { StatusPill } from '@/app/listing/users/status';
import { CompanyInformation, CompanyStateId } from '@/app/api/morgan-thermal/company';
import { UserOrderByOptions } from '@/app/api/morgan-thermal/user/search';

import classNames from './styles.module.css';

const columnHelper = createColumnHelper<UserListingItem>();

export const createColumns = (company: CompanyInformation) => [
  columnHelper.accessor('name', {
    header: 'Name',
    size: 310,
    meta: {
      orderBy: UserOrderByOptions.Name,
      detailsLink: (row) => `/companies/${company.id}/users/${row.original.id}`,
    },
    cell: (info) => {
      const roleId = info.row.original.roleId
      const isAdmin = roleId === UserRole.GlobalAdministrator || roleId === UserRole.CompanyAdmin || roleId === UserRole.GlobalCompanyManagerAdmin;

      return <>
        {info.renderValue()}
        {isAdmin && <span className={classNames.role}>{info.row.original.role}</span>}
      </>;
    },
  }),
  columnHelper.accessor('email', {
    header: 'Email',
    size: 285,
    cell: info => info.renderValue(),
    meta: {
      orderBy: UserOrderByOptions.Email,
    },
  }),
  columnHelper.accessor('isActive', {
    header: 'Status',
    size: 248,
    cell: info => <StatusPill user={info.row.original} isPendingCompany={company.companyStateId === CompanyStateId.PENDING}/>,
  }),
  columnHelper.accessor('createdDate', {
    header: 'Joined',
    size: 168,
    cell: info => isoToReadableString(info.getValue()),
    meta: {
      orderBy: UserOrderByOptions.CreatedDate,
    },
  }),
  columnHelper.accessor('lastLoginDate', {
    header: 'Last Login',
    size: 168,
    cell: info => isoToReadableString(info.getValue()),
    meta: {
      orderBy: UserOrderByOptions.LastLoginDate,
    },
  }),
];