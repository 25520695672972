import { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { app } from '@/app';
import { number } from '@/shared/libs/yup';
import { HookFormField, FieldSelectProps } from '@/shared/libs/hook-form';
import { Country } from '@/app/api/morgan-thermal/configuration/get-countries';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { createSelectOptions } from '@/shared/libs/hook-form/select';
import { MaterialCategory } from '@/app/api/morgan-thermal/configuration/get-material-categories';
import { FormField, FormFieldProps } from '@/shared/design-system/thermal-ceramics/components/form/field';
import { Select } from '@/shared/design-system/thermal-ceramics/components/form/select';

export const materialCategorySchema = number().typeError('${path} is a required field').nullable().label('Material Category');

export const useMaterialCategoriesSelector = () => {
  const [materialCategories] = useState(() => {
    const synchronizableValue = new SynchronizableValue<Country[]>([], app.configuration.getMaterialCategories);
    synchronizableValue.synchronize().catch(console.error);
    return synchronizableValue;
  });

  const options = useMemo(() => createSelectOptions(materialCategories.value), [createSelectOptions(materialCategories.value)])

  const hasOptions = useMemo(() => !!materialCategories.value.length, [materialCategories.value.length]);

  return {
    materialCategories,
    options,
    hasOptions,
  };
};

export type MaterialCategoryHookFormFieldProps = Omit<FieldSelectProps, 'options'> & {
  materialCategories: SynchronizableValue<MaterialCategory[]>;
};

export const MaterialCategoryHookFormField = observer((props: MaterialCategoryHookFormFieldProps) => {
  const { materialCategories } = props;

  return (
    <HookFormField.Select
      label="Material Category"
      options={createSelectOptions(materialCategories.value)}
      inProgress={materialCategories.isSynchronizationInProgress}
      {...props}
    />
  );
});

export type MaterialCategoryFieldProps = Omit<FormFieldProps, 'renderControl'> & {
  hasAllOption?: boolean,
  onChange?: (id: MaterialCategory['id']) => void,
};

export const MaterialCategoryField = observer((props: MaterialCategoryFieldProps) => {
  const { hasAllOption, onChange } = props;
  const [value, setValue] = useState<MaterialCategory>();
  const materialCategoriesSelector = useMaterialCategoriesSelector();

  return (
    <FormField
      label="Type"
      renderControl={(props) => (
        <Select<MaterialCategory>
          {...props}
          hasAllOption={hasAllOption}
          value={value}
          options={materialCategoriesSelector.materialCategories.value}
          createKey={(option) => option.id}
          createOptionLabel={(option) => option.name}
          inProgress={materialCategoriesSelector.materialCategories.isSynchronizationInProgress}
          onChange={(value) => {
            if (onChange) onChange(value?.id ?? null);
            setValue(value);
          }}
        />
      )}
      {...props}
    />
  );
});