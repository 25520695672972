import React, { PropsWithChildren, ReactElement } from 'react';
import { clsx } from 'clsx';
import { UnorderedList } from '@/shared/design-system/thermal-ceramics/components/list';
import { VisuallyHidden } from '@/shared/design-system/thermal-ceramics/components/visually-hidden';
import classNames from './styles.module.css';

export type SocialLink = PropsWithChildren<JSX.IntrinsicElements['a']> & {
  id: string,
  icon: ReactElement,
};

export type SocialLinksProps = JSX.IntrinsicElements['ul'] & {
  links: SocialLink[],
}

export function SocialLinks({ links, ...props }: SocialLinksProps) {
  return (
    <UnorderedList<SocialLink>
      {...props}
      className={clsx(classNames.socialLinks, props.className)}
      items={links}
      createKey={(link) => link.id}
      render={({ icon, children, ...props }) => (
        <a {...props} className={clsx(classNames.link, props.className)}>
          <span className={classNames.iconContainer} aria-hidden="true">{icon}</span>
          <VisuallyHidden>{children}</VisuallyHidden>
        </a>
      )}
    />
  );
}