import { useCallback } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { app } from '@/app';
import { Page } from '@/shared/design-system/thermal-ceramics/components/page';
import { Breadcrumbs } from '@/app/routing/breadcrumbs';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';
import { SaveUser } from '@/app/api/morgan-thermal/user/update';
import { SaveUserForm } from '@/app/form/scenarios/save-user';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';
import { showInformativeModal } from '@/app/components/informative-modal';
import { updateUser } from '@/app/api/morgan-thermal/user/update';

export const ProfileEditRouteView = observer(() => {
  const navigate = useNavigate();
  const handleSubmit = useCallback((data: SaveUser) => {
    return app.morganThermalAPI.fetch(updateUser(data))
      .then((response) => {
        app.session.user.synchronize({ noCache: true }).catch(console.error);
        navigate('/profile');
        showInformativeModal(response).catch();
      })
      .catch((response) => {
        showInformativeModal(response).catch();
      });
  }, [navigate]);

  return (
    <Page hasOperationsInProgress={app.session.user.isSynchronizationInProgress} loaded={!!app.session.user.value}>
      <Breadcrumbs/>

      <Heading as="h1">Edit Profile</Heading>

      {app.session.user.value && (
        <SaveUserForm
          user={app.session.user.value}
          companyId={app.session.user.value.companyId}
          submit={handleSubmit}
          footer={<>
            <Button modifier={ButtonModifier.TERTIARY} as={NavLink} to="/profile">Cancel</Button>
            <Button type="submit">Save</Button>
          </>}
        />
      )}
    </Page>
  );
});