import { number } from '@/shared/libs/yup';
import { HookFormField, FieldInputProps } from '@/shared/libs/hook-form';
import { ValueMeasurement } from '@/app/form/fields/material/helpers/value-measurement';

export const densitySchema = number().moreThan(0).label('Density');

export const DensityHookFormField = (props: FieldInputProps) => (
  <HookFormField.Input
    label="Density"
    extendProps={(props) => ({
      ...props,
      endSlot: (
        <ValueMeasurement
          imperial={<><sup>Lbs</sup>/<sub>Ft<sup>3</sup></sub></>}
          metric={<><sup>Kg</sup>/<sub>M<sup>3</sup></sub></>}
        />
      )
    })}
    {...props}
  />
);