import { TopLinks, TopLink } from '@/shared/design-system/morgan/components/footer/top-links';
import { Accreditations, Accreditation } from '@/shared/design-system/morgan/components/footer/accreditations';
import { ExternalLinks, ExternalLink } from '@/shared/design-system/morgan/components/footer/external-links';
import { SocialLinks, SocialLink } from '@/shared/design-system/morgan/components/footer/social-links';
import { AdditionalLinks, AdditionalLink } from '@/shared/design-system/morgan/components/footer/additional-links';
import { BackToTopButton } from '@/shared/design-system/morgan/components/back-to-top';
import { Container } from '@/shared/design-system/thermal-ceramics/components/container';
import classNames from './styles.module.css';

export type FooterProps = {
  topLinks?: TopLink[],
  accreditations?: Accreditation[],
  externalLinks?: ExternalLink[],
  socialLinks?: SocialLink[],
  additionalLinks?: AdditionalLink[],
};

export function Footer({ topLinks, accreditations, externalLinks, socialLinks, additionalLinks }: FooterProps) {
  return (
    <footer className={classNames.footer}>
      {topLinks && (
        <div className={classNames.topLinks}>
          <Container>
            <TopLinks links={topLinks}/>
          </Container>
        </div>
      )}

      {accreditations && (
        <div className={classNames.accreditations}>
          <Container>
            <Accreditations items={accreditations}/>
          </Container>
        </div>
      )}

      {(externalLinks || socialLinks) && (
        <div className={classNames.middleSection}>
          <Container>
            <div className={classNames.middleSectionGrid}>
              {externalLinks && <ExternalLinks className={classNames.externalLinks} links={externalLinks}/>}
              {socialLinks && <SocialLinks className={classNames.socialLinks} links={socialLinks}/>}
            </div>
          </Container>
        </div>
      )}

      <div className={classNames.bottom}>
        <Container>
          <div className={classNames.bottomWrapper}>
            <div className={classNames.bottomContent}>
              <p className={classNames.copyright}>
                <small className={classNames.copyrightParagraph}>© Copyright of Morgan Advanced Materials and its affiliates, 2023. All rights reserved.</small>
                <small className={classNames.copyrightParagraph}>Morgan Advanced Materials plc is registered in England<br/>Registered office at York House, Sheet Street, Windsor, SL4 1DD UK. Company number: 286773.</small>
              </p>

              {additionalLinks && <AdditionalLinks links={additionalLinks}/>}
            </div>

            <BackToTopButton className={classNames.backToTop}>Top</BackToTopButton>
          </div>
        </Container>
      </div>
    </footer>
  );
}