import { createEndpoint } from '@/shared/models/api';
import { Informative } from '@/app/api/morgan-thermal';
import { CompanyInformation } from '@/app/api/morgan-thermal/company';

export type RegistrationUserData = {
  name: string,
  email: string,
  preferMetric: boolean,
  job?: string,
  contactPhone: string,
  secondaryPhone?: string,
  address: string,
  additionalAddress?: string,
  city: string,
  zip: string,
  countryId: number,
  stateProvinceId?: number | null,
};

export type RegistrationCompanyData = {
  name: string,
  contactPhone: string,
  secondaryPhone?: string,
  address: string,
  additionalAddress?: string,
  city: string,
  zip: string,
  industryId: number | null,
  countryId: number,
  stateProvinceId?: number | null,
  domains: string[],
};

export type RegistrationData = {
  user: RegistrationUserData,
  company?: RegistrationCompanyData,
}

export const register = createEndpoint<Informative & { id: CompanyInformation['id'] }>(({ fetch }) => (data: RegistrationData) => {
  return fetch(`/Registration`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
});

export const registerCompany = createEndpoint<Informative & { id: CompanyInformation['id'] }>(({ fetch }) => (company: RegistrationCompanyData) => {
  return fetch(`/Registration`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ company }),
  });
});