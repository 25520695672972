import { app } from '@/app';
import { copyProject as copy, CopyProjectResponse } from '@/app/api/morgan-thermal/project/copy';
import { ActionType, runServerSideAction } from '@/app/actions';
import { ProjectInformation } from '@/app/api/morgan-thermal/project';

export const copyProject = (id: ProjectInformation['id'], name: ProjectInformation['project']) => runServerSideAction<CopyProjectResponse>({
  type: ActionType.POSITIVE,

  title: 'Save As',
  confirmationMessage: 'Duplicate project data and all calculations',
  approveLabel: 'Duplicate',

  successMessage: (result) => <>Your project has been saved as <a href={`/projects/${result.id}/edit`}>Copy Of {name}</a>.</>,

  runAction: () => app.morganThermalAPI.fetch(copy(id)),
});