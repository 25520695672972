import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Radio } from '@/shared/design-system/thermal-ceramics/components/form/radio';
import { number } from '@/shared/libs/yup';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { Casing } from '@/app/api/morgan-thermal/configuration/get-casings';
import { app } from '@/app';
import { HookFormField, FieldProps } from '@/shared/libs/hook-form';

export const casingSchema = number().typeError('${path} is a required field').label('Casing');

export type CasingsHookFormFieldProps = Omit<FieldProps, 'renderControl'>;

export const CasingsHookFormField = observer((props: CasingsHookFormFieldProps) => {
  const [casings] = useState(() => {
    const synchronizableValue = new SynchronizableValue<Casing[]>([], app.configuration.getCasings);
    synchronizableValue.synchronize();
    return synchronizableValue;
  });
  const { value } = casings;

  return (
    <HookFormField label="Casing" {...props} renderControl={(props) => {
      return value.map((casing) => <Radio key={casing.id} {...props} value={casing.id} checked={casing.id === Number.parseInt(props.value)}>{casing.name}</Radio>);
    }}/>
  );
});