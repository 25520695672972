import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';

import { Listing } from '@/app/listing';
import { Button } from '@/shared/design-system/thermal-ceramics/components/button';
import { Search } from '@/shared/design-system/thermal-ceramics/components/form/search';
import { ToggleButton } from '@/shared/design-system/thermal-ceramics/components/toggle-button/button';
import { ToggleButtonGroup } from '@/shared/design-system/thermal-ceramics/components/toggle-button/group';
import { ActionsDropdown, ActionsDropdownItemModifier } from '@/app/components/actions-dropdown';

import { createColumns } from './columns';
import { MaterialListingModel } from './model';
import { MaterialListingItem } from '@/app/api/morgan-thermal/material';
import { MeasurementSystemsToggle } from '@/app/measurement-systems/view';
import { app } from '@/app';
import { MaterialCategoryField } from '@/app/form/fields/material/category';
import { OriginOfManufacturerFormField } from '@/app/form/fields/material/origin-of-manufacturer';

import classNames from './styles.module.css';
import { MaterialOrderByOptions } from '@/app/api/morgan-thermal/material/search';

export const MaterialListing = observer(() => {
  const [{ listing, showDisabledFilter, materialCategoryIdFilter, regionIdFilter, materialNameFilter, enable, disable }] = useState(() => new MaterialListingModel());
  const { data, results, totalRecords, numberOfRecords, pageFilter, orderByFilter, orderByAscendingFilter } = listing;

  return (
    <div className={classNames.materialListing}>
      <Listing<MaterialListingItem, MaterialOrderByOptions>
        navigation={
          <Button className={classNames.addMaterial} as={NavLink} to={'/materials/create'}>Create material</Button>
        }
        header={
          <>
            <ToggleButtonGroup
              value={showDisabledFilter.value}
              onChange={showDisabledFilter.setValue}
            >
              <ToggleButton value={false}>Active</ToggleButton>
              <ToggleButton value={true}>Disabled</ToggleButton>
            </ToggleButtonGroup>
            <MaterialCategoryField
              hasAllOption={true}
              onChange={materialCategoryIdFilter.setValue}
            />
            <OriginOfManufacturerFormField
              hasAllOption={true}
              onChange={regionIdFilter.setValue}
            />
            <Search value={materialNameFilter.value} onSearch={materialNameFilter.setValue}/>
            <MeasurementSystemsToggle/>
          </>
        }
        renderActions={(item: MaterialListingItem) => (
          <ActionsDropdown>
            <ActionsDropdown.Item key="details" as={NavLink} to={`/materials/${item.id}`}>Details</ActionsDropdown.Item>
            {!item.isActive && <ActionsDropdown.Item key="approve" modifier={ActionsDropdownItemModifier.POSITIVE} onClick={() => enable(item.id)}>Enable</ActionsDropdown.Item>}
            {item.isActive && <ActionsDropdown.Item key="disable" modifier={ActionsDropdownItemModifier.DANGER} onClick={() => disable(item.id)}>Disable</ActionsDropdown.Item>}
          </ActionsDropdown>
        )}
        columns={createColumns(app.measurementSystems.currentSystem)}
        data={results}
        isInitialized={!!data}
        hasOperationsInProgress={data.isSynchronizationInProgress}
        totalRecords={totalRecords}
        numberOfRecords={numberOfRecords}
        page={pageFilter.value}
        onPageChange={pageFilter.setValue}

        orderBy={orderByFilter.value as MaterialOrderByOptions}
        orderByAscending={orderByAscendingFilter.value}
        onOrderByChange={orderByFilter.setValue}
        onOrderByAscendingChange={orderByAscendingFilter.setValue}
      />
    </div>
  );
});