import { createEndpoint } from '@/shared/models/api';
import { SearchFilters, SearchResponse } from '@/app/api/morgan-thermal';
import { ProjectListing } from '@/app/api/morgan-thermal/project';

export type ProjectSearchFilters = SearchFilters<{
  customerOrProjectName: string,
  showDisabled: boolean,
}>;

export type ProjectSearchResponse = SearchResponse<ProjectListing>;

export enum ProjectOrderByOptions {
  Project = 'Project',
  Customer = 'Customer',
  RevisionNumber = 'RevisionNumber',
  RevisionDate = 'RevisionDate',
  UserId = 'UserId'
}

export const searchProjects = createEndpoint<ProjectSearchResponse>(({ fetch }) => (filters: ProjectSearchFilters) => {
  return fetch('/Project/Search', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(filters),
  });
});