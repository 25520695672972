import { Avatar } from '@/shared/design-system/thermal-ceramics/components/avatar';
import classNames from './styles.module.css';

export type UserCaptionProps = {
  src: string,
  name: string,
};

export const UserCaption = (props: UserCaptionProps) => {
  const { src, name } = props;

  return (
    <div className={classNames.userCaption}>
      <Avatar src={src}/>
      <span className={classNames.name}>{name}</span>
    </div>
  );
};