import { observer } from 'mobx-react-lite';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { app } from '@/app';
import { PlusIcon, RemoveIcon } from '@/shared/design-system/thermal-ceramics/icons';
import { MeasurementSystem } from '@/app/measurement-systems/model';
import { ClickableArea } from '@/shared/design-system/thermal-ceramics/components/clickable-area';
import { VisuallyHidden } from '@/shared/design-system/thermal-ceramics/components/visually-hidden';
import { Heading } from '@/shared/design-system/thermal-ceramics/components/heading';
import { HookFormField } from '@/shared/libs/hook-form';

import classNames from './styles.module.css';

export type ThermalConductivityFieldsetProps = {
  name: string,
};

export const ThermalConductivityFieldset = observer(({ name }: ThermalConductivityFieldsetProps) => {
  const isImperialSystem = app.measurementSystems.currentSystem === MeasurementSystem.IMPERIAL;
  const fieldArray = useFieldArray({ name });
  const { resetField } = useFormContext();

  return (
    <div className={classNames.thermalConductivityFieldset}>
      <Heading className={classNames.thermalConductivityValuesHeading}>Thermal Conductivity Values</Heading>

      <div className={classNames.thermalConductivityLabels}>
        <div>Temperature ({isImperialSystem ? <>&deg;F</> : <>&deg;C</>})</div>
        <div>K Value ({isImperialSystem ? <>BTU</> : <><sup>W</sup>/<sub>M&#215;K</sub></>})</div>
      </div>
      {
        fieldArray.fields.map((field, index) => (
          <div key={field.id} className={classNames.thermalConductivityItem}>
            <div className={classNames.thermalConductivityInputs}>
              <HookFormField.Input
                name={`kValues.${index}.temperature`}
                type="number"
                decimalPlaces={0}
                required
              />
              <HookFormField.Input
                name={`kValues.${index}.kValue`}
                type="number"
                decimalPlaces={3}
                required
              />
            </div>
            {index >= 3 && (
              <ClickableArea type="button" className={classNames.thermalConductivityRemove} onClick={() => fieldArray.remove(index)}>
                <RemoveIcon aria-hidden="true"/>
                <VisuallyHidden>Remove</VisuallyHidden>
              </ClickableArea>
            )}
          </div>
        ))
      }
      <div>
        <ClickableArea
          type="button"
          className={classNames.thermalConductivityAdd} onClick={() => {
          fieldArray.append({
            temperature: undefined,
            kValue: undefined,
          }, { shouldFocus: false });
          setTimeout(() => {
            resetField(`${name}.${fieldArray.fields.length}`, {
              defaultValue: {
                temperature: undefined,
                kValue: undefined,
              },
            });
          });
        }}>
          <PlusIcon className={classNames.thermalConductivityAddIcon}/>
          <span className={classNames.thermalConductivityAddLabel}>Add another row</span>
        </ClickableArea>
      </div>
    </div>
  );
});