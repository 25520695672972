import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

export function useSyncRefs<TType>(
  ...refs: (
    | MutableRefObject<TType | null>
    | ((instance: TType) => void)
    | null
    | undefined
    )[]
) {
  let cache = useRef(refs);

  useEffect(() => {
    cache.current = refs;
  }, [refs]);

  return useCallback(
    (value: TType) => {
      for (let ref of cache.current) {
        if (ref == null) continue;

        if (typeof ref === 'function') {
          ref(value);
          continue;
        }

        ref.current = value;
      }
    },
    [cache]
  );
}