import { Field } from '@/shared/libs/hook-form/field';
import { FieldInput } from '@/shared/libs/hook-form/input';
import { FieldSelect } from '@/shared/libs/hook-form/select';
import { FieldCheckbox } from '@/shared/libs/hook-form/checkbox';
import { FieldArray } from '@/shared/libs/hook-form/array';
import { FieldRadio } from '@/shared/libs/hook-form/radio';
import { FieldTextarea } from '@/shared/libs/hook-form/textarea';

export const HookFormField = Object.assign(Field, {
  Input: FieldInput,
  Textarea: FieldTextarea,
  Select: FieldSelect,
  Checkbox: FieldCheckbox,
  Radio: FieldRadio,
  Array: FieldArray,
});

export type { FieldProps } from '@/shared/libs/hook-form/field';
export type { FieldInputProps } from '@/shared/libs/hook-form/input';
export type { FieldTextareaProps } from '@/shared/libs/hook-form/textarea';
export type { FieldRadioProps } from '@/shared/libs/hook-form/radio';
export type { FieldSelectProps, FieldSelectOption } from '@/shared/libs/hook-form/select';
export type { FieldCheckboxProps } from '@/shared/libs/hook-form/checkbox';
export type { FieldArrayProps } from '@/shared/libs/hook-form/array';