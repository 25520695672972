import { ReactNode } from 'react';
import { useController, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { FormField, FormFieldProps, FormFieldControlProps } from '@/shared/design-system/thermal-ceramics/components/form/field';

type RenderControlProps = FormFieldControlProps & ControllerRenderProps<FieldValues, string>;

export type FieldProps = Omit<FormFieldProps, 'renderControl'> & {
  name: string,
  renderControl: (props: RenderControlProps) => ReactNode,
  extendFieldProps?: (props: Omit<FormFieldProps, 'renderControl'>) => Omit<FormFieldProps, 'renderControl'>;
}

export const Field = (props: FieldProps) => {
  const { name, required = false, renderControl, extendFieldProps, ...formFieldProps } = props;
  const extendedFieldProps = extendFieldProps ? extendFieldProps(formFieldProps) : formFieldProps;

  const { field: reactHookFormProps, fieldState: { error } } = useController({
    name,
    rules: {
      required,
    },
  });

  const renderFormFieldControl = (formFieldProps: FormFieldControlProps) => (
    renderControl({ ...formFieldProps, ...reactHookFormProps, required })
  );

  return (
    <FormField
      {...extendedFieldProps}
      required={required}
      error={error?.message}
      renderControl={renderFormFieldControl}/>
  );
}