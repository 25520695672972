import { app } from '@/app';
import { enableProject as enable } from '@/app/api/morgan-thermal/project/enable';
import { ActionType, runServerSideAction } from '@/app/actions';
import { ProjectInformation } from '@/app/api/morgan-thermal/project';

export const enableProject = (id: ProjectInformation['id']) => runServerSideAction({
  type: ActionType.POSITIVE,

  title: 'Enable',
  confirmationMessage: 'Enable this project?',
  approveLabel: 'Yes, enable',

  runAction: () => app.morganThermalAPI.fetch(enable(id)),
});