import {
  FocusEvent,
  ReactElement,
  TextareaHTMLAttributes,
  useRef,
  useState,
  forwardRef,
} from 'react';

import { clsx } from 'clsx';
import { useSyncRefs } from '@/shared/hooks/use-sync-refs';
import { Spinner } from '@/shared/design-system/thermal-ceramics/components/spinner';

import classNames from './styles.module.css';

export type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  inProgress?: boolean,
  startSlot?: ReactElement,
  endSlot?: ReactElement,
};

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const { inProgress, startSlot, endSlot, disabled, onFocus, onBlur, className, ...attributes } = props;
  const textareaElement = useRef<HTMLTextAreaElement>(null);
  const mergedRefs = useSyncRefs(textareaElement, ref);
  const [hasFocus, setHasFocus] = useState(false);

  const handleFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
    if (typeof onFocus === 'function') onFocus(event);
    setHasFocus(true);
  }

  const handleBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    if (typeof onBlur === 'function') onBlur(event);
    setHasFocus(false);
  }

  const computedClassName = clsx(classNames.container, className, {
    [classNames.invalid]: attributes['aria-invalid'] === 'true' || attributes['aria-invalid'] === true,
    [classNames.disabled]: inProgress ? true : disabled,
    [classNames.hasFocus]: hasFocus,
  });

  return (
    <div className={computedClassName}>
      {startSlot}
      <textarea
        {...attributes}
        ref={mergedRefs}
        disabled={inProgress ? true : disabled}
        className={classNames.textarea}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {inProgress ? <Spinner className={classNames.spinner}/> : endSlot}
    </div>
  );
});