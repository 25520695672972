import { app } from '@/app';
import { sendHelpEmail as send, SendHelpEmailRequest } from '@/app/api/morgan-thermal/user/send-help-email';
import { ActionType, runServerSideAction } from '@/app/actions';
import { Modal } from '@/shared/design-system/thermal-ceramics/components/modal';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';
import { SendHelpEmailForm } from '@/app/form/scenarios/send-help-email';
import { VisuallyHidden } from '@/shared/design-system/thermal-ceramics/components/visually-hidden';

import classNames from './styles.module.css';

export const sendHelpEmail = () => runServerSideAction({
  type: ActionType.DEFAULT,

  renderConfirmation: (approve, decline) => {
    return (
      <>
        <Modal.Body>
          <Modal.Title>Need Help?</Modal.Title>
          <Modal.Description className={classNames.description}>Submit a help request using this form below.</Modal.Description>
          <Modal.Description className={classNames.description}>Your message will be sent to a Morgan Thermal admin and we will get back to you shortly!</Modal.Description>
          <div className={classNames.formWrap}>
            <SendHelpEmailForm
              submit={(requestBody) => approve(requestBody)}
              footer={
                <Modal.Footer className={classNames.footer}>
                  <Button type="button" modifier={ButtonModifier.SECONDARY} onClick={decline}>Cancel</Button>
                  <Button type="submit">Submit</Button>
                </Modal.Footer>
              }
            />
          </div>
        </Modal.Body>
      </>
    );
  },

  runAction: (requestBody: SendHelpEmailRequest) => app.morganThermalAPI.fetch(send(requestBody)),
});


export const SendHelpEmailButton = () => (
  <Button className={classNames.button} modifier={[ButtonModifier.CIRCLE, ButtonModifier.SECONDARY]} type="button" onClick={sendHelpEmail}>
    <span className={classNames.mark} aria-hidden="true">?</span>
    <VisuallyHidden>Send Help Email</VisuallyHidden>
  </Button>
);