import { Radio, RadioProps } from '@/shared/design-system/thermal-ceramics/components/form/radio';
import { Field, FieldProps } from '@/shared/libs/hook-form/field';

export type FieldRadioProps = Omit<FieldProps, 'renderControl'> & {
  extendProps?: (props: RadioProps) => RadioProps,
};

export const FieldRadio = (props: FieldRadioProps) => {
  const { extendProps, label, ...fieldProps } = props;

  return <Field {...fieldProps} renderControl={(checkboxProps) => {
    const extendedProps: RadioProps = extendProps ? extendProps(checkboxProps) : checkboxProps;
    return <Radio {...extendedProps}>{label}</Radio>;
  }}/>
}