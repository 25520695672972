import {
  showWarningModal
} from '@/shared/design-system/thermal-ceramics/components/modal/promise/confirmation';
import { Modal } from '@/shared/design-system/thermal-ceramics/components/modal';
import { Button, ButtonModifier } from '@/shared/design-system/thermal-ceramics/components/button';

export function confirmDomainRemove(email: string) {
  return showWarningModal({
    body: () => <>
      <Modal.Title>Deletion of domain</Modal.Title>
      <Modal.Description>All registered users with emails containing {email} will be disabled. Continue?</Modal.Description>
    </>,
    footer: (resolve, reject) => <>
      <Button modifier={ButtonModifier.SECONDARY} onClick={reject}>No</Button>
      <Button onClick={resolve}>Yes, disable</Button>
    </>
  });
}