import { PropsWithChildren } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { VisuallyHidden } from '@/shared/design-system/thermal-ceramics/components/visually-hidden';

import classNames from './styles.module.css';

export const FillLink = ({ children, ...navLinkProps }: PropsWithChildren<NavLinkProps>) => (
  <div className={classNames.fillLink}>
    <div aria-hidden="true">{children}</div>
    <NavLink className={classNames.link} {...navLinkProps}><VisuallyHidden>{children}</VisuallyHidden></NavLink>
  </div>
);