import { computed, makeObservable, observable, runInAction } from 'mobx';

import { AuthorizationProvider, AuthorizationProviderStatus } from '@/app/auth/providers/types';
import SynchronizableValue from '@/shared/models/synchronizable-value';
import { UserInformation, UserRole } from '@/app/api/morgan-thermal/user';
import { createLocalStorageCache, LocalStorageCache } from '@/shared/models/local-storage-cache';
import { MorganThermalAPI } from '@/app/api/morgan-thermal';
import { getCurrentUser } from '@/app/api/morgan-thermal/user/get-current';
import { setLastLoginDate } from '@/app/api/morgan-thermal/user/set-last-login-date';

export enum SessionStatus {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  LOGGING_IN = 'LOGGING_IN',
  LOGGING_OUT = 'LOGGING_OUT',
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
}

export type SessionOptions = {
  morganThermalAPI: MorganThermalAPI;
  authorizationProvider: AuthorizationProvider;
};

export type UserSynchronizationParameters = { noCache?: boolean };

export class Session {
  status: SessionStatus;
  localStorageCache: LocalStorageCache;
  morganThermalAPI: MorganThermalAPI;
  authorizationProvider: AuthorizationProvider;
  user: SynchronizableValue<UserInformation | null>;

  constructor({ morganThermalAPI, authorizationProvider }: SessionOptions) {
    makeObservable(this, {
      status: observable,

      authorizationProvider: observable,

      user: observable,

      isAdmin: computed,
      isRegularUser: computed,
      isGlobalAdmin: computed,
      isCompanyAdmin: computed,

      isUnauthenticated: computed,
      isLoggedIn: computed,
      isLoggedOut: computed,
      isLoggingIn: computed,
      isLoggingOut: computed,
    });

    this.status = SessionStatus.UNAUTHENTICATED;
    this.localStorageCache = createLocalStorageCache();
    this.morganThermalAPI = morganThermalAPI;
    this.authorizationProvider = authorizationProvider;
    this.user = new SynchronizableValue<UserInformation | null>(null, this.synchronizeUser.bind(this));

    this.authorizationProvider.on('statusChange', this.handleAuthorizationProviderStatusChange.bind(this));

    if (this.authorizationProvider.isLoggedIn) {
      this.morganThermalAPI.fetch(setLastLoginDate()).catch(console.error);
      this.user.synchronize().then(() => this.status = SessionStatus.LOGGED_IN);
    }
  }

  get isAdmin() {
    return this.user.value?.roleId === UserRole.CompanyAdmin || this.user.value?.roleId === UserRole.GlobalAdministrator;
  }

  get isRegularUser() {
    return this.user.value?.roleId === UserRole.User;
  }

  get isGlobalAdmin() {
    return this.user.value?.roleId === UserRole.GlobalAdministrator;
  }

  get isGlobalCompanyManagerAdmin() {
    return this.user.value?.roleId === UserRole.GlobalCompanyManagerAdmin;
  }

  get isCompanyAdmin() {
    return this.user.value?.roleId === UserRole.CompanyAdmin;
  }

  get isUnauthenticated() {
    return this.status === SessionStatus.UNAUTHENTICATED;
  }

  get isLoggedIn() {
    return this.status === SessionStatus.LOGGED_IN;
  }

  get isLoggedOut() {
    return this.status === SessionStatus.LOGGED_OUT;
  }

  get isLoggingIn() {
    return this.status === SessionStatus.LOGGING_IN;
  }

  get isLoggingOut() {
    return this.status === SessionStatus.LOGGING_OUT;
  }

  login() {
    return this.authorizationProvider.login();
  }

  logout() {
    return this.authorizationProvider.logout();
  }

  resetPassword() {
    return this.authorizationProvider.resetPassword();
  }

  getToken() {
    return this.authorizationProvider.getToken();
  }

  synchronizeUser({ noCache = false }: UserSynchronizationParameters = {}) {
    const authorizationProviderAccount = this.authorizationProvider.getAccount();

    if (!authorizationProviderAccount) return Promise.resolve(null);

    const email = authorizationProviderAccount.email;
    const cacheKey = `USER[${email}]`;
    const cachedUser = this.localStorageCache.get(cacheKey) as UserInformation;

    const fetchUser = () => this.morganThermalAPI.fetch(getCurrentUser())
      .then((user) => {
        if (!user) return null;

        const today = new Date();
        const cacheExpiresAt = new Date(today);
        cacheExpiresAt.setDate(cacheExpiresAt.getDate() + 24);

        this.localStorageCache.set(cacheKey, user, cacheExpiresAt);

        return user;
      })
      .catch(() => null);

    if (cachedUser && !noCache) {
      fetchUser().then((user) => this.user.set(user));
      return Promise.resolve<UserInformation>(cachedUser);
    }

    return fetchUser();
  }

  handleAuthorizationProviderStatusChange(status: AuthorizationProviderStatus) {
    runInAction(() => {
      switch (status) {
        case AuthorizationProviderStatus.UNAUTHENTICATED: {
          this.status = SessionStatus.UNAUTHENTICATED;
          this.user.reset(null);
          break;
        }

        case AuthorizationProviderStatus.LOGGING_IN: {
          this.status = SessionStatus.LOGGING_IN;
          break;
        }

        case AuthorizationProviderStatus.LOGGING_OUT: {
          this.status = SessionStatus.LOGGING_OUT;
          break;
        }

        case AuthorizationProviderStatus.LOGGED_IN: {
          this.morganThermalAPI.fetch(setLastLoginDate()).catch(console.error);
          this.user.synchronize({ noCache: true }).then(() => {
            runInAction(() => {
              this.status = SessionStatus.LOGGED_IN
            });
          });
          break;
        }

        case AuthorizationProviderStatus.LOGGED_OUT: {
          this.status = SessionStatus.LOGGED_OUT;
          this.user.reset(null);
          break;
        }
      }
    });
  }
}