import EventEmitter from 'eventemitter3';
import { action, makeObservable, observable } from 'mobx';
import debounce from 'lodash.debounce';

export type FilterOptions<TType> = {
  name: string,
  initialValue: TType,
  debouncedBy?: number,
};

export class Filter<TType> extends EventEmitter {
  name: string;
  value: TType;
  debouncedBy: number;

  emitDebounced: (event: string, ...args: any) => boolean | undefined;

  constructor(options: FilterOptions<TType>) {
    super();

    makeObservable(this, {
      name: observable,
      value: observable,

      setValue: action.bound,
      setValueWithoutSideEffects: action.bound,
    });

    this.name = options.name;
    this.value = options.initialValue;
    this.debouncedBy = options.debouncedBy ?? 0;

    this.emitDebounced = debounce(this.emit.bind(this), this.debouncedBy);
  }

  setValue(value: TType) {
    this.value = value;
    this.emitDebounced('change', value);
  }

  setValueWithoutSideEffects(value: TType) {
    this.value = value;
  }
}

export class Filters extends EventEmitter {
  filters: Map<string, Filter<any>>;

  constructor(filters: Filter<any>[]) {
    super();

    makeObservable(this, {
      filters: observable,

      get: action.bound,
      getCurrentState: action.bound,
    });

    this.filters = observable.map(new Map(filters.map((filter) => [filter.name, filter])));
    this.filters.forEach((filter) => {
      filter.on('change', () => this.handleFilterChange(filter));
    });
  }

  get(name: string) {
    return this.filters.get(name);
  }

  getCurrentState() {
    const currentState: Record<string, any> = {};

    this.filters.forEach((filter) => {
      currentState[filter.name] = filter.value;
    });

    return currentState;
  }

  handleFilterChange(filter: Filter<any>) {
    this.emit('change', {
      currentState: this.getCurrentState(),
      name: filter.name,
      value: filter.value,
    })
  }
  
  add(filter: Filter<any>) {
    this.filters.set(filter.name, filter);
    filter.on('change', () => this.handleFilterChange(filter));
  }
}